import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiResponse } from "src/app/models/apiResponse";
import { PageSizes } from "src/app/models/consts";
import { SearchRequest } from "src/app/models/searchRequest";
import { SearchResponse } from "src/app/models/searchResponse";
import { SwipeBoard } from "src/app/models/swipeBoard";
import { Video } from "src/app/models/video";
import { ApiService } from "src/app/services/api/api.service";
import { PaginatorService } from "src/app/services/shared/paginator.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";
import { StaticDataService } from "src/app/services/shared/static.data.service";

@Component({
  selector: "app-videos-affiliate-list",
  templateUrl: "./videos-affiliate-list.component.html",
  styleUrls: ["./videos-affiliate-list.component.scss"],
})
export class VideosAffiliateListComponent implements OnChanges, OnInit {
  pageSizes = PageSizes;
  @Input() resultsPerPage = this.pageSizes[3];
  @Input() currentPage = 1;
  totalPages = 0;
  pages: string[] = [];
  isAffiliate: boolean = true;
  videos!: SearchResponse<Video>;

  @Input() sortProp = "total";
  @Input() orderAsc = false;
  @Input() sortingIndex: number = 1;

  contentLoaded: boolean = false;

  @Input() keyword: string = "";
  @Input() affiliateNetworkIds: number[] = [];
  @Input() selectedOfferIds: number[] = [];
  @Input() categoryIds: number[] = [];
  @Input() countryId: number = 0;
  @Input() languageId: string = "";
  @Input() durationId: string = "";
  @Input() videoStatusId: string = "all";
  @Input() isMainDisplay: boolean = false;
  @Input() loadedParams: boolean = false;
  @Input() selectedInterval: string = "";

  @Output() videoModalOpened: EventEmitter<string> = new EventEmitter<string>();
  @Output() videoModalClosed: EventEmitter<void> = new EventEmitter();

  @Output() currentPageChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() resultsPerPageChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() sortingChanged: EventEmitter<object> = new EventEmitter<object>();

  durationMoreThan: number | undefined = undefined;
  durationLessThan: number | undefined = undefined;
  swipeBoards: SwipeBoard[] = [];
  isPayingUser: boolean = false;

  constructor(
    public apiService: ApiService,
    public popupService: PopupService,
    public stateService: StateService,
    public staticDataService: StaticDataService,
    public route: ActivatedRoute,
    public router: Router,
    public paginator: PaginatorService
  ) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.loadedParams) {
      this.contentLoaded = false;
      [this.durationMoreThan, this.durationLessThan] = this.ParseDuration(
        this.durationId
      );
      this.LoadAffiliateVideos();
    }
  }

  ngOnInit(): void {
    this.SubscribeToUser();
    this.LoadSwipeBoards();
  }

  public SubscribeToUser() {
    this.stateService.user$.subscribe((user) => {
      this.isPayingUser = this.stateService.IsPayingUserState(user);
    });
  }

  private async LoadAffiliateVideos() {
    if (
      this.sortProp === "last30Days" &&
      this.selectedInterval === "last90Days"
    )
      this.sortProp = "last90Days";
    else if (
      this.sortProp === "last90Days" &&
      this.selectedInterval === "last30Days"
    )
      this.sortProp = "last30Days";
    const result = await this.apiService.FullSearchAffiliateVideoAds(
      new SearchRequest(
        this.keyword,
        this.resultsPerPage,
        this.currentPage,
        this.sortProp,
        this.orderAsc,
        this.isAffiliate,
        this.affiliateNetworkIds,
        this.categoryIds,
        this.countryId,
        this.languageId,
        this.durationMoreThan,
        this.durationLessThan,
        this.videoStatusId,
        [],
        this.selectedOfferIds
      )
    );
    this.ProcessData(result);
  }

  private ProcessData(result: ApiResponse<SearchResponse<Video>> | undefined) {
    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) {
      this.contentLoaded = true;
      this.videos = result.data;
    }
  }

  public async OnPageSizeChange(value: string) {
    this.currentPage = 1;
    this.resultsPerPage = this.pageSizes.filter((ps) => ps == Number(value))[0];
    this.resultsPerPageChanged.emit(this.resultsPerPage);
  }

  public async ChangePage(page: number) {
    this.currentPage = page;
    this.currentPageChanged.emit(page);
  }

  public async SetSorting(index: number, sortProp: string, sortOrder: boolean) {
    this.currentPage = 1;
    this.sortingIndex = index;
    this.sortProp = sortProp;
    this.orderAsc = sortOrder;
    this.sortingChanged.emit({
      sortProp: this.sortProp,
      orderAsc: this.orderAsc,
      sortingIndex: this.sortingIndex,
    });
    // await this.LoadAffiliateVideos();
  }

  public OpenVideoInfo(video: Video) {
    this.VideoModalOpened(video.ytVideoId);
    this.popupService.openVideoInfoModal(
      video.ytVideoId,
      () => this.VideoModalClosed(),
      () => {},
      () => {
        video.isSwiped = true;
      },
      () => {
        video.isSwiped = false;
      }
    );
  }
  public VideoModalOpened(ytVideoId: string) {
    this.videoModalOpened.emit(ytVideoId);
  }

  public VideoModalClosed() {
    this.videoModalClosed.emit();
  }

  public OpenVideoBrand(video: Video, event: Event) {
    event.stopPropagation();
    const currentRoute = this.route.snapshot.routeConfig?.path;
    this.popupService.OpenBrandPreview(video.brandId, currentRoute);
  }

  public ParseDuration(duration: string) {
    return duration.split("-").map((str) => {
      return parseInt(str);
    });
  }

  public getBlurIfNeeded(index: number) {
    return {
      filter:
        this.currentPage > 1 && !this.isPayingUser ? "blur(7px)" : "blur(0)",
      "user-select":
        this.currentPage > 1 && !this.isPayingUser ? "none" : "auto",
      "pointer-events":
        this.currentPage > 1 && !this.isPayingUser ? "none" : "auto",
    };
  }

  public RemoveVideoSwipe(video: Video) {
    this.popupService.AskToConfirm(
      "Swipe deletion",
      "Are you sure that you want to delete selected brand?",
      async () => {
        await this.DeleteVideoSwipe(video.ytVideoId);
        video.isSwiped = false;
      }
    );
  }

  public async DeleteVideoSwipe(ytVideoId: string) {
    const result = await this.apiService.RemoveSwipedVideo(ytVideoId);

    if (result?.errorMessage) {
      this.popupService.Notify(result?.errorMessage);
      return;
    }
    this.popupService.Notify("Video successfuly removed from Swipes.");
  }

  public async VideoSwipeSaved(boardsIds: number[], video: Video) {
    const result = await this.apiService.SwipeVideo(video.ytVideoId, boardsIds);
    if (result?.errorMessage) {
      this.popupService.Notify(result?.errorMessage);
      return;
    }
    video.isSwiped = true;
    this.popupService.Notify("Video successfuly swiped.");
  }

  async LoadSwipeBoards() {
    const result = await this.apiService.GetSwipeBoards();
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data)
      this.swipeBoards = [
        {
          name: "All swipes",
          swipeBoardId: 0,
          isChecked: true,
          shareId: "",
          isShared: false,
          email: "",
          userName: "",
        },
        ...result.data,
      ];
  }

  public GetOfferLink(offerId: number) {
    return (
      window.location.origin +
      "/#/dashboard/entity-info?offerId=" +
      offerId +
      "&isAffiliate=true" +
      "&keyword="
    );
  }

  // public async OpenSelectedBrand(event: Event, brandId: number) {
  //   // event.stopImmediatePropagation();
  //   // if (this.isPayingUser) {
  //   //   await this.router.navigate(["/dashboard/entity-info"], {
  //   //     queryParams: { brandId: brandId, isAffiliate: true },
  //   //   });
  //   // } else this.popupService.AskForAccountUpgrade();
  // }
}
