import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModuleType } from "src/app/models/enums";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
})
export class SettingsComponent implements OnInit {
  selectedItem: string = "details";
  isInceptlyUser: boolean = false;
  constructor(private router: Router, public stateService: StateService) {}

  ngOnInit(): void {
    this.stateService.TabSelected("settings");
    this.stateService.ModuleSelected(ModuleType.Marketer);
    this.SubscribeToUser();
  }

  SwitchTab(item: string) {
    this.selectedItem = item;
  }

  public SubscribeToUser() {
    this.stateService.user$.subscribe((user) => {
      this.isInceptlyUser = this.stateService.IsInceptlyUser(user);
    });
  }
}
