import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModuleType } from "src/app/models/enums";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-module-select",
  templateUrl: "./module-select.component.html",
  styleUrls: ["./module-select.component.scss"],
})
export class ModuleSelectComponent implements OnInit {
  moduleType = ModuleType;
  @Input() isPayingUser: boolean = true;
  @Output() moduleTypeChanged: EventEmitter<ModuleType> =
    new EventEmitter<ModuleType>();
  type: ModuleType = ModuleType.Marketer;

  constructor(public stateService: StateService) {
    this.type = this.stateService.moduleType;
  }

  ngOnInit(): void {
    this.SubscribeToUser();
  }

  public MarketerSelected() {
    this.stateService.moduleType = ModuleType.Marketer;
    sessionStorage.setItem("moduleType", ModuleType.Marketer);
    this.moduleTypeChanged.emit(ModuleType.Marketer);
  }

  public AffiliateSelected() {
    this.stateService.moduleType = ModuleType.Affiliate;
    sessionStorage.setItem("moduleType", ModuleType.Affiliate);
    this.moduleTypeChanged.emit(ModuleType.Affiliate);
  }

  public SubscribeToUser() {
    this.stateService.selectedModuleChanged$.subscribe((value) => {
      if (value) {
        this.type = value as ModuleType;
        sessionStorage.setItem("moduleType", this.type);
      }
    });
  }
}
