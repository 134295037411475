import { Component, OnInit } from "@angular/core";
import { User } from "src/app/models/user";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-account-info",
  templateUrl: "./account-info.component.html",
})
export class AccountInfoComponent implements OnInit {
  user: User = new User();
  constructor(
    private apiService: ApiService,
    private popupService: PopupService,
    private stateService: StateService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.LoadUser();
  }

  public async LoadUser() {
    const result = await this.apiService.GetUser();

    if (result?.errorMessage) {
      this.popupService.Notify("Error loading user.");
      return;
    }

    if (result?.data) {
      this.user = result.data;
    }
  }

  public async SaveUser() {
    const result = await this.apiService.UpdateUser(this.user.name);

    if (result?.errorMessage) {
      this.popupService.Notify("Error on updating user.");
      return;
    }

    if (result?.data) {
      this.popupService.Notify("User successfuly updated");
    }
  }
}
