<ng-container *ngIf="contentLoaded">
    <table>
        <thead>
            <tr>
                <th class="pl-4">Thumbnail</th>
                <th>Title</th>
                <th>Offer</th>
                <th>Network</th>
                <th>
                    Total ad spend
                    <ng-container *ngIf="isPayingUser">
                        <mat-icon (click)="SetSorting(1, 'total', false)" *ngIf="sortingIndex != 1"
                            class="mr-2 order-icon" [svgIcon]="'arrow-up-down'">
                        </mat-icon>
                        <mat-icon (click)="SetSorting(1, 'total', true)" *ngIf="sortingIndex == 1 && !orderAsc"
                            class="mr-2 order-icon" [svgIcon]="'arrow-down-black'">
                        </mat-icon>
                        <mat-icon (click)="SetSorting(1, 'total', false)" *ngIf="sortingIndex == 1 && orderAsc"
                            class="mr-2 order-icon" [svgIcon]="'arrow-up-black'">
                        </mat-icon>
                    </ng-container>
                </th>
                <th *ngIf="selectedInterval === 'last30Days'">
                    Ad spend 30
                    <ng-container *ngIf="isPayingUser">
                        <mat-icon (click)="SetSorting(2, 'last30Days', false)" *ngIf="sortingIndex != 2"
                            class="mr-2 order-icon" [svgIcon]="'arrow-up-down'">
                        </mat-icon>
                        <mat-icon (click)="SetSorting(2, 'last30Days', true)" *ngIf="sortingIndex == 2 && !orderAsc"
                            class="mr-2 order-icon" [svgIcon]="'arrow-down-black'">
                        </mat-icon>
                        <mat-icon (click)="SetSorting(2, 'last30Days', false)" *ngIf="sortingIndex == 2 && orderAsc"
                            class="mr-2 order-icon" [svgIcon]="'arrow-up-black'">
                        </mat-icon>
                    </ng-container>
                </th>
                <th *ngIf="selectedInterval === 'last90Days'">
                    Ad spend 90
                    <ng-container *ngIf="isPayingUser">
                        <mat-icon (click)="SetSorting(2, 'last90Days', false)" *ngIf="sortingIndex != 2"
                            class="mr-2 order-icon" [svgIcon]="'arrow-up-down'">
                        </mat-icon>
                        <mat-icon (click)="SetSorting(2, 'last90Days', true)" *ngIf="sortingIndex == 2 && !orderAsc"
                            class="mr-2 order-icon" [svgIcon]="'arrow-down-black'">
                        </mat-icon>
                        <mat-icon (click)="SetSorting(2, 'last90Days', false)" *ngIf="sortingIndex == 2 && orderAsc"
                            class="mr-2 order-icon" [svgIcon]="'arrow-up-black'">
                        </mat-icon>
                    </ng-container>
                </th>
                <th>
                    Publish Date
                    <ng-container *ngIf="isPayingUser">
                        <mat-icon (click)="SetSorting(3, 'date', false)" *ngIf="sortingIndex != 3"
                            class="mr-2 order-icon" [svgIcon]="'arrow-up-down'">
                        </mat-icon>
                        <mat-icon (click)="SetSorting(3, 'date', true)" *ngIf="sortingIndex == 3 && !orderAsc"
                            class="mr-2 order-icon" [svgIcon]="'arrow-down-black'">
                        </mat-icon>
                        <mat-icon (click)="SetSorting(3, 'date', false)" *ngIf="sortingIndex == 3 && orderAsc"
                            class="mr-2 order-icon" [svgIcon]="'arrow-up-black'">
                        </mat-icon>
                    </ng-container>
                </th>
                <th>
                    Duration
                </th>
                <th *ngIf="isMainDisplay">Swipe</th>
            </tr>
        </thead>
        <tbody>
            <tr [ngStyle]="getBlurIfNeeded(i)" (click)="OpenVideoInfo(video)"
                *ngFor="let video of videos?.results, let i = index" class="list-option">
                <td class="logo-display" data-label="Frame">
                    <div class="ml-4 thumbnail icon-container">
                        <img style="width: 40px; height: 40px;" [src]='video.thumbnail' />
                    </div>
                </td>
                <td class="text-clipped" data-label="Title" dark>{{video.title}}
                </td>
                <td data-label="Offer name">
                    <a [href]="GetOfferLink(video.affiliateOfferId)"
                        class="inline-block content-end text-decoration-none">
                        <div class="tag tag2 inline-block">
                            <span class="clickable-link">
                                {{video.offerName}}
                            </span>
                        </div>
                    </a>
                </td>
                <td data-label="Network">{{this.staticDataService.affiliateNetworksMap.get(video.affiliateNetworkId) ||
                    'No'}}
                </td>
                <td [ngStyle]="{'filter': !isPayingUser ? 'blur(4px)' : 'blur(0)', 'user-select': !isPayingUser ? 'none' : 'auto'}"
                    data-label="Total ad spend">${{(video.totalSpend || 0) | valueFormat}}</td>
                <td *ngIf="selectedInterval === 'last30Days'"
                    [ngStyle]="{'filter': !isPayingUser ? 'blur(4px)' : 'blur(0)', 'user-select': !isPayingUser ? 'none' : 'auto'}"
                    data-label="Ad spend 30">${{(video.last30Days || 0) | valueFormat}}</td>
                <td *ngIf="selectedInterval === 'last90Days'"
                    [ngStyle]="{'filter': !isPayingUser ? 'blur(4px)' : 'blur(0)', 'user-select': !isPayingUser ? 'none' : 'auto'}"
                    data-label="Ad spend 90">${{(video.last90Days || 0) | valueFormat}}</td>
                <td data-label="Publish Date">{{video.publishedAt | dateFormat}}</td>
                <td data-label="Duration">{{video.duration | durationFormat}}</td>
                <td (click)="$event.stopPropagation()" data-label="Swipe" *ngIf="isMainDisplay">
                    <div style="max-width: 110px;">
                        <swipe-button [swipeBoards]="swipeBoards" (swipeRemoved)="RemoveVideoSwipe(video)"
                            class="w-auto mb-2" [isSwiped]="video.isSwiped"
                            (swipeSaved)="VideoSwipeSaved($event, video)"></swipe-button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <app-paginator [hasMore]="videos.hasMore" [resultsPerPage]="resultsPerPage" [currentPage]="currentPage"
        (pageSizeChanged)="OnPageSizeChange($event)" (pageNumChanged)="ChangePage($event)">
    </app-paginator>
</ng-container>
<div *ngIf="!contentLoaded" class="p-3">
    <app-skeleton-loader [linesNum]="resultsPerPage"></app-skeleton-loader>
</div>