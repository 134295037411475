export class SearchRequest {
  searchTerm: string = "";
  limit: number = 10;
  page: number = 1;
  sortProp: string = "";
  orderAsc: boolean = false;
  countryId: number = 0;

  //for affiliate search
  isAffiliate: boolean = false;
  affiliateNetworkIds: number[] = [];
  affiliateCountryId: number = 0;
  //
  categoryIds: number[] = [];
  softwareIds: number[] = [];
  offerIds: number[] = [];
  language: string = "";
  durationMoreThen: number | undefined = undefined;
  durationLessThen: number | undefined = undefined;
  showVideos: string = "";

  constructor(
    searchTerm: string,
    limit?: number,
    page?: number,
    sortProp?: string,
    orderAsc?: boolean,
    //affiliate mode props
    isAffiliate?: boolean,
    affiliateNetworkIds?: number[],
    categoryIds?: number[],
    countryId?: number,
    languageId?: string,
    durationMoreThen?: number,
    durationLessThen?: number,
    videoStatus?: string,
    softwareIds?: number[],
    offerIds?: number[]
  ) {
    this.searchTerm = searchTerm;
    this.limit = limit ? limit : 0;
    this.page = page ? page : 1;
    this.sortProp = sortProp ? sortProp : "";
    this.orderAsc = orderAsc ? orderAsc : false;

    this.isAffiliate = isAffiliate ? isAffiliate : false;
    this.affiliateNetworkIds = affiliateNetworkIds ? affiliateNetworkIds : [];
    this.categoryIds = categoryIds ? categoryIds : [];
    this.affiliateCountryId = countryId ? countryId : 0;
    this.countryId = countryId ? countryId : 0;
    this.language = languageId ? languageId : "";
    this.durationMoreThen = durationMoreThen ? durationMoreThen : undefined;
    this.durationLessThen = durationLessThen ? durationLessThen : undefined;
    this.showVideos = videoStatus ? videoStatus : "unlisted";
    this.softwareIds = softwareIds ? softwareIds : [];
    this.offerIds = offerIds ? offerIds : [];
  }
}
