<form [formGroup]="loginForm" class="container p-4">
    <div class="d-flex position-relative">
        <img class="mx-auto" style="height: 30px;" src="assets/icons/logo-black-notext.png" />
        <img *ngIf="canClose" (click)="CloseModal()" class="my-auto position-absolute"
            style="height: 24px; right: 5px; cursor: pointer;" src="assets/icons/x-close.png" />
    </div>

    <h3 class="title mb-1 mt-3">Log in to your account</h3>
    <p class="welcome-text">Welcome back! Please enter your details.</p>


    <p class="label mb-1">Email</p>
    <input formControlName="emailLogin" type="text" class="form-control input-element" placeholder="Enter your email" />
    <p class="label mb-1 mt-3">Password</p>
    <input formControlName="passwordLogin" type="password" #password class="form-control input-element"
        placeholder="Enter your password" />
    <div class="mt-2" id="recaptcha-container"></div>

    <div class="d-flex mt-3">
        <input type="checkbox" />
        <span class="label ml-2">Remember for 30 days</span>
        <span (click)="PasswordForgotten()" class="label ml-auto link">Forgot password?</span>
    </div>
    <button [disabled]="loginForm.invalid || !isRecaptchaVerified" (click)="Login()"
        class="btn sign-in-button mt-3">Sign in</button>
    <button [disabled]="!isRecaptchaVerified" (click)="LoginWithGoogle()"
        class="btn text-center google-button mt-2 w-100 d-flex align-items-center">
        <div [ngClass]="isRecaptchaVerified ? 'hide-spinner': ''" class="spinner mr-2"></div>
        <img style="height: 24px;" class="ml-2" src="assets/icons/google-icon.png" />
        <span class="ml-3">Sign in with Google</span>
    </button>
</form>