import { Component, OnInit } from "@angular/core";
import { StateService } from "./services/shared/state.service";
import { Observable, Subscription, debounceTime, filter } from "rxjs";
import { NavigationEnd, Router } from "@angular/router";
import { RudderAnalytics } from "@rudderstack/analytics-js";
import { environment } from "src/environments/environment";
import Avo, { AvoEnv } from "src/assets/Avo";
import { RudderStackService } from "./services/shared/rudderstack.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  userNotLoggedId: boolean = true;
  currentUser$!: Observable<any>;
  routerEventSubscription: Subscription | undefined;
  constructor(
    public stateService: StateService,
    public router: Router,
    public rudderStackService: RudderStackService
  ) {}

  ngOnInit(): void {
    this.SubscribeToUser();
    this.SubscribeToRouteChanges();
    // this.InitializeRudderStack();
  }

  public SubscribeToUser() {
    this.stateService.user$.subscribe((user) => {
      this.userNotLoggedId = !(user ? true : false);
    });
  }

  public SubscribeToRouteChanges() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        debounceTime(100) // Adjust time based on your needs
      )
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          //@ts-ignore
          window.dataLayer.push({
            event: "virtualPageview",
            pagePath: event.urlAfterRedirects,
          });
          this.rudderStackService.Page(event.url);
        }
      });
  }
}
