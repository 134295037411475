import { Injectable } from '@angular/core';
import html2canvas from 'html2canvas';




@Injectable({
    providedIn: 'root'
})
export class ScreenshotService {

    constructor() {
    }

    CaptureWithWatermark(element: HTMLElement): Promise<void> {
        return html2canvas(element).then(canvas => {
            const image = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = image;
            link.download = 'screenshot.png';
            link.click();
        });
    }
}
