import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SwipeBoard } from "src/app/models/swipeBoard";
import { PopupService } from "src/app/services/shared/popup.service";

@Component({
  selector: "app-edit-swipe-board",
  templateUrl: "./edit-board.component.html",
  styleUrls: ["./edit-board.component.scss"],
})
export class EditBoardComponent {
  constructor(public popupService: PopupService) {}
  // @Input() boardId: number = 0;
  // @Input() boardName: string = "";
  @Input() board!: SwipeBoard;
  @Output() editBoard: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleteBoard: EventEmitter<void> = new EventEmitter<void>();

  public EditBoard(boardName: string) {
    this.editBoard.emit(boardName);
  }

  public DeleteBoard() {
    this.popupService.AskToConfirm(
      "Board deletion",
      "Are you sure that you want to delete selected board?",
      () => this.deleteBoard.emit()
    );
  }
}
