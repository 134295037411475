import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { debounceTime } from "rxjs";
import { DEBOUNCE_TIME2 } from "src/app/models/consts";
import { ModuleType } from "src/app/models/enums";
import { Training } from "src/app/models/training";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { RudderStackService } from "src/app/services/shared/rudderstack.service";
import { StateService } from "src/app/services/shared/state.service";
import { StaticDataService } from "src/app/services/shared/static.data.service";

@Component({
  selector: "app-trainings",
  templateUrl: "./trainings.component.html",
  styleUrls: ["./trainings.component.scss"],
})
export class TrainingsComponent implements OnInit {
  keyword: string = "";
  allTrainings: any[] = [];
  filteredTrainings: any[] = [];
  isYt: boolean = false;
  id: string = "";
  selectedCategory: string = "all";
  isPayingUser: boolean = false;
  public searchControl: FormControl = new FormControl();
  constructor(
    private apiService: ApiService,
    public router: Router,
    public stateService: StateService,
    public staticDataService: StaticDataService,
    public popupService: PopupService,
    public rudderStackService: RudderStackService
  ) {}

  async ngOnInit(): Promise<void> {
    this.stateService.TabSelected("trainings");
    this.stateService.ModuleSelected(ModuleType.Marketer);
    this.SubscribeFilter();
    this.SubscribeToUser();
    await this.LoadTraingings();
  }

  public async LoadTraingings() {
    // const result = await this.apiService.GetTrainings();
    // if (result?.errorMessage) {
    //   this.popupService.Notify(result.errorMessage);
    //   return;
    // }
    // const trainings = result?.data.trainings;
    // const vexpower = result?.data.vexpower;
    // this.allTrainings = [...trainings, ...vexpower];
    // this.filteredTrainings = [...this.allTrainings];
    this.allTrainings = [...this.staticDataService.GetCategorizedTrainings()];
    this.filteredTrainings = [...this.allTrainings];
  }

  public SubscribeToUser() {
    this.stateService.user$.subscribe((user) => {
      this.isPayingUser = this.stateService.IsPayingUserState(user);
    });
  }

  public CategorySelected(category: string) {
    this.selectedCategory = category;
    this.FilterTrainings(category);
  }

  private SubscribeFilter() {
    this.searchControl.valueChanges
      .pipe(debounceTime(DEBOUNCE_TIME2))
      .subscribe(() => {
        this.keyword = this.searchControl.value;
        this.FilterTrainings(this.selectedCategory);
      });
  }

  public FilterTrainings(category: string) {
    this.filteredTrainings = this.allTrainings.reduce((acc, obj) => {
      const filteredValues = obj.trainings.filter(
        (t: any) =>
          (this.selectedCategory != "all" ? t.category === category : true) &&
          t.name.toLowerCase().includes(this.keyword.toLowerCase())
      );
      acc.push({ ...obj, trainings: filteredValues });
      return acc;
    }, []);
    return;
  }

  public OpenTrainingPage(training: Training) {
    if (
      training.category == "youtubeAds" ||
      training.category == "welcome-videos"
    ) {
      this.isYt = true;
      this.id = training.id;
    } else {
      this.isYt = false;
      this.id = training.slug;
    }
    this.rudderStackService.UserStartedTraining(
      this.stateService.user?.userId,
      this.id,
      training.name,
      training.category
    );
    this.router.navigate(["dashboard/trainings", this.id], {
      queryParams: {
        isYt: this.isYt,
      },
    });
  }

  public OpenSubscriptionModal() {
    this.popupService.AskForAccountUpgrade();
  }
}
