import { HttpClient } from "@angular/common/http";
import { Component, Inject, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ApiService } from "src/app/services/api/api.service";
import { StateService } from "src/app/services/shared/state.service";
import { PaddleService } from "src/app/services/paddle/paddle.service";
import { PlanInfo } from "src/app/models/planInfo";
import { Router } from "@angular/router";

@Component({
  selector: "app-subscription",
  templateUrl: "./subscription.component.html",
  styleUrls: ["../login/login.component.scss"],
})
export class SubscriptionComponent implements OnInit {
  subscriptionPlan: PlanInfo = new PlanInfo();

  constructor(
    public dialogRef: MatDialogRef<SubscriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public apiService: ApiService,
    public router: Router,
    private afAuth: AngularFireAuth,
    public state: StateService,
    public paddleService: PaddleService
  ) {
    this.subscriptionPlan = data.paymentPlan;
  }

  async ngOnInit() {
    this.afAuth.currentUser.then((user) => {
      if (user) {
        this.paddleService.OpenSubscriptionsCheckout(
          user?.uid,
          user.email,
          this.subscriptionPlan.planId,
          () => {
            this.CloseModal();
          }
        );
      }
    });
  }

  public CloseModal() {
    this.dialogRef.close();
  }
}
