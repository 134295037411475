<ng-container *ngIf="!isMultiple">
    <mat-form-field appearance="outline" class="no-line">
        <mat-select class="select" [placeholder]="placeholder" [(ngModel)]="selectedSwipeBoardId"
            disableOptionCentering>
            <mat-option (click)="BoardSelected(board)" [value]="board.swipeBoardId"
                *ngFor="let board of swipeBoards; let i = index">
                <div class="d-flex">
                    <p *ngIf="!board.isShared" class="mb-0 d-flex align-items-center word-break-all"> {{ board.name }}
                    </p>
                    <p *ngIf="board.isShared" style="color: rgb(62, 59, 126) !important;"
                        class="mb-0 d-flex align-items-center word-break-all">{{
                        board.userName ? board.userName: board.email
                        }}
                        - {{ board.name }}
                    </p>
                    <i (click)="$event.stopPropagation()" *ngIf="i>0" [matMenuTriggerFor]="menu"
                        #trigger="matMenuTrigger" style="font-size: 30px; margin-left: 10px; color: lightgray;"
                        class='bx bx-dots-horizontal-rounded show-dots'></i>
                    <mat-menu #menu="matMenu" xPosition="after">
                        <app-edit-swipe-board (deleteBoard)="DeleteBoard(board)"
                            (editBoard)="EditBoard($event,board.swipeBoardId)" [board]="board"></app-edit-swipe-board>
                    </mat-menu>
                </div>
            </mat-option>
        </mat-select>
    </mat-form-field>
</ng-container>
<ng-container *ngIf="isMultiple">
    <mat-form-field appearance="outline" class="no-line">
        <mat-select multiple class="select" [placeholder]="placeholder" [(ngModel)]="selectedSwipeBoardIds"
            disableOptionCentering>
            <ng-container *ngFor="let board of swipeBoards; let i = index">
                <mat-option *ngIf="!board.isShared" [disabled]="(i == 0) && isAdding" (click)="BoardsSelected(board,i)"
                    [value]="board.swipeBoardId">
                    <div class="d-flex">
                        <p class="mb-0 d-flex align-items-center"> {{ board.name }}</p>
                    </div>
                </mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>
</ng-container>