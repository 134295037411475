import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageSizes } from 'src/app/models/consts';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {

  pageSizes = PageSizes;

  @Input() currentPage: number = 1;
  @Input() hasMore: boolean = true;
  @Input() resultsPerPage: number = this.pageSizes[0];
  @Output() pageNumChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() pageSizeChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  public NewChunk(pageNum: number) {
    this.pageNumChanged.emit(pageNum);
  }

  async onPageSizeChange(value: string) {
    this.pageSizeChanged.emit(value);
  }

}
