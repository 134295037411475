import { Component, Inject, Input, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";
import { StaticDataService } from "src/app/services/shared/static.data.service";

@Component({
  selector: "app-question-modal",
  templateUrl: "./question-modal.component.html",
  styleUrls: ["../login/login.component.scss"],
})
export class QuestionModalComponent implements OnInit {
  @Input() displayType: string = "";
  canClose: boolean = true;
  constructor(
    private dialogRef: MatDialogRef<QuestionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private popupService: PopupService,
    private stateService: StateService,
    private staticDataService: StaticDataService
  ) {
    this.displayType = data.displayType;
    this.canClose = data.canClose;
  }

  ngOnInit(): void {}
  Register() {
    this.CloseModal();
    this.popupService.openRegisterModal(true);
  }

  Upgrade() {
    this.CloseModal();
    if (this.stateService.user?.isFreeTrialUsed)
      this.popupService.OpenPremiumSubscription(
        this.staticDataService.paymentPlans.monthly
      );
    else
      this.popupService.OpenPremiumSubscription(
        this.staticDataService.paymentPlans.monthly24HTrial
      );
  }

  CloseModal() {
    this.dialogRef.close();
  }
}
