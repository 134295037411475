<ng-container *ngIf="contentLoaded">
    <table>
        <thead>
            <tr>
                <th class="pl-4">Thumbnail</th>
                <th>Network</th>
                <th>Offer</th>
                <th>
                    Ad spend 30
                    <mat-icon (click)="SetSorting(2, 'last30Days', false)" *ngIf="sortingIndex != 2"
                        class="mr-2 order-icon" [svgIcon]="'arrow-up-down'">
                    </mat-icon>
                    <mat-icon (click)="SetSorting(2, 'last30Days', true)" *ngIf="sortingIndex == 2 && !orderAsc"
                        class="mr-2 order-icon" [svgIcon]="'arrow-down-black'">
                    </mat-icon>
                    <mat-icon (click)="SetSorting(2, 'last30Days', false)" *ngIf="sortingIndex == 2 && orderAsc"
                        class="mr-2 order-icon" [svgIcon]="'arrow-up-black'">
                    </mat-icon>
                </th>
                <th>
                    Total spend
                    <mat-icon (click)="SetSorting(1, 'total', false)" *ngIf="sortingIndex != 1" class="mr-2 order-icon"
                        [svgIcon]="'arrow-up-down'">
                    </mat-icon>
                    <mat-icon (click)="SetSorting(1, 'total', true)" *ngIf="sortingIndex == 1 && !orderAsc"
                        class="mr-2 order-icon" [svgIcon]="'arrow-down-black'">
                    </mat-icon>
                    <mat-icon (click)="SetSorting(1, 'total', false)" *ngIf="sortingIndex == 1 && orderAsc"
                        class="mr-2 order-icon" [svgIcon]="'arrow-up-black'">
                    </mat-icon>
                </th>
                <th>Description</th>
                <th *ngIf="isSwipes && !shareBoardId">Boards</th>
                <th *ngIf="isMainDisplay">Swipe</th>
            </tr>
        </thead>
        <tbody>
            <tr [ngStyle]="getBlurIfNeeded(i)" (click)="OpenOfferPage(offer.affiliateOfferId)"
                *ngFor="let offer of offers?.results; let i = index" class="list-option">
                <td class="logo-display" data-label="Frame">
                    <div class="pl-4 thumbnail">
                        <img style="width: 60px" (error)="HandleImageError(offer)" alt="image" *ngIf="offer.thumbnail"
                            [src]='offer.thumbnail' />
                        <img style="width: 60px" *ngIf="!offer.thumbnail" src="assets/icons/menu-16.svg" />
                    </div>
                </td>
                <td class="text-clipped" data-label="Network" dark>
                    {{staticDataService.affiliateNetworksMap.get(offer.affiliateNetworkId)}}</td>
                <td data-label="Offer">{{offer.name.slice(0,80)}}</td>
                <td [ngStyle]="{'filter': !isPayingUser ? 'blur(4px)' : 'blur(0)', 'user-select': !isPayingUser ? 'none' : 'auto'}"
                    data-label="Ad spend 30">${{offer.last30DaysSpend | valueFormat}}</td>
                <td [ngStyle]="{'filter': !isPayingUser ? 'blur(4px)' : 'blur(0)', 'user-select': !isPayingUser ? 'none' : 'auto'}"
                    data-label="Total spend">${{offer.totalSpend | valueFormat}}</td>
                <td data-label="Description">
                    <button *ngIf="offer.description" (click)="OpenDescriptionModal(offer.description, $event)"
                        style="font-size: 14px;" class="btn light-blue-button">See
                        description</button>
                </td>
                <td (click)="$event.stopPropagation()" *ngIf="isSwipes && !shareBoardId" data-label="Boards">
                    <app-swipe-board-select [isAdding]="false" (deleteSwipe)="DeleteOfferSwipe(offer.affiliateOfferId)"
                        (boardsSelected)="BoardsSelected($event,offer.affiliateOfferId)"
                        [selectedSwipeBoardIds]="offer.swipeBoardIds" [isMultiple]="true"
                        [swipeBoards]="swipeBoards"></app-swipe-board-select>
                </td>
                <td (click)="$event.stopPropagation()" data-label="Swipe" *ngIf="isMainDisplay">
                    <div style="max-width: 110px;">
                        <swipe-button [swipeBoards]="swipeBoards" (swipeRemoved)="RemoveOfferSwipe(offer)"
                            class="w-auto mb-2" [isSwiped]="offer.isSwiped"
                            (swipeSaved)="OfferSwipeSaved($event, offer)"></swipe-button>
                    </div>
                </td>

            </tr>
        </tbody>
    </table>
    <app-paginator [hasMore]="offers.hasMore" [resultsPerPage]="resultsPerPage" [currentPage]="currentPage"
        (pageSizeChanged)="OnPageSizeChange($event)" (pageNumChanged)="ChangePage($event)">
    </app-paginator>
</ng-container>
<div *ngIf="!contentLoaded" class="p-3">
    <app-skeleton-loader [linesNum]="resultsPerPage"></app-skeleton-loader>
</div>