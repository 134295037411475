import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { OverlayOptions, OverlayListenerOptions } from "primeng/api";
import { Category } from "src/app/models/category";
import { Software } from "src/app/models/software";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { StaticDataService } from "src/app/services/shared/static.data.service";

@Component({
  selector: "app-software-select",
  templateUrl: "./software-select.component.html",
  styleUrls: ["./software-select.component.scss"],
})
export class SoftwareSelectComponent implements OnInit {
  softwares: Software[] = [];
  nodes!: any[];
  selectedNodes: any;
  @Input() type: "single" | "multiple" | "checkbox" = "checkbox";
  @Input() placeholder: string = "-Select category-";

  @Input() selectedSoftwareIds: number[] = [];
  @Output() selectedSoftwareChanged: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();

  constructor(
    public apiService: ApiService,
    public popupService: PopupService,
    public staticDataService: StaticDataService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.type == "checkbox" && this.nodes?.length > 0)
      this.selectedNodes = this.FindObjectsByKeys(
        this.nodes,
        this.selectedSoftwareIds
      );
  }

  ngOnInit(): void {
    this.LoadSoftwares();
  }

  public TransformDataForTreeselect = (categories: Software[]): any[] => {
    return categories.map((item) => ({
      label: item.name,
      data: item.name,
      key: item.softwareId,
      ...(item.children && {
        children: this.TransformDataForTreeselect(item.children),
      }),
    }));
  };

  private async LoadSoftwares() {
    this.softwares = [...this.staticDataService.softwares];
    this.nodes = this.TransformDataForTreeselect(this.softwares);
    this.selectedNodes = this.FindObjectsByKeys(
      this.nodes,
      this.selectedSoftwareIds
    );
  }

  public NodeSelectionChanged() {
    const ids = this.selectedNodes.map((sn: { key: any }) => sn.key);
    this.selectedSoftwareChanged.emit(ids);
  }

  public FindObjectByKey = (
    softwares: any[],
    keyToFind: number
  ): any | null => {
    for (const software of softwares) {
      if (software.key === keyToFind) {
        return software;
      }
      if (software.children) {
        const foundInChildren = this.FindObjectByKey(
          software.children,
          keyToFind
        );
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }
    return null;
  };
  public FindObjectsByKeys = (softwares: number[], keysToFind: number[]) => {
    const foundObjects: any[] = [];

    const findRecursively = (software: any) => {
      if (keysToFind.includes(software.key)) {
        foundObjects.push(software);
      }
      if (software.children) {
        for (const child of software.children) {
          findRecursively(child);
        }
      }
    };

    for (const software of softwares) {
      findRecursively(software);
    }

    return foundObjects.length > 0 ? foundObjects : null;
  };

  getOverlayOptions(): OverlayOptions {
    return {
      listener: (event: Event, options?: OverlayListenerOptions) => {
        if (options?.type == "scroll") return false;
        return true;
      },
    };
  }
}
