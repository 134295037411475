<div class="container">
  <div [ngClass]="{'blur': isInputFocused, 'opacity-low': isInputFocused}">
    <section class="search-section">
      <div class="d-block mt-5 optional-width">
        <h1 class="section-header mb-0">Find Your Competitors’</h1>
        <h1 class="section-header">
          <span class="text-style mr-3">YouTube</span>Ads. Instantly.
        </h1>
        <div>
          <div class="d-flex align-items-start input-position">
            <div [ngClass]="{'hidden': isInputFocused}" class="d-block position-relative container-options">
              <div #inputContainer name="inputContainer" class="d-flex min-container">
                <div class="search-icon d-flex align-items-center px-3">
                  <i class="fa fa-search fa-lg"></i>
                </div>
                <input (focus)="InputFieldFocused($event)" [(ngModel)]="keyword" spellcheck="false"
                  class="input-field form-control pl-1 pr-0" autocomplete="off"
                  placeholder="Analyze any company or video ad..." type="text" />
              </div>
            </div>

            <img class="curved-arrow" />
          </div>
        </div>
        <div class="underline"></div>
      </div>
    </section>

    <section style="margin-top: 100px">
      <p class="small-title">Features</p>
      <h2 class="header">VidTao: YouTube Advertising Edge</h2>
      <p class="text-desc mt-3">
        Explore VidTao, the top YouTube ad intelligence platform. Leverage our
        powerful data, tools, and training to enhance your advertising success.
      </p>
    </section>

    <section class="mt-5">
      <div class="row">
        <div class="col-12 col-md-4">
          <img src="assets/icons/affiliates-icon.png" />
          <p class="subheader mt-3">Affiliates</p>
          <p class="text-desc-2">
            Introducing a powerful feature within VidTao that enables users to
            efficiently pinpoint YouTube video ads linked to affiliate offers . By
            doing so, VidTao users can swiftly distinguish between affiliate offer
            ads and regular direct response or branding ads. This innovative
            functionality not only identifies if an ad is connected to an
            affiliate offer but also determines the associated affiliate network
          </p>
        </div>
        <div class="col-12 col-md-4">
          <img src="assets/icons/agencies-icon.png" />
          <p class="subheader mt-3">Agencies</p>
          <p class="text-desc-2">
            VidTao's advanced search and filtering empower agencies to investigate
            niche-specific ads, offering valuable insights for new client
            campaigns. For new clients, analyze successful competitor ads with
            VidTao and create high-converting campaigns. Besides robust search
            functionality, VidTao provides extensive training to help agencies
            excel in YouTube ad creation, media buying, tracking and beyond.
          </p>
        </div>
        <div class="col-12 col-md-4">
          <img src="assets/icons/brands-icon.png" />
          <p class="subheader mt-3">Brands</p>
          <p class="text-desc-2">
            VidTao offers brands a unique opportunity to not only analyze their
            competitors' ads but also to learn from them. With our extensive
            database and niche-focused search capabilities, you can create
            effective ads for your own brand based on winning strategies. Benefit
            from our in-depth training resources that cover everything you need to
            succeed with YouTube ads, from ad creation to media buying and
            tracking.
          </p>
        </div>
      </div>
      <div class="underline-2"></div>
    </section>

    <section class="logos text-center">
      <p>
        Join over 95,000 users, from individuals to 80% of Fortune 500 companies,
        already experiencing growth with us.
      </p>
      <div>
        <img src="assets/icons/logo-1.png" />
        <img src="assets/icons/logo-2.png" />
        <img src="assets/icons/logo-3.png" />
        <img src="assets/icons/logo-4.png" />
        <img src="assets/icons/logo-5.png" />
      </div>
      <div class="underline-2"></div>
    </section>

    <section style="margin-top: 100px">
      <p class="small-title">Our data</p>
      <h2 class="header">Unparalleled YouTube Ad Intelligence</h2>
      <p class="text-desc mt-3">
        VidTao delivers the data and insights you need to dominate the YouTube
        advertising space. Harness the power of our robust database and
        cutting-edge technology to make data-driven decisions and outperform your
        competition.
      </p>
    </section>

    <section style="margin-top: 100px">
      <div class="row">
        <div class="col-12 col-md-6 d-flex">
          <div class="d-flex flex-column justify-content-center">
            <article class="left-border pt-4">
              <h3 class="article-header">Extensive Data Coverage</h3>
              <p class="article-text">
                Our comprehensive database includes 800k companies and 8 million
                ads, updated multiple times a week to ensure you have the most
                accurate and up-to-date information.
              </p>
            </article>
            <article class="left-border">
              <h3 class="article-header">Sophisticated Data Collection</h3>
              <p class="article-text">
                We employ advanced data collection techniques to gather YouTube ad
                intelligence, transforming it into actionable insights to help you
                succeed in your advertising campaigns.
              </p>
            </article>
            <article class="left-border">
              <h3 class="article-header">
                Tailored Insights for YouTube Success
              </h3>
              <p class="article-text">
                Find your competitors’ YouTube ads instantly: Access 12 million video ads and landing pages for
                unmatched ad intelligence.
              </p>
            </article>
          </div>
        </div>
        <div class="col-12 col-md-6 overflow-hidden">
          <img class="image-border" src="assets/icons/screen-mockup.png" />
        </div>
      </div>
      <div class="underline-2 mt-0"></div>
    </section>
    <section>
      <div class="row">
        <div class="col-12 col-md-4">
          <img src="assets/icons/icon-1.png" />
          <p class="header mt-4">
            VidTao: Ultimate Advertising Analysis and Strategy Platform
          </p>
        </div>

        <div class="col-12 col-md-4 d-flex flex-column">
          <section class="article-div mt-3">
            <img src="assets/icons/icon-2.png" />
            <h3 class="subheader mt-5">Exclusive Focus on YouTube Ads</h3>
            <p class="article-test">
              Ad coverage across countries User-friendly data presentation
              Informed market research
            </p>
          </section>
          <section class="article-div mt-3">
            <img src="assets/icons/icon-4.png" />
            <h3 class="subheader mt-5">Ad Performance & Formats</h3>
            <p class="article-test">
              Ad spend tracking over time Insights on various ad formats
            </p>
          </section>
        </div>

        <div class="col-12 col-md-4 d-flex flex-column">
          <section class="article-div mt-3">
            <img src="assets/icons/icon-3.png" />
            <h3 class="subheader mt-5">Competitive Analysis & Insights</h3>
            <p class="article-test">
              Competitive analysis Brand and sub-brand analysis Competitor ad
              spend tracking
            </p>
          </section>
          <section class="article-div mt-3">
            <img src="assets/icons/icon-5.png" />
            <h3 class="subheader mt-5">Landing Page & Affiliate Analysis</h3>
            <p class="article-test">
              Landing page insights Affiliate offer analysis
            </p>
          </section>
        </div>
      </div>
      <div class="underline-2"></div>
    </section>

    <section class="login-container d-flex mb-5">
      <div>
        <h2 class="login-header">Register</h2>
        <p class="login-text mb-0">
          Join 95k+ users, from individuals to 80% of Fortune 500, and attain
          success with YouTube ads.
        </p>
      </div>
      <button (click)="OpenSignupModal()" class="btn main-button l-button">
        Sign up
      </button>
    </section>
  </div>
  <div (click)="InputFieldUnfocused($event)" #overlayContainer class="overlay-container">
    <div #overlayInnerContainer class="d-flex min-container position-absolute">
      <div class="search-icon d-flex align-items-center px-3">
        <i class="fa fa-search fa-lg"></i>
      </div>

      <input #overlayInput (keyup.enter)="SubmitSearch()" [(ngModel)]="keyword" name="searchInput" spellcheck="false"
        class="input-field input-field1 form-control pl-1 pr-0" autocomplete="off"
        placeholder="Analyze any company or video ad..." type="text" />
      <div style="background: #fff;" class="search-btn-container d-flex align-items-center px-2">
        <button (click)="SubmitSearch()" class="search-button btn">Search</button>
      </div>
      <div class="optional-position d-flex" style="margin-top: 70px; font-size: 14px;">
        <div class="trending-icon-container my-auto">
          <i style="font-size: 20px; vertical-align: bottom;" class='bx bx-trending-up'></i>
        </div>
        <div class="ml-2 my-auto">
          Trending
        </div>
        <div (click)="PopularIconClicked('Mikkelsen Twins')" class="popular-option ml-2">
          Mikkelsen Twins
        </div>
        <div (click)="PopularIconClicked('ClickFunnels')" class="popular-option ml-2">
          ClickFunnels
        </div>
      </div>
      <app-search-suggestions [top]="'50'" [keyword]="keyword"></app-search-suggestions>
    </div>
  </div>
</div>
<app-footer></app-footer>