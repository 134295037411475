<button *ngIf="!isSwiped" [matMenuTriggerFor]="menu" #trigger="matMenuTrigger"
    class="option-button btn custom-btn w-100">
    <i style="font-size: 20px;" class='bx bx-star mr-2'></i>
    Swipe
    <mat-menu #menu="matMenu" xPosition="after">
        <choose-board-list-menu [swipeBoards]="swipeBoards" (saved)="SwipeSaved($event)"
            (closed)="trigger.closeMenu()"></choose-board-list-menu>
    </mat-menu>
</button>
<button (click)="Remove()" *ngIf="isSwiped" class="light-red option-button btn custom-btn w-100">
    <i style="font-size: 20px;" class='bx bx-star mr-2'></i>
    Remove
</button>