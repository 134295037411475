import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";
import { AffiliateOffer } from "src/app/models/affiliateOffer";
import { ApiResponse } from "src/app/models/apiResponse";
import { PageSizes } from "src/app/models/consts";
import { Offer } from "src/app/models/offer";
import { SearchRequest } from "src/app/models/searchRequest";
import { SearchResponse } from "src/app/models/searchResponse";
import { SwipeBoard } from "src/app/models/swipeBoard";
import { ApiService } from "src/app/services/api/api.service";
import { PaginatorService } from "src/app/services/shared/paginator.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";
import { StaticDataService } from "src/app/services/shared/static.data.service";

@Component({
  selector: "app-offers-list",
  templateUrl: "./offers-list.component.html",
  styleUrls: ["./offers-list.component.scss"],
})
export class OffersListComponent implements OnChanges, OnInit {
  pageSizes = PageSizes;
  @Input() resultsPerPage = this.pageSizes[3];
  @Input() currentPage = 1;

  @Input() categoryId: number = 0;
  @Input() affiliateNetworkIds: number[] = [];
  @Input() categoryIds: number[] = [];
  @Input() countryId: number = 0;
  @Input() languageId: string = "";
  @Input() keyword: string = "";
  @Input() type: string = "offers";
  isAffiliate: boolean = true;
  @Input() isSwipes: boolean = false;
  @Input() swipeBoards: SwipeBoard[] = [];
  @Input() selectedBoardId: number = 0;
  @Input() shareBoardId: string = "";
  @Output() boardsSelected: EventEmitter<object> = new EventEmitter<object>();
  @Input() isMainDisplay: boolean = false;
  @Input() loadedParams: boolean = false;
  @Input() displayAdditionalInfo: boolean = false;

  @Output() currentPageChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() resultsPerPageChanged: EventEmitter<number> =
    new EventEmitter<number>();

  sortProp = "total";
  orderAsc = false;
  sortingIndex: number = 1;

  contentLoaded: boolean = false;

  offers!: SearchResponse<AffiliateOffer>;
  isPayingUser: boolean = false;

  constructor(
    public apiService: ApiService,
    public stateService: StateService,
    public staticDataService: StaticDataService,
    public popupService: PopupService,
    public router: Router,
    public paginator: PaginatorService
  ) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.loadedParams) {
      this.contentLoaded = false;
      await this.LoadData();
    }
  }

  ngOnInit(): void {
    this.SubscribeToUser();
    this.LoadSwipeBoards();
  }

  public SubscribeToUser() {
    this.stateService.user$.subscribe((user) => {
      this.isPayingUser = this.stateService.IsPayingUserState(user);
    });
  }

  private LoadData() {
    if (this.type == "offers") this.LoadOffers();
    else if (this.type == "swiped-offers") this.LoadSwipedOffers();
  }

  private async LoadOffers() {
    const result = await this.apiService.FullSearchOffers(
      new SearchRequest(
        this.keyword,
        this.resultsPerPage,
        this.currentPage,
        this.sortProp,
        this.orderAsc,
        this.isAffiliate,
        this.affiliateNetworkIds,
        this.categoryIds,
        this.countryId,
        this.languageId
      )
    );

    this.ProcessData(result);
  }

  private async LoadSwipedOffers() {
    let result;
    if (!this.shareBoardId)
      result = await this.apiService.LoadOfferSwipes(
        this.keyword,
        this.currentPage,
        this.resultsPerPage,
        this.selectedBoardId
      );
    else
      result = await this.apiService.GetSharedBoardOffers(
        this.keyword,
        this.currentPage,
        this.resultsPerPage,
        this.shareBoardId
      );
    this.ProcessData(result);
  }

  private ProcessData(
    result: ApiResponse<SearchResponse<AffiliateOffer>> | undefined
  ) {
    if (result?.errorMessage) {
      this.popupService.Notify(result?.errorMessage);
    }

    if (result?.data?.results) {
      this.contentLoaded = true;
      this.offers = result.data;
    }
  }

  async SetSorting(index: number, sortProp: string, sortOrder: boolean) {
    this.currentPage = 1;
    this.sortingIndex = index;
    this.sortProp = sortProp;
    this.orderAsc = sortOrder;
    this.contentLoaded = false;
    await this.LoadData();
  }

  public async OnPageSizeChange(value: string) {
    this.currentPage = 1;
    this.resultsPerPage = this.pageSizes.filter((ps) => ps == Number(value))[0];
    if (this.type === "swiped-offers") await this.LoadData();
    this.resultsPerPageChanged.emit(this.resultsPerPage);
  }

  public async ChangePage(page: number) {
    this.currentPage = page;
    if (this.type === "swiped-offers") await this.LoadData();
    this.currentPageChanged.emit(page);
  }

  public OpenOfferPage(affiliateOfferId: number) {
    this.router.navigate(["dashboard/entity-info"], {
      queryParams: { offerId: affiliateOfferId },
    });
  }

  public getBlurIfNeeded(index: number) {
    return {
      filter:
        this.currentPage > 1 && !this.isPayingUser ? "blur(7px)" : "blur(0)",
      "user-select":
        this.currentPage > 1 && !this.isPayingUser ? "none" : "auto",
      "pointer-events":
        this.currentPage > 1 && !this.isPayingUser ? "none" : "auto",
    };
  }

  public OpenDescriptionModal(description: string, event: Event) {
    event.stopPropagation();
    this.popupService.OpenDescriptionModal(description);
  }

  public BoardsSelected(boardIds: number[], offerId: number) {
    this.boardsSelected.emit({ boardIds, offerId });
  }

  public async DeleteOfferSwipe(offerId: number) {
    const result = await this.apiService.RemoveSwipedOffer(offerId);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    this.popupService.Notify("Offer successfuly removed from Swipes.");
    await this.LoadData();
  }

  public RemoveOfferSwipe(offer: AffiliateOffer) {
    this.popupService.AskToConfirm(
      "Swipe deletion",
      "Are you sure that you want to delete selected brand?",
      async () => {
        await this.DeleteOfferSwipe(offer.affiliateOfferId);
        offer.isSwiped = false;
      }
    );
  }

  public async OfferSwipeSaved(boardsIds: number[], offer: AffiliateOffer) {
    const result = await this.apiService.SwipeOffer(
      offer.affiliateOfferId,
      boardsIds
    );
    if (result?.errorMessage) {
      this.popupService.Notify(result?.errorMessage);
      return;
    }
    offer.isSwiped = true;
    this.popupService.Notify("Video successfuly swiped.");
  }

  async LoadSwipeBoards() {
    const result = await this.apiService.GetSwipeBoards();
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data)
      this.swipeBoards = [
        {
          name: "All swipes",
          swipeBoardId: 0,
          isChecked: true,
          shareId: "",
          isShared: false,
          email: "",
          userName: "",
        },
        ...result.data,
      ];
  }

  public HandleImageError(offer: AffiliateOffer) {
    offer.thumbnail = "";
  }
}
