<section class="p-3">
    <h4 class="account-section-title">Personal info</h4>
    <p class="section-desc">Update your personal details here.</p>
    <div class="section-underline"></div>
    <div class="row mt-3 pb-3">
        <div class="col-12 col-md-4 col-lg-3 field-label">
            <p class="mb-0 pl-md-4 pb-1">Name</p>
        </div>
        <div class="col-12 col-md-6 mb-2 mb-md-0">
            <input [(ngModel)]="user.name" class="form-control" type="text">
        </div>
        <!-- <div class="col-12 col-md-4 col-lg-3">
            <input [(ngModel)]="lastName" class="form-control" type="text">
        </div> -->
    </div>
    <div class="section-underline"> </div>
    <div class="row mt-3 pb-3">
        <div class="col-12 col-md-4 col-lg-3 field-label">
            <p class="mb-0 pl-md-4 pb-1">Email address</p>
        </div>
        <div class="col-12 col-md-6">
            <div disabled class="d-flex align-items-center email-container disabled">
                <input disabled [(ngModel)]="user.email" style="border: none;" class="form-control" type="email">
            </div>
        </div>
    </div>
    <div class="section-underline"> </div>
    <div class="row mt-3 pb-3">
        <div class="col-12 col-md-4 col-lg-3 field-label">
            <p class="mb-0 pl-md-4 pb-1">Access token</p>
        </div>
        <div class="col-12 col-md-6">
            <div class="d-flex align-items-center email-container">
                <input disabled [(ngModel)]="user.accessToken" style="border: none;" class="form-control" type="email">
            </div>
        </div>
    </div>
    <div class="section-underline"> </div>
    <div class="d-flex justify-content-end">
        <button class="btn transparent-button mt-3 mr-3">Cancel</button>
        <button (click)="SaveUser()" class="btn light-blue-button  mt-3 mr-3">Save</button>
    </div>
</section>
<!-- <section class="p-3">
    <h4 class="account-section-title">Delete account</h4>
    <p class="delete-desc b-700 mb-1">Deleting your account is an action that can't be undone.</p>
    <p class="delete-desc">
        If you chose to remove your account VidTao will delete from its records all of your personal information,
        settings, swipes and any other data stored. Please proceed with caution, this is an action that can't be undone.
    </p>
    <div class="section-underline"> </div>
    <div class="d-flex justify-content-end mb-5">
        <button class="btn red-button mt-3 mr-3">Delete account</button>
    </div> 
</section> -->