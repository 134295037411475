<app-in-app-navbar (sidebarToggled)="SidebarToggled()"></app-in-app-navbar>
<div class="d-flex">
    <app-in-app-sidebar *ngIf="userExists"></app-in-app-sidebar>
    <div [ngClass]="userExists ? 'show-overflow': ''" class="page-content">
        <div style="flex-grow: 1;" [@routeAnimations]="PrepareRoute(outlet)">
            <router-outlet #outlet="outlet"></router-outlet>
        </div>
    </div>
</div>

<div *ngIf="showBanner" class="CTA-banner">
    <!-- <app-book-strategy-session (removeSessionBanner)="RemoveSessionBanner()"></app-book-strategy-session> -->
    <!-- <app-navigator-session (removeSessionBanner)="RemoveSessionBanner()"></app-navigator-session> -->
</div>