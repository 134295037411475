import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, from, lastValueFrom } from "rxjs";
import { StateService } from "src/app/services/shared/state.service";
import { TokenService } from "src/app/services/shared/token.service";
import { RudderStackService } from "src/app/services/shared/rudderstack.service";

@Injectable()
export class SessionKeyInterceptor implements HttpInterceptor {
  constructor(private rudderStackService: RudderStackService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handleIntercept(request, next));
  }

  private async handleIntercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    const sessionKey = await this.rudderStackService.sessionKey;
    if (sessionKey) {
      request = request.clone({
        headers: request.headers.set("Session-Key", sessionKey.toString()),
      });
    }

    return await lastValueFrom(next.handle(request));
  }
}
