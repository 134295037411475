import { Component, EventEmitter, Output } from "@angular/core";
import { RudderStackService } from "src/app/services/shared/rudderstack.service";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-navigator-session",
  templateUrl: "./navigator-session.component.html",
  styleUrl: "./navigator-session.component.scss",
})
export class NavigatorSessionComponent {
  @Output() removeSessionBanner: EventEmitter<void> = new EventEmitter();
  jumpPage: string =
    "https://docs.google.com/document/d/1ugIcdshZndMBBUWEsvYdhm2B4o7q3C0U-HoMUYQw5A4";
  constructor(
    public rudderstackService: RudderStackService,
    public stateService: StateService
  ) {}

  GetStarted() {
    this.rudderstackService.UserClickedOnNavigatorSession(
      this.stateService.user?.userId,
      this.stateService.user?.email
    );
    window.open(this.jumpPage, "_blank");
  }

  HideBanner() {
    this.removeSessionBanner.emit();
  }
}
