import { Spends } from "./spends";

export class Offer {
  public affiliateOfferId: number = 0;
  public affiliateNetworkId: number = 0;
  public name: string = "";
  public description: string = "";
  public categoryId: number = 0;
  public thumbnail: string = "";
  public spend: number = 0;
  public swiped: boolean = false;
}
