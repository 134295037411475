import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { environment } from 'src/environments/environment';
import firebase from 'firebase/compat/app';



@NgModule({
    imports: [
        // other imports
        AngularFireModule.initializeApp(environment.firebaseConfig.firebase),
        AngularFirestoreModule.enablePersistence(),
        AngularFireAuthModule,
        AngularFireStorageModule

    ],
    // other declarations and providers
})
export class FirebaseModule {
    constructor(@Optional() @SkipSelf() parentModule: FirebaseModule) {
        firebase.initializeApp(environment.firebaseConfig.firebase);
        if (parentModule) {
            throw new Error(
                'FirebaseModule is already loaded. Import it in the AppModule only'
            );
        }
        if (environment.firebaseConfig.recaptcha) {
            const appCheck = firebase.appCheck();
            appCheck.activate(environment.firebaseConfig.recaptcha, true);
        }
    }

    static forRoot(): ModuleWithProviders<FirebaseModule> {
        return {
            ngModule: FirebaseModule,
            providers: [

            ]
        };
    }
}