<mat-chip-listbox #chipList>
    <ng-container *ngIf="isAffiliate">
        <mat-chip-option [disabled]="id===0" *ngFor="let id of selectedNetworkIds" [removable]="true"
            [selectable]="false" (removed)="RemoveNetwork(id)">
            {{staticDataService.affiliateNetworksMap.get(id)}}
            <mat-icon matChipRemove class="ml-2 mat-chip-item" [svgIcon]="'x-close'"></mat-icon>
        </mat-chip-option>
    </ng-container>
    <ng-container>
        <mat-chip-option [disabled]="id===0" *ngFor="let id of selectedOfferIds" [removable]="true" [selectable]="false"
            (removed)="RemoveOffer(id)">
            {{staticDataService.offersMap.get(id)}}
            <mat-icon matChipRemove class="ml-2 mat-chip-item" [svgIcon]="'x-close'"></mat-icon>
        </mat-chip-option>
    </ng-container>
    <ng-container *ngIf="selectedCategoryIds.length > 4">
        <mat-chip-option [removable]="true" [selectable]="false" (removed)="RemoveAllCategories()">
            Remove categories filter
            <mat-icon matChipRemove class="ml-2 mat-chip-item" [svgIcon]="'x-close'"></mat-icon>
        </mat-chip-option>
    </ng-container>
    <ng-container *ngIf="selectedCategoryIds.length < 5">
        <mat-chip-option [disabled]="id==0" *ngFor="let id of selectedCategoryIds" [removable]="true"
            [selectable]="false" (removed)="RemoveCategory(id)">
            {{staticDataService.categoriesMap.get(id)?.name}}
            <mat-icon matChipRemove class="ml-2 mat-chip-item" [svgIcon]="'x-close'"></mat-icon>
        </mat-chip-option>

    </ng-container>
    <mat-chip-option [disabled]="selectedCountryId===0" *ngIf="!isAffiliate && !displayingAds" [removable]="true"
        [selectable]="false" (removed)="RemoveCountry(selectedCountryId)">
        {{staticDataService.countriesMap.get(selectedCountryId)?.name}}
        <mat-icon matChipRemove class="ml-2 mat-chip-item" [svgIcon]="'x-close'"></mat-icon>
    </mat-chip-option>

    <mat-chip-option [disabled]="selectedLanguageId===''" *ngIf="isAffiliate || displayingAds" [removable]="true"
        [selectable]="false" (removed)="RemoveLanguage(selectedLanguageId)">
        {{staticDataService.languagesMap.get(selectedLanguageId)}}
        <mat-icon matChipRemove class="ml-2 mat-chip-item" [svgIcon]="'x-close'"></mat-icon>
    </mat-chip-option>

    <mat-chip-option [disabled]="selectedDurationId===''" *ngIf="displayingAds" [removable]="true" [selectable]="false"
        (removed)="RemoveDuration(selectedDurationId)">
        {{staticDataService.durationsMap.get(selectedDurationId)}}
        <mat-icon matChipRemove class="ml-2 mat-chip-item" [svgIcon]="'x-close'"></mat-icon>
    </mat-chip-option>

    <mat-chip-option *ngFor="let id of selectedSoftwareIds" [removable]="true" [selectable]="false"
        (removed)="RemoveSoftware(id)">
        {{staticDataService.softwaresMap.get(id)?.name}}
        <mat-icon matChipRemove class="ml-2 mat-chip-item" [svgIcon]="'x-close'"></mat-icon>
    </mat-chip-option>
</mat-chip-listbox>