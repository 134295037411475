import { Component, OnInit } from "@angular/core";
import { GetPaymentPlans } from "src/app/models/getPaymentPlans";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";

@Component({
  selector: "app-pricing",
  templateUrl: "./pricing.component.html",
  styleUrls: ["./pricing.component.scss"],
})
export class PricingComponent implements OnInit {
  availablePlans: GetPaymentPlans = new GetPaymentPlans();
  planData: any = {
    sections: [
      {
        sectionName: "Basic Module",
        props: [
          {
            propName: "Ad, brand, advertiser search",
            free: true,
            monthly: true,
            yearly: true,
          },
          {
            propName: "View ad details",
            free: true,
            monthly: true,
            yearly: true,
          },
          {
            propName: "Basic brand and company info",
            free: true,
            monthly: true,
            yearly: true,
          },
        ],
      },
      {
        sectionName: "Affiliate advertiser module",
        props: [
          {
            propName: "Discover profitable offers",
            free: "100 affiliate searches",
            monthly: true,
            yearly: true,
          },
          {
            propName: "Analyze offer performance",
            free: "100 affiliate searches",
            monthly: true,
            yearly: true,
          },
          {
            propName: "View top ads and landing pages",
            free: "100 affiliate searches",
            monthly: true,
            yearly: true,
          },
          {
            propName: "Identify top affiliate advertisers",
            free: "100 affiliate searches",
            monthly: true,
            yearly: true,
          },
          {
            propName: "Filter by niche or network",
            free: "100 affiliate searches",
            monthly: true,
            yearly: true,
          },
        ],
      },
      {
        sectionName: "Category searches",
        props: [
          {
            propName: "Video ads, brands, advertisers",
            free: false,
            monthly: true,
            yearly: true,
          },
        ],
      },
      {
        sectionName: "Detailed brand/advertiser analysis",
        props: [
          {
            propName: "Ad spend and testing volume analysis",
            free: false,
            monthly: true,
            yearly: true,
          },
          {
            propName: "Visual charts and performance metrics",
            free: false,
            monthly: true,
            yearly: true,
          },
          {
            propName: "Tracking the life cycle of winning ads",
            free: false,
            monthly: true,
            yearly: true,
          },
        ],
      },
      // {
      //   sectionName: "Rankings page",
      //   props: [
      //     {
      //       propName: "Trending ads, brands, advertisers",
      //       free: false,
      //       monthly: true,
      //       yearly: true
      //     },
      //     {
      //       propName: "Filter by category, country, time",
      //       free: false,
      //       monthly: true,
      //       yearly: true
      //     }
      //   ]
      // },
      {
        sectionName: "Landing page and funnel insights",
        props: [
          {
            propName: "Analyze funnels from ad to lander",
            free: false,
            monthly: true,
            yearly: true,
          },
        ],
      },
      {
        sectionName: "Swipes page",
        props: [
          {
            propName: "Save favorite ads, brands, advertisers",
            free: false,
            monthly: true,
            yearly: true,
          },
        ],
      },
      {
        sectionName: "Exclusive ad training resources",
        props: [
          {
            propName: "YouTube media buying",
            free: false,
            monthly: true,
            yearly: true,
          },
          {
            propName: "YouTube strategy, copy, production",
            free: false,
            monthly: true,
            yearly: true,
          },
          {
            propName: "Marketing analytics and data science",
            free: false,
            monthly: true,
            yearly: true,
          },
          {
            propName: "Digital and growth marketing",
            free: false,
            monthly: true,
            yearly: true,
          },
        ],
      },
      {
        sectionName: "Private VidTao Discord Channel Access",
        props: [
          {
            propName: "Expert advice & feedback by Inceptly",
            free: false,
            monthly: false,
            yearly: true,
          },
          {
            propName: "Connect with YouTube ad pros",
            free: false,
            monthly: false,
            yearly: true,
          },
          {
            propName: "Explore cutting-edge, proven tactics",
            free: false,
            monthly: false,
            yearly: true,
          },
        ],
      },
      {
        sectionName: "Priority access and feature requests",
        props: [
          {
            propName: "Early access to new features",
            free: false,
            monthly: false,
            yearly: true,
          },
          {
            propName: "Prioritized feature requests",
            free: false,
            monthly: false,
            yearly: true,
          },
        ],
      },
      {
        sectionName: "Discount",
        props: [
          {
            propName: "Save 10% on all VidTao Live Events",
            free: false,
            monthly: false,
            yearly: true,
          },
          {
            propName: "Save vs. Monthly Premium",
            free: false,
            monthly: false,
            yearly: true,
          },
        ],
      },
    ],
  };

  constructor(
    public apiService: ApiService,
    public popupService: PopupService
  ) {}

  ngOnInit(): void {
    this.LoadPaymentPlans();
  }

  public async LoadPaymentPlans() {
    const result = await this.apiService.GetAvailablePlans();

    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);

    if (result?.data) this.availablePlans = result.data;
  }
}
