import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[focused]'
})
export class InputFocusDirective {
  @Input('focused') isFocused?: boolean;
  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngOnChanges() {
    if (this.isFocused) {
      this.elementRef.nativeElement.focus();
    } else {
      this.elementRef.nativeElement.blur();
    }
  }

}
