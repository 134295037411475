<ng-container *ngIf="!isInceptlyUser">
    <div #protectedDiv>
        <div (click)="togglePlay()" style="position: relative;width: 100%;padding-bottom: 56.25%;"
            id="player-container">
            <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; border-radius: 12px; pointer-events: none;"
                id="player" #player></div>
        </div>
        <div class="controls pr-2">
            <i (click)="togglePlay()" *ngIf="!isPlaying" class='bx bx-play play-icons'></i>
            <i (click)="togglePlay()" *ngIf="isPlaying" class='bx bx-pause play-icons'></i>
            <div class="seek-bar" (click)="seek($event)">
                <div class="progress" [style.width.%]="(currentTime / duration) * 100"></div>
            </div>
            <div class="time">{{ formatTime(currentTime) }} / {{ formatTime(duration) }}</div>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="isInceptlyUser">
    <div style="position: relative;width: 100%;padding-bottom: 56.25%;" id="player-container">
        <iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; border-radius: 12px;"
            class="w-100" height="315" [src]="videoSafeUrl" frameborder="0" allowfullscreen></iframe>
    </div>
</ng-container>