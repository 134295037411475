<mat-form-field appearance="outline" class="no-line">
    <mat-select [formControl]="languageControl" class="select" placeholder="-Select Language-"
        [(ngModel)]="selectedLanguageId" disableOptionCentering>
        <mat-option>
            <ngx-mat-select-search [disableInitialFocus]="true" noEntriesFoundLabel="No categories found"
                [formControl]="searchControl" [hideClearSearchButton]="true" placeholderLabel="Find language...">
            </ngx-mat-select-search>
        </mat-option>
        <mat-select-trigger style="display: flex;">
            {{selectedLanguageName}}
        </mat-select-trigger>
        <mat-option (click)="LanguageSelected()" [value]="language.code" *ngFor="let language of filteredLanguages">
            {{ language.name }}
        </mat-option>
    </mat-select>
</mat-form-field>