import { Component, Input } from '@angular/core';
import { Company } from 'src/app/models/company';

@Component({
  selector: 'app-offer-info',
  templateUrl: './offer-info.component.html',
  styleUrls: ['../../entity-info.component.scss']
})
export class OfferInfoComponent {
  @Input() networkName: string | undefined = '';
  @Input() activeAdsNum: number = 0;
  @Input() advertisersNum: number = 0;
  @Input() primaryLanguage: string = 'English'
}
