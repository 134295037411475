import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "valueFormat",
})
export class ValueFormatPipe implements PipeTransform {
  // transform(value: any): string {
  //   const number = Number(value);
  //   return number.toLocaleString(undefined, {
  //     minimumFractionDigits: 2,
  //     maximumFractionDigits: 2,
  //   });
  // }

  transform(value: any): string {
    const number = Number(value);

    if (isNaN(number)) {
      return value; // If not a number, return the value as is
    }

    if (number >= 1_000_000) {
      return (number / 1_000_000).toFixed(1).replace(/\.0$/, "") + "M";
    } else if (number >= 1_000) {
      return (number / 1_000).toFixed(1).replace(/\.0$/, "") + "k";
    } else {
      return number.toLocaleString();
    }
  }
}
