<div class="container-fluid p-5">
    <h3 class="section-heading mb-5">Affiliate landers</h3>
    <div class="container search-container">
        <div #inputContainer style="margin-top: 20px;" class="d-flex min-container position-relative">
            <div class="search-icon d-flex align-items-center px-3">
                <i class="fa fa-search fa-lg"></i>
            </div>
            <input #Input [(ngModel)]="keyword" name="searchInput" spellcheck="false"
                class="input-field input-field1 border-right form-control pl-1 pr-0" autocomplete="off"
                placeholder="Use search and filters to find affiliate landing pages..." type="text" />
        </div>
    </div>
</div>