<ng-container *ngIf="!(currentUser$ | async)"> <!--If user is not logged in -->
    <nav class="navbar navbar-light navbar-expand-lg bg-faded justify-content-center">
        <div class="container container-1400">
            <a href="/" class="navbar-brand d-flex mr-auto">
                <img class="logo" src="assets/icons/logo-black.png" />
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar3">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="navbar-collapse collapse w-100" id="collapsingNavbar3">
                <ul class="navbar-nav w-100 justify-content-start">
                    <li class="nav-item my-auto">
                        <a class="nav-link" (click)="OpenPricingPage()" href="javascript:void(0);">Pricing</a>
                    </li>
                    <li class="nav-item my-auto">
                        <a class="nav-link" (click)="OpenAboutPage()" href="javascript:void(0);">About</a>
                    </li>
                    <li class="nav-item my-auto">
                        <a class="nav-link" (click)="OpenContactPage()" href="javascript:void(0);">Contact</a>
                    </li>
                    <li class="nav-item my-auto">
                        <a class="nav-link" (click)="OpenBlogPage()" href="javascript:void(0);">Blog</a>
                    </li>
                </ul>
                <ul class="navbar-nav w-100 justify-content-end">
                    <ng-container *ngIf="!(currentUser$ | async)">
                        <li class="nav-item my-auto">
                            <a class="nav-link" (click)="OpenLoginModal()" href="javascript:void(0);">Log in</a>
                        </li>
                        <li class="nav-item my-auto center-content">
                            <button (click)="OpenSignUpModal()" style="font-weight: 600"
                                class="btn px-2 py-1 main-button">Get
                                Started</button>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>
    </nav>
</ng-container>