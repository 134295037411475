import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-range-select',
  templateUrl: './range-select.component.html',
  styleUrls: ['./range-select.component.scss']
})
export class RangeSelectComponent implements OnInit {
  @Input() sliderOneValue: number = 0;
  @Input() sliderTwoValue: number = 20;
  @Input() sliderMaxValue: number = 300;
  @Input() sliderMinValue: number = 0;
  @Input() selectName: string = "Duration";
  @Input() step = 15;
  @Input() rangeType: string = "time";
  @Input() minGap: number = 5;
  isOpened: boolean = false;
  constructor() {

  }

  ngOnInit(): void {
    this.SlideOne();
    this.SlideTwo();
  }

  ToggleDropdown() {
    this.isOpened = !this.isOpened;
  }

  SlideOne() {
    var sliderOne = document.getElementById("slider-1") as HTMLInputElement;
    if (this.sliderTwoValue - this.sliderOneValue <= this.minGap) {
      this.sliderOneValue = this.sliderTwoValue - this.minGap;
      sliderOne.value = this.sliderOneValue.toString();
    }
    this.FillColor();
  }

  SlideTwo() {
    var sliderTwo = document.getElementById("slider-2") as HTMLInputElement;
    if (this.sliderTwoValue - this.sliderOneValue <= this.minGap) {
      this.sliderTwoValue = this.sliderOneValue + this.minGap;
      sliderTwo.value = this.sliderTwoValue.toString();
    }
    this.FillColor();
  }

  FillColor() {
    var sliderTrack = document.querySelector(".slider-track") as HTMLDivElement;
    var percent1 = (this.sliderOneValue / this.sliderMaxValue) * 100;
    var percent2 = (this.sliderTwoValue / this.sliderMaxValue) * 100;
    if (sliderTrack) {
      sliderTrack.style.background = `linear-gradient(to right, #dadae5 ${percent1}% , #3264fe ${percent1}% , #3264fe ${percent2}%, #dadae5 ${percent2}%)`;
    }
  }

}



