export class Views {
    public today: number = 0;
    public last7Days: number = 0;
    public last14Days: number = 0;
    public last30Days: number = 0;
    public last60Days: number = 0;
    public last90Days: number = 0;
    public last180Days: number = 0;
    public last365Days: number = 0;
    public last720Days: number = 0;
}