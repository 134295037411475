<section class="p-3">
    <h4 class="account-section-title">VidTao Community</h4>
    <p class="section-desc">Only available for VidTao premium yearly plan.</p>
    <div class="section-underline"></div>
    <section class="p-3">
        <div class="d-block d-sm-flex">
            <img class="mr-3" style="height: 55px; width: 42px;" src="assets/icons/vidtao-community.png" />
            <div class="d-block">
                <p class="mb-1 newsletters-headers">
                    VidTao Community
                </p>
                <div class="d-block d-sm-flex">
                    <p class="mb-0 section-desc">
                        The VidTao Community is the place to be for everyone serious about achieving success on YouTube.
                        This lively ecosystem, made up of committed marketers, industry professionals, and the
                        responsive
                        Inceptly Agency team, offers a thriving interactive space where insights are exchanged, and all
                        aspects of YouTube advertising are explored collaboratively.
                    </p>
                    <button *ngIf="!userJoined" (click)="SubscribeToNewsletter()"
                        class="btn light-blue-button fit-button ml-0 mt-3 ml-sm-3 mt-sm-0">Subscribe</button>
                    <button *ngIf="userJoined" (click)="UnsubscribeFromNewsletter()"
                        class="btn light-blue-button fit-button ml-0 mt-3 ml-sm-3 mt-sm-0">Unsubscribe</button>
                </div>
            </div>
        </div>
        <div class="section-underline my-3"></div>
        <p class="mb-0 field-label">Any questions?</p>
        <p class="mb-0 section-desc">Please reach out to our friendly team via support&#64;vidtao.com</p>
    </section>
</section>
<!-- <section class="pl-3 pr-3 pb-3 pt-0">
    <h4 class="account-section-title">Newsletters</h4>
    <p class="section-desc">Subscribe to our newsletters by using the toggle to the right.</p>
    <div class="section-underline"></div>
    <div class="p-3">
        <div class="d-block d-sm-flex">
            <img class="mr-3" style="height: 45px; width: 40px;" src="assets/icons/vidtao-logo3.png" />
            <div class="d-block mr-3">
                <p class="mb-1 newsletters-headers">
                    VidTao
                </p>
                <p class="mb-0 section-desc">
                    Stay ahead with our expertly curated YouTube advertising newsletter. We dive into scriptwriting,
                    video production, media buying, and tracking. Uncover the secrets behind successful ads and discover
                    YouTube's latest features and AI tools. Subscribe now to revolutionize your marketing strategy!
                </p>
            </div>
            <label class="switch mr-3 mb-0 my-2 align-self-center ml-auto">
                <input type="checkbox">
                <span class="slider round"></span>
            </label>
        </div>
        <div class="section-underline mt-3"></div>
        <div class="d-block d-sm-flex mt-3">
            <img class="mr-3" style="height: 45px; width: 45px;" src="assets/icons/inceptly-logo.png" />
            <div class="d-block mr-3">
                <p class="mb-1 newsletters-headers">
                    Inceptly
                </p>
                <p class="mb-0 section-desc">
                    Join Inceptly for an insider's view into successful video advertising. With a track record of $950M
                    in client revenue, our insights - gleaned from serving 100+ customers - offer a deep dive into
                    creative strategies, tracking, and media buying.
                </p>
            </div>
            <label class="switch mr-3 mb-0 my-2 align-self-center ml-auto">
                <input type="checkbox">
                <span class="slider round"></span>
            </label>
        </div>
        <div class="section-underline mt-3"></div>
        <div class="d-block d-sm-flex mt-3">
            <img class="mr-3" style="height: 45px; width: 45px;" src="assets/icons/ad-snack.png" />
            <div class="d-block mr-3">
                <p class="mb-1 newsletters-headers">
                    Ad Snack
                </p>
                <p class="mb-0 section-desc">
                    Daily dose of ads for the performance marketer.
                </p>
            </div>
            <label class="switch mr-3 mb-0 my-2 align-self-center ml-auto">
                <input type="checkbox">
                <span class="slider round"></span>
            </label>
        </div>
        <div class="section-underline mt-3"></div>
        <div class="d-block d-sm-flex mt-3">
            <img class="mr-3" style="height: 45px; width: 45px;" src="assets/icons/ahora.png" />
            <div class="d-block mr-3">
                <p class="mb-1 newsletters-headers">
                    Ads da Ahora
                </p>
                <p class="mb-0 section-desc">
                    Quer saber o que tá dando certo na propaganda hoje em dia? Então cola com a gente no Ads Da Ahora!
                    Todo dia a gente manda um email com as propagandas mais top, escolhidas a dedo pela nossa equipe de
                    experts. Saia na frente e aprenda com os melhores no Ads Da Ahora. Não importa se você é um super
                    profissional ou se tá começando agora, nossa newsletter é a ferramenta perfeita pra te manter ligado
                    e inspirado. Vem com a gente e não perca as últimas novidades!
                </p>
            </div>
            <label class="switch mr-3 mb-0 my-2 align-self-center ml-auto">
                <input type="checkbox">
                <span class="slider round"></span>
            </label>
        </div>
        <div class="section-underline mt-3 mb-5"></div>
    </div>
</section> -->