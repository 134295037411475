import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatest, debounceTime, map } from "rxjs";
import { DEBOUNCE_TIME2 } from "src/app/models/consts";
import { ModuleType, SearchType } from "src/app/models/enums";
import { SwipedAd } from "src/app/models/swipedAd";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { SwipeBoard } from "src/app/models/swipeBoard";
import { RudderStackService } from "src/app/services/shared/rudderstack.service";

@Component({
  selector: "app-swipes",
  templateUrl: "./swipes.component.html",
  styleUrls: ["./swipes.component.scss"],
})
export class SwipesComponent implements OnInit {
  searchType = SearchType;
  isInputFocused: boolean = false;
  type: SearchType = SearchType.Ads;
  newBoardName: string = "";
  keyword: string = "";
  filterKeyword: string = "";
  isAffiliate: boolean = false;
  swipeBoards: SwipeBoard[] = [];
  filteredSwipeBoards: SwipeBoard[] = [];
  selectedBoardId: number = 0;
  public swipeControl: FormControl = new FormControl();

  sharedBoardId: string = "";
  sharedBoardUserEmail: string = "";
  isSharingMode: boolean = false;
  isUserLoggedIn: boolean = false;
  isPayingUser: boolean = false;
  constructor(
    public apiService: ApiService,
    public route: ActivatedRoute,
    public router: Router,
    public stateService: StateService,
    public popupService: PopupService,
    public rudderStackService: RudderStackService
  ) {}

  async ngOnInit(): Promise<void> {
    this.stateService.TabSelected("swipes");
    this.SubscribeFilter();
    this.SubscribeToRoute();
  }

  private SubscribeFilter() {
    this.swipeControl.valueChanges
      .pipe(debounceTime(DEBOUNCE_TIME2))
      .subscribe(() => {
        this.filterKeyword = this.keyword;
      });
  }

  async LoadSwipeBoards() {
    const result = await this.apiService.GetSwipeBoards();
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data)
      this.swipeBoards = [
        {
          name: "All swipes",
          swipeBoardId: 0,
          isChecked: false,
          shareId: "",
          email: "",
          isShared: false,
          userName: "",
        },
        ...result.data,
      ];
    this.filteredSwipeBoards = [...this.swipeBoards];
  }

  private SubscribeToRoute() {
    combineLatest([
      this.route.params,
      this.route.queryParams,
      this.stateService.user$,
    ])
      .pipe(
        map(async ([routeParams, queryParams, user]) => {
          if (user) {
            this.isPayingUser = this.stateService.IsPayingUserState(user);
            //we need to to hide "Accept board" button for users without accounts
            this.LoadSwipeBoards();
            this.isUserLoggedIn = true;
          }
          this.sharedBoardId = routeParams["id"];
          this.stateService.sharedUserType = queryParams["t"];
          if (this.sharedBoardId) {
            //if we are using someone's shared swipes link
            this.isSharingMode = true;
            this.GetSharedBoardUser();
            this.rudderStackService.SwipeBoardShared(
              this.stateService.user?.userId,
              this.sharedBoardId
            );
            if (!user) {
              this.stateService.verificationRedirectUrl = this.router.url;
              this.popupService.openRegisterModal(true);
            }
          }
        })
      )
      .subscribe();
  }

  public async AddBoard(boardName: string) {
    await this.UpsertSwipeBoard(boardName, undefined);
  }

  public async EditBoard(data: any) {
    await this.UpsertSwipeBoard(data.boardName, data.boardId);
  }

  public async DeleteBoard(swipeBoard: SwipeBoard) {
    this.DeleteSwipeBoard(swipeBoard);
  }

  async DeleteSwipeBoard(swipeBoard: SwipeBoard) {
    let result;
    if (!swipeBoard.isShared)
      result = await this.apiService.DeleteSwipeBoard(swipeBoard.swipeBoardId);
    else
      result = await this.apiService.UnsubscribeFromSharedBoard(
        swipeBoard.swipeBoardId
      );
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data) {
      this.popupService.Notify("Swipe board deleted.");
      await this.LoadSwipeBoards();
    }
  }

  async UpsertSwipeBoard(swipeBoardName: string, swipeBoardId?: number) {
    const result = await this.apiService.UpsertSwipeBoard(
      swipeBoardName,
      swipeBoardId
    );
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data) {
      this.popupService.Notify("Swipe board successfully saved.");
      await this.LoadSwipeBoards();
    }
  }

  public BoardSelected(board: SwipeBoard) {
    this.sharedBoardId = board.isShared ? board.shareId : "";
    this.selectedBoardId = board.swipeBoardId;
  }

  public async UpdateVideoSwipe(data: any) {
    await this.UpsertVideoSwipe(data.boardIds, data.ytVideoId);
    await this.LoadSwipeBoards();
  }

  public async UpdateCompanySwipe(data: any) {
    await this.UpsertCompanySwipe(data.boardIds, data.companyId);
    await this.LoadSwipeBoards();
  }

  public async UpdateBrandSwipe(data: any) {
    await this.UpsertBrandSwipe(data.boardIds, data.brandId);
    await this.LoadSwipeBoards();
  }

  public async UpdateOfferSwipe(data: any) {
    await this.UpsertOfferSwipe(data.boardIds, data.offerId);
    await this.LoadSwipeBoards();
  }

  public async UpsertVideoSwipe(boardIds: number[], ytVideoId: string) {
    const result = await this.apiService.SwipeVideo(ytVideoId, boardIds);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }
    this.popupService.Notify("Swipe successfuly updated.");
  }

  public async UpsertCompanySwipe(boardIds: number[], companyId: number) {
    const result = await this.apiService.SwipeCompany(companyId, boardIds);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }
    this.popupService.Notify("Swipe successfuly updated.");
  }

  public async UpsertBrandSwipe(boardIds: number[], brandId: number) {
    const result = await this.apiService.SwipeBrand(brandId, boardIds);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }
    this.popupService.Notify("Swipe successfuly updated.");
  }

  public async UpsertOfferSwipe(boardIds: number[], offerId: number) {
    const result = await this.apiService.SwipeOffer(offerId, boardIds);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }
    this.popupService.Notify("Swipe successfuly updated.");
  }

  public get getCurrentUrl() {
    const userParam = this.isPayingUser ? "sb-premium" : "sb-free";
    // return (
    //   window.location.origin +
    //   `/#/shared/
    //   ${
    //     this.swipeBoards.filter(
    //       (sb) => sb.swipeBoardId == this.selectedBoardId
    //     )[0]?.shareId
    //   }
    //   /swipes?isAffiliate=
    //   ${this.isAffiliate}&t=${userParam}`
    // );
    return (
      window.location.origin +
      `/#/shared/${encodeURIComponent(
        this.swipeBoards.filter(
          (sb) => sb.swipeBoardId == this.selectedBoardId
        )[0]?.shareId
      )}/swipes?isAffiliate=${encodeURIComponent(
        this.isAffiliate
      )}&t=${encodeURIComponent(userParam)}`
    );
  }

  public async GetSharedBoardUser() {
    const result = await this.apiService.GetSharedBoardUser(this.sharedBoardId);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) this.sharedBoardUserEmail = result.data.email;
  }

  public async AcceptBoard() {
    const result = await this.apiService.AcceptSharedBoard(this.sharedBoardId);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    this.popupService.Notify("Board successfully added to collection.");

    await this.router.navigate(["/dashboard/swipes"]);
  }
}
