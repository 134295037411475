<mat-form-field appearance="outline" class="no-line">
    <mat-select class="select" placeholder="Status" (ngModelChange)="StatusSelected($event)"
        [(ngModel)]="selectedStatus" disableOptionCentering>
        <mat-option value="all">
            All
        </mat-option>
        <mat-option value="public">
            Public
        </mat-option>
        <mat-option value="unlisted">
            Unlisted
        </mat-option>
    </mat-select>
</mat-form-field>