<!-- <app-entity-info (dialogClosed)="CloseModal($event)" [previewCompanyId]="companyId"
    [previewBrandId]="brandId"></app-entity-info> -->

<ng-container *ngIf="displayMode === 'company'">
    <app-preview-company-info [isPayingUser]="isPayingUser" [isAffiliate]="isAffiliate" [currentRoute]="currentRoute"
        (dialogClosed)="CloseModal($event)" [companyId]="companyId">
    </app-preview-company-info>
</ng-container>

<ng-container *ngIf="displayMode === 'brand'">
    <app-preview-brand-info [isPayingUser]="isPayingUser" [currentRoute]="currentRoute"
        (dialogClosed)="CloseModal($event)" [brandId]="brandId">
    </app-preview-brand-info>
</ng-container>