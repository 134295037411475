<ng-container *ngIf="isYt; else vexpower">
    <div style="margin-top: 200px; " class="container container-1400">
        <div class="row">
            <div class="col-12 col-lg-8 order-2 order-lg-1 mt-3 mt-lg-0">
                <div class="iframe-div">
                    <iframe class="frame-player" [src]="selectedVideo.url | safeUrl" [title]="selectedVideo.name"
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
                    </iframe>
                </div>
            </div>
            <div class="col-12 col-lg-4 order-1 order-lg-2">
                <div class="training-info-card">
                    <h5 class="mb-1">{{ytTraining.name}}</h5>
                    <div *ngFor="let video of ytTraining.videos, index as i" class="d-block">
                        <div (click)="SelectVideo(video)" class="d-flex video-name-div mt-3">
                            <div [ngClass]="video.name == selectedVideo.name ? 'img-selected': ''"
                                class="play-button-img">
                            </div>
                            <p [ngClass]="video.name == selectedVideo.name ? 'p-selected': ''" class="mb-1">
                                {{video.name}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-8 order-3 mt-3 mb-5">
                <div *ngIf="ytTraining.description" class="training-info-card">
                    <p [innerHtml]="ytTraining.description"></p>
                </div>
            </div>
        </div>

    </div>
</ng-container>

<ng-template #vexpower>
    <div class="container my-5">
        <div class="training-section trainging-title-section">
            <div class="white-border">
                <h3 *ngIf="vexpowerTraining" class="mb-3">{{vexpowerTraining.title}}</h3>
                <p *ngIf="vexpowerTraining" class="mb-0">{{vexpowerTraining.description}}</p>
            </div>
        </div>

        <ng-container *ngFor="let chapter of vexpowerTraining?.chapters, let i = index">
            <h5 class="text mt-3">Chapter {{i+1}}</h5>
            <div class="training-section mt-3">
                <ng-container *ngFor="let section of chapter.sections, let j = index">
                    <ng-container [ngSwitch]="section.format">
                        <div *ngSwitchCase="'slugline'" class="my-3 text">
                            <h5>{{section.text}}</h5>
                        </div>
                        <div *ngSwitchCase="'description'" class="text">
                            <p>{{section.text}}</p>
                        </div>
                        <div *ngSwitchCase="'message'" class="text">
                            <ng-container *ngFor="let sentence of ParseMessageToSentences(section.text)">
                                <div class="message-div">
                                    <p class="p-3 dialogue-text">{{sentence}}</p>
                                </div>
                            </ng-container>
                        </div>
                        <div *ngSwitchCase="'brief'" class="mb-3 mt-5 text">
                            <h5 class="mb-3">{{section.title}}</h5>
                            <div class="traning-image">
                                <img [src]="section.imageURL" />
                            </div>
                            <p class="pl-4 pr-4 pb-4 pt-3 fs-14">{{section.text}}</p>
                        </div>
                        <div *ngSwitchCase="'howTo'" class="my-3 text">
                            <div class="iframe-div">
                                <iframe *ngIf="section.videoURL" class="frame-player" [src]="section.videoURL | safeUrl"
                                    [title]="selectedVideo.name" frameborder="0"
                                    allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
                                </iframe>
                            </div>
                            <div>
                                <p class="text mt-3">Transcript</p>
                                <ng-container *ngIf="!textArray.includes(section.sectionID)">
                                    <ng-container
                                        *ngFor="let sentence of ParseMessageToSentences(section.text.slice(0,150))">
                                        <div class="message-div mt-3">
                                            <p *ngIf="ContainsNonWhitespace(sentence)" class="p-3 dialogue-text">
                                                {{sentence}}</p>
                                        </div>
                                    </ng-container>
                                    <button (click)="TagData(section.sectionID)" style="color: rgb(87, 87, 214);"
                                        class="btn">
                                        Show more
                                    </button>
                                </ng-container>

                                <ng-container *ngIf="textArray.includes(section.sectionID)">
                                    <ng-container *ngFor="let sentence of ParseMessageToSentences(section.text)">
                                        <div class="message-div mt-3">
                                            <p *ngIf="ContainsNonWhitespace(sentence)" class="p-3 dialogue-text">
                                                {{sentence}}</p>
                                        </div>
                                    </ng-container>
                                    <button (click)="TagData(section.sectionID)" style="color: rgb(87, 87, 214);"
                                        class="btn">
                                        Show less
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                        <div *ngSwitchCase="'File'" class="my-3 text fs-14">
                            <div (click)="OpenFileInNewTab(section.fileLocation)" class="file-download-div d-flex">
                                <div>
                                    {{section.fileName}}
                                </div>
                                <div class="d-flex ml-auto">
                                    Download
                                    <i style="font-size: 25px;" class='bx bxs-download'></i>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="'file'" class="my-3 text fs-14">
                            <div (click)="OpenFileInNewTab(section.fileLocation)" class="file-download-div d-flex">
                                <div>
                                    {{section.fileName}}
                                </div>
                                <div class="d-flex ml-auto">
                                    Download
                                    <i style="font-size: 25px;" class='bx bxs-download'></i>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="'choice'" class="my-3 text">
                            <p class="fw-500">{{section.text}}</p>
                            <mat-radio-group
                                (change)='RadioChange($event.value, section.correctOptions, section.sectionID)'
                                class="d-flex flex-column">
                                <mat-radio-button *ngFor="let option of section.options" [value]="option">
                                    {{option}}
                                </mat-radio-button>
                            </mat-radio-group>

                            <p class="correct-answer ml-2" *ngIf='optionsArray.includes(section.sectionID)'>
                                Correct!
                            </p>
                            <p class="wrong-answer ml-2"
                                *ngIf='!optionsArray.includes(section.sectionID) && wrongAnswers.includes(section.sectionID)'>
                                Incorrect.
                            </p>
                        </div>
                        <div *ngSwitchCase="'freeText'">
                            <p class="fw-500">{{section.text}}</p>
                            <textarea class="answer-textarea" matInput
                                placeholder="Write your answer here..."></textarea>

                            <button *ngIf="!answerArray.includes(section.sectionID)"
                                (click)="TagAnswers(section.sectionID)" style="color: rgb(87, 87, 214);" class="btn">
                                Show answer
                            </button>
                            <ng-container *ngIf="answerArray.includes(section.sectionID)">
                                <p class="text fw-500 mb-1">Answer: {{section.answer}}</p>
                                <p class="text">Did you get this right?</p>
                                <mat-radio-group class="d-flex flex-column">
                                    <mat-radio-button value="1">Correct</mat-radio-button>
                                    <mat-radio-button value="2">Incorrect</mat-radio-button>
                                </mat-radio-group>
                                <button (click)="TagAnswers(section.sectionID)" style="color: rgb(87, 87, 214);"
                                    class="btn">
                                    Hide answer
                                </button>
                            </ng-container>
                        </div>
                        <div *ngSwitchCase="'quote'" class="my-3 text">
                            <p class="text fw-500 mb-0">Quote</p>
                            <p class="p-2 dialogue-text">{{section.text}}</p>
                            <a [href]='section.sourceLink' target='_blank' class="img-link-wrapper">
                                <div *ngIf='section.authorPhotoURL' class="thumbnail d-flex justify-content-end">
                                    <img style=" width: 40px; height: 40px; border-radius: 50%;"
                                        [src]='section.authorPhotoURL' />
                                </div>
                                <p class="text quote-text ml-auto mb-0" *ngIf='section.authorName'>
                                    {{section.authorName}}</p>
                                <p class="text quote-text ml-auto mb-0" *ngIf='section.authorTitle'>
                                    {{section.authorTitle}}</p>
                            </a>
                        </div>
                        <div *ngSwitchCase="'proTip'" class="my-3 text">
                            <p class="text fw-500 mb-0">Pro tip</p>
                            <p class="p-2 dialogue-text">{{section.text}}</p>
                        </div>
                        <div *ngSwitchCase="'definition'" class="my-3 text">
                            <p class="text fw-500 mb-0">{{section.title}}</p>
                            <p class="p-2">{{section.text}}</p>
                        </div>
                        <div *ngSwitchCase="'email'" class="my-3 text">
                            <p class="text fw-500 mb-0">Email</p>
                            <ng-container *ngFor="let sentence of ParseMessageToSentences(section.text)">
                                <p class="p-3 dialogue-text">{{sentence}}</p>
                            </ng-container>
                            <div *ngFor="let item of section.attachments">
                                <div (click)="OpenFileInNewTab(section.fileLocation)"
                                    class="file-download-div d-flex fs-14 mb-2">
                                    <div>
                                        {{item.fileName}}
                                    </div>
                                    <div class="d-flex ml-auto">
                                        Download
                                        <i style="font-size: 25px;" class='bx bxs-download'></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="'inPerson'" class="my-3 text">
                            <p class="text fw-500">Meeting</p>
                            <div class="video-wrapper m-b-s" *ngIf='section.videoURL'>
                                <div class="iframe-div">
                                    <iframe class="frame-player" [src]="section.videoURL | safeUrl" [title]="'name'"
                                        frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen>
                                    </iframe>
                                </div>
                            </div>
                            <ng-container *ngFor="let sentence of ParseMessageToSentences(section.text)">
                                <div class="message-div">
                                    <p class="p-3 dialogue-text">{{sentence}}</p>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>
        <!-- <div class="col-12 flex fw-wrap relative vex-container">
            <div class="col-7 p-l-0 p-t-0">
                <mat-card class="w-full mobile-scroll">
                    <mat-card-header class="p-b-0">
                        <mat-card-title>{{vexpowerTraining.title}}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <h3>{{vexpowerTraining.description}}</h3>
                    </mat-card-content>
                </mat-card>
                <ng-container *ngFor='let section of vexpowerTraining.sections; let i = index'>
                    <div class='section-view' *ngIf='section.value && section.value.length' #vexElements>
                        <mat-card class="scenario p-t-xl">
                            <p class="section-title">{{section.title}}</p>
                            <p class="section-title">{{section.description}}</p>
                            <ng-container *ngFor='let scenario of section.value'>
                                <ng-container [ngSwitch]="scenario.format">
                                    <h4><b>{{scenario.title}}</b></h4>
                                    <div *ngSwitchCase="'slugline'" class="w-full m-y-m">
                                        <h2>{{scenario.text}}</h2>
                                    </div>
                                    <div *ngSwitchCase="'description'" class="w-full m-y-m">
                                        <p>{{scenario.text}}</p>
                                    </div>
                                    <div *ngSwitchCase="'message'" class="w-full m-y-m">
                                        <p class="m-y-m">Message</p>
                                        <div [innerHTML]="scenario.text"></div>
                                    </div>
                                    <div *ngSwitchCase="'proTip'" class="w-full m-y-m">
                                        <p class="m-y-m">Pro tip</p>
                                        <p [innerHTML]="scenario.text"></p>
                                    </div>
                                    <div *ngSwitchCase="'definition'" class="w-full m-y-m">
                                        <p [innerHTML]="scenario.text"></p>
                                    </div>
                                    <div *ngSwitchCase="'brief'" class="w-full m-y-m">
                                        <img *ngIf='scenario.imageURL' [src]="scenario.imageURL" alt=''>
                                        <p class="m-y-m">{{scenario.text}}</p>
                                    </div>
                                    <div *ngSwitchCase="'howTo'" class="w-full m-y-m">
                                        <div class="video-wrapper m-b-s" *ngIf='scenario.videoURL'>
                                            <iframe [src]="scenario.videoURL | safeUrl" [title]="'name'" frameborder="0"
                                                allow="fullscreen; picture-in-picture" allowfullscreen>
                                            </iframe>
                                        </div>
                                        <p class="m-y-m">Transcript</p>
                                        <ng-container *ngIf='textArray.includes(scenario.sectionID); else lessText'>
                                            <div class="m-y-m">
                                                <p [innerHTML]="scenario.text"></p>
                                            </div>

                                            <button class="show-btn" (click)='tagData(scenario.sectionID)'>Show
                                                Less</button>
                                        </ng-container>

                                        <ng-template #lessText>
                                            <div class="m-y-m">
                                                <p [innerHTML]="scenario.text.slice(0, 150)">...</p>
                                            </div>
                                            <button class="show-btn" (click)='tagData(scenario.sectionID)'>Show
                                                More</button>
                                        </ng-template>
                                    </div>
                                    <div *ngSwitchCase="'videoCall'" class="w-full m-y-m">
                                        <div class="video-wrapper m-b-s" *ngIf='scenario.videoURL'>
                                            <iframe [src]="scenario.videoURL | safeUrl" [title]="'name'" frameborder="0"
                                                allow="fullscreen; picture-in-picture" allowfullscreen>
                                            </iframe>
                                        </div>
                                        <ng-container *ngIf='textArray.includes(scenario.sectionID); else lessText'>
                                            <div class="m-y-m">
                                                <div [innerHTML]="scenario.text"></div>
                                            </div>

                                            <button class="show-btn" (click)='tagData(scenario.sectionID)'>Show
                                                Less</button>
                                        </ng-container>

                                        <ng-template #lessText>
                                            <div class="m-y-m">
                                                <p [innerHTML]="scenario.text.slice(0, 150)">...</p>
                                            </div>
                                            <button class="show-btn" (click)='tagData(scenario.sectionID)'>Show
                                                More</button>
                                        </ng-template>
                                    </div>
                                    <div *ngSwitchCase="'inPerson'" class="w-full m-y-m">
                                        <p class="m-y-m">Meeting</p>
                                        <div class="video-wrapper m-b-s" *ngIf='scenario.videoURL'>
                                            <iframe [src]="scenario.videoURL | safeUrl" [title]="'name'" frameborder="0"
                                                allow="fullscreen; picture-in-picture" allowfullscreen>
                                            </iframe>
                                        </div>
                                        <div class="m-y-m">
                                            <div [innerHTML]="scenario.text"></div>
                                        </div>

                                    </div>
                                    <div *ngSwitchCase="'email'" class="w-full m-y-m">
                                        <p class="fw-bold m-y-m">Email</p>
                                        <div class="m-y-m">
                                            <div [innerHTML]="scenario.text"></div>
                                        </div>
                                        <div *ngFor="let item of scenario.attachments"
                                            class="vexpower-attachments flex ai-center jc-between">
                                            <a [href]='item.fileLocation'>{{item.fileFormat}}</a>
                                            <button>Download</button>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'quote'" class="w-full m-y-m">
                                        <p class="m-y-m">Quote</p>
                                        <div class="m-y-m w-full flex fw-wrap jc-end quote-wrapper"
                                            style='font-style: italic'>
                                            <div class="flex ai-center w-full quote-container">
                                                <q class="quote" [innerHTML]="scenario.text"></q>
                                            </div>
                                            <a [href]='scenario.sourceLink' target='_blank'
                                                class="ta-center m-y-s img-link-wrapper">
                                                <img class="author-img" *ngIf='scenario.authorPhotoURL'
                                                    [src]='scenario.authorPhotoURL' alt=''>
                                                <p *ngIf='scenario.authorName'>{{scenario.authorName}}</p>
                                                <p *ngIf='scenario.authorTitle'>{{scenario.authorTitle}}</p>
                                            </a>
                                        </div>
                                    </div>
                                    <div *ngSwitchCase="'File'" class="w-full m-y-m flex ai-center">
                                        <img src="/assets/icons/script-download-svg.svg" alt="grid column image"
                                            class="m-r-s file-icon">
                                        <a class="file-link" [href]='scenario.fileLocation'
                                            target='_blank'>{{scenario.fileName}}sads</a>
                                    </div>
                                    <div *ngSwitchCase="'file'" class="w-full m-y-m flex ai-center">
                                        <img src="/assets/icons/script-download-svg.svg" alt="grid column image"
                                            class="m-r-s file-icon">
                                        <a class="file-link" [href]='scenario.fileLocation'
                                            target='_blank'>{{scenario.fileName}}</a>
                                    </div>
                                    <div *ngSwitchCase="'freeText'" class="w-full m-y-m">
                                        <p>{{scenario.text}}</p>

                                        <textarea matInput placeholder="Write your answer here..."></textarea>

                                        <p>{{scenario.answer}}</p>
                                        <div class="m-y-s">
                                            <label>Did you get this right?</label>
                                        </div>
                                        <mat-radio-group class="flex fd-col m-b-s">
                                            <mat-radio-button value="1">Correct</mat-radio-button>
                                            <mat-radio-button value="2">Incorrect</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                    <div *ngSwitchCase="'choice'" class="w-full m-y-m">
                                        <p class="m-y-s">{{scenario.text}}</p>
                                        <div class="m-y-s">
                                            <label>Options selected</label>
                                        </div>
                                        <mat-radio-group class="flex fd-col"
                                            (change)='radioChange($event.value, scenario.correctOptions, scenario.sectionID)'>
                                            <mat-radio-button *ngFor="let option of scenario.options" [value]="option">
                                                {{option}}
                                            </mat-radio-button>
                                        </mat-radio-group>

                                        <p *ngIf='optionsArray.includes(scenario.sectionID); else lessText'>
                                            <mat-icon class="correct-answer">check</mat-icon>
                                        </p>
                                        <ng-template #lessText>
                                            <mat-icon class="wrong-answer">close</mat-icon>
                                        </ng-template>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </mat-card>
                    </div>
                </ng-container>
            </div>
        </div> -->
    </div>
</ng-template>