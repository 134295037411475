import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { of } from "rxjs";
import { switchMap, takeLast } from "rxjs/operators";
//import { Company } from 'src/app/models/company';
import { ApiResponse } from "src/app/models/apiResponse";
import { Brand } from "src/app/models/brand";
import {
  MINIMUM_LETTERS_SEARCH_START,
  RESULT_SUGGESTIONS,
} from "src/app/models/consts";
import { ModuleType, SearchType } from "src/app/models/enums";
import { SearchRequest } from "src/app/models/searchRequest";
import { SearchResponse } from "src/app/models/searchResponse";
import { ApiService } from "src/app/services/api/api.service";
import { StateService } from "src/app/services/shared/state.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { Company } from "src/app/models/company";
import { Video } from "src/app/models/video";
import { StaticDataService } from "src/app/services/shared/static.data.service";

@Component({
  selector: "app-search-suggestions",
  templateUrl: "./search-suggestions.component.html",
  styleUrls: ["./search-suggestions.component.scss"],
  animations: [
    trigger("expandAnimation", [
      state("collapsed", style({ height: "0px" })),
      state("expanded", style({ height: "320px" })),
      transition("collapsed <=> expanded", animate("0.3s ease-in-out")),
    ]),
  ],
})
export class SearchSuggestionsComponent implements OnInit {
  selectedIndex: number = 0;
  moduleType = ModuleType;

  searchType = SearchType;
  @Input() type: SearchType = SearchType.Ads;

  @Input() keyword = "";
  @Input() top!: string;
  @Input() viewType: ModuleType = ModuleType.Marketer;

  @Output() focusChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() displayTypeChanged: EventEmitter<string> =
    new EventEmitter<string>();
  showResults = false;

  searchResultsCompanies?: SearchResponse<Company> | null;
  searchResultsBrands?: SearchResponse<Brand> | null;
  searchResultsVideoAds?: SearchResponse<Video> | null;
  animationState: string = "collapsed";
  isPayingUser: boolean = false;
  constructor(
    public apiService: ApiService,
    public stateService: StateService,
    public staticDataService: StaticDataService,
    private popupService: PopupService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.SubscribeUser();
  }

  async SearchPreview() {
    this.showResults =
      this.keyword.length > MINIMUM_LETTERS_SEARCH_START - 1 ? true : false;
    if (this.showResults) {
      this.ShowResultsContainer();
      if (this.type == SearchType.Companies) this.LoadCompanies();
      if (this.type == SearchType.Brands) this.LoadBrands();
      if (this.type == SearchType.Ads) this.LoadVideoAds();
    } else this.ClearValues();
  }

  private ClearValues() {
    this.searchResultsCompanies = null;
    this.searchResultsBrands = null;
    this.animationState = "collapsed";
  }

  public SubscribeUser() {
    this.stateService.user$.subscribe((user) => {
      this.isPayingUser = this.stateService.IsPayingUserState(user);
    });
  }

  public async LoadCompanies() {
    this.displayTypeChanged.emit(this.type);
    const result = await this.apiService.QuickSearchCompanies(
      new SearchRequest(this.keyword, RESULT_SUGGESTIONS)
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
    }

    if (result?.data) {
      this.searchResultsCompanies = result.data;
    }
  }

  public async LoadBrands() {
    this.displayTypeChanged.emit(this.type);
    const result = await this.apiService.QuickSearchBrands(
      new SearchRequest(this.keyword, RESULT_SUGGESTIONS)
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
    }

    if (result?.data) {
      this.searchResultsBrands = result.data;
    }
  }

  public async LoadVideoAds() {
    this.displayTypeChanged.emit(this.type);
    const result = await this.apiService.PreviewVideoAds(
      new SearchRequest(this.keyword, RESULT_SUGGESTIONS, 1)
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
    }

    if (result?.data) {
      this.searchResultsVideoAds = result.data;
    }
  }

  public OpenSelectedCompany(company: Company) {
    if (!this.stateService.IsUserLoggedIn()) {
      this.popupService.AskForRegister();
      return;
    }

    // this.ShowScroll();
    const currentRoute = this.activatedRoute.snapshot.routeConfig?.path;
    const queryParams: NavigationExtras = {
      queryParams: {
        companyId: company.companyId,
        keyword: this.keyword,
      },
      replaceUrl: true,
      relativeTo: this.activatedRoute,
    };

    if (currentRoute === "entity-info") {
      this.router.navigate([], queryParams);
      this.HideResultsContainer();
      this.focusChanged.emit(true);
    } else {
      this.router.navigate(["entity-info"], queryParams);
    }
  }
  public OpenSelectedBrand(brand: Brand) {
    if (!this.stateService.IsUserLoggedIn()) {
      this.popupService.AskForRegister();
      return;
    }

    // this.ShowScroll();
    const currentRoute = this.activatedRoute.snapshot.routeConfig?.path;
    const queryParams: NavigationExtras = {
      queryParams: {
        brandId: brand.brandId,
        keyword: this.keyword,
      },
      replaceUrl: true,
      relativeTo: this.activatedRoute,
    };

    if (currentRoute === "entity-info") {
      this.router.navigate([], queryParams);
      this.HideResultsContainer();
      this.focusChanged.emit(true);
    } else {
      this.router.navigate(["entity-info"], queryParams);
    }
  }

  public OpenSelectedAd(ad: Video) {
    this.popupService.openVideoInfoModal(
      ad.ytVideoId,
      () => {},
      () => {},
      () => {},
      () => {}
    );
  }
  // private ShowScroll() {
  //   this.ApplyCssPropertyToClass("no-scroll", "padding-right", "0px");
  //   this.renderer.removeClass(document.body, "no-scroll");
  // }
  public ApplyCssPropertyToClass(
    className: string,
    property: string,
    value: string
  ) {
    const elements = document.getElementsByClassName(className);

    for (let i = 0; i < elements.length; i++) {
      this.renderer.setStyle(elements[i], property, value);
    }
  }
  public ShowResultsContainer() {
    this.animationState = "expanded";
  }
  public HideResultsContainer() {
    this.animationState = "collapsed";
  }
}
