import { Component, Inject, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-basic-info",
  templateUrl: "./basic-info.component.html",
  styleUrls: ["../../login/login.component.scss"],
})
export class BasicInfoComponent implements OnInit {
  registerForm!: FormGroup;
  email: string = "";
  submitted: boolean = false;
  canClose: boolean = true;
  constructor(
    public fb: FormBuilder,
    public stateService: StateService,
    public dialogRef: MatDialogRef<BasicInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public matData: any
  ) {
    this.InitializeRegisterForm();
    this.email = matData.email;
    this.canClose = matData.canClose;
  }

  ngOnInit(): void {}

  private InitializeRegisterForm() {
    this.registerForm = this.fb.group(
      {
        firstName: ["", [Validators.required, this.firstNameValidator]],
        lastName: [""],
        password: ["", Validators.required],
        repeatPassword: ["", Validators.required],
      },
      { validator: this.passwordMatchValidator }
    );
  }

  firstNameValidator(control: any) {
    const value = control.value;
    if (value.trim() === "" || value === " ") {
      return { invalidFirstName: true };
    }
    return null;
  }

  passwordMatchValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const password = control.get("password");
    const repeatPassword = control.get("repeatPassword");

    if (password && repeatPassword && password.value !== repeatPassword.value) {
      return { passwordMismatch: true };
    }

    if (password && password.value.length < 6) {
      return { passwordTooShort: true };
    }
    return null;
  }

  CloseModal() {
    this.dialogRef.close();
  }

  Register() {
    this.submitted = true;
    const data = this.registerForm.getRawValue();
    this.stateService.SignUpEmailAndPassword(
      this.email,
      data.password,
      data.firstName + " " + data.lastName,
      () => this.CloseModal()
    );
  }
}
