<div class="container container-1400">
    <h2 class="settings-title">Settings</h2>
    <div class="tabs d-flex">
        <p [class.selected]="selectedItem === 'details'" (click)="SwitchTab('details')"
            class="tab selected mb-0 px-3 pb-2">
            My details
        </p>
        <ng-container *ngIf="!isInceptlyUser">
            <p [class.selected]="selectedItem === 'plan'" (click)="SwitchTab('plan')" class="tab mb-0 px-3 pb-2">
                Plan
            </p>
            <p [class.selected]="selectedItem === 'billing'" (click)="SwitchTab('billing')" class="tab mb-0 px-3 pb-2">
                Billing
            </p>
        </ng-container>
        <p [class.selected]="selectedItem === 'newsletters'" (click)="SwitchTab('newsletters')"
            class="tab mb-0 px-3 pb-2">
            Community & Newsletters
        </p>
    </div>
    <app-subscription-plan *ngIf="selectedItem === 'plan'"></app-subscription-plan>
    <app-billing-info *ngIf="selectedItem === 'billing'"></app-billing-info>
    <app-account-info *ngIf="selectedItem === 'details'"></app-account-info>
    <app-newsletters-info *ngIf="selectedItem === 'newsletters'"></app-newsletters-info>
</div>