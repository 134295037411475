<div class="p-3">
    <p class="field-label mb-0 text-right">{{selectedQuestionIndex}} / {{totalQuestionsNum}}</p>
    @if (selectedQuestionIndex == 1){
    <h3 class="title mb-1 mt-3 text-left px-1">Lets customize your flow</h3>
    <p class="welcome-text text-left px-1">{{question1.title}}</p>
    <div class="row mx-0">
        @for (answer of question1.options; track answer; let i = $index){
        <div class="col-12 col-sm-6 px-1">
            <div (click)="selectedAnswerIndex = i" [ngClass]="{'answer-option-active': selectedAnswerIndex == i}"
                class="answer-option p-3 field-label">
                {{answer}}</div>
        </div>
        }
        <button [disabled]="selectedAnswerIndex === -1" (click)="NextQuestion()"
            class="btn main-button mt-3 w-auto d-flex ml-auto">
            Next
            <i style="font-size: 22px;" class='bx bx-right-arrow-alt ml-1'></i>
        </button>
    </div>
    }
    @if (selectedQuestionIndex == 2){
    <h3 class="title mb-1 mt-3 text-left px-1">Lets customize your flow</h3>
    <p class="welcome-text text-left px-1">{{question2.title}}</p>
    <div class="row mx-0">
        @for (answer of question2.options; track answer; let i = $index){
        <div class="col-12 col-sm-6 px-1">
            <div (click)="ToggleAnswer(i)"
                [ngClass]="{'answer-option-active': mutipleAnswersSelected.includes(question2.options[i])}"
                class="answer-option p-3 field-label">
                {{answer}}</div>
        </div>
        }
        <button [disabled]="mutipleAnswersSelected.length === 0" (click)="NextQuestion()"
            class="btn main-button mt-3 w-auto d-flex ml-auto">
            Next
            <i style="font-size: 22px;" class='bx bx-right-arrow-alt ml-1'></i>
        </button>
    </div>
    }
    @if (selectedQuestionIndex == 3){
    <h3 class="title mb-1 mt-3 text-left px-1">Lets customize your flow</h3>
    <p class="welcome-text text-left px-1">{{question3.title}}</p>
    <div class="row mx-0">
        @for (answer of question3.options; track answer; let i = $index){
        <div class="col-12 col-sm-6 px-1">
            <div (click)="selectedAnswerIndex = i" [ngClass]="{'answer-option-active': selectedAnswerIndex == i}"
                class="answer-option p-3 field-label">
                {{answer}}</div>
        </div>
        }
        <button [disabled]="selectedAnswerIndex === -1" (click)="NextQuestion()"
            class="btn main-button mt-3 w-auto d-flex ml-auto">
            Next
            <i style="font-size: 22px;" class='bx bx-right-arrow-alt ml-1'></i>
        </button>
    </div>
    }

    <!--Question with input answers-->

    @if (selectedQuestionIndex == 4){
    <h3 class="title mb-1 mt-3 text-left px-1">Few more steps</h3>
    <p class="welcome-text text-left px-1 mb-4">{{question4.title}}</p>
    <app-spend-select [spendValues]="question4.options" placeholder="Ad spend"
        (selectedSpendChanged)="SelectedSpendChanged($event)"></app-spend-select>
    <button (click)="NextQuestion()" class="btn main-button mt-3 w-auto d-flex ml-auto">
        Next
        <i style="font-size: 22px;" class='bx bx-right-arrow-alt ml-1'></i>
    </button>
    }

    @if (selectedQuestionIndex == 5){
    <h3 class="title mb-1 mt-3 text-left px-1">Few more steps</h3>
    <p class="welcome-text text-left px-1 mb-4">{{question5.title}}</p>
    <input style="font-size: 15px;" [(ngModel)]="question5.answer" placeholder="www.example-website.com"
        class="form-control" />
    <button (click)="NextQuestion()" class="btn main-button mt-3 w-auto d-flex ml-auto">
        Next
        <i style="font-size: 22px;" class='bx bx-right-arrow-alt ml-1'></i>
    </button>
    }


    @if (selectedQuestionIndex == 6){
    <h3 class="title mb-1 mt-3 text-left px-1">Few more steps</h3>
    <p class="welcome-text text-left px-1 mb-4">{{question6.title}}</p>
    <textarea style="font-size: 15px;" [(ngModel)]="question6.answer" placeholder="www.example-website.com"
        class="form-control ta"></textarea>
    <button (click)="Finish()" class="btn main-button mt-3 w-auto d-flex ml-auto">
        Finish
        <i style="font-size: 22px;" class='bx bx-right-arrow-alt ml-1'></i>
    </button>
    }
</div>