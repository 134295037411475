import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-select-training-category",
  templateUrl: "./select-training-category.component.html",
  styleUrls: ["./select-training-category.component.scss"],
})
export class SelectTrainingCategoryComponent {
  selectedCategory: string = "all";
  categories = [
    {
      name: "All",
      value: "all",
    },
    {
      name: "Welcome videos",
      value: "welcome-videos",
    },
    {
      name: "Youtube Ads Trainings",
      value: "youtubeAds",
    },
    {
      name: "Content",
      value: "content",
    },
    {
      name: "Advertising",
      value: "advertising",
    },
    {
      name: "Attribution",
      value: "attribution",
    },
    {
      name: "Conversion",
      value: "conversion",
    },
    {
      name: "Virality",
      value: "virality",
    },
    {
      name: "Retention",
      value: "retention",
    },
  ];
  @Output() trainingCategorySelected: EventEmitter<string> =
    new EventEmitter<string>();

  constructor() {}

  public CategorySelected(category: string) {
    this.trainingCategorySelected.emit(category);
  }
}
