import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ChartPerformancePeriod } from "src/app/models/enums";
import { Chart, Plugin } from "chart.js";

import "chartjs-adapter-moment";
import "chartjs-plugin-annotation";
const moment = require("moment");

const shadowPlugin: Plugin = {
  id: "customShadow",
  afterDraw: (chart) => {
    const ctx = chart.ctx;
    ctx.save();
    ctx.shadowColor = "#2D31A6";
    ctx.shadowBlur = 10;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 4;
    chart.getDatasetMeta(0).controller.draw();
    ctx.restore();
  },
};

@Component({
  selector: "app-line-chart",
  templateUrl: "./line-chart.component.html",
  styleUrls: ["./line-chart.component.scss"],
})
export class LineChartComponent implements OnInit, OnChanges {
  chartPeriod = ChartPerformancePeriod;

  lineChart: any;
  displayedYvalues: string[] = [];

  isGraphInitialized: boolean = false;
  @Input() yValues: string[] = [];
  @Input() xValues: number[] = [];

  @Input() yTitle!: string;
  @Input() xTitle!: string;
  @Input() type!: any;
  @Input() chartId!: string;
  @Input() dateRangeOptions: string[] = [];
  @Input() selectedChartPeriod!: string;
  @Input() timeUnit: string = "";
  @Input() isVideoChart: boolean = false;
  @Input() isPayingUser: boolean = true;

  @Output() dateRangeChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.GraphXyChanged();
  }

  private InitializeGraph() {
    const data = {
      labels: this.yValues,
      datasets: [
        {
          data: this.xValues,
          borderColor: "#52559e",
          borderWidth: 2,
          fill: false,
          tension: 0.2,
          pointRadius: 0,
        },
      ],
    };
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: "time",
          time: {
            unit: this.timeUnit, // You can specify the time unit (e.g., 'day', 'month', 'year', etc.)
          },
          ticks: {
            callback: (value: any, index: any, values: any) => {
              if (value === values[0].value) {
                return moment.unix(value / 1000).format("DD-MM-YYYY");
              }
              if (value === values[values.length - 1].value) {
                return moment.unix(value / 1000).format("DD-MM-YYYY");
              }
              return;
            },
            display: true,
            maxTicksLimit: 2,
            maxRotation: 0,
          },
          title: {
            display: true,
            text: this.xTitle,
          },
          grid: {
            display: false,
          },
        },
        y: {
          beginAtZero: false,
          min: function (context: any) {
            var min = Math.min(...context.chart.data.datasets[0].data);
            return Math.ceil(min * 0.95);
          },
          max: function (context: any) {
            var max = Math.max(...context.chart.data.datasets[0].data);
            return Math.ceil(max * 1.05);
          },
          ticks: {
            stepSize: function (context: any) {
              var max = Math.max(...context.chart.data.datasets[0].data);
              return Math.ceil(max * 0.4);
            },
            callback: (value: any, index: any, values: any) => {
              return this.NumberToString(value);
            },
            precision: 0, // Number of decimal places in the ticks
            font: {},
          },
          title: {
            display: true,
            text: this.yTitle,
          },
        },
      },
      plugins: {
        tooltip: {
          enabled: true,
          intersect: false,
        },
      },
      interaction: {
        intersect: false,
      },
    };
    const ctx = document.getElementById(this.chartId) as HTMLCanvasElement;

    let chartStatus = Chart.getChart(this.chartId); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    this.lineChart = new Chart(ctx, {
      type: this.type,
      data: data,
      options: options,
      plugins: [shadowPlugin],
    });
  }

  private GraphXyChanged() {
    if (this.xValues.length < 1 || this.yValues.length < 1) return;
    this.RemoveDroppingSpend();
    this.InitializeGraph();
  }

  ChangeGraphDateRange(period: string) {
    this.selectedChartPeriod = period;
    this.dateRangeChanged.emit(period);
  }

  NumberToString(num: number) {
    if (Math.abs(num) >= 1000000000) return (num / 1000000000).toFixed(1) + "B";
    else if (Math.abs(num) >= 1000000) return (num / 1000000).toFixed(1) + "M";
    else if (Math.abs(num) >= 1_000) return (num / 1000).toFixed(1) + "k";
    else return num.toString();
  }

  public RemoveDroppingSpend() {
    if (this.xValues.length == 1) return;
    for (let i = 1; i < this.xValues.length; i++) {
      if (this.xValues[i] < this.xValues[i - 1])
        this.xValues[i] = this.xValues[i - 1];
    }
  }

  public getBlurIfNeeded() {
    return {
      filter: !this.isPayingUser ? "blur(7px)" : "blur(0)",
      "user-select": !this.isPayingUser ? "none" : "auto",
      "pointer-events": !this.isPayingUser ? "none" : "auto",
    };
  }
}
