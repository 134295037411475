import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from "@angular/core";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";

@Component({
  selector: "app-upsert-swipe-board",
  templateUrl: "./upsert-board.component.html",
  styleUrls: ["./upsert-board.component.scss"],
})
export class UpsertBoardComponent {
  @Output() menuClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() addBoard: EventEmitter<string> = new EventEmitter<string>();
  @Output() editBoard: EventEmitter<string> = new EventEmitter<string>();

  @Input() boardId: number | undefined = undefined;
  @Input() boardName: string = "";

  constructor(
    public apiService: ApiService,
    public popupService: PopupService
  ) {}

  public async AddNewBoard() {
    if (this.boardName === "") {
      this.popupService.Notify("Enter board name.");
      return;
    }
    this.CloseMenu();
    if (this.boardId) this.editBoard.emit(this.boardName);
    else this.addBoard.emit(this.boardName);
    this.boardName = "";
  }

  CloseMenu() {
    this.menuClosed.emit();
  }
}
