<div style="max-width: 660px;" class="container">
    <h1 class="entry-title">About Vidtao</h1>
    <h2 class="blue-subtitle text-center">VidTao: Grow your business with the power of YouTube advertising</h2>
    <p class="mt-4">At VidTao we provide:</p>
    <ul>
        <li>Tools</li>
        <li>Solutions</li>
        <li>Community</li>
        <li>Support</li>
    </ul>
    <p class="mt-4">…To take the guesswork out of using YouTube ads to scale your business.</p>
    <p class="mt-4">Our solutions include:</p>
    <p>
        <strong>VidTao.com</strong>
        – the internet’s #1 free destination for learning more about how to use YouTube ads,
        including:
    </p>
    <ul>
        <li>YouTube Video Ad Breakdowns: Get frameworks you can model to create your own high-performing YouTube ads, no
            matter what your budget.</li>
        <li>Step-by-step “how to” Guides & Tutorials: Use the techniques & strategies that we are using now to manage
            over $1M/mo in YouTube adspend.</li>
    </ul>
    <p>
        <strong>
            <span style="display: inline-block; text-decoration: underline;">VidTao YouTube Ad Spy Tool &amp;
                Library</span>
        </strong> – Our proprietary Youtube ads research tool and library allows you to find your
        competitors’ best ads, and discover the best targeting options so you can leverage what’s already working in
        your market right now.
    </p>
    <p>
        <strong>
            <span style="display: inline-block; text-decoration: underline;">VidTao Community</span>
        </strong>
        – The
        private slack community where we and other top YouTube advertisers share what’s working, right now, giving you a
        huge competitive advantage over competitors in your market.
    </p>
    <p>
        <strong>
            <span style="display: inline-block; text-decoration: underline;">VidTao Team</span>
        </strong>
        – Our team
        manages over $1 Million USD per month in profitable YouTube adspend for clients across markets including:
    </p>
    <ul class="mb-5">
        <li>Digital Information products</li>
        <li>Ecommerce</li>
        <li>Live events</li>
    </ul>
</div>