import { Injectable, ViewContainerRef } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { Overlay, ScrollStrategy } from "@angular/cdk/overlay";
import { LoginComponent } from "src/app/components/modals/login/login.component";
import { VideoDetailComponent } from "src/app/components/modals/video-detail/video-detail.component";
import { ResetPasswordComponent } from "src/app/components/modals/reset-password/reset-password.component";
import { RegisterComponent } from "src/app/components/modals/register/register.component";
import { SubscriptionComponent } from "src/app/components/modals/subscription/subscription.component";
import { QuestionModalComponent } from "src/app/components/modals/question-modal/question-modal.component";
import { PreviewComponent } from "src/app/components/modals/preview/preview.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SelectPayingPlanComponent } from "src/app/components/modals/select-paying-plan/select-paying-plan.component";
import { YesNoComponent } from "src/app/components/modals/yes-no/yes-no.component";
import { PlanInfo } from "src/app/models/planInfo";
import { WelcomeComponent } from "src/app/components/modals/welcome/welcome.component";
import { ShowDescriptionComponent } from "src/app/components/modals/show-description/show-description.component";
import { BasicInfoComponent } from "src/app/components/modals/signup-sequence/basic-info/basic-info.component";
import { PickAnswerQuestionComponent } from "src/app/components/modals/signup-sequence/pick-answer-question/pick-answer-question.component";
import { VerifyCheckComponent } from "src/app/components/modals/verify-check/verify-check.component";
import { TutorialComponent } from "src/app/components/modals/tutorial/tutorial.component";
import { faL } from "@fortawesome/free-solid-svg-icons";

@Injectable({
  providedIn: "root",
})
export class PopupService {
  dialogConfig: MatDialogConfig = new MatDialogConfig();
  modalOpened: boolean = false;
  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private overlay: Overlay
  ) {
    this.dialogConfig.autoFocus = true;
    this.dialogConfig.panelClass = "dialog-class";
  }

  public openLoginModal(disableClose: boolean = false) {
    this.dialogConfig.minWidth = 350;
    this.dialogConfig.maxWidth = 350;
    this.dialogConfig.minHeight = 100;
    this.dialogConfig.disableClose = disableClose;
    this.dialogConfig.data = {
      canClose: !disableClose,
    };
    const dialogRef = this.dialog.open(LoginComponent, this.dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //ako login vrati true odraditi isto kao sa Func delegatima
      } else {
      }
    });
  }
  public openRegisterModal(disableClose: boolean = false) {
    this.dialogConfig.minWidth = 350;
    this.dialogConfig.maxWidth = 400;
    this.dialogConfig.minHeight = 100;
    this.dialogConfig.disableClose = disableClose;
    this.dialogConfig.scrollStrategy = this.overlay.scrollStrategies.noop();
    this.dialogConfig.data = {
      canClose: !disableClose,
    };
    const dialogRef = this.dialog.open(RegisterComponent, this.dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //ako login vrati true odraditi isto kao sa Func delegatima
      } else {
      }
    });
  }

  public openSignupSequence(f?: Function) {
    this.dialogConfig.minWidth = 330; //350
    this.dialogConfig.maxWidth = 650; //450
    this.dialogConfig.minHeight = 100;
    this.dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(
      PickAnswerQuestionComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (f) f();
    });
  }

  public openVerifyCheck() {
    this.dialogConfig.minWidth = 350; //350
    this.dialogConfig.maxWidth = 450; //450
    this.dialogConfig.minHeight = 100;
    this.dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(VerifyCheckComponent, this.dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //ako login vrati true odraditi isto kao sa Func delegatima
      } else {
      }
    });
  }

  public OpenBasicInfoModal(email: string, disableClose: boolean = false) {
    this.dialogConfig.minWidth = 350;
    this.dialogConfig.maxWidth = 450;
    this.dialogConfig.minHeight = 100;
    this.dialogConfig.disableClose = disableClose;
    this.dialogConfig.data = {
      email: email,
      canClose: !disableClose,
    };
    const dialogRef = this.dialog.open(BasicInfoComponent, this.dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //ako login vrati true odraditi isto kao sa Func delegatima
      } else {
      }
    });
  }
  public OpenResetPasswordModal(email: string) {
    this.dialogConfig.minWidth = 350;
    this.dialogConfig.maxWidth = 450;
    this.dialogConfig.minHeight = 100;
    this.dialogConfig.data = {
      email: email,
    };
    const dialogRef = this.dialog.open(
      ResetPasswordComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //ako login vrati true odraditi isto kao sa Func delegatima
      } else {
      }
    });
  }
  public openVideoInfoModal(
    ytVideoId: string,
    modalClosed: Function,
    deleted: Function,
    swipeAdded: Function,
    swipeRemoved: Function
  ) {
    this.dialogConfig.width = "inherit";
    this.dialogConfig.minWidth = 360;
    this.dialogConfig.maxWidth = 800;
    this.dialogConfig.data = {
      ytVideoId: ytVideoId,
    };
    const dialogRef = this.dialog.open(VideoDetailComponent, this.dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result == "deleted") deleted();
      if (result == "swipe-added") swipeAdded();
      if (result == "swipe-removed") swipeRemoved();
      modalClosed();
    });
  }
  public OpenPremiumSubscription(paymentPlan: PlanInfo) {
    this.dialogConfig.minHeight = "80vh";
    this.dialogConfig.minWidth = 360;
    this.dialogConfig.maxWidth = 750;
    this.dialogConfig.data = {
      paymentPlan: paymentPlan,
    };
    const dialogRef = this.dialog.open(
      SubscriptionComponent,
      this.dialogConfig
    );
    this.modalOpened = true;
    dialogRef.afterClosed().subscribe((result) => {
      this.modalOpened = false;
    });
  }
  public AskForRegister(disableClose: boolean = false) {
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.maxWidth = 400;
    this.dialogConfig.minHeight = 100;
    this.dialogConfig.disableClose = disableClose;
    this.dialogConfig.data = {
      displayType: "recommend-login",
      canClose: !disableClose,
    };
    const dialogRef = this.dialog.open(
      QuestionModalComponent,
      this.dialogConfig
    );
  }
  public AskForAccountUpgrade() {
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.maxWidth = 400;
    this.dialogConfig.minHeight = 100;
    this.dialogConfig.data = {
      displayType: "recommend-upgrade",
    };
    const dialogRef = this.dialog.open(
      QuestionModalComponent,
      this.dialogConfig
    );
  }
  public OpenBrandPreview(brandId: number, currentRoute?: string) {
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.minHeight = "50vh";
    this.dialogConfig.width = "inherit";
    this.dialogConfig.minWidth = 360;
    this.dialogConfig.maxWidth = 750;
    this.dialogConfig.data = {
      brandId: brandId,
      displayMode: "brand",
      currentRoute: currentRoute,
    };
    const dialogRef = this.dialog.open(PreviewComponent, this.dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //ako login vrati true odraditi isto kao sa Func delegatima
      } else {
      }
    });
  }
  public OpenCompanyPreview(
    companyId: number,
    currentRoute?: string,
    isAffiliate?: boolean
  ) {
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.minHeight = "50vh";
    this.dialogConfig.minWidth = 360;
    this.dialogConfig.width = "inherit";
    this.dialogConfig.maxWidth = 750;
    this.dialogConfig.data = {
      companyId: companyId,
      displayMode: "company",
      currentRoute: currentRoute,
      isAffiliate: isAffiliate,
    };
    const dialogRef = this.dialog.open(PreviewComponent, this.dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //ako login vrati true odraditi isto kao sa Func delegatima
      } else {
      }
    });
  }
  public OpenPayingPlanPicker(paymentPlanId?: number) {
    this.dialogConfig.minHeight = "35vh";
    this.dialogConfig.maxWidth = 1000;
    this.dialogConfig.width = "inherit";
    this.dialogConfig.data = {
      paymentPlanId: paymentPlanId,
    };
    const dialogRef = this.dialog.open(
      SelectPayingPlanComponent,
      this.dialogConfig
    );
    dialogRef.afterClosed().subscribe((plan) => {
      if (plan && plan != 0) {
        this.OpenPremiumSubscription(plan);
      }
    });
  }

  public Notify(message: string) {
    this.snackBar.open(message, "Ok", {
      duration: 3000,
      verticalPosition: "top",
      horizontalPosition: "center",
      panelClass: ["snackbar-style"],
    });
  }

  public AskToConfirm(
    title: string,
    message: string,
    confirm: Function,
    decline?: Function
  ) {
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.maxWidth = 400;
    this.dialogConfig.minHeight = 100;
    this.dialogConfig.data = {
      message: message,
      title: title,
    };
    const dialogRef = this.dialog.open(YesNoComponent, this.dialogConfig);
    dialogRef.afterClosed().subscribe((flag: boolean) => {
      if (flag) confirm();
      else if (decline) decline();
    });
  }

  public OpenWelcomeModal() {
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.minWidth = 360;
    this.dialogConfig.maxWidth = "none";
    this.dialogConfig.minHeight = 100;
    this.dialogConfig.disableClose = true;
    const dialogRef = this.dialog.open(WelcomeComponent, this.dialogConfig);
  }

  public OpenDescriptionModal(description: string) {
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.maxWidth = 500;
    this.dialogConfig.minHeight = 100;
    this.dialogConfig.data = {
      description,
    };
    const dialogRef = this.dialog.open(
      ShowDescriptionComponent,
      this.dialogConfig
    );
  }

  public OpenAffiliateTutorial() {
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.minWidth = 360;
    this.dialogConfig.maxWidth = "none";
    this.dialogConfig.minHeight = 100;
    this.dialogConfig.disableClose = false;
    this.dialogConfig.data = {
      mode: "affiliate",
    };
    this.dialog.open(TutorialComponent, this.dialogConfig);
  }
  public OpenBrandsTutorial() {
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.minWidth = 360;
    this.dialogConfig.maxWidth = "none";
    this.dialogConfig.minHeight = 100;
    this.dialogConfig.disableClose = false;
    this.dialogConfig.data = {
      mode: "brands",
    };
    this.dialog.open(TutorialComponent, this.dialogConfig);
  }
  public OpenAdsTutorial() {
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.minWidth = 360;
    this.dialogConfig.maxWidth = "none";
    this.dialogConfig.minHeight = 100;
    this.dialogConfig.disableClose = false;
    this.dialogConfig.data = {
      mode: "ads",
    };
    this.dialog.open(TutorialComponent, this.dialogConfig);
  }
  public OpenSearchTutorial() {
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.minWidth = 360;
    this.dialogConfig.maxWidth = "none";
    this.dialogConfig.minHeight = 100;
    this.dialogConfig.disableClose = false;
    this.dialogConfig.data = {
      mode: "search",
    };
    this.dialog.open(TutorialComponent, this.dialogConfig);
  }
  public OpenEntityTutorial() {
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.minWidth = 360;
    this.dialogConfig.maxWidth = "none";
    this.dialogConfig.minHeight = 100;
    this.dialogConfig.disableClose = false;
    this.dialogConfig.data = {
      mode: "entity",
    };
    this.dialog.open(TutorialComponent, this.dialogConfig);
  }
}
