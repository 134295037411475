import { Component, OnInit } from "@angular/core";
import { ModuleType } from "src/app/models/enums";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-creative",
  templateUrl: "./creative.component.html",
})
export class CreativeComponent implements OnInit {
  isPayingUser: boolean = false;
  appletUrl: string = "";
  constructor(public stateService: StateService) {}

  public SubscribeToUser() {
    this.stateService.user$.subscribe((user) => {
      this.isPayingUser = this.stateService.IsPayingUserState(user);
      this.appletUrl = `https://creativescore.vidtao.com/?uid=${user?.userId}&email=${user?.email}`;
    });
  }

  ngOnInit(): void {
    this.SubscribeToUser();
    this.stateService.TabSelected("creatives");
    this.stateService.ModuleSelected(ModuleType.Marketer);
  }
}
