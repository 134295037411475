import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { Category } from "src/app/models/category";
import { PageSizes } from "src/app/models/consts";
import { ModuleType, SearchType } from "src/app/models/enums";
import { ApiService } from "src/app/services/api/api.service";
import { StateService } from "src/app/services/shared/state.service";
import { PopupService } from "src/app/services/shared/popup.service";

@Component({
  selector: "app-rankings",
  templateUrl: "./rankings.component.html",
  styleUrls: ["./rankings.component.scss"],
})
export class RankingsComponent implements OnInit, AfterViewInit {
  selectedIndex: number = 0;
  activeAscenderIndex: number = 0;
  categories: Category[] = [];
  mainCategories: Category[] = [];
  subcategories: Category[] = [];

  selectedType: string = "brands";

  searchType = SearchType;
  type: SearchType = SearchType.Brands;

  pageSizes = PageSizes;
  @Input() resultsPerPage = this.pageSizes[3];

  page: number = 1;

  loaded: boolean = false;
  requiresTotal: boolean = true;

  selectedInterval: string = "last30Days";
  selectedCategoryId: number = 0;
  selectedCountryId: number = 0;

  constructor(
    public apiService: ApiService,
    public stateService: StateService,
    private router: Router,
    private route: ActivatedRoute,
    private popupService: PopupService // private activatedRoute: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    this.stateService.TabSelected("rankings");
    this.stateService.ModuleSelected(ModuleType.Marketer);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SubscribeRouteParams();
    }, 100);
  }

  private SubscribeRouteParams() {
    this.route.queryParams.subscribe(async (params) => {
      if (params["selectedInterval"])
        this.selectedInterval = params["selectedInterval"];

      if (params["selectedCategoryId"]) {
        this.selectedCategoryId = Number(params["selectedCategoryId"]);
      }

      if (params["selectedCountryId"]) {
        this.selectedCountryId = Number(params["selectedCountryId"]);
      }

      if (params["selectedType"]) {
        this.selectedType = params["selectedType"];
        if (this.selectedType == "brands") this.type = SearchType.Brands;
        else this.type = SearchType.Companies;
      }

      if (params["resultsPerPage"]) {
        this.resultsPerPage = Number(params["resultsPerPage"]);
      }

      if (params["page"]) {
        this.page = Number(params["page"]);
      }
      this.FilterRankingsParamsChanged();
      this.loaded = true;
    });
  }

  public FilterRankingsParamsChanged() {
    const queryParams: NavigationExtras = {
      queryParams: {
        selectedInterval: this.selectedInterval,
        selectedCategoryId: this.selectedCategoryId,
        selectedCountryId: this.selectedCountryId,
        selectedType: this.selectedType,
        resultsPerPage: this.resultsPerPage,
        page: this.page,
      },
      // relativeTo: this.activatedRoute,
      relativeTo: this.route,
      replaceUrl: true,
    };
    this.router.navigate([], queryParams);
  }

  public SetAscenderIndex(index: number) {
    this.activeAscenderIndex = index;
  }

  public SelectedCategoryChanged(categoryId: number) {
    this.selectedCategoryId = categoryId;
    this.page = 1;
    this.FilterRankingsParamsChanged();
  }

  public SelectedCountryChanged(countryId: number) {
    this.selectedCountryId = countryId;
    this.page = 1;
    this.FilterRankingsParamsChanged();
  }

  public IntervalSelected(interval: string) {
    this.selectedInterval = interval;
    this.page = 1;
    this.FilterRankingsParamsChanged();
  }

  public CompaniesSelected(selectedType: string) {
    this.type = SearchType.Companies;
    this.selectedType = selectedType;
    this.page = 1;
    this.FilterRankingsParamsChanged();
  }

  public BrandsSelected(selectedType: string) {
    this.type = SearchType.Brands;
    this.selectedType = selectedType;
    this.page = 1;
    this.FilterRankingsParamsChanged();
  }

  public ResultsPerPageChanged(resultsPerPage: number) {
    this.resultsPerPage = resultsPerPage;
    this.page = 1;
    this.FilterRankingsParamsChanged();
  }

  public CurrentPageBrandsChanged(currentPage: number) {
    this.page = currentPage;
    this.FilterRankingsParamsChanged();
  }

  public CurrentPageCompaniesChanged(currentPage: number) {
    this.page = currentPage;
    this.FilterRankingsParamsChanged();
  }
}
