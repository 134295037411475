export const performanceDummydata = {
  data: {
    dataPoints: [
      {
        date: "2022-11-09",
        value: 47440046,
      },
      {
        date: "2023-05-13",
        value: 54168372,
      },
      {
        date: "2023-08-11",
        value: 57847579,
      },
      {
        date: "2023-09-10",
        value: 61669524,
      },
      {
        date: "2023-10-10",
        value: 75012952,
      },
      {
        date: "2023-10-19",
        value: 79441446,
      },
      {
        date: "2023-10-26",
        value: 79751846,
      },
      {
        date: "2023-11-02",
        value: 80506984,
      },
      {
        date: "2023-11-09",
        value: 80683460,
      },
    ],
  },
  errorMessage: "",
};
export const performanceDummydata2 = {
  data: {
    dataPoints: [
      {
        date: "2023-05-11",
        value: 54168372,
      },
      {
        date: "2023-08-11",
        value: 57847579,
      },
      {
        date: "2023-09-10",
        value: 61669524,
      },
      {
        date: "2023-10-10",
        value: 75012952,
      },
      {
        date: "2023-10-19",
        value: 79441446,
      },
      {
        date: "2023-10-26",
        value: 79751846,
      },
      {
        date: "2023-11-02",
        value: 80506984,
      },
      {
        date: "2023-11-09",
        value: 80683460,
      },
    ],
  },
  errorMessage: "",
};

export const testingDummydata = {
  data: {
    dataPoints: [
      {
        date: "2017",
        value: 12656730,
      },
      {
        date: "2018",
        value: 61413808,
      },
      {
        date: "2019",
        value: 75142556,
      },
      {
        date: "2020",
        value: 551501540,
      },
      {
        date: "2021",
        value: 698511046,
      },
      {
        date: "2022",
        value: 885891257,
      },
      {
        date: "2023",
        value: 826302572,
      },
    ],
  },
  errorMessage: "",
};

export const successDistributionDummydata = {
  data: {
    spendPercentiles: {
      failed: 1681,
      initialSuccess: 2354,
      winners: 2017,
      topPerformers: 672,
    },
    dataPoints: [],
  },
  errorMessage: "",
};

export const videoTimelineDummyData = {
  data: {
    videos: [
      {
        videoId: "iTkCystNwcM",
        dataPoints: [
          {
            date: "2020-12-24",
            value: 0,
            views: 0,
          },
          {
            date: "2021-11-03",
            value: 0,
            views: 75862111,
          },
          {
            date: "2021-11-04",
            value: 0,
            views: 75868453,
          },
          {
            date: "2021-11-04",
            value: 0,
            views: 75870908,
          },
          {
            date: "2021-11-04",
            value: 0,
            views: 75870908,
          },
          {
            date: "2021-11-04",
            value: 0,
            views: 75870978,
          },
          {
            date: "2021-11-05",
            value: 0,
            views: 75871738,
          },
          {
            date: "2021-11-06",
            value: 0,
            views: 75874438,
          },
          {
            date: "2021-11-07",
            value: 0,
            views: 75878024,
          },
          {
            date: "2021-11-08",
            value: 0,
            views: 75887658,
          },
          {
            date: "2021-11-09",
            value: 0,
            views: 75892551,
          },
          {
            date: "2021-11-10",
            value: 0,
            views: 75898287,
          },
          {
            date: "2021-11-11",
            value: 0,
            views: 75903561,
          },
          {
            date: "2021-11-12",
            value: 0,
            views: 75905534,
          },
          {
            date: "2021-11-13",
            value: 0,
            views: 75907637,
          },
          {
            date: "2021-11-14",
            value: 0,
            views: 75908607,
          },
          {
            date: "2021-11-15",
            value: 0,
            views: 75909565,
          },
          {
            date: "2021-11-16",
            value: 0,
            views: 75910040,
          },
          {
            date: "2021-11-17",
            value: 0,
            views: 75910551,
          },
          {
            date: "2021-11-18",
            value: 0,
            views: 75910764,
          },
          {
            date: "2021-11-19",
            value: 0,
            views: 75911001,
          },
          {
            date: "2021-11-20",
            value: 0,
            views: 75911500,
          },
          {
            date: "2021-11-21",
            value: 0,
            views: 75911966,
          },
          {
            date: "2021-11-22",
            value: 0,
            views: 75912107,
          },
          {
            date: "2021-11-23",
            value: 0,
            views: 75912240,
          },
          {
            date: "2021-11-24",
            value: 0,
            views: 75912240,
          },
          {
            date: "2021-11-25",
            value: 0,
            views: 75912240,
          },
          {
            date: "2021-11-26",
            value: 0,
            views: 75912243,
          },
          {
            date: "2021-11-27",
            value: 0,
            views: 75912253,
          },
          {
            date: "2021-11-28",
            value: 0,
            views: 75912259,
          },
          {
            date: "2021-11-29",
            value: 0,
            views: 75912272,
          },
          {
            date: "2021-12-01",
            value: 0,
            views: 75912301,
          },
          {
            date: "2021-12-02",
            value: 0,
            views: 75912309,
          },
          {
            date: "2021-12-03",
            value: 0,
            views: 75912320,
          },
          {
            date: "2021-12-04",
            value: 0,
            views: 75912322,
          },
          {
            date: "2021-12-05",
            value: 0,
            views: 75912329,
          },
          {
            date: "2021-12-06",
            value: 0,
            views: 75912333,
          },
          {
            date: "2021-12-07",
            value: 0,
            views: 75912339,
          },
          {
            date: "2021-12-09",
            value: 0,
            views: 75912466,
          },
          {
            date: "2021-12-10",
            value: 0,
            views: 75912502,
          },
          {
            date: "2021-12-12",
            value: 0,
            views: 75912534,
          },
          {
            date: "2021-12-13",
            value: 0,
            views: 75912593,
          },
          {
            date: "2021-12-14",
            value: 0,
            views: 75912675,
          },
          {
            date: "2021-12-15",
            value: 0,
            views: 75912685,
          },
          {
            date: "2021-12-17",
            value: 0,
            views: 75912798,
          },
          {
            date: "2021-12-18",
            value: 0,
            views: 75912865,
          },
          {
            date: "2021-12-19",
            value: 0,
            views: 75912921,
          },
          {
            date: "2021-12-20",
            value: 0,
            views: 75912961,
          },
          {
            date: "2021-12-21",
            value: 0,
            views: 75912978,
          },
          {
            date: "2021-12-22",
            value: 0,
            views: 75913000,
          },
          {
            date: "2021-12-23",
            value: 0,
            views: 75913008,
          },
          {
            date: "2021-12-24",
            value: 0,
            views: 75913014,
          },
          {
            date: "2021-12-25",
            value: 0,
            views: 75913021,
          },
          {
            date: "2021-12-26",
            value: 0,
            views: 75913026,
          },
          {
            date: "2021-12-27",
            value: 0,
            views: 75913036,
          },
          {
            date: "2021-12-28",
            value: 0,
            views: 75913049,
          },
          {
            date: "2021-12-29",
            value: 0,
            views: 75913061,
          },
          {
            date: "2021-12-30",
            value: 0,
            views: 75913075,
          },
          {
            date: "2021-12-31",
            value: 0,
            views: 75913082,
          },
          {
            date: "2022-01-01",
            value: 0,
            views: 75913091,
          },
          {
            date: "2022-01-02",
            value: 0,
            views: 75913103,
          },
          {
            date: "2022-01-03",
            value: 0,
            views: 75913149,
          },
          {
            date: "2022-01-04",
            value: 0,
            views: 75913232,
          },
          {
            date: "2022-01-05",
            value: 0,
            views: 75913267,
          },
          {
            date: "2022-01-06",
            value: 0,
            views: 75913291,
          },
          {
            date: "2022-01-09",
            value: 0,
            views: 75913306,
          },
          {
            date: "2022-01-10",
            value: 0,
            views: 75913312,
          },
          {
            date: "2022-01-11",
            value: 0,
            views: 75913319,
          },
          {
            date: "2022-01-12",
            value: 0,
            views: 75913339,
          },
          {
            date: "2022-01-15",
            value: 0,
            views: 75913357,
          },
          {
            date: "2022-01-17",
            value: 0,
            views: 75913363,
          },
          {
            date: "2022-02-15",
            value: 0,
            views: 75913468,
          },
          {
            date: "2022-04-29",
            value: 0,
            views: 75913828,
          },
          {
            date: "2022-05-02",
            value: 0,
            views: 75913841,
          },
          {
            date: "2022-05-06",
            value: 0,
            views: 75913879,
          },
          {
            date: "2022-05-11",
            value: 0,
            views: 75913894,
          },
          {
            date: "2022-05-13",
            value: 0,
            views: 75913904,
          },
          {
            date: "2022-05-17",
            value: 0,
            views: 75913919,
          },
          {
            date: "2022-05-19",
            value: 0,
            views: 75913926,
          },
          {
            date: "2022-05-20",
            value: 0,
            views: 75913930,
          },
          {
            date: "2022-05-21",
            value: 0,
            views: 75913931,
          },
          {
            date: "2022-05-22",
            value: 0,
            views: 75913933,
          },
          {
            date: "2022-05-23",
            value: 0,
            views: 75913938,
          },
          {
            date: "2022-05-24",
            value: 0,
            views: 75913940,
          },
          {
            date: "2022-05-25",
            value: 0,
            views: 75913945,
          },
          {
            date: "2022-05-26",
            value: 0,
            views: 75913952,
          },
          {
            date: "2022-05-27",
            value: 0,
            views: 75913956,
          },
          {
            date: "2022-05-28",
            value: 0,
            views: 75913959,
          },
          {
            date: "2022-05-29",
            value: 0,
            views: 75913960,
          },
          {
            date: "2022-05-30",
            value: 0,
            views: 75913964,
          },
          {
            date: "2022-05-31",
            value: 0,
            views: 75913973,
          },
          {
            date: "2022-06-01",
            value: 0,
            views: 75913980,
          },
          {
            date: "2022-06-02",
            value: 0,
            views: 75913986,
          },
          {
            date: "2022-06-03",
            value: 0,
            views: 75913987,
          },
          {
            date: "2022-06-04",
            value: 0,
            views: 75913988,
          },
          {
            date: "2022-06-05",
            value: 0,
            views: 75913991,
          },
          {
            date: "2022-06-06",
            value: 0,
            views: 75913995,
          },
          {
            date: "2022-06-07",
            value: 0,
            views: 75913998,
          },
          {
            date: "2022-06-08",
            value: 0,
            views: 75914003,
          },
          {
            date: "2022-06-09",
            value: 0,
            views: 75914006,
          },
          {
            date: "2022-06-10",
            value: 0,
            views: 75914014,
          },
          {
            date: "2022-06-11",
            value: 0,
            views: 75914018,
          },
          {
            date: "2022-06-12",
            value: 0,
            views: 75914018,
          },
          {
            date: "2022-06-13",
            value: 0,
            views: 75914024,
          },
          {
            date: "2022-06-14",
            value: 0,
            views: 75914031,
          },
          {
            date: "2022-06-15",
            value: 0,
            views: 75914034,
          },
          {
            date: "2022-06-16",
            value: 0,
            views: 75914040,
          },
          {
            date: "2022-06-17",
            value: 0,
            views: 75914040,
          },
          {
            date: "2022-06-18",
            value: 0,
            views: 75914043,
          },
          {
            date: "2022-06-19",
            value: 0,
            views: 75914048,
          },
          {
            date: "2022-06-20",
            value: 0,
            views: 75914054,
          },
          {
            date: "2022-06-21",
            value: 0,
            views: 75914058,
          },
          {
            date: "2022-06-22",
            value: 0,
            views: 75914074,
          },
          {
            date: "2022-06-23",
            value: 0,
            views: 75914082,
          },
          {
            date: "2022-06-24",
            value: 0,
            views: 75914086,
          },
          {
            date: "2022-06-25",
            value: 0,
            views: 75914086,
          },
          {
            date: "2022-06-26",
            value: 0,
            views: 75914086,
          },
          {
            date: "2022-06-27",
            value: 0,
            views: 75914091,
          },
          {
            date: "2022-06-28",
            value: 0,
            views: 75914099,
          },
          {
            date: "2022-06-29",
            value: 0,
            views: 75914103,
          },
          {
            date: "2022-06-30",
            value: 0,
            views: 75914106,
          },
          {
            date: "2022-07-01",
            value: 0,
            views: 75914108,
          },
          {
            date: "2022-07-02",
            value: 0,
            views: 75914112,
          },
          {
            date: "2022-07-03",
            value: 0,
            views: 75914113,
          },
          {
            date: "2022-07-04",
            value: 0,
            views: 75914126,
          },
          {
            date: "2022-07-05",
            value: 0,
            views: 75914134,
          },
          {
            date: "2022-07-06",
            value: 0,
            views: 75914138,
          },
          {
            date: "2022-07-07",
            value: 0,
            views: 75914141,
          },
          {
            date: "2022-07-08",
            value: 0,
            views: 75914152,
          },
          {
            date: "2022-07-09",
            value: 0,
            views: 75914155,
          },
          {
            date: "2022-07-10",
            value: 0,
            views: 75914157,
          },
          {
            date: "2022-07-11",
            value: 0,
            views: 75914163,
          },
          {
            date: "2022-07-12",
            value: 0,
            views: 75914169,
          },
          {
            date: "2022-07-13",
            value: 0,
            views: 75914175,
          },
          {
            date: "2022-07-14",
            value: 0,
            views: 75914178,
          },
          {
            date: "2022-07-15",
            value: 0,
            views: 75914182,
          },
          {
            date: "2022-07-16",
            value: 0,
            views: 75914185,
          },
          {
            date: "2022-07-17",
            value: 0,
            views: 75914189,
          },
          {
            date: "2022-07-18",
            value: 0,
            views: 75914196,
          },
          {
            date: "2022-07-19",
            value: 0,
            views: 75914203,
          },
          {
            date: "2022-07-20",
            value: 0,
            views: 75914211,
          },
          {
            date: "2022-07-21",
            value: 0,
            views: 75914217,
          },
          {
            date: "2022-07-22",
            value: 0,
            views: 75914229,
          },
          {
            date: "2022-07-23",
            value: 0,
            views: 75914230,
          },
          {
            date: "2022-07-24",
            value: 0,
            views: 75914232,
          },
          {
            date: "2022-07-25",
            value: 0,
            views: 75914239,
          },
          {
            date: "2022-07-26",
            value: 0,
            views: 75914248,
          },
          {
            date: "2022-07-27",
            value: 0,
            views: 75914260,
          },
          {
            date: "2022-07-28",
            value: 0,
            views: 75914265,
          },
          {
            date: "2022-07-29",
            value: 0,
            views: 75914269,
          },
          {
            date: "2022-07-30",
            value: 0,
            views: 75914272,
          },
          {
            date: "2022-07-31",
            value: 0,
            views: 75914280,
          },
          {
            date: "2022-08-01",
            value: 0,
            views: 75914285,
          },
          {
            date: "2022-08-02",
            value: 0,
            views: 75914297,
          },
          {
            date: "2022-08-03",
            value: 0,
            views: 75914304,
          },
          {
            date: "2022-08-04",
            value: 0,
            views: 75914310,
          },
          {
            date: "2022-08-05",
            value: 0,
            views: 75914314,
          },
          {
            date: "2022-08-06",
            value: 0,
            views: 75914323,
          },
          {
            date: "2022-08-07",
            value: 0,
            views: 75914327,
          },
          {
            date: "2022-08-08",
            value: 0,
            views: 75914332,
          },
          {
            date: "2022-08-09",
            value: 0,
            views: 75914338,
          },
          {
            date: "2022-08-10",
            value: 0,
            views: 75914344,
          },
          {
            date: "2022-08-11",
            value: 0,
            views: 75914354,
          },
          {
            date: "2022-08-12",
            value: 0,
            views: 75914359,
          },
          {
            date: "2022-08-13",
            value: 0,
            views: 75914362,
          },
          {
            date: "2022-08-14",
            value: 0,
            views: 75914369,
          },
          {
            date: "2022-08-15",
            value: 0,
            views: 75914372,
          },
          {
            date: "2022-08-16",
            value: 0,
            views: 75914382,
          },
          {
            date: "2022-08-17",
            value: 0,
            views: 75914389,
          },
          {
            date: "2022-08-18",
            value: 0,
            views: 75914397,
          },
          {
            date: "2022-08-19",
            value: 0,
            views: 75914403,
          },
          {
            date: "2022-08-20",
            value: 0,
            views: 75914407,
          },
          {
            date: "2022-08-21",
            value: 0,
            views: 75914412,
          },
          {
            date: "2022-08-22",
            value: 0,
            views: 75914418,
          },
          {
            date: "2022-08-23",
            value: 0,
            views: 75914425,
          },
          {
            date: "2022-08-24",
            value: 0,
            views: 75914440,
          },
          {
            date: "2022-08-25",
            value: 0,
            views: 75914442,
          },
          {
            date: "2022-08-26",
            value: 0,
            views: 75914449,
          },
          {
            date: "2022-08-27",
            value: 0,
            views: 75914453,
          },
          {
            date: "2022-08-28",
            value: 0,
            views: 75914456,
          },
          {
            date: "2022-08-29",
            value: 0,
            views: 75914466,
          },
          {
            date: "2022-08-30",
            value: 0,
            views: 75914475,
          },
          {
            date: "2022-08-31",
            value: 0,
            views: 75914482,
          },
          {
            date: "2022-09-01",
            value: 0,
            views: 75914489,
          },
          {
            date: "2022-09-02",
            value: 0,
            views: 75914493,
          },
          {
            date: "2022-09-03",
            value: 0,
            views: 75914494,
          },
          {
            date: "2022-09-04",
            value: 0,
            views: 75914500,
          },
          {
            date: "2022-09-05",
            value: 0,
            views: 75914509,
          },
          {
            date: "2022-09-06",
            value: 0,
            views: 75914517,
          },
          {
            date: "2022-09-07",
            value: 0,
            views: 75914521,
          },
          {
            date: "2022-09-08",
            value: 0,
            views: 75914525,
          },
          {
            date: "2022-09-09",
            value: 0,
            views: 75914526,
          },
          {
            date: "2022-09-10",
            value: 0,
            views: 75914528,
          },
          {
            date: "2022-09-11",
            value: 0,
            views: 75914537,
          },
          {
            date: "2022-09-12",
            value: 0,
            views: 75914562,
          },
          {
            date: "2022-09-13",
            value: 0,
            views: 75914570,
          },
          {
            date: "2022-09-14",
            value: 0,
            views: 75914582,
          },
          {
            date: "2022-09-15",
            value: 0,
            views: 75914591,
          },
          {
            date: "2022-09-16",
            value: 0,
            views: 75914598,
          },
          {
            date: "2022-09-17",
            value: 0,
            views: 75914602,
          },
          {
            date: "2022-09-18",
            value: 0,
            views: 75914605,
          },
          {
            date: "2022-09-19",
            value: 0,
            views: 75914614,
          },
          {
            date: "2022-09-20",
            value: 0,
            views: 75914630,
          },
          {
            date: "2022-09-21",
            value: 0,
            views: 75914639,
          },
          {
            date: "2022-09-22",
            value: 0,
            views: 75914643,
          },
          {
            date: "2022-09-23",
            value: 0,
            views: 75914644,
          },
          {
            date: "2022-09-24",
            value: 0,
            views: 75914645,
          },
          {
            date: "2022-09-25",
            value: 0,
            views: 75914649,
          },
          {
            date: "2022-09-26",
            value: 0,
            views: 75914657,
          },
          {
            date: "2022-09-27",
            value: 0,
            views: 75914666,
          },
          {
            date: "2022-09-28",
            value: 0,
            views: 75914672,
          },
          {
            date: "2022-09-29",
            value: 0,
            views: 75914685,
          },
          {
            date: "2022-09-30",
            value: 0,
            views: 75914695,
          },
          {
            date: "2022-10-01",
            value: 0,
            views: 75914700,
          },
          {
            date: "2022-10-02",
            value: 0,
            views: 75914701,
          },
          {
            date: "2022-10-03",
            value: 0,
            views: 75914705,
          },
          {
            date: "2022-10-04",
            value: 0,
            views: 75914713,
          },
          {
            date: "2022-10-05",
            value: 0,
            views: 75914717,
          },
          {
            date: "2022-10-06",
            value: 0,
            views: 75914726,
          },
          {
            date: "2022-10-07",
            value: 0,
            views: 75914731,
          },
          {
            date: "2022-10-08",
            value: 0,
            views: 75914732,
          },
          {
            date: "2022-10-09",
            value: 0,
            views: 75914738,
          },
          {
            date: "2022-10-10",
            value: 0,
            views: 75914751,
          },
          {
            date: "2022-10-11",
            value: 0,
            views: 75914783,
          },
          {
            date: "2022-10-12",
            value: 0,
            views: 75914800,
          },
          {
            date: "2022-10-13",
            value: 0,
            views: 75914812,
          },
          {
            date: "2022-10-14",
            value: 0,
            views: 75914817,
          },
          {
            date: "2022-10-15",
            value: 0,
            views: 75914820,
          },
          {
            date: "2022-10-16",
            value: 0,
            views: 75914829,
          },
          {
            date: "2022-10-17",
            value: 0,
            views: 75914838,
          },
          {
            date: "2022-10-18",
            value: 0,
            views: 75914847,
          },
          {
            date: "2022-10-19",
            value: 0,
            views: 75914857,
          },
          {
            date: "2022-10-20",
            value: 0,
            views: 75914866,
          },
          {
            date: "2022-10-21",
            value: 0,
            views: 75914870,
          },
          {
            date: "2022-10-22",
            value: 0,
            views: 75914878,
          },
          {
            date: "2022-10-23",
            value: 0,
            views: 75914888,
          },
          {
            date: "2022-10-24",
            value: 0,
            views: 75914895,
          },
          {
            date: "2022-10-25",
            value: 0,
            views: 75914901,
          },
          {
            date: "2022-10-26",
            value: 0,
            views: 75914906,
          },
          {
            date: "2022-10-27",
            value: 0,
            views: 75914910,
          },
          {
            date: "2022-10-28",
            value: 0,
            views: 75914911,
          },
          {
            date: "2022-10-29",
            value: 0,
            views: 75914911,
          },
          {
            date: "2022-10-30",
            value: 0,
            views: 75914915,
          },
          {
            date: "2022-10-31",
            value: 0,
            views: 75914921,
          },
          {
            date: "2022-11-01",
            value: 0,
            views: 75914934,
          },
          {
            date: "2022-11-02",
            value: 0,
            views: 75914943,
          },
          {
            date: "2022-11-03",
            value: 0,
            views: 75914947,
          },
          {
            date: "2022-11-04",
            value: 0,
            views: 75914953,
          },
          {
            date: "2022-11-05",
            value: 0,
            views: 75914958,
          },
          {
            date: "2022-11-06",
            value: 0,
            views: 75914964,
          },
          {
            date: "2022-11-07",
            value: 0,
            views: 75914970,
          },
          {
            date: "2022-11-08",
            value: 0,
            views: 75914981,
          },
          {
            date: "2022-11-09",
            value: 0,
            views: 75914992,
          },
          {
            date: "2022-11-15",
            value: 0,
            views: 75915043,
          },
          {
            date: "2022-11-16",
            value: 0,
            views: 75915049,
          },
          {
            date: "2022-11-19",
            value: 0,
            views: 75915062,
          },
          {
            date: "2022-11-20",
            value: 0,
            views: 75915065,
          },
          {
            date: "2022-11-21",
            value: 0,
            views: 75915069,
          },
          {
            date: "2022-11-22",
            value: 0,
            views: 75915078,
          },
          {
            date: "2022-11-23",
            value: 0,
            views: 75915090,
          },
          {
            date: "2022-11-25",
            value: 0,
            views: 75915099,
          },
          {
            date: "2022-11-26",
            value: 0,
            views: 75915102,
          },
          {
            date: "2022-11-27",
            value: 0,
            views: 75915105,
          },
          {
            date: "2022-11-28",
            value: 0,
            views: 75915112,
          },
          {
            date: "2022-11-29",
            value: 0,
            views: 75915118,
          },
          {
            date: "2022-11-30",
            value: 0,
            views: 75915127,
          },
          {
            date: "2022-12-01",
            value: 0,
            views: 75915132,
          },
          {
            date: "2022-12-02",
            value: 0,
            views: 75915137,
          },
          {
            date: "2022-12-03",
            value: 0,
            views: 75915146,
          },
          {
            date: "2022-12-04",
            value: 0,
            views: 75915153,
          },
          {
            date: "2022-12-05",
            value: 0,
            views: 75915160,
          },
          {
            date: "2022-12-06",
            value: 0,
            views: 75915165,
          },
          {
            date: "2022-12-08",
            value: 0,
            views: 75915187,
          },
          {
            date: "2022-12-09",
            value: 0,
            views: 75915193,
          },
          {
            date: "2022-12-10",
            value: 0,
            views: 75915195,
          },
          {
            date: "2022-12-11",
            value: 0,
            views: 75915197,
          },
          {
            date: "2022-12-12",
            value: 0,
            views: 75915203,
          },
          {
            date: "2022-12-13",
            value: 0,
            views: 75915207,
          },
          {
            date: "2022-12-14",
            value: 0,
            views: 75915219,
          },
          {
            date: "2022-12-15",
            value: 0,
            views: 75915230,
          },
          {
            date: "2022-12-16",
            value: 0,
            views: 75915235,
          },
          {
            date: "2022-12-17",
            value: 0,
            views: 75915235,
          },
          {
            date: "2022-12-18",
            value: 0,
            views: 75915238,
          },
          {
            date: "2022-12-19",
            value: 0,
            views: 75915314,
          },
          {
            date: "2022-12-20",
            value: 0,
            views: 75915338,
          },
          {
            date: "2022-12-21",
            value: 0,
            views: 75915349,
          },
          {
            date: "2022-12-22",
            value: 0,
            views: 75915354,
          },
          {
            date: "2022-12-23",
            value: 0,
            views: 75915362,
          },
          {
            date: "2022-12-25",
            value: 0,
            views: 75915366,
          },
          {
            date: "2022-12-26",
            value: 0,
            views: 75915368,
          },
          {
            date: "2022-12-27",
            value: 0,
            views: 75915374,
          },
          {
            date: "2022-12-28",
            value: 0,
            views: 75915385,
          },
          {
            date: "2022-12-29",
            value: 0,
            views: 75915390,
          },
          {
            date: "2022-12-30",
            value: 0,
            views: 75915395,
          },
          {
            date: "2022-12-31",
            value: 0,
            views: 75915395,
          },
          {
            date: "2023-01-01",
            value: 0,
            views: 75915398,
          },
          {
            date: "2023-01-02",
            value: 0,
            views: 75915403,
          },
          {
            date: "2023-01-03",
            value: 0,
            views: 75915405,
          },
          {
            date: "2023-01-04",
            value: 0,
            views: 75915410,
          },
          {
            date: "2023-01-05",
            value: 0,
            views: 75915416,
          },
          {
            date: "2023-01-06",
            value: 0,
            views: 75915421,
          },
          {
            date: "2023-01-07",
            value: 0,
            views: 75915423,
          },
          {
            date: "2023-01-08",
            value: 0,
            views: 75915431,
          },
          {
            date: "2023-01-09",
            value: 0,
            views: 75915439,
          },
          {
            date: "2023-01-10",
            value: 0,
            views: 75915443,
          },
          {
            date: "2023-01-11",
            value: 0,
            views: 75915449,
          },
          {
            date: "2023-01-12",
            value: 0,
            views: 75915462,
          },
          {
            date: "2023-01-13",
            value: 0,
            views: 75915480,
          },
          {
            date: "2023-01-14",
            value: 0,
            views: 75915485,
          },
          {
            date: "2023-01-15",
            value: 0,
            views: 75915487,
          },
          {
            date: "2023-01-16",
            value: 0,
            views: 75915493,
          },
          {
            date: "2023-01-17",
            value: 0,
            views: 75915498,
          },
          {
            date: "2023-01-18",
            value: 0,
            views: 75915501,
          },
          {
            date: "2023-01-19",
            value: 0,
            views: 75915507,
          },
          {
            date: "2023-01-20",
            value: 0,
            views: 75915509,
          },
          {
            date: "2023-01-21",
            value: 0,
            views: 75915514,
          },
          {
            date: "2023-01-22",
            value: 0,
            views: 75915521,
          },
          {
            date: "2023-01-23",
            value: 0,
            views: 75915531,
          },
          {
            date: "2023-01-24",
            value: 0,
            views: 75915538,
          },
          {
            date: "2023-01-25",
            value: 0,
            views: 75915543,
          },
          {
            date: "2023-01-26",
            value: 0,
            views: 75915550,
          },
          {
            date: "2023-01-27",
            value: 0,
            views: 75915558,
          },
          {
            date: "2023-01-29",
            value: 0,
            views: 75915567,
          },
          {
            date: "2023-01-30",
            value: 0,
            views: 75915574,
          },
          {
            date: "2023-01-31",
            value: 0,
            views: 75915579,
          },
          {
            date: "2023-02-01",
            value: 0,
            views: 75915580,
          },
          {
            date: "2023-02-02",
            value: 0,
            views: 75915592,
          },
          {
            date: "2023-02-03",
            value: 0,
            views: 75915601,
          },
          {
            date: "2023-02-04",
            value: 0,
            views: 75915605,
          },
          {
            date: "2023-02-05",
            value: 0,
            views: 75915609,
          },
          {
            date: "2023-02-06",
            value: 0,
            views: 75915614,
          },
          {
            date: "2023-02-08",
            value: 0,
            views: 75915622,
          },
          {
            date: "2023-02-09",
            value: 0,
            views: 75915628,
          },
          {
            date: "2023-02-10",
            value: 0,
            views: 75915634,
          },
          {
            date: "2023-02-12",
            value: 0,
            views: 75915640,
          },
          {
            date: "2023-02-14",
            value: 0,
            views: 75915659,
          },
          {
            date: "2023-02-16",
            value: 0,
            views: 75915679,
          },
          {
            date: "2023-02-18",
            value: 0,
            views: 75915688,
          },
          {
            date: "2023-02-20",
            value: 0,
            views: 75915701,
          },
          {
            date: "2023-02-22",
            value: 0,
            views: 75915711,
          },
          {
            date: "2023-02-24",
            value: 0,
            views: 75915719,
          },
          {
            date: "2023-02-26",
            value: 0,
            views: 75915729,
          },
          {
            date: "2023-02-28",
            value: 0,
            views: 75915745,
          },
          {
            date: "2023-03-02",
            value: 0,
            views: 75915761,
          },
          {
            date: "2023-03-07",
            value: 0,
            views: 75915781,
          },
          {
            date: "2023-03-09",
            value: 0,
            views: 75915792,
          },
          {
            date: "2023-03-15",
            value: 0,
            views: 75915833,
          },
          {
            date: "2023-03-17",
            value: 0,
            views: 75915840,
          },
          {
            date: "2023-03-19",
            value: 0,
            views: 75915850,
          },
          {
            date: "2023-03-21",
            value: 0,
            views: 75915863,
          },
          {
            date: "2023-03-23",
            value: 0,
            views: 75915873,
          },
          {
            date: "2023-03-25",
            value: 0,
            views: 75915878,
          },
          {
            date: "2023-03-28",
            value: 0,
            views: 75915895,
          },
          {
            date: "2023-03-31",
            value: 0,
            views: 75915911,
          },
          {
            date: "2023-04-01",
            value: 0,
            views: 75915914,
          },
          {
            date: "2023-04-03",
            value: 0,
            views: 75915925,
          },
          {
            date: "2023-04-05",
            value: 0,
            views: 75915932,
          },
          {
            date: "2023-04-07",
            value: 0,
            views: 75915942,
          },
          {
            date: "2023-04-09",
            value: 0,
            views: 75915947,
          },
          {
            date: "2023-04-11",
            value: 0,
            views: 75915957,
          },
          {
            date: "2023-04-13",
            value: 0,
            views: 75915968,
          },
          {
            date: "2023-04-15",
            value: 0,
            views: 75915970,
          },
          {
            date: "2023-04-17",
            value: 0,
            views: 75915974,
          },
          {
            date: "2023-04-19",
            value: 0,
            views: 75915983,
          },
          {
            date: "2023-04-21",
            value: 0,
            views: 75915986,
          },
          {
            date: "2023-04-23",
            value: 0,
            views: 75915989,
          },
          {
            date: "2023-05-06",
            value: 0,
            views: 75916038,
          },
          {
            date: "2023-05-13",
            value: 0,
            views: 75916080,
          },
          {
            date: "2023-05-31",
            value: 0,
            views: 75916253,
          },
          {
            date: "2023-06-04",
            value: 0,
            views: 75916306,
          },
          {
            date: "2023-06-05",
            value: 0,
            views: 75916314,
          },
          {
            date: "2023-06-13",
            value: 0,
            views: 75916444,
          },
          {
            date: "2023-07-29",
            value: 0,
            views: 75916749,
          },
          {
            date: "2023-08-02",
            value: 0,
            views: 75916768,
          },
          {
            date: "2023-08-05",
            value: 0,
            views: 75916785,
          },
          {
            date: "2023-08-07",
            value: 0,
            views: 75916797,
          },
          {
            date: "2023-08-09",
            value: 0,
            views: 75916832,
          },
          {
            date: "2023-08-12",
            value: 0,
            views: 75916849,
          },
          {
            date: "2023-08-16",
            value: 0,
            views: 75916875,
          },
          {
            date: "2023-08-18",
            value: 0,
            views: 75916877,
          },
          {
            date: "2023-08-22",
            value: 0,
            views: 75916912,
          },
          {
            date: "2023-08-23",
            value: 0,
            views: 75916915,
          },
          {
            date: "2023-08-25",
            value: 0,
            views: 75916931,
          },
          {
            date: "2023-08-28",
            value: 0,
            views: 75916948,
          },
          {
            date: "2023-08-30",
            value: 0,
            views: 75916958,
          },
          {
            date: "2023-09-05",
            value: 0,
            views: 75917004,
          },
          {
            date: "2023-09-07",
            value: 0,
            views: 75917030,
          },
          {
            date: "2023-09-13",
            value: 0,
            views: 75917087,
          },
          {
            date: "2023-09-16",
            value: 0,
            views: 75917117,
          },
          {
            date: "2023-09-18",
            value: 0,
            views: 75917131,
          },
          {
            date: "2023-09-22",
            value: 0,
            views: 75917155,
          },
          {
            date: "2023-09-25",
            value: 0,
            views: 75917175,
          },
          {
            date: "2023-09-27",
            value: 0,
            views: 75917201,
          },
          {
            date: "2023-09-29",
            value: 0,
            views: 75917228,
          },
          {
            date: "2023-10-02",
            value: 0,
            views: 75917242,
          },
          {
            date: "2023-10-04",
            value: 0,
            views: 75917257,
          },
          {
            date: "2023-10-06",
            value: 0,
            views: 75917273,
          },
          {
            date: "2023-10-09",
            value: 0,
            views: 75917294,
          },
          {
            date: "2023-10-11",
            value: 0,
            views: 75917304,
          },
          {
            date: "2023-10-11",
            value: 0,
            views: 75917305,
          },
          {
            date: "2023-10-17",
            value: 0,
            views: 75917341,
          },
          {
            date: "2023-10-18",
            value: 0,
            views: 75917348,
          },
          {
            date: "2023-10-20",
            value: 0,
            views: 75917363,
          },
          {
            date: "2023-10-24",
            value: 0,
            views: 75917380,
          },
          {
            date: "2023-10-25",
            value: 0,
            views: 75917390,
          },
          {
            date: "2023-10-26",
            value: 0,
            views: 75917392,
          },
          {
            date: "2023-10-27",
            value: 0,
            views: 75917405,
          },
          {
            date: "2023-10-30",
            value: 0,
            views: 75917417,
          },
          {
            date: "2023-11-03",
            value: 0,
            views: 75917445,
          },
          {
            date: "2023-11-07",
            value: 0,
            views: 75917460,
          },
          {
            date: "2023-11-08",
            value: 0,
            views: 75917469,
          },
        ],
      },
      {
        videoId: "qEO5ttBnHSg",
        dataPoints: [
          {
            date: "2020-10-29",
            value: 0,
            views: 0,
          },
          {
            date: "2021-10-08",
            value: 0,
            views: 36151119,
          },
          {
            date: "2021-10-09",
            value: 0,
            views: 36154579,
          },
          {
            date: "2021-10-10",
            value: 0,
            views: 36156751,
          },
          {
            date: "2021-10-11",
            value: 0,
            views: 36157792,
          },
          {
            date: "2021-10-12",
            value: 0,
            views: 36158790,
          },
          {
            date: "2021-10-13",
            value: 0,
            views: 36159099,
          },
          {
            date: "2021-10-14",
            value: 0,
            views: 36159175,
          },
          {
            date: "2021-10-15",
            value: 0,
            views: 36159198,
          },
          {
            date: "2021-10-16",
            value: 0,
            views: 36159218,
          },
          {
            date: "2021-10-17",
            value: 0,
            views: 36159272,
          },
          {
            date: "2021-10-18",
            value: 0,
            views: 36159345,
          },
          {
            date: "2021-10-19",
            value: 0,
            views: 36159423,
          },
          {
            date: "2021-10-20",
            value: 0,
            views: 36159462,
          },
          {
            date: "2021-10-21",
            value: 0,
            views: 36159467,
          },
          {
            date: "2021-10-22",
            value: 0,
            views: 36159494,
          },
          {
            date: "2021-10-23",
            value: 0,
            views: 36159662,
          },
          {
            date: "2021-10-24",
            value: 0,
            views: 36159918,
          },
          {
            date: "2021-10-25",
            value: 0,
            views: 36159969,
          },
          {
            date: "2021-10-26",
            value: 0,
            views: 36159981,
          },
          {
            date: "2021-10-27",
            value: 0,
            views: 36159998,
          },
          {
            date: "2021-10-28",
            value: 0,
            views: 36160004,
          },
          {
            date: "2021-10-29",
            value: 0,
            views: 36160012,
          },
          {
            date: "2021-10-30",
            value: 0,
            views: 36160030,
          },
          {
            date: "2021-10-31",
            value: 0,
            views: 36160044,
          },
          {
            date: "2021-11-01",
            value: 0,
            views: 36160072,
          },
          {
            date: "2021-11-02",
            value: 0,
            views: 36160095,
          },
          {
            date: "2021-11-03",
            value: 0,
            views: 36160110,
          },
          {
            date: "2021-11-04",
            value: 0,
            views: 36160123,
          },
          {
            date: "2021-11-04",
            value: 0,
            views: 36160153,
          },
          {
            date: "2021-11-04",
            value: 0,
            views: 36160153,
          },
          {
            date: "2021-11-04",
            value: 0,
            views: 36160155,
          },
          {
            date: "2021-11-05",
            value: 0,
            views: 36160156,
          },
          {
            date: "2021-11-06",
            value: 0,
            views: 36160175,
          },
          {
            date: "2021-11-07",
            value: 0,
            views: 36160187,
          },
          {
            date: "2021-11-08",
            value: 0,
            views: 36160208,
          },
          {
            date: "2021-11-09",
            value: 0,
            views: 36160230,
          },
          {
            date: "2021-11-10",
            value: 0,
            views: 36160240,
          },
          {
            date: "2021-11-11",
            value: 0,
            views: 36160246,
          },
          {
            date: "2021-11-12",
            value: 0,
            views: 36160253,
          },
          {
            date: "2021-11-13",
            value: 0,
            views: 36160262,
          },
          {
            date: "2021-11-14",
            value: 0,
            views: 36160271,
          },
          {
            date: "2021-11-15",
            value: 0,
            views: 36160282,
          },
          {
            date: "2021-11-17",
            value: 0,
            views: 36160294,
          },
          {
            date: "2021-11-18",
            value: 0,
            views: 36160304,
          },
          {
            date: "2021-11-19",
            value: 0,
            views: 36160312,
          },
          {
            date: "2021-11-20",
            value: 0,
            views: 36160317,
          },
          {
            date: "2021-11-21",
            value: 0,
            views: 36160325,
          },
          {
            date: "2021-11-22",
            value: 0,
            views: 36160334,
          },
          {
            date: "2021-11-23",
            value: 0,
            views: 36160345,
          },
          {
            date: "2021-11-24",
            value: 0,
            views: 36160352,
          },
          {
            date: "2021-11-25",
            value: 0,
            views: 36160354,
          },
          {
            date: "2021-11-26",
            value: 0,
            views: 36160357,
          },
          {
            date: "2021-11-27",
            value: 0,
            views: 36160357,
          },
          {
            date: "2021-11-28",
            value: 0,
            views: 36160362,
          },
          {
            date: "2021-12-02",
            value: 0,
            views: 36160387,
          },
          {
            date: "2021-12-05",
            value: 0,
            views: 36160393,
          },
          {
            date: "2021-12-12",
            value: 0,
            views: 36160459,
          },
          {
            date: "2021-12-15",
            value: 0,
            views: 36160498,
          },
          {
            date: "2021-12-17",
            value: 0,
            views: 36160509,
          },
          {
            date: "2021-12-19",
            value: 0,
            views: 36160509,
          },
          {
            date: "2021-12-21",
            value: 0,
            views: 36160513,
          },
          {
            date: "2021-12-22",
            value: 0,
            views: 36160513,
          },
          {
            date: "2021-12-24",
            value: 0,
            views: 36160518,
          },
          {
            date: "2021-12-25",
            value: 0,
            views: 36160522,
          },
          {
            date: "2021-12-26",
            value: 0,
            views: 36160524,
          },
          {
            date: "2021-12-27",
            value: 0,
            views: 36160528,
          },
          {
            date: "2021-12-29",
            value: 0,
            views: 36160537,
          },
          {
            date: "2021-12-30",
            value: 0,
            views: 36160539,
          },
          {
            date: "2021-12-31",
            value: 0,
            views: 36160541,
          },
          {
            date: "2022-01-01",
            value: 0,
            views: 36160541,
          },
          {
            date: "2022-01-03",
            value: 0,
            views: 36160545,
          },
          {
            date: "2022-01-05",
            value: 0,
            views: 36160556,
          },
          {
            date: "2022-04-29",
            value: 0,
            views: 36160737,
          },
          {
            date: "2022-05-02",
            value: 0,
            views: 36160742,
          },
          {
            date: "2022-05-06",
            value: 0,
            views: 36160750,
          },
          {
            date: "2022-05-11",
            value: 0,
            views: 36160758,
          },
          {
            date: "2022-05-13",
            value: 0,
            views: 36160760,
          },
          {
            date: "2022-05-17",
            value: 0,
            views: 36160763,
          },
          {
            date: "2022-05-19",
            value: 0,
            views: 36160766,
          },
          {
            date: "2022-05-20",
            value: 0,
            views: 36160766,
          },
          {
            date: "2022-05-21",
            value: 0,
            views: 36160767,
          },
          {
            date: "2022-05-22",
            value: 0,
            views: 36160770,
          },
          {
            date: "2022-05-23",
            value: 0,
            views: 36160776,
          },
          {
            date: "2022-05-24",
            value: 0,
            views: 36160779,
          },
          {
            date: "2022-05-25",
            value: 0,
            views: 36160780,
          },
          {
            date: "2022-05-26",
            value: 0,
            views: 36160782,
          },
          {
            date: "2022-05-27",
            value: 0,
            views: 36160783,
          },
          {
            date: "2022-05-28",
            value: 0,
            views: 36160783,
          },
          {
            date: "2022-05-29",
            value: 0,
            views: 36160784,
          },
          {
            date: "2022-05-30",
            value: 0,
            views: 36160785,
          },
          {
            date: "2022-05-31",
            value: 0,
            views: 36160785,
          },
          {
            date: "2022-06-01",
            value: 0,
            views: 36160791,
          },
          {
            date: "2022-06-02",
            value: 0,
            views: 36160793,
          },
          {
            date: "2022-06-03",
            value: 0,
            views: 36160793,
          },
          {
            date: "2022-06-04",
            value: 0,
            views: 36160795,
          },
          {
            date: "2022-06-05",
            value: 0,
            views: 36160795,
          },
          {
            date: "2022-06-06",
            value: 0,
            views: 36160795,
          },
          {
            date: "2022-06-07",
            value: 0,
            views: 36160795,
          },
          {
            date: "2022-06-08",
            value: 0,
            views: 36160796,
          },
          {
            date: "2022-06-09",
            value: 0,
            views: 36160797,
          },
          {
            date: "2022-06-10",
            value: 0,
            views: 36160799,
          },
          {
            date: "2022-06-11",
            value: 0,
            views: 36160799,
          },
          {
            date: "2022-06-12",
            value: 0,
            views: 36160799,
          },
          {
            date: "2022-06-13",
            value: 0,
            views: 36160804,
          },
          {
            date: "2022-06-14",
            value: 0,
            views: 36160804,
          },
          {
            date: "2022-06-15",
            value: 0,
            views: 36160804,
          },
          {
            date: "2022-06-16",
            value: 0,
            views: 36160811,
          },
          {
            date: "2022-06-17",
            value: 0,
            views: 36160811,
          },
          {
            date: "2022-06-18",
            value: 0,
            views: 36160811,
          },
          {
            date: "2022-06-19",
            value: 0,
            views: 36160812,
          },
          {
            date: "2022-06-20",
            value: 0,
            views: 36160820,
          },
          {
            date: "2022-06-21",
            value: 0,
            views: 36160822,
          },
          {
            date: "2022-06-22",
            value: 0,
            views: 36160828,
          },
          {
            date: "2022-06-23",
            value: 0,
            views: 36160831,
          },
          {
            date: "2022-06-24",
            value: 0,
            views: 36160832,
          },
          {
            date: "2022-06-25",
            value: 0,
            views: 36160832,
          },
          {
            date: "2022-06-26",
            value: 0,
            views: 36160832,
          },
          {
            date: "2022-06-27",
            value: 0,
            views: 36160833,
          },
          {
            date: "2022-06-28",
            value: 0,
            views: 36160833,
          },
          {
            date: "2022-06-29",
            value: 0,
            views: 36160834,
          },
          {
            date: "2022-06-30",
            value: 0,
            views: 36160835,
          },
          {
            date: "2022-07-01",
            value: 0,
            views: 36160835,
          },
          {
            date: "2022-07-02",
            value: 0,
            views: 36160837,
          },
          {
            date: "2022-07-03",
            value: 0,
            views: 36160839,
          },
          {
            date: "2022-07-04",
            value: 0,
            views: 36160844,
          },
          {
            date: "2022-07-05",
            value: 0,
            views: 36160846,
          },
          {
            date: "2022-07-06",
            value: 0,
            views: 36160846,
          },
          {
            date: "2022-07-07",
            value: 0,
            views: 36160846,
          },
          {
            date: "2022-07-08",
            value: 0,
            views: 36160850,
          },
          {
            date: "2022-07-09",
            value: 0,
            views: 36160852,
          },
          {
            date: "2022-07-10",
            value: 0,
            views: 36160852,
          },
          {
            date: "2022-07-11",
            value: 0,
            views: 36160853,
          },
          {
            date: "2022-07-12",
            value: 0,
            views: 36160854,
          },
          {
            date: "2022-07-13",
            value: 0,
            views: 36160858,
          },
          {
            date: "2022-07-14",
            value: 0,
            views: 36160859,
          },
          {
            date: "2022-07-15",
            value: 0,
            views: 36160859,
          },
          {
            date: "2022-07-16",
            value: 0,
            views: 36160859,
          },
          {
            date: "2022-07-17",
            value: 0,
            views: 36160861,
          },
          {
            date: "2022-07-18",
            value: 0,
            views: 36160865,
          },
          {
            date: "2022-07-19",
            value: 0,
            views: 36160866,
          },
          {
            date: "2022-07-20",
            value: 0,
            views: 36160866,
          },
          {
            date: "2022-07-21",
            value: 0,
            views: 36160868,
          },
          {
            date: "2022-07-22",
            value: 0,
            views: 36160874,
          },
          {
            date: "2022-07-23",
            value: 0,
            views: 36160874,
          },
          {
            date: "2022-07-24",
            value: 0,
            views: 36160874,
          },
          {
            date: "2022-07-25",
            value: 0,
            views: 36160877,
          },
          {
            date: "2022-07-26",
            value: 0,
            views: 36160877,
          },
          {
            date: "2022-07-27",
            value: 0,
            views: 36160880,
          },
          {
            date: "2022-07-28",
            value: 0,
            views: 36160881,
          },
          {
            date: "2022-07-29",
            value: 0,
            views: 36160883,
          },
          {
            date: "2022-07-30",
            value: 0,
            views: 36160884,
          },
          {
            date: "2022-07-31",
            value: 0,
            views: 36160887,
          },
          {
            date: "2022-08-01",
            value: 0,
            views: 36160891,
          },
          {
            date: "2022-08-02",
            value: 0,
            views: 36160895,
          },
          {
            date: "2022-08-03",
            value: 0,
            views: 36160896,
          },
          {
            date: "2022-08-04",
            value: 0,
            views: 36160898,
          },
          {
            date: "2022-08-05",
            value: 0,
            views: 36160899,
          },
          {
            date: "2022-08-06",
            value: 0,
            views: 36160899,
          },
          {
            date: "2022-08-07",
            value: 0,
            views: 36160899,
          },
          {
            date: "2022-08-08",
            value: 0,
            views: 36160902,
          },
          {
            date: "2022-08-09",
            value: 0,
            views: 36160904,
          },
          {
            date: "2022-08-10",
            value: 0,
            views: 36160905,
          },
          {
            date: "2022-08-11",
            value: 0,
            views: 36160907,
          },
          {
            date: "2022-08-12",
            value: 0,
            views: 36160909,
          },
          {
            date: "2022-08-13",
            value: 0,
            views: 36160910,
          },
          {
            date: "2022-08-14",
            value: 0,
            views: 36160910,
          },
          {
            date: "2022-08-15",
            value: 0,
            views: 36160911,
          },
          {
            date: "2022-08-16",
            value: 0,
            views: 36160917,
          },
          {
            date: "2022-08-17",
            value: 0,
            views: 36160919,
          },
          {
            date: "2022-08-18",
            value: 0,
            views: 36160921,
          },
          {
            date: "2022-08-19",
            value: 0,
            views: 36160921,
          },
          {
            date: "2022-08-20",
            value: 0,
            views: 36160921,
          },
          {
            date: "2022-08-21",
            value: 0,
            views: 36160922,
          },
          {
            date: "2022-08-22",
            value: 0,
            views: 36160926,
          },
          {
            date: "2022-08-23",
            value: 0,
            views: 36160928,
          },
          {
            date: "2022-08-24",
            value: 0,
            views: 36160933,
          },
          {
            date: "2022-08-25",
            value: 0,
            views: 36160937,
          },
          {
            date: "2022-08-26",
            value: 0,
            views: 36160938,
          },
          {
            date: "2022-08-27",
            value: 0,
            views: 36160941,
          },
          {
            date: "2022-08-28",
            value: 0,
            views: 36160941,
          },
          {
            date: "2022-08-29",
            value: 0,
            views: 36160942,
          },
          {
            date: "2022-08-30",
            value: 0,
            views: 36160942,
          },
          {
            date: "2022-08-31",
            value: 0,
            views: 36160943,
          },
          {
            date: "2022-09-01",
            value: 0,
            views: 36160943,
          },
          {
            date: "2022-09-02",
            value: 0,
            views: 36160944,
          },
          {
            date: "2022-09-03",
            value: 0,
            views: 36160944,
          },
          {
            date: "2022-09-04",
            value: 0,
            views: 36160949,
          },
          {
            date: "2022-09-05",
            value: 0,
            views: 36160953,
          },
          {
            date: "2022-09-06",
            value: 0,
            views: 36160955,
          },
          {
            date: "2022-09-07",
            value: 0,
            views: 36160957,
          },
          {
            date: "2022-09-08",
            value: 0,
            views: 36160958,
          },
          {
            date: "2022-09-09",
            value: 0,
            views: 36160959,
          },
          {
            date: "2022-09-10",
            value: 0,
            views: 36160959,
          },
          {
            date: "2022-09-11",
            value: 0,
            views: 36160971,
          },
          {
            date: "2022-09-12",
            value: 0,
            views: 36160978,
          },
          {
            date: "2022-09-13",
            value: 0,
            views: 36160981,
          },
          {
            date: "2022-09-14",
            value: 0,
            views: 36160987,
          },
          {
            date: "2022-09-15",
            value: 0,
            views: 36160992,
          },
          {
            date: "2022-09-16",
            value: 0,
            views: 36160994,
          },
          {
            date: "2022-09-17",
            value: 0,
            views: 36160996,
          },
          {
            date: "2022-09-18",
            value: 0,
            views: 36160997,
          },
          {
            date: "2022-09-19",
            value: 0,
            views: 36160998,
          },
          {
            date: "2022-09-20",
            value: 0,
            views: 36161002,
          },
          {
            date: "2022-09-21",
            value: 0,
            views: 36161006,
          },
          {
            date: "2022-09-22",
            value: 0,
            views: 36161006,
          },
          {
            date: "2022-09-23",
            value: 0,
            views: 36161006,
          },
          {
            date: "2022-09-24",
            value: 0,
            views: 36161006,
          },
          {
            date: "2022-09-25",
            value: 0,
            views: 36161006,
          },
          {
            date: "2022-09-26",
            value: 0,
            views: 36161012,
          },
          {
            date: "2022-09-27",
            value: 0,
            views: 36161017,
          },
          {
            date: "2022-09-28",
            value: 0,
            views: 36161020,
          },
          {
            date: "2022-09-29",
            value: 0,
            views: 36161024,
          },
          {
            date: "2022-09-30",
            value: 0,
            views: 36161027,
          },
          {
            date: "2022-10-01",
            value: 0,
            views: 36161032,
          },
          {
            date: "2022-10-02",
            value: 0,
            views: 36161032,
          },
          {
            date: "2022-10-03",
            value: 0,
            views: 36161034,
          },
          {
            date: "2022-10-04",
            value: 0,
            views: 36161036,
          },
          {
            date: "2022-10-05",
            value: 0,
            views: 36161036,
          },
          {
            date: "2022-10-06",
            value: 0,
            views: 36161037,
          },
          {
            date: "2022-10-07",
            value: 0,
            views: 36161039,
          },
          {
            date: "2022-10-08",
            value: 0,
            views: 36161043,
          },
          {
            date: "2022-10-09",
            value: 0,
            views: 36161044,
          },
          {
            date: "2022-10-10",
            value: 0,
            views: 36161046,
          },
          {
            date: "2022-10-11",
            value: 0,
            views: 36161049,
          },
          {
            date: "2022-10-12",
            value: 0,
            views: 36161056,
          },
          {
            date: "2022-10-13",
            value: 0,
            views: 36161057,
          },
          {
            date: "2022-10-14",
            value: 0,
            views: 36161058,
          },
          {
            date: "2022-10-15",
            value: 0,
            views: 36161058,
          },
          {
            date: "2022-10-16",
            value: 0,
            views: 36161059,
          },
          {
            date: "2022-10-17",
            value: 0,
            views: 36161062,
          },
          {
            date: "2022-10-18",
            value: 0,
            views: 36161062,
          },
          {
            date: "2022-10-19",
            value: 0,
            views: 36161065,
          },
          {
            date: "2022-10-20",
            value: 0,
            views: 36161067,
          },
          {
            date: "2022-10-21",
            value: 0,
            views: 36161069,
          },
          {
            date: "2022-10-22",
            value: 0,
            views: 36161069,
          },
          {
            date: "2022-10-23",
            value: 0,
            views: 36161072,
          },
          {
            date: "2022-10-24",
            value: 0,
            views: 36161076,
          },
          {
            date: "2022-10-25",
            value: 0,
            views: 36161078,
          },
          {
            date: "2022-10-26",
            value: 0,
            views: 36161079,
          },
          {
            date: "2022-10-27",
            value: 0,
            views: 36161079,
          },
          {
            date: "2022-10-28",
            value: 0,
            views: 36161080,
          },
          {
            date: "2022-10-29",
            value: 0,
            views: 36161080,
          },
          {
            date: "2022-10-30",
            value: 0,
            views: 36161080,
          },
          {
            date: "2022-10-31",
            value: 0,
            views: 36161080,
          },
          {
            date: "2022-11-01",
            value: 0,
            views: 36161086,
          },
          {
            date: "2022-11-02",
            value: 0,
            views: 36161089,
          },
          {
            date: "2022-11-03",
            value: 0,
            views: 36161089,
          },
          {
            date: "2022-11-04",
            value: 0,
            views: 36161090,
          },
          {
            date: "2022-11-05",
            value: 0,
            views: 36161092,
          },
          {
            date: "2022-11-06",
            value: 0,
            views: 36161092,
          },
          {
            date: "2022-11-07",
            value: 0,
            views: 36161095,
          },
          {
            date: "2022-11-08",
            value: 0,
            views: 36161096,
          },
          {
            date: "2022-11-09",
            value: 0,
            views: 36161099,
          },
          {
            date: "2022-11-15",
            value: 0,
            views: 36161106,
          },
          {
            date: "2022-11-16",
            value: 0,
            views: 36161114,
          },
          {
            date: "2022-11-19",
            value: 0,
            views: 36161117,
          },
          {
            date: "2022-11-20",
            value: 0,
            views: 36161119,
          },
          {
            date: "2022-11-21",
            value: 0,
            views: 36161120,
          },
          {
            date: "2022-11-22",
            value: 0,
            views: 36161123,
          },
          {
            date: "2022-11-23",
            value: 0,
            views: 36161125,
          },
          {
            date: "2022-11-25",
            value: 0,
            views: 36161130,
          },
          {
            date: "2022-11-26",
            value: 0,
            views: 36161130,
          },
          {
            date: "2022-11-27",
            value: 0,
            views: 36161131,
          },
          {
            date: "2022-11-28",
            value: 0,
            views: 36161133,
          },
          {
            date: "2022-11-29",
            value: 0,
            views: 36161134,
          },
          {
            date: "2022-11-30",
            value: 0,
            views: 36161136,
          },
          {
            date: "2022-12-01",
            value: 0,
            views: 36161138,
          },
          {
            date: "2022-12-02",
            value: 0,
            views: 36161139,
          },
          {
            date: "2022-12-03",
            value: 0,
            views: 36161139,
          },
          {
            date: "2022-12-04",
            value: 0,
            views: 36161141,
          },
          {
            date: "2022-12-05",
            value: 0,
            views: 36161142,
          },
          {
            date: "2022-12-06",
            value: 0,
            views: 36161152,
          },
          {
            date: "2022-12-08",
            value: 0,
            views: 36161173,
          },
          {
            date: "2022-12-09",
            value: 0,
            views: 36161175,
          },
          {
            date: "2022-12-10",
            value: 0,
            views: 36161177,
          },
          {
            date: "2022-12-11",
            value: 0,
            views: 36161181,
          },
          {
            date: "2022-12-12",
            value: 0,
            views: 36161189,
          },
          {
            date: "2022-12-13",
            value: 0,
            views: 36161191,
          },
          {
            date: "2022-12-14",
            value: 0,
            views: 36161196,
          },
          {
            date: "2022-12-15",
            value: 0,
            views: 36161202,
          },
          {
            date: "2022-12-16",
            value: 0,
            views: 36161202,
          },
          {
            date: "2022-12-17",
            value: 0,
            views: 36161202,
          },
          {
            date: "2022-12-18",
            value: 0,
            views: 36161204,
          },
          {
            date: "2022-12-19",
            value: 0,
            views: 36161208,
          },
          {
            date: "2022-12-20",
            value: 0,
            views: 36161213,
          },
          {
            date: "2022-12-21",
            value: 0,
            views: 36161216,
          },
          {
            date: "2022-12-22",
            value: 0,
            views: 36161217,
          },
          {
            date: "2022-12-23",
            value: 0,
            views: 36161217,
          },
          {
            date: "2022-12-25",
            value: 0,
            views: 36161220,
          },
          {
            date: "2022-12-26",
            value: 0,
            views: 36161221,
          },
          {
            date: "2022-12-27",
            value: 0,
            views: 36161223,
          },
          {
            date: "2022-12-28",
            value: 0,
            views: 36161227,
          },
          {
            date: "2022-12-29",
            value: 0,
            views: 36161229,
          },
          {
            date: "2022-12-30",
            value: 0,
            views: 36161231,
          },
          {
            date: "2022-12-31",
            value: 0,
            views: 36161232,
          },
          {
            date: "2023-01-01",
            value: 0,
            views: 36161233,
          },
          {
            date: "2023-01-02",
            value: 0,
            views: 36161234,
          },
          {
            date: "2023-01-03",
            value: 0,
            views: 36161236,
          },
          {
            date: "2023-01-04",
            value: 0,
            views: 36161238,
          },
          {
            date: "2023-01-05",
            value: 0,
            views: 36161241,
          },
          {
            date: "2023-01-06",
            value: 0,
            views: 36161244,
          },
          {
            date: "2023-01-07",
            value: 0,
            views: 36161246,
          },
          {
            date: "2023-01-08",
            value: 0,
            views: 36161251,
          },
          {
            date: "2023-01-09",
            value: 0,
            views: 36161253,
          },
          {
            date: "2023-01-10",
            value: 0,
            views: 36161255,
          },
          {
            date: "2023-01-11",
            value: 0,
            views: 36161259,
          },
          {
            date: "2023-01-12",
            value: 0,
            views: 36161261,
          },
          {
            date: "2023-01-13",
            value: 0,
            views: 36161264,
          },
          {
            date: "2023-01-14",
            value: 0,
            views: 36161266,
          },
          {
            date: "2023-01-15",
            value: 0,
            views: 36161269,
          },
          {
            date: "2023-01-16",
            value: 0,
            views: 36161275,
          },
          {
            date: "2023-01-17",
            value: 0,
            views: 36161278,
          },
          {
            date: "2023-01-18",
            value: 0,
            views: 36161282,
          },
          {
            date: "2023-01-19",
            value: 0,
            views: 36161286,
          },
          {
            date: "2023-01-20",
            value: 0,
            views: 36161291,
          },
          {
            date: "2023-01-21",
            value: 0,
            views: 36161292,
          },
          {
            date: "2023-01-22",
            value: 0,
            views: 36161297,
          },
          {
            date: "2023-01-23",
            value: 0,
            views: 36161300,
          },
          {
            date: "2023-01-24",
            value: 0,
            views: 36161306,
          },
          {
            date: "2023-01-25",
            value: 0,
            views: 36161310,
          },
          {
            date: "2023-01-26",
            value: 0,
            views: 36161320,
          },
          {
            date: "2023-01-27",
            value: 0,
            views: 36161332,
          },
          {
            date: "2023-01-29",
            value: 0,
            views: 36161344,
          },
          {
            date: "2023-01-30",
            value: 0,
            views: 36161348,
          },
          {
            date: "2023-01-31",
            value: 0,
            views: 36161354,
          },
          {
            date: "2023-02-01",
            value: 0,
            views: 36161358,
          },
          {
            date: "2023-02-02",
            value: 0,
            views: 36161362,
          },
          {
            date: "2023-02-03",
            value: 0,
            views: 36161370,
          },
          {
            date: "2023-02-04",
            value: 0,
            views: 36161371,
          },
          {
            date: "2023-02-06",
            value: 0,
            views: 36161377,
          },
          {
            date: "2023-02-08",
            value: 0,
            views: 36161387,
          },
          {
            date: "2023-02-09",
            value: 0,
            views: 36161391,
          },
          {
            date: "2023-02-10",
            value: 0,
            views: 36161392,
          },
          {
            date: "2023-02-12",
            value: 0,
            views: 36161400,
          },
          {
            date: "2023-02-14",
            value: 0,
            views: 36161411,
          },
          {
            date: "2023-02-16",
            value: 0,
            views: 36161423,
          },
          {
            date: "2023-02-18",
            value: 0,
            views: 36161432,
          },
          {
            date: "2023-02-20",
            value: 0,
            views: 36161442,
          },
          {
            date: "2023-02-22",
            value: 0,
            views: 36161449,
          },
          {
            date: "2023-02-24",
            value: 0,
            views: 36161455,
          },
          {
            date: "2023-02-26",
            value: 0,
            views: 36161463,
          },
          {
            date: "2023-02-28",
            value: 0,
            views: 36161474,
          },
          {
            date: "2023-03-02",
            value: 0,
            views: 36161477,
          },
          {
            date: "2023-03-07",
            value: 0,
            views: 36161500,
          },
          {
            date: "2023-03-09",
            value: 0,
            views: 36161508,
          },
          {
            date: "2023-03-15",
            value: 0,
            views: 36161524,
          },
          {
            date: "2023-03-17",
            value: 0,
            views: 36161530,
          },
          {
            date: "2023-03-19",
            value: 0,
            views: 36161535,
          },
          {
            date: "2023-03-21",
            value: 0,
            views: 36161538,
          },
          {
            date: "2023-03-23",
            value: 0,
            views: 36161546,
          },
          {
            date: "2023-03-25",
            value: 0,
            views: 36161548,
          },
          {
            date: "2023-03-28",
            value: 0,
            views: 36161554,
          },
          {
            date: "2023-03-31",
            value: 0,
            views: 36161561,
          },
          {
            date: "2023-04-01",
            value: 0,
            views: 36161563,
          },
          {
            date: "2023-04-03",
            value: 0,
            views: 36161567,
          },
          {
            date: "2023-04-05",
            value: 0,
            views: 36161571,
          },
          {
            date: "2023-04-07",
            value: 0,
            views: 36161574,
          },
          {
            date: "2023-04-09",
            value: 0,
            views: 36161583,
          },
          {
            date: "2023-04-11",
            value: 0,
            views: 36161587,
          },
          {
            date: "2023-04-13",
            value: 0,
            views: 36161594,
          },
          {
            date: "2023-04-15",
            value: 0,
            views: 36161594,
          },
          {
            date: "2023-04-17",
            value: 0,
            views: 36161596,
          },
          {
            date: "2023-04-19",
            value: 0,
            views: 36161605,
          },
          {
            date: "2023-04-21",
            value: 0,
            views: 36161605,
          },
          {
            date: "2023-04-23",
            value: 0,
            views: 36161608,
          },
          {
            date: "2023-05-07",
            value: 0,
            views: 36161625,
          },
          {
            date: "2023-05-13",
            value: 0,
            views: 36161647,
          },
          {
            date: "2023-06-04",
            value: 0,
            views: 36161794,
          },
          {
            date: "2023-06-06",
            value: 0,
            views: 36161799,
          },
          {
            date: "2023-06-13",
            value: 0,
            views: 36161857,
          },
          {
            date: "2023-07-30",
            value: 0,
            views: 36161993,
          },
          {
            date: "2023-08-02",
            value: 0,
            views: 36162001,
          },
          {
            date: "2023-08-05",
            value: 0,
            views: 36162014,
          },
          {
            date: "2023-08-07",
            value: 0,
            views: 36162026,
          },
          {
            date: "2023-08-10",
            value: 0,
            views: 36162041,
          },
          {
            date: "2023-08-13",
            value: 0,
            views: 36162049,
          },
          {
            date: "2023-08-16",
            value: 0,
            views: 36162055,
          },
          {
            date: "2023-08-18",
            value: 0,
            views: 36162056,
          },
          {
            date: "2023-08-23",
            value: 0,
            views: 36162070,
          },
          {
            date: "2023-08-24",
            value: 0,
            views: 36162071,
          },
          {
            date: "2023-08-25",
            value: 0,
            views: 36162073,
          },
          {
            date: "2023-08-28",
            value: 0,
            views: 36162077,
          },
          {
            date: "2023-08-31",
            value: 0,
            views: 36162081,
          },
          {
            date: "2023-09-05",
            value: 0,
            views: 36162099,
          },
          {
            date: "2023-09-07",
            value: 0,
            views: 36162103,
          },
          {
            date: "2023-09-11",
            value: 0,
            views: 36162113,
          },
          {
            date: "2023-09-16",
            value: 0,
            views: 36162123,
          },
          {
            date: "2023-09-19",
            value: 0,
            views: 36162125,
          },
          {
            date: "2023-09-20",
            value: 0,
            views: 36162127,
          },
          {
            date: "2023-09-22",
            value: 0,
            views: 36162139,
          },
          {
            date: "2023-09-25",
            value: 0,
            views: 36162140,
          },
          {
            date: "2023-09-27",
            value: 0,
            views: 36162144,
          },
          {
            date: "2023-09-29",
            value: 0,
            views: 36162148,
          },
          {
            date: "2023-10-02",
            value: 0,
            views: 36162162,
          },
          {
            date: "2023-10-05",
            value: 0,
            views: 36162164,
          },
          {
            date: "2023-10-06",
            value: 0,
            views: 36162166,
          },
          {
            date: "2023-10-10",
            value: 0,
            views: 36162173,
          },
          {
            date: "2023-10-11",
            value: 0,
            views: 36162175,
          },
          {
            date: "2023-10-13",
            value: 0,
            views: 36162182,
          },
          {
            date: "2023-10-17",
            value: 0,
            views: 36162189,
          },
          {
            date: "2023-10-18",
            value: 0,
            views: 36162192,
          },
          {
            date: "2023-10-21",
            value: 0,
            views: 36162196,
          },
          {
            date: "2023-10-25",
            value: 0,
            views: 36162207,
          },
          {
            date: "2023-10-26",
            value: 0,
            views: 36162210,
          },
          {
            date: "2023-10-27",
            value: 0,
            views: 36162214,
          },
          {
            date: "2023-10-31",
            value: 0,
            views: 36162222,
          },
          {
            date: "2023-11-02",
            value: 0,
            views: 36162229,
          },
          {
            date: "2023-11-04",
            value: 0,
            views: 36162234,
          },
        ],
      },
      {
        videoId: "ruZmITxlKvk",
        dataPoints: [
          {
            date: "2020-09-01",
            value: 0,
            views: 0,
          },
          {
            date: "2021-10-06",
            value: 0,
            views: 35126974,
          },
          {
            date: "2021-10-07",
            value: 0,
            views: 35127021,
          },
          {
            date: "2021-10-08",
            value: 0,
            views: 35127235,
          },
          {
            date: "2021-10-09",
            value: 0,
            views: 35127574,
          },
          {
            date: "2021-10-10",
            value: 0,
            views: 35127872,
          },
          {
            date: "2021-10-11",
            value: 0,
            views: 35128129,
          },
          {
            date: "2021-10-12",
            value: 0,
            views: 35128494,
          },
          {
            date: "2021-10-13",
            value: 0,
            views: 35129008,
          },
          {
            date: "2021-10-14",
            value: 0,
            views: 35130588,
          },
          {
            date: "2021-10-15",
            value: 0,
            views: 35131760,
          },
          {
            date: "2021-10-16",
            value: 0,
            views: 35131824,
          },
          {
            date: "2021-10-17",
            value: 0,
            views: 35131917,
          },
          {
            date: "2021-10-18",
            value: 0,
            views: 35132055,
          },
          {
            date: "2021-10-19",
            value: 0,
            views: 35132171,
          },
          {
            date: "2021-10-20",
            value: 0,
            views: 35132197,
          },
          {
            date: "2021-10-21",
            value: 0,
            views: 35132205,
          },
          {
            date: "2021-10-22",
            value: 0,
            views: 35132208,
          },
          {
            date: "2021-10-23",
            value: 0,
            views: 35132215,
          },
          {
            date: "2021-10-24",
            value: 0,
            views: 35132219,
          },
          {
            date: "2021-10-25",
            value: 0,
            views: 35132234,
          },
          {
            date: "2021-10-26",
            value: 0,
            views: 35132264,
          },
          {
            date: "2021-10-27",
            value: 0,
            views: 35132279,
          },
          {
            date: "2021-10-28",
            value: 0,
            views: 35132281,
          },
          {
            date: "2021-10-29",
            value: 0,
            views: 35132297,
          },
          {
            date: "2021-10-30",
            value: 0,
            views: 35132320,
          },
          {
            date: "2021-10-31",
            value: 0,
            views: 35132335,
          },
          {
            date: "2021-11-01",
            value: 0,
            views: 35132369,
          },
          {
            date: "2021-11-02",
            value: 0,
            views: 35132408,
          },
          {
            date: "2021-11-03",
            value: 0,
            views: 35132428,
          },
          {
            date: "2021-11-04",
            value: 0,
            views: 35132454,
          },
          {
            date: "2021-11-04",
            value: 0,
            views: 35132478,
          },
          {
            date: "2021-11-04",
            value: 0,
            views: 35132478,
          },
          {
            date: "2021-11-04",
            value: 0,
            views: 35132478,
          },
          {
            date: "2021-11-05",
            value: 0,
            views: 35132489,
          },
          {
            date: "2021-11-06",
            value: 0,
            views: 35132515,
          },
          {
            date: "2021-11-07",
            value: 0,
            views: 35132536,
          },
          {
            date: "2021-11-08",
            value: 0,
            views: 35132569,
          },
          {
            date: "2021-11-09",
            value: 0,
            views: 35132605,
          },
          {
            date: "2021-11-10",
            value: 0,
            views: 35132620,
          },
          {
            date: "2021-11-11",
            value: 0,
            views: 35132634,
          },
          {
            date: "2021-11-12",
            value: 0,
            views: 35132646,
          },
          {
            date: "2021-11-13",
            value: 0,
            views: 35132660,
          },
          {
            date: "2021-11-14",
            value: 0,
            views: 35132687,
          },
          {
            date: "2021-11-15",
            value: 0,
            views: 35132716,
          },
          {
            date: "2021-11-16",
            value: 0,
            views: 35132749,
          },
          {
            date: "2021-11-17",
            value: 0,
            views: 35132760,
          },
          {
            date: "2021-11-18",
            value: 0,
            views: 35132774,
          },
          {
            date: "2021-11-19",
            value: 0,
            views: 35132784,
          },
          {
            date: "2021-11-20",
            value: 0,
            views: 35132791,
          },
          {
            date: "2021-11-21",
            value: 0,
            views: 35132799,
          },
          {
            date: "2021-11-22",
            value: 0,
            views: 35132810,
          },
          {
            date: "2021-11-23",
            value: 0,
            views: 35132823,
          },
          {
            date: "2021-11-24",
            value: 0,
            views: 35132835,
          },
          {
            date: "2021-11-25",
            value: 0,
            views: 35132859,
          },
          {
            date: "2021-11-26",
            value: 0,
            views: 35132929,
          },
          {
            date: "2021-11-27",
            value: 0,
            views: 35133024,
          },
          {
            date: "2021-11-28",
            value: 0,
            views: 35133051,
          },
          {
            date: "2021-12-02",
            value: 0,
            views: 35133118,
          },
          {
            date: "2021-12-05",
            value: 0,
            views: 35133130,
          },
          {
            date: "2021-12-12",
            value: 0,
            views: 35133206,
          },
          {
            date: "2021-12-17",
            value: 0,
            views: 35133229,
          },
          {
            date: "2021-12-19",
            value: 0,
            views: 35133233,
          },
          {
            date: "2021-12-21",
            value: 0,
            views: 35133237,
          },
          {
            date: "2021-12-22",
            value: 0,
            views: 35133237,
          },
          {
            date: "2021-12-24",
            value: 0,
            views: 35133237,
          },
          {
            date: "2021-12-26",
            value: 0,
            views: 35133238,
          },
          {
            date: "2021-12-27",
            value: 0,
            views: 35133238,
          },
          {
            date: "2021-12-29",
            value: 0,
            views: 35133238,
          },
          {
            date: "2021-12-30",
            value: 0,
            views: 35133240,
          },
          {
            date: "2021-12-31",
            value: 0,
            views: 35133241,
          },
          {
            date: "2022-01-03",
            value: 0,
            views: 35133243,
          },
          {
            date: "2022-01-05",
            value: 0,
            views: 35133248,
          },
          {
            date: "2022-04-29",
            value: 0,
            views: 35133402,
          },
          {
            date: "2022-05-02",
            value: 0,
            views: 35133405,
          },
          {
            date: "2022-05-06",
            value: 0,
            views: 35133415,
          },
          {
            date: "2022-05-11",
            value: 0,
            views: 35133417,
          },
          {
            date: "2022-05-13",
            value: 0,
            views: 35133417,
          },
          {
            date: "2022-05-17",
            value: 0,
            views: 35133419,
          },
          {
            date: "2022-05-19",
            value: 0,
            views: 35133419,
          },
          {
            date: "2022-05-20",
            value: 0,
            views: 35133419,
          },
          {
            date: "2022-05-21",
            value: 0,
            views: 35133419,
          },
          {
            date: "2022-05-22",
            value: 0,
            views: 35133419,
          },
          {
            date: "2022-05-23",
            value: 0,
            views: 35133420,
          },
          {
            date: "2022-05-24",
            value: 0,
            views: 35133421,
          },
          {
            date: "2022-05-25",
            value: 0,
            views: 35133421,
          },
          {
            date: "2022-05-26",
            value: 0,
            views: 35133424,
          },
          {
            date: "2022-05-27",
            value: 0,
            views: 35133424,
          },
          {
            date: "2022-05-28",
            value: 0,
            views: 35133424,
          },
          {
            date: "2022-05-29",
            value: 0,
            views: 35133424,
          },
          {
            date: "2022-05-30",
            value: 0,
            views: 35133424,
          },
          {
            date: "2022-05-31",
            value: 0,
            views: 35133427,
          },
          {
            date: "2022-06-01",
            value: 0,
            views: 35133429,
          },
          {
            date: "2022-06-02",
            value: 0,
            views: 35133429,
          },
          {
            date: "2022-06-03",
            value: 0,
            views: 35133429,
          },
          {
            date: "2022-06-04",
            value: 0,
            views: 35133430,
          },
          {
            date: "2022-06-05",
            value: 0,
            views: 35133430,
          },
          {
            date: "2022-06-06",
            value: 0,
            views: 35133431,
          },
          {
            date: "2022-06-07",
            value: 0,
            views: 35133431,
          },
          {
            date: "2022-06-08",
            value: 0,
            views: 35133433,
          },
          {
            date: "2022-06-09",
            value: 0,
            views: 35133433,
          },
          {
            date: "2022-06-10",
            value: 0,
            views: 35133435,
          },
          {
            date: "2022-06-11",
            value: 0,
            views: 35133436,
          },
          {
            date: "2022-06-12",
            value: 0,
            views: 35133436,
          },
          {
            date: "2022-06-13",
            value: 0,
            views: 35133436,
          },
          {
            date: "2022-06-14",
            value: 0,
            views: 35133436,
          },
          {
            date: "2022-06-15",
            value: 0,
            views: 35133437,
          },
          {
            date: "2022-06-16",
            value: 0,
            views: 35133437,
          },
          {
            date: "2022-06-17",
            value: 0,
            views: 35133437,
          },
          {
            date: "2022-06-18",
            value: 0,
            views: 35133437,
          },
          {
            date: "2022-06-19",
            value: 0,
            views: 35133438,
          },
          {
            date: "2022-06-20",
            value: 0,
            views: 35133440,
          },
          {
            date: "2022-06-21",
            value: 0,
            views: 35133441,
          },
          {
            date: "2022-06-22",
            value: 0,
            views: 35133441,
          },
          {
            date: "2022-06-23",
            value: 0,
            views: 35133443,
          },
          {
            date: "2022-06-24",
            value: 0,
            views: 35133443,
          },
          {
            date: "2022-06-25",
            value: 0,
            views: 35133443,
          },
          {
            date: "2022-06-26",
            value: 0,
            views: 35133443,
          },
          {
            date: "2022-06-27",
            value: 0,
            views: 35133444,
          },
          {
            date: "2022-06-28",
            value: 0,
            views: 35133444,
          },
          {
            date: "2022-06-29",
            value: 0,
            views: 35133444,
          },
          {
            date: "2022-06-30",
            value: 0,
            views: 35133444,
          },
          {
            date: "2022-07-01",
            value: 0,
            views: 35133446,
          },
          {
            date: "2022-07-02",
            value: 0,
            views: 35133446,
          },
          {
            date: "2022-07-03",
            value: 0,
            views: 35133446,
          },
          {
            date: "2022-07-04",
            value: 0,
            views: 35133446,
          },
          {
            date: "2022-07-05",
            value: 0,
            views: 35133446,
          },
          {
            date: "2022-07-06",
            value: 0,
            views: 35133446,
          },
          {
            date: "2022-07-07",
            value: 0,
            views: 35133446,
          },
          {
            date: "2022-07-08",
            value: 0,
            views: 35133446,
          },
          {
            date: "2022-07-09",
            value: 0,
            views: 35133446,
          },
          {
            date: "2022-07-10",
            value: 0,
            views: 35133446,
          },
          {
            date: "2022-07-11",
            value: 0,
            views: 35133446,
          },
          {
            date: "2022-07-12",
            value: 0,
            views: 35133453,
          },
          {
            date: "2022-07-13",
            value: 0,
            views: 35133453,
          },
          {
            date: "2022-07-14",
            value: 0,
            views: 35133453,
          },
          {
            date: "2022-07-15",
            value: 0,
            views: 35133453,
          },
          {
            date: "2022-07-16",
            value: 0,
            views: 35133453,
          },
          {
            date: "2022-07-17",
            value: 0,
            views: 35133453,
          },
          {
            date: "2022-07-18",
            value: 0,
            views: 35133454,
          },
          {
            date: "2022-07-19",
            value: 0,
            views: 35133455,
          },
          {
            date: "2022-07-20",
            value: 0,
            views: 35133455,
          },
          {
            date: "2022-07-21",
            value: 0,
            views: 35133455,
          },
          {
            date: "2022-07-22",
            value: 0,
            views: 35133457,
          },
          {
            date: "2022-07-23",
            value: 0,
            views: 35133457,
          },
          {
            date: "2022-07-24",
            value: 0,
            views: 35133457,
          },
          {
            date: "2022-07-25",
            value: 0,
            views: 35133457,
          },
          {
            date: "2022-07-26",
            value: 0,
            views: 35133457,
          },
          {
            date: "2022-07-27",
            value: 0,
            views: 35133457,
          },
          {
            date: "2022-07-28",
            value: 0,
            views: 35133458,
          },
          {
            date: "2022-07-29",
            value: 0,
            views: 35133458,
          },
          {
            date: "2022-07-30",
            value: 0,
            views: 35133459,
          },
          {
            date: "2022-07-31",
            value: 0,
            views: 35133462,
          },
          {
            date: "2022-08-01",
            value: 0,
            views: 35133464,
          },
          {
            date: "2022-08-02",
            value: 0,
            views: 35133465,
          },
          {
            date: "2022-08-03",
            value: 0,
            views: 35133466,
          },
          {
            date: "2022-08-04",
            value: 0,
            views: 35133467,
          },
          {
            date: "2022-08-05",
            value: 0,
            views: 35133467,
          },
          {
            date: "2022-08-06",
            value: 0,
            views: 35133467,
          },
          {
            date: "2022-08-07",
            value: 0,
            views: 35133467,
          },
          {
            date: "2022-08-08",
            value: 0,
            views: 35133467,
          },
          {
            date: "2022-08-09",
            value: 0,
            views: 35133467,
          },
          {
            date: "2022-08-10",
            value: 0,
            views: 35133467,
          },
          {
            date: "2022-08-11",
            value: 0,
            views: 35133467,
          },
          {
            date: "2022-08-12",
            value: 0,
            views: 35133468,
          },
          {
            date: "2022-08-13",
            value: 0,
            views: 35133468,
          },
          {
            date: "2022-08-14",
            value: 0,
            views: 35133468,
          },
          {
            date: "2022-08-15",
            value: 0,
            views: 35133468,
          },
          {
            date: "2022-08-16",
            value: 0,
            views: 35133469,
          },
          {
            date: "2022-08-17",
            value: 0,
            views: 35133471,
          },
          {
            date: "2022-08-18",
            value: 0,
            views: 35133472,
          },
          {
            date: "2022-08-19",
            value: 0,
            views: 35133472,
          },
          {
            date: "2022-08-20",
            value: 0,
            views: 35133472,
          },
          {
            date: "2022-08-21",
            value: 0,
            views: 35133472,
          },
          {
            date: "2022-08-22",
            value: 0,
            views: 35133472,
          },
          {
            date: "2022-08-23",
            value: 0,
            views: 35133472,
          },
          {
            date: "2022-08-24",
            value: 0,
            views: 35133475,
          },
          {
            date: "2022-08-25",
            value: 0,
            views: 35133476,
          },
          {
            date: "2022-08-26",
            value: 0,
            views: 35133476,
          },
          {
            date: "2022-08-27",
            value: 0,
            views: 35133477,
          },
          {
            date: "2022-08-28",
            value: 0,
            views: 35133477,
          },
          {
            date: "2022-08-29",
            value: 0,
            views: 35133477,
          },
          {
            date: "2022-08-30",
            value: 0,
            views: 35133478,
          },
          {
            date: "2022-08-31",
            value: 0,
            views: 35133478,
          },
          {
            date: "2022-09-01",
            value: 0,
            views: 35133479,
          },
          {
            date: "2022-09-02",
            value: 0,
            views: 35133480,
          },
          {
            date: "2022-09-03",
            value: 0,
            views: 35133480,
          },
          {
            date: "2022-09-04",
            value: 0,
            views: 35133481,
          },
          {
            date: "2022-09-05",
            value: 0,
            views: 35133482,
          },
          {
            date: "2022-09-06",
            value: 0,
            views: 35133483,
          },
          {
            date: "2022-09-07",
            value: 0,
            views: 35133488,
          },
          {
            date: "2022-09-08",
            value: 0,
            views: 35133488,
          },
          {
            date: "2022-09-09",
            value: 0,
            views: 35133488,
          },
          {
            date: "2022-09-10",
            value: 0,
            views: 35133488,
          },
          {
            date: "2022-09-11",
            value: 0,
            views: 35133493,
          },
          {
            date: "2022-09-12",
            value: 0,
            views: 35133494,
          },
          {
            date: "2022-09-13",
            value: 0,
            views: 35133494,
          },
          {
            date: "2022-09-14",
            value: 0,
            views: 35133494,
          },
          {
            date: "2022-09-15",
            value: 0,
            views: 35133494,
          },
          {
            date: "2022-09-16",
            value: 0,
            views: 35133494,
          },
          {
            date: "2022-09-17",
            value: 0,
            views: 35133495,
          },
          {
            date: "2022-09-18",
            value: 0,
            views: 35133495,
          },
          {
            date: "2022-09-19",
            value: 0,
            views: 35133496,
          },
          {
            date: "2022-09-20",
            value: 0,
            views: 35133498,
          },
          {
            date: "2022-09-21",
            value: 0,
            views: 35133498,
          },
          {
            date: "2022-09-22",
            value: 0,
            views: 35133499,
          },
          {
            date: "2022-09-23",
            value: 0,
            views: 35133499,
          },
          {
            date: "2022-09-24",
            value: 0,
            views: 35133499,
          },
          {
            date: "2022-09-25",
            value: 0,
            views: 35133499,
          },
          {
            date: "2022-09-26",
            value: 0,
            views: 35133499,
          },
          {
            date: "2022-09-27",
            value: 0,
            views: 35133501,
          },
          {
            date: "2022-09-28",
            value: 0,
            views: 35133501,
          },
          {
            date: "2022-09-29",
            value: 0,
            views: 35133506,
          },
          {
            date: "2022-09-30",
            value: 0,
            views: 35133508,
          },
          {
            date: "2022-10-01",
            value: 0,
            views: 35133508,
          },
          {
            date: "2022-10-02",
            value: 0,
            views: 35133508,
          },
          {
            date: "2022-10-03",
            value: 0,
            views: 35133509,
          },
          {
            date: "2022-10-04",
            value: 0,
            views: 35133510,
          },
          {
            date: "2022-10-05",
            value: 0,
            views: 35133510,
          },
          {
            date: "2022-10-06",
            value: 0,
            views: 35133510,
          },
          {
            date: "2022-10-07",
            value: 0,
            views: 35133512,
          },
          {
            date: "2022-10-08",
            value: 0,
            views: 35133512,
          },
          {
            date: "2022-10-09",
            value: 0,
            views: 35133512,
          },
          {
            date: "2022-10-10",
            value: 0,
            views: 35133514,
          },
          {
            date: "2022-10-11",
            value: 0,
            views: 35133516,
          },
          {
            date: "2022-10-12",
            value: 0,
            views: 35133519,
          },
          {
            date: "2022-10-13",
            value: 0,
            views: 35133519,
          },
          {
            date: "2022-10-14",
            value: 0,
            views: 35133520,
          },
          {
            date: "2022-10-15",
            value: 0,
            views: 35133520,
          },
          {
            date: "2022-10-16",
            value: 0,
            views: 35133520,
          },
          {
            date: "2022-10-17",
            value: 0,
            views: 35133521,
          },
          {
            date: "2022-10-18",
            value: 0,
            views: 35133524,
          },
          {
            date: "2022-10-19",
            value: 0,
            views: 35133525,
          },
          {
            date: "2022-10-20",
            value: 0,
            views: 35133525,
          },
          {
            date: "2022-10-21",
            value: 0,
            views: 35133525,
          },
          {
            date: "2022-10-22",
            value: 0,
            views: 35133525,
          },
          {
            date: "2022-10-23",
            value: 0,
            views: 35133525,
          },
          {
            date: "2022-10-24",
            value: 0,
            views: 35133526,
          },
          {
            date: "2022-10-25",
            value: 0,
            views: 35133528,
          },
          {
            date: "2022-10-26",
            value: 0,
            views: 35133528,
          },
          {
            date: "2022-10-27",
            value: 0,
            views: 35133528,
          },
          {
            date: "2022-10-28",
            value: 0,
            views: 35133529,
          },
          {
            date: "2022-10-29",
            value: 0,
            views: 35133529,
          },
          {
            date: "2022-10-30",
            value: 0,
            views: 35133531,
          },
          {
            date: "2022-10-31",
            value: 0,
            views: 35133531,
          },
          {
            date: "2022-11-01",
            value: 0,
            views: 35133534,
          },
          {
            date: "2022-11-02",
            value: 0,
            views: 35133535,
          },
          {
            date: "2022-11-03",
            value: 0,
            views: 35133535,
          },
          {
            date: "2022-11-04",
            value: 0,
            views: 35133535,
          },
          {
            date: "2022-11-05",
            value: 0,
            views: 35133536,
          },
          {
            date: "2022-11-06",
            value: 0,
            views: 35133537,
          },
          {
            date: "2022-11-07",
            value: 0,
            views: 35133537,
          },
          {
            date: "2022-11-08",
            value: 0,
            views: 35133538,
          },
          {
            date: "2022-11-09",
            value: 0,
            views: 35133540,
          },
          {
            date: "2022-11-15",
            value: 0,
            views: 35133546,
          },
          {
            date: "2022-11-16",
            value: 0,
            views: 35133548,
          },
          {
            date: "2022-11-19",
            value: 0,
            views: 35133552,
          },
          {
            date: "2022-11-20",
            value: 0,
            views: 35133553,
          },
          {
            date: "2022-11-21",
            value: 0,
            views: 35133554,
          },
          {
            date: "2022-11-22",
            value: 0,
            views: 35133557,
          },
          {
            date: "2022-11-23",
            value: 0,
            views: 35133558,
          },
          {
            date: "2022-11-25",
            value: 0,
            views: 35133558,
          },
          {
            date: "2022-11-26",
            value: 0,
            views: 35133558,
          },
          {
            date: "2022-11-27",
            value: 0,
            views: 35133558,
          },
          {
            date: "2022-11-28",
            value: 0,
            views: 35133559,
          },
          {
            date: "2022-11-29",
            value: 0,
            views: 35133559,
          },
          {
            date: "2022-11-30",
            value: 0,
            views: 35133559,
          },
          {
            date: "2022-12-01",
            value: 0,
            views: 35133560,
          },
          {
            date: "2022-12-02",
            value: 0,
            views: 35133562,
          },
          {
            date: "2022-12-03",
            value: 0,
            views: 35133562,
          },
          {
            date: "2022-12-04",
            value: 0,
            views: 35133562,
          },
          {
            date: "2022-12-05",
            value: 0,
            views: 35133562,
          },
          {
            date: "2022-12-06",
            value: 0,
            views: 35133562,
          },
          {
            date: "2022-12-08",
            value: 0,
            views: 35133569,
          },
          {
            date: "2022-12-09",
            value: 0,
            views: 35133569,
          },
          {
            date: "2022-12-10",
            value: 0,
            views: 35133569,
          },
          {
            date: "2022-12-11",
            value: 0,
            views: 35133569,
          },
          {
            date: "2022-12-12",
            value: 0,
            views: 35133570,
          },
          {
            date: "2022-12-13",
            value: 0,
            views: 35133571,
          },
          {
            date: "2022-12-14",
            value: 0,
            views: 35133572,
          },
          {
            date: "2022-12-15",
            value: 0,
            views: 35133576,
          },
          {
            date: "2022-12-16",
            value: 0,
            views: 35133576,
          },
          {
            date: "2022-12-17",
            value: 0,
            views: 35133576,
          },
          {
            date: "2022-12-18",
            value: 0,
            views: 35133576,
          },
          {
            date: "2022-12-19",
            value: 0,
            views: 35133577,
          },
          {
            date: "2022-12-20",
            value: 0,
            views: 35133579,
          },
          {
            date: "2022-12-21",
            value: 0,
            views: 35133581,
          },
          {
            date: "2022-12-22",
            value: 0,
            views: 35133581,
          },
          {
            date: "2022-12-23",
            value: 0,
            views: 35133581,
          },
          {
            date: "2022-12-25",
            value: 0,
            views: 35133582,
          },
          {
            date: "2022-12-26",
            value: 0,
            views: 35133582,
          },
          {
            date: "2022-12-27",
            value: 0,
            views: 35133582,
          },
          {
            date: "2022-12-28",
            value: 0,
            views: 35133584,
          },
          {
            date: "2022-12-29",
            value: 0,
            views: 35133585,
          },
          {
            date: "2022-12-30",
            value: 0,
            views: 35133586,
          },
          {
            date: "2022-12-31",
            value: 0,
            views: 35133586,
          },
          {
            date: "2023-01-01",
            value: 0,
            views: 35133586,
          },
          {
            date: "2023-01-02",
            value: 0,
            views: 35133586,
          },
          {
            date: "2023-01-03",
            value: 0,
            views: 35133586,
          },
          {
            date: "2023-01-04",
            value: 0,
            views: 35133587,
          },
          {
            date: "2023-01-05",
            value: 0,
            views: 35133587,
          },
          {
            date: "2023-01-06",
            value: 0,
            views: 35133588,
          },
          {
            date: "2023-01-07",
            value: 0,
            views: 35133588,
          },
          {
            date: "2023-01-08",
            value: 0,
            views: 35133590,
          },
          {
            date: "2023-01-09",
            value: 0,
            views: 35133590,
          },
          {
            date: "2023-01-10",
            value: 0,
            views: 35133591,
          },
          {
            date: "2023-01-11",
            value: 0,
            views: 35133592,
          },
          {
            date: "2023-01-12",
            value: 0,
            views: 35133592,
          },
          {
            date: "2023-01-13",
            value: 0,
            views: 35133595,
          },
          {
            date: "2023-01-14",
            value: 0,
            views: 35133595,
          },
          {
            date: "2023-01-15",
            value: 0,
            views: 35133597,
          },
          {
            date: "2023-01-16",
            value: 0,
            views: 35133600,
          },
          {
            date: "2023-01-17",
            value: 0,
            views: 35133601,
          },
          {
            date: "2023-01-18",
            value: 0,
            views: 35133601,
          },
          {
            date: "2023-01-19",
            value: 0,
            views: 35133602,
          },
          {
            date: "2023-01-20",
            value: 0,
            views: 35133603,
          },
          {
            date: "2023-01-21",
            value: 0,
            views: 35133604,
          },
          {
            date: "2023-01-22",
            value: 0,
            views: 35133607,
          },
          {
            date: "2023-01-23",
            value: 0,
            views: 35133607,
          },
          {
            date: "2023-01-24",
            value: 0,
            views: 35133608,
          },
          {
            date: "2023-01-25",
            value: 0,
            views: 35133611,
          },
          {
            date: "2023-01-26",
            value: 0,
            views: 35133612,
          },
          {
            date: "2023-01-27",
            value: 0,
            views: 35133617,
          },
          {
            date: "2023-01-29",
            value: 0,
            views: 35133617,
          },
          {
            date: "2023-01-30",
            value: 0,
            views: 35133618,
          },
          {
            date: "2023-01-31",
            value: 0,
            views: 35133618,
          },
          {
            date: "2023-02-01",
            value: 0,
            views: 35133618,
          },
          {
            date: "2023-02-02",
            value: 0,
            views: 35133618,
          },
          {
            date: "2023-02-03",
            value: 0,
            views: 35133620,
          },
          {
            date: "2023-02-04",
            value: 0,
            views: 35133620,
          },
          {
            date: "2023-02-06",
            value: 0,
            views: 35133620,
          },
          {
            date: "2023-02-08",
            value: 0,
            views: 35133621,
          },
          {
            date: "2023-02-09",
            value: 0,
            views: 35133621,
          },
          {
            date: "2023-02-10",
            value: 0,
            views: 35133621,
          },
          {
            date: "2023-02-12",
            value: 0,
            views: 35133621,
          },
          {
            date: "2023-02-14",
            value: 0,
            views: 35133623,
          },
          {
            date: "2023-02-16",
            value: 0,
            views: 35133626,
          },
          {
            date: "2023-02-18",
            value: 0,
            views: 35133626,
          },
          {
            date: "2023-02-20",
            value: 0,
            views: 35133628,
          },
          {
            date: "2023-02-22",
            value: 0,
            views: 35133628,
          },
          {
            date: "2023-02-24",
            value: 0,
            views: 35133628,
          },
          {
            date: "2023-02-26",
            value: 0,
            views: 35133630,
          },
          {
            date: "2023-02-28",
            value: 0,
            views: 35133633,
          },
          {
            date: "2023-03-02",
            value: 0,
            views: 35133633,
          },
          {
            date: "2023-03-07",
            value: 0,
            views: 35133635,
          },
          {
            date: "2023-03-09",
            value: 0,
            views: 35133637,
          },
          {
            date: "2023-03-15",
            value: 0,
            views: 35133641,
          },
          {
            date: "2023-03-17",
            value: 0,
            views: 35133641,
          },
          {
            date: "2023-03-19",
            value: 0,
            views: 35133641,
          },
          {
            date: "2023-03-21",
            value: 0,
            views: 35133641,
          },
          {
            date: "2023-03-23",
            value: 0,
            views: 35133641,
          },
          {
            date: "2023-03-25",
            value: 0,
            views: 35133641,
          },
          {
            date: "2023-03-28",
            value: 0,
            views: 35133645,
          },
          {
            date: "2023-03-31",
            value: 0,
            views: 35133646,
          },
          {
            date: "2023-04-01",
            value: 0,
            views: 35133647,
          },
          {
            date: "2023-04-03",
            value: 0,
            views: 35133648,
          },
          {
            date: "2023-04-05",
            value: 0,
            views: 35133651,
          },
          {
            date: "2023-04-07",
            value: 0,
            views: 35133654,
          },
          {
            date: "2023-04-09",
            value: 0,
            views: 35133654,
          },
          {
            date: "2023-04-11",
            value: 0,
            views: 35133654,
          },
          {
            date: "2023-04-13",
            value: 0,
            views: 35133654,
          },
          {
            date: "2023-04-15",
            value: 0,
            views: 35133654,
          },
          {
            date: "2023-04-17",
            value: 0,
            views: 35133654,
          },
          {
            date: "2023-04-19",
            value: 0,
            views: 35133654,
          },
          {
            date: "2023-04-21",
            value: 0,
            views: 35133654,
          },
          {
            date: "2023-04-23",
            value: 0,
            views: 35133656,
          },
          {
            date: "2023-05-08",
            value: 0,
            views: 35133667,
          },
          {
            date: "2023-05-13",
            value: 0,
            views: 35133675,
          },
          {
            date: "2023-05-27",
            value: 0,
            views: 35133688,
          },
          {
            date: "2023-06-04",
            value: 0,
            views: 35133692,
          },
          {
            date: "2023-06-06",
            value: 0,
            views: 35133692,
          },
          {
            date: "2023-06-13",
            value: 0,
            views: 35133712,
          },
          {
            date: "2023-07-30",
            value: 0,
            views: 35133757,
          },
          {
            date: "2023-08-02",
            value: 0,
            views: 35133758,
          },
          {
            date: "2023-08-05",
            value: 0,
            views: 35133761,
          },
          {
            date: "2023-08-08",
            value: 0,
            views: 35133769,
          },
          {
            date: "2023-08-10",
            value: 0,
            views: 35133770,
          },
          {
            date: "2023-08-13",
            value: 0,
            views: 35133770,
          },
          {
            date: "2023-08-16",
            value: 0,
            views: 35133774,
          },
          {
            date: "2023-08-18",
            value: 0,
            views: 35133774,
          },
          {
            date: "2023-08-24",
            value: 0,
            views: 35133777,
          },
          {
            date: "2023-08-25",
            value: 0,
            views: 35133778,
          },
          {
            date: "2023-08-28",
            value: 0,
            views: 35133779,
          },
          {
            date: "2023-08-31",
            value: 0,
            views: 35133781,
          },
          {
            date: "2023-09-05",
            value: 0,
            views: 35133781,
          },
          {
            date: "2023-09-07",
            value: 0,
            views: 35133783,
          },
          {
            date: "2023-09-11",
            value: 0,
            views: 35133784,
          },
          {
            date: "2023-09-13",
            value: 0,
            views: 35133784,
          },
          {
            date: "2023-09-16",
            value: 0,
            views: 35133784,
          },
          {
            date: "2023-09-19",
            value: 0,
            views: 35133786,
          },
          {
            date: "2023-09-20",
            value: 0,
            views: 35133786,
          },
          {
            date: "2023-09-22",
            value: 0,
            views: 35133788,
          },
          {
            date: "2023-09-25",
            value: 0,
            views: 35133788,
          },
          {
            date: "2023-09-27",
            value: 0,
            views: 35133790,
          },
          {
            date: "2023-09-29",
            value: 0,
            views: 35133791,
          },
          {
            date: "2023-10-02",
            value: 0,
            views: 35133792,
          },
          {
            date: "2023-10-05",
            value: 0,
            views: 35133793,
          },
          {
            date: "2023-10-06",
            value: 0,
            views: 35133793,
          },
          {
            date: "2023-10-10",
            value: 0,
            views: 35133795,
          },
          {
            date: "2023-10-11",
            value: 0,
            views: 35133796,
          },
          {
            date: "2023-10-17",
            value: 0,
            views: 35133801,
          },
          {
            date: "2023-10-18",
            value: 0,
            views: 35133805,
          },
          {
            date: "2023-10-21",
            value: 0,
            views: 35133806,
          },
          {
            date: "2023-10-25",
            value: 0,
            views: 35133808,
          },
          {
            date: "2023-10-26",
            value: 0,
            views: 35133809,
          },
          {
            date: "2023-10-31",
            value: 0,
            views: 35133811,
          },
          {
            date: "2023-11-02",
            value: 0,
            views: 35133814,
          },
          {
            date: "2023-11-04",
            value: 0,
            views: 35133815,
          },
          {
            date: "2023-11-07",
            value: 0,
            views: 35133820,
          },
        ],
      },
      {
        videoId: "27lZfyfQr6s",
        dataPoints: [
          {
            date: "2018-05-17",
            value: 0,
            views: 0,
          },
          {
            date: "2022-02-04",
            value: 0,
            views: 33435228,
          },
          {
            date: "2022-02-05",
            value: 0,
            views: 33435228,
          },
          {
            date: "2022-02-06",
            value: 0,
            views: 33435228,
          },
          {
            date: "2022-02-07",
            value: 0,
            views: 33435228,
          },
          {
            date: "2022-02-08",
            value: 0,
            views: 33435228,
          },
          {
            date: "2022-02-09",
            value: 0,
            views: 33435228,
          },
          {
            date: "2022-02-10",
            value: 0,
            views: 33435229,
          },
          {
            date: "2022-02-11",
            value: 0,
            views: 33435229,
          },
          {
            date: "2022-02-12",
            value: 0,
            views: 33435231,
          },
          {
            date: "2022-02-13",
            value: 0,
            views: 33435231,
          },
          {
            date: "2022-02-14",
            value: 0,
            views: 33435231,
          },
          {
            date: "2022-02-15",
            value: 0,
            views: 33435232,
          },
          {
            date: "2022-02-16",
            value: 0,
            views: 33435232,
          },
          {
            date: "2022-02-17",
            value: 0,
            views: 33435232,
          },
          {
            date: "2022-02-18",
            value: 0,
            views: 33435232,
          },
          {
            date: "2022-02-19",
            value: 0,
            views: 33435232,
          },
          {
            date: "2022-02-20",
            value: 0,
            views: 33435232,
          },
          {
            date: "2022-02-21",
            value: 0,
            views: 33435232,
          },
          {
            date: "2022-02-22",
            value: 0,
            views: 33435232,
          },
          {
            date: "2022-02-23",
            value: 0,
            views: 33435233,
          },
          {
            date: "2022-02-24",
            value: 0,
            views: 33435241,
          },
          {
            date: "2022-02-25",
            value: 0,
            views: 33435247,
          },
          {
            date: "2022-02-26",
            value: 0,
            views: 33435248,
          },
          {
            date: "2022-02-27",
            value: 0,
            views: 33435248,
          },
          {
            date: "2022-02-28",
            value: 0,
            views: 33435248,
          },
          {
            date: "2022-03-01",
            value: 0,
            views: 33435250,
          },
          {
            date: "2022-03-02",
            value: 0,
            views: 33435253,
          },
          {
            date: "2022-03-03",
            value: 0,
            views: 33435256,
          },
          {
            date: "2022-03-04",
            value: 0,
            views: 33435257,
          },
          {
            date: "2022-03-05",
            value: 0,
            views: 33435259,
          },
          {
            date: "2022-03-06",
            value: 0,
            views: 33435260,
          },
          {
            date: "2022-03-07",
            value: 0,
            views: 33435260,
          },
          {
            date: "2022-03-08",
            value: 0,
            views: 33435260,
          },
          {
            date: "2022-03-09",
            value: 0,
            views: 33435263,
          },
          {
            date: "2022-03-10",
            value: 0,
            views: 33435264,
          },
          {
            date: "2022-03-11",
            value: 0,
            views: 33435264,
          },
          {
            date: "2022-03-12",
            value: 0,
            views: 33435265,
          },
          {
            date: "2022-03-13",
            value: 0,
            views: 33435265,
          },
          {
            date: "2022-03-14",
            value: 0,
            views: 33435265,
          },
          {
            date: "2022-03-15",
            value: 0,
            views: 33435265,
          },
          {
            date: "2022-03-16",
            value: 0,
            views: 33435265,
          },
          {
            date: "2022-03-17",
            value: 0,
            views: 33435265,
          },
          {
            date: "2022-03-18",
            value: 0,
            views: 33435267,
          },
          {
            date: "2022-03-19",
            value: 0,
            views: 33435268,
          },
          {
            date: "2022-03-20",
            value: 0,
            views: 33435269,
          },
          {
            date: "2022-03-21",
            value: 0,
            views: 33435269,
          },
          {
            date: "2022-03-22",
            value: 0,
            views: 33435270,
          },
          {
            date: "2022-03-23",
            value: 0,
            views: 33435270,
          },
          {
            date: "2022-03-24",
            value: 0,
            views: 33435272,
          },
          {
            date: "2022-03-25",
            value: 0,
            views: 33435275,
          },
          {
            date: "2022-04-22",
            value: 0,
            views: 33435301,
          },
          {
            date: "2022-04-23",
            value: 0,
            views: 33435304,
          },
          {
            date: "2022-04-24",
            value: 0,
            views: 33435304,
          },
          {
            date: "2022-04-25",
            value: 0,
            views: 33435304,
          },
          {
            date: "2022-04-26",
            value: 0,
            views: 33435313,
          },
          {
            date: "2022-04-27",
            value: 0,
            views: 33435314,
          },
          {
            date: "2022-04-28",
            value: 0,
            views: 33435315,
          },
          {
            date: "2022-04-29",
            value: 0,
            views: 33435316,
          },
          {
            date: "2022-05-01",
            value: 0,
            views: 33435317,
          },
          {
            date: "2022-05-02",
            value: 0,
            views: 33435317,
          },
          {
            date: "2022-05-05",
            value: 0,
            views: 33435319,
          },
          {
            date: "2022-05-06",
            value: 0,
            views: 33435319,
          },
          {
            date: "2022-05-07",
            value: 0,
            views: 33435319,
          },
          {
            date: "2022-05-09",
            value: 0,
            views: 33435319,
          },
          {
            date: "2022-05-10",
            value: 0,
            views: 33435320,
          },
          {
            date: "2022-05-11",
            value: 0,
            views: 33435320,
          },
          {
            date: "2022-05-12",
            value: 0,
            views: 33435320,
          },
          {
            date: "2022-05-13",
            value: 0,
            views: 33435320,
          },
          {
            date: "2022-05-15",
            value: 0,
            views: 33435321,
          },
          {
            date: "2022-05-16",
            value: 0,
            views: 33435321,
          },
          {
            date: "2022-05-17",
            value: 0,
            views: 33435321,
          },
          {
            date: "2022-05-18",
            value: 0,
            views: 33435324,
          },
          {
            date: "2022-05-19",
            value: 0,
            views: 33435324,
          },
          {
            date: "2022-05-20",
            value: 0,
            views: 33435324,
          },
          {
            date: "2022-05-21",
            value: 0,
            views: 33435324,
          },
          {
            date: "2022-05-22",
            value: 0,
            views: 33435324,
          },
          {
            date: "2022-05-23",
            value: 0,
            views: 33435324,
          },
          {
            date: "2022-05-24",
            value: 0,
            views: 33435324,
          },
          {
            date: "2022-05-25",
            value: 0,
            views: 33435324,
          },
          {
            date: "2022-05-26",
            value: 0,
            views: 33435325,
          },
          {
            date: "2022-05-27",
            value: 0,
            views: 33435325,
          },
          {
            date: "2022-05-28",
            value: 0,
            views: 33435325,
          },
          {
            date: "2022-05-29",
            value: 0,
            views: 33435326,
          },
          {
            date: "2022-05-30",
            value: 0,
            views: 33435327,
          },
          {
            date: "2022-05-31",
            value: 0,
            views: 33435327,
          },
          {
            date: "2022-06-01",
            value: 0,
            views: 33435330,
          },
          {
            date: "2022-06-02",
            value: 0,
            views: 33435332,
          },
          {
            date: "2022-06-03",
            value: 0,
            views: 33435334,
          },
          {
            date: "2022-06-04",
            value: 0,
            views: 33435334,
          },
          {
            date: "2022-06-05",
            value: 0,
            views: 33435334,
          },
          {
            date: "2022-06-06",
            value: 0,
            views: 33435334,
          },
          {
            date: "2022-06-07",
            value: 0,
            views: 33435334,
          },
          {
            date: "2022-06-08",
            value: 0,
            views: 33435334,
          },
          {
            date: "2022-06-09",
            value: 0,
            views: 33435335,
          },
          {
            date: "2022-06-10",
            value: 0,
            views: 33435335,
          },
          {
            date: "2022-06-11",
            value: 0,
            views: 33435342,
          },
          {
            date: "2022-06-12",
            value: 0,
            views: 33435342,
          },
          {
            date: "2022-06-13",
            value: 0,
            views: 33435345,
          },
          {
            date: "2022-06-14",
            value: 0,
            views: 33435346,
          },
          {
            date: "2022-06-15",
            value: 0,
            views: 33435347,
          },
          {
            date: "2022-06-16",
            value: 0,
            views: 33435349,
          },
          {
            date: "2022-06-17",
            value: 0,
            views: 33435351,
          },
          {
            date: "2022-06-18",
            value: 0,
            views: 33435360,
          },
          {
            date: "2022-06-19",
            value: 0,
            views: 33435363,
          },
          {
            date: "2022-06-20",
            value: 0,
            views: 33435365,
          },
          {
            date: "2022-06-21",
            value: 0,
            views: 33435368,
          },
          {
            date: "2022-06-22",
            value: 0,
            views: 33435369,
          },
          {
            date: "2022-06-23",
            value: 0,
            views: 33435371,
          },
          {
            date: "2022-06-24",
            value: 0,
            views: 33435373,
          },
          {
            date: "2022-06-25",
            value: 0,
            views: 33435373,
          },
          {
            date: "2022-06-26",
            value: 0,
            views: 33435375,
          },
          {
            date: "2022-06-27",
            value: 0,
            views: 33435376,
          },
          {
            date: "2022-06-28",
            value: 0,
            views: 33435378,
          },
          {
            date: "2022-06-29",
            value: 0,
            views: 33435379,
          },
          {
            date: "2022-06-30",
            value: 0,
            views: 33435381,
          },
          {
            date: "2022-07-01",
            value: 0,
            views: 33435382,
          },
          {
            date: "2022-07-02",
            value: 0,
            views: 33435383,
          },
          {
            date: "2022-07-03",
            value: 0,
            views: 33435386,
          },
          {
            date: "2022-07-04",
            value: 0,
            views: 33435387,
          },
          {
            date: "2022-07-05",
            value: 0,
            views: 33435387,
          },
          {
            date: "2022-07-06",
            value: 0,
            views: 33435388,
          },
          {
            date: "2022-07-07",
            value: 0,
            views: 33435390,
          },
          {
            date: "2022-07-08",
            value: 0,
            views: 33435393,
          },
          {
            date: "2022-07-09",
            value: 0,
            views: 33435393,
          },
          {
            date: "2022-07-10",
            value: 0,
            views: 33435394,
          },
          {
            date: "2022-07-11",
            value: 0,
            views: 33435394,
          },
          {
            date: "2022-07-12",
            value: 0,
            views: 33435394,
          },
          {
            date: "2022-07-13",
            value: 0,
            views: 33435394,
          },
          {
            date: "2022-07-14",
            value: 0,
            views: 33435394,
          },
          {
            date: "2022-07-15",
            value: 0,
            views: 33435395,
          },
          {
            date: "2022-07-16",
            value: 0,
            views: 33435395,
          },
          {
            date: "2022-07-17",
            value: 0,
            views: 33435395,
          },
          {
            date: "2022-07-18",
            value: 0,
            views: 33435395,
          },
          {
            date: "2022-07-19",
            value: 0,
            views: 33435395,
          },
          {
            date: "2022-07-20",
            value: 0,
            views: 33435396,
          },
          {
            date: "2022-07-21",
            value: 0,
            views: 33435397,
          },
          {
            date: "2022-07-22",
            value: 0,
            views: 33435398,
          },
          {
            date: "2022-07-23",
            value: 0,
            views: 33435402,
          },
          {
            date: "2022-07-24",
            value: 0,
            views: 33435402,
          },
          {
            date: "2022-07-25",
            value: 0,
            views: 33435402,
          },
          {
            date: "2022-07-26",
            value: 0,
            views: 33435402,
          },
          {
            date: "2022-07-27",
            value: 0,
            views: 33435402,
          },
          {
            date: "2022-07-28",
            value: 0,
            views: 33435402,
          },
          {
            date: "2022-07-29",
            value: 0,
            views: 33435405,
          },
          {
            date: "2022-07-30",
            value: 0,
            views: 33435405,
          },
          {
            date: "2022-07-31",
            value: 0,
            views: 33435405,
          },
          {
            date: "2022-08-01",
            value: 0,
            views: 33435408,
          },
          {
            date: "2022-08-02",
            value: 0,
            views: 33435408,
          },
          {
            date: "2022-08-03",
            value: 0,
            views: 33435408,
          },
          {
            date: "2022-08-04",
            value: 0,
            views: 33435408,
          },
          {
            date: "2022-08-05",
            value: 0,
            views: 33435411,
          },
          {
            date: "2022-08-06",
            value: 0,
            views: 33435412,
          },
          {
            date: "2022-08-07",
            value: 0,
            views: 33435412,
          },
          {
            date: "2022-08-08",
            value: 0,
            views: 33435413,
          },
          {
            date: "2022-08-09",
            value: 0,
            views: 33435414,
          },
          {
            date: "2022-08-10",
            value: 0,
            views: 33435415,
          },
          {
            date: "2022-08-11",
            value: 0,
            views: 33435415,
          },
          {
            date: "2022-08-12",
            value: 0,
            views: 33435416,
          },
          {
            date: "2022-08-13",
            value: 0,
            views: 33435419,
          },
          {
            date: "2022-08-14",
            value: 0,
            views: 33435420,
          },
          {
            date: "2022-08-15",
            value: 0,
            views: 33435421,
          },
          {
            date: "2022-08-16",
            value: 0,
            views: 33435426,
          },
          {
            date: "2022-08-17",
            value: 0,
            views: 33435428,
          },
          {
            date: "2022-08-18",
            value: 0,
            views: 33435429,
          },
          {
            date: "2022-08-19",
            value: 0,
            views: 33435430,
          },
          {
            date: "2022-08-20",
            value: 0,
            views: 33435431,
          },
          {
            date: "2022-08-21",
            value: 0,
            views: 33435432,
          },
          {
            date: "2022-08-22",
            value: 0,
            views: 33435435,
          },
          {
            date: "2022-08-23",
            value: 0,
            views: 33435436,
          },
          {
            date: "2022-08-24",
            value: 0,
            views: 33435436,
          },
          {
            date: "2022-08-25",
            value: 0,
            views: 33435436,
          },
          {
            date: "2022-08-26",
            value: 0,
            views: 33435437,
          },
          {
            date: "2022-08-27",
            value: 0,
            views: 33435438,
          },
          {
            date: "2022-08-28",
            value: 0,
            views: 33435438,
          },
          {
            date: "2022-08-29",
            value: 0,
            views: 33435438,
          },
          {
            date: "2022-08-30",
            value: 0,
            views: 33435439,
          },
          {
            date: "2022-08-31",
            value: 0,
            views: 33435440,
          },
          {
            date: "2022-09-01",
            value: 0,
            views: 33435445,
          },
          {
            date: "2022-09-02",
            value: 0,
            views: 33435451,
          },
          {
            date: "2022-09-03",
            value: 0,
            views: 33435451,
          },
          {
            date: "2022-09-04",
            value: 0,
            views: 33435451,
          },
          {
            date: "2022-09-05",
            value: 0,
            views: 33435453,
          },
          {
            date: "2022-09-06",
            value: 0,
            views: 33435453,
          },
          {
            date: "2022-09-07",
            value: 0,
            views: 33435453,
          },
          {
            date: "2022-09-08",
            value: 0,
            views: 33435454,
          },
          {
            date: "2022-09-09",
            value: 0,
            views: 33435455,
          },
          {
            date: "2022-09-10",
            value: 0,
            views: 33435455,
          },
          {
            date: "2022-09-11",
            value: 0,
            views: 33435455,
          },
          {
            date: "2022-09-12",
            value: 0,
            views: 33435455,
          },
          {
            date: "2022-09-13",
            value: 0,
            views: 33435456,
          },
          {
            date: "2022-09-14",
            value: 0,
            views: 33435456,
          },
          {
            date: "2022-09-15",
            value: 0,
            views: 33435456,
          },
          {
            date: "2022-09-16",
            value: 0,
            views: 33435456,
          },
          {
            date: "2022-09-17",
            value: 0,
            views: 33435458,
          },
          {
            date: "2022-09-18",
            value: 0,
            views: 33435459,
          },
          {
            date: "2022-09-19",
            value: 0,
            views: 33435459,
          },
          {
            date: "2022-09-20",
            value: 0,
            views: 33435459,
          },
          {
            date: "2022-09-21",
            value: 0,
            views: 33435459,
          },
          {
            date: "2022-09-22",
            value: 0,
            views: 33435459,
          },
          {
            date: "2022-09-23",
            value: 0,
            views: 33435460,
          },
          {
            date: "2022-09-24",
            value: 0,
            views: 33435460,
          },
          {
            date: "2022-09-25",
            value: 0,
            views: 33435460,
          },
          {
            date: "2022-09-26",
            value: 0,
            views: 33435461,
          },
          {
            date: "2022-09-27",
            value: 0,
            views: 33435462,
          },
          {
            date: "2022-09-28",
            value: 0,
            views: 33435463,
          },
          {
            date: "2022-09-29",
            value: 0,
            views: 33435465,
          },
          {
            date: "2022-09-30",
            value: 0,
            views: 33435466,
          },
          {
            date: "2022-10-01",
            value: 0,
            views: 33435466,
          },
          {
            date: "2022-10-02",
            value: 0,
            views: 33435466,
          },
          {
            date: "2022-10-03",
            value: 0,
            views: 33435466,
          },
          {
            date: "2022-10-04",
            value: 0,
            views: 33435466,
          },
          {
            date: "2022-10-05",
            value: 0,
            views: 33435467,
          },
          {
            date: "2022-10-06",
            value: 0,
            views: 33435468,
          },
          {
            date: "2022-10-07",
            value: 0,
            views: 33435470,
          },
          {
            date: "2022-10-08",
            value: 0,
            views: 33435470,
          },
          {
            date: "2022-10-09",
            value: 0,
            views: 33435470,
          },
          {
            date: "2022-10-10",
            value: 0,
            views: 33435473,
          },
          {
            date: "2022-10-11",
            value: 0,
            views: 33435473,
          },
          {
            date: "2022-10-12",
            value: 0,
            views: 33435476,
          },
          {
            date: "2022-10-13",
            value: 0,
            views: 33435478,
          },
          {
            date: "2022-10-14",
            value: 0,
            views: 33435478,
          },
          {
            date: "2022-10-15",
            value: 0,
            views: 33435479,
          },
          {
            date: "2022-10-16",
            value: 0,
            views: 33435479,
          },
          {
            date: "2022-10-17",
            value: 0,
            views: 33435484,
          },
          {
            date: "2022-10-18",
            value: 0,
            views: 33435485,
          },
          {
            date: "2022-10-19",
            value: 0,
            views: 33435486,
          },
          {
            date: "2022-10-20",
            value: 0,
            views: 33435486,
          },
          {
            date: "2022-10-21",
            value: 0,
            views: 33435487,
          },
          {
            date: "2022-10-22",
            value: 0,
            views: 33435488,
          },
          {
            date: "2022-10-23",
            value: 0,
            views: 33435488,
          },
          {
            date: "2022-10-24",
            value: 0,
            views: 33435488,
          },
          {
            date: "2022-10-25",
            value: 0,
            views: 33435488,
          },
          {
            date: "2022-10-26",
            value: 0,
            views: 33435489,
          },
          {
            date: "2022-10-27",
            value: 0,
            views: 33435493,
          },
          {
            date: "2022-10-28",
            value: 0,
            views: 33435493,
          },
          {
            date: "2022-10-29",
            value: 0,
            views: 33435494,
          },
          {
            date: "2022-10-30",
            value: 0,
            views: 33435494,
          },
          {
            date: "2022-10-31",
            value: 0,
            views: 33435495,
          },
          {
            date: "2022-11-01",
            value: 0,
            views: 33435495,
          },
          {
            date: "2022-11-02",
            value: 0,
            views: 33435495,
          },
          {
            date: "2022-11-03",
            value: 0,
            views: 33435497,
          },
          {
            date: "2022-11-04",
            value: 0,
            views: 33435497,
          },
          {
            date: "2022-11-05",
            value: 0,
            views: 33435498,
          },
          {
            date: "2022-11-06",
            value: 0,
            views: 33435498,
          },
          {
            date: "2022-11-07",
            value: 0,
            views: 33435498,
          },
          {
            date: "2022-11-08",
            value: 0,
            views: 33435499,
          },
          {
            date: "2022-11-09",
            value: 0,
            views: 33435500,
          },
          {
            date: "2022-11-15",
            value: 0,
            views: 33435507,
          },
          {
            date: "2022-11-16",
            value: 0,
            views: 33435509,
          },
          {
            date: "2022-11-17",
            value: 0,
            views: 33435510,
          },
          {
            date: "2022-11-19",
            value: 0,
            views: 33435512,
          },
          {
            date: "2022-11-20",
            value: 0,
            views: 33435512,
          },
          {
            date: "2022-11-21",
            value: 0,
            views: 33435513,
          },
          {
            date: "2022-11-22",
            value: 0,
            views: 33435514,
          },
          {
            date: "2022-11-23",
            value: 0,
            views: 33435514,
          },
          {
            date: "2022-11-25",
            value: 0,
            views: 33435515,
          },
          {
            date: "2022-11-26",
            value: 0,
            views: 33435517,
          },
          {
            date: "2022-11-27",
            value: 0,
            views: 33435518,
          },
          {
            date: "2022-11-28",
            value: 0,
            views: 33435519,
          },
          {
            date: "2022-11-29",
            value: 0,
            views: 33435523,
          },
          {
            date: "2022-11-30",
            value: 0,
            views: 33435529,
          },
          {
            date: "2022-12-01",
            value: 0,
            views: 33435531,
          },
          {
            date: "2022-12-02",
            value: 0,
            views: 33435531,
          },
          {
            date: "2022-12-03",
            value: 0,
            views: 33435531,
          },
          {
            date: "2022-12-04",
            value: 0,
            views: 33435531,
          },
          {
            date: "2022-12-05",
            value: 0,
            views: 33435532,
          },
          {
            date: "2022-12-06",
            value: 0,
            views: 33435532,
          },
          {
            date: "2022-12-08",
            value: 0,
            views: 33435540,
          },
          {
            date: "2022-12-09",
            value: 0,
            views: 33435540,
          },
          {
            date: "2022-12-10",
            value: 0,
            views: 33435542,
          },
          {
            date: "2022-12-11",
            value: 0,
            views: 33435544,
          },
          {
            date: "2022-12-12",
            value: 0,
            views: 33435544,
          },
          {
            date: "2022-12-13",
            value: 0,
            views: 33435545,
          },
          {
            date: "2022-12-14",
            value: 0,
            views: 33435546,
          },
          {
            date: "2022-12-15",
            value: 0,
            views: 33437101,
          },
          {
            date: "2022-12-16",
            value: 0,
            views: 33437853,
          },
          {
            date: "2022-12-17",
            value: 0,
            views: 33437893,
          },
          {
            date: "2022-12-18",
            value: 0,
            views: 33438086,
          },
          {
            date: "2022-12-19",
            value: 0,
            views: 33438390,
          },
          {
            date: "2022-12-20",
            value: 0,
            views: 33438716,
          },
          {
            date: "2022-12-21",
            value: 0,
            views: 33439379,
          },
          {
            date: "2022-12-22",
            value: 0,
            views: 33440193,
          },
          {
            date: "2022-12-23",
            value: 0,
            views: 33440425,
          },
          {
            date: "2022-12-24",
            value: 0,
            views: 33440428,
          },
          {
            date: "2022-12-25",
            value: 0,
            views: 33440432,
          },
          {
            date: "2022-12-26",
            value: 0,
            views: 33440432,
          },
          {
            date: "2022-12-27",
            value: 0,
            views: 33440432,
          },
          {
            date: "2022-12-28",
            value: 0,
            views: 33440432,
          },
          {
            date: "2022-12-29",
            value: 0,
            views: 33440432,
          },
          {
            date: "2022-12-30",
            value: 0,
            views: 33440432,
          },
          {
            date: "2022-12-31",
            value: 0,
            views: 33440433,
          },
          {
            date: "2023-01-01",
            value: 0,
            views: 33440433,
          },
          {
            date: "2023-01-02",
            value: 0,
            views: 33440434,
          },
          {
            date: "2023-01-03",
            value: 0,
            views: 33440434,
          },
          {
            date: "2023-01-04",
            value: 0,
            views: 33440434,
          },
          {
            date: "2023-01-05",
            value: 0,
            views: 33440435,
          },
          {
            date: "2023-01-06",
            value: 0,
            views: 33440435,
          },
          {
            date: "2023-01-07",
            value: 0,
            views: 33440435,
          },
          {
            date: "2023-01-08",
            value: 0,
            views: 33440435,
          },
          {
            date: "2023-01-09",
            value: 0,
            views: 33440435,
          },
          {
            date: "2023-01-10",
            value: 0,
            views: 33440435,
          },
          {
            date: "2023-01-11",
            value: 0,
            views: 33440437,
          },
          {
            date: "2023-01-12",
            value: 0,
            views: 33440440,
          },
          {
            date: "2023-01-13",
            value: 0,
            views: 33440447,
          },
          {
            date: "2023-01-14",
            value: 0,
            views: 33440449,
          },
          {
            date: "2023-01-15",
            value: 0,
            views: 33440449,
          },
          {
            date: "2023-01-16",
            value: 0,
            views: 33440449,
          },
          {
            date: "2023-01-17",
            value: 0,
            views: 33440453,
          },
          {
            date: "2023-01-18",
            value: 0,
            views: 33440453,
          },
          {
            date: "2023-01-19",
            value: 0,
            views: 33440455,
          },
          {
            date: "2023-01-20",
            value: 0,
            views: 33440462,
          },
          {
            date: "2023-01-21",
            value: 0,
            views: 33440463,
          },
          {
            date: "2023-01-22",
            value: 0,
            views: 33440466,
          },
          {
            date: "2023-01-23",
            value: 0,
            views: 33440469,
          },
          {
            date: "2023-01-24",
            value: 0,
            views: 33440469,
          },
          {
            date: "2023-01-25",
            value: 0,
            views: 33440476,
          },
          {
            date: "2023-01-26",
            value: 0,
            views: 33440478,
          },
          {
            date: "2023-01-27",
            value: 0,
            views: 33440481,
          },
          {
            date: "2023-01-28",
            value: 0,
            views: 33440486,
          },
          {
            date: "2023-01-29",
            value: 0,
            views: 33440488,
          },
          {
            date: "2023-01-30",
            value: 0,
            views: 33440490,
          },
          {
            date: "2023-01-31",
            value: 0,
            views: 33440491,
          },
          {
            date: "2023-02-01",
            value: 0,
            views: 33440492,
          },
          {
            date: "2023-02-02",
            value: 0,
            views: 33440495,
          },
          {
            date: "2023-02-03",
            value: 0,
            views: 33440495,
          },
          {
            date: "2023-02-04",
            value: 0,
            views: 33440498,
          },
          {
            date: "2023-02-05",
            value: 0,
            views: 33440499,
          },
          {
            date: "2023-02-06",
            value: 0,
            views: 33440499,
          },
          {
            date: "2023-02-08",
            value: 0,
            views: 33440500,
          },
          {
            date: "2023-02-09",
            value: 0,
            views: 33440501,
          },
          {
            date: "2023-02-10",
            value: 0,
            views: 33440503,
          },
          {
            date: "2023-02-12",
            value: 0,
            views: 33440504,
          },
          {
            date: "2023-02-14",
            value: 0,
            views: 33440505,
          },
          {
            date: "2023-02-16",
            value: 0,
            views: 33440508,
          },
          {
            date: "2023-02-18",
            value: 0,
            views: 33440514,
          },
          {
            date: "2023-02-20",
            value: 0,
            views: 33440517,
          },
          {
            date: "2023-02-22",
            value: 0,
            views: 33440520,
          },
          {
            date: "2023-02-24",
            value: 0,
            views: 33440523,
          },
          {
            date: "2023-02-26",
            value: 0,
            views: 33440523,
          },
          {
            date: "2023-02-28",
            value: 0,
            views: 33440527,
          },
          {
            date: "2023-03-02",
            value: 0,
            views: 33440530,
          },
          {
            date: "2023-03-07",
            value: 0,
            views: 33440533,
          },
          {
            date: "2023-03-09",
            value: 0,
            views: 33440536,
          },
          {
            date: "2023-03-15",
            value: 0,
            views: 33440541,
          },
          {
            date: "2023-03-17",
            value: 0,
            views: 33440550,
          },
          {
            date: "2023-03-19",
            value: 0,
            views: 33440551,
          },
          {
            date: "2023-03-21",
            value: 0,
            views: 33440556,
          },
          {
            date: "2023-03-23",
            value: 0,
            views: 33440560,
          },
          {
            date: "2023-03-25",
            value: 0,
            views: 33440563,
          },
          {
            date: "2023-03-28",
            value: 0,
            views: 33440564,
          },
          {
            date: "2023-03-29",
            value: 0,
            views: 33440564,
          },
          {
            date: "2023-03-30",
            value: 0,
            views: 33440564,
          },
          {
            date: "2023-03-31",
            value: 0,
            views: 33440564,
          },
          {
            date: "2023-04-01",
            value: 0,
            views: 33440566,
          },
          {
            date: "2023-04-03",
            value: 0,
            views: 33440568,
          },
          {
            date: "2023-04-05",
            value: 0,
            views: 33440571,
          },
          {
            date: "2023-04-07",
            value: 0,
            views: 33440573,
          },
          {
            date: "2023-04-09",
            value: 0,
            views: 33440574,
          },
          {
            date: "2023-04-11",
            value: 0,
            views: 33440575,
          },
          {
            date: "2023-04-13",
            value: 0,
            views: 33440579,
          },
          {
            date: "2023-04-15",
            value: 0,
            views: 33440580,
          },
          {
            date: "2023-04-17",
            value: 0,
            views: 33440581,
          },
          {
            date: "2023-04-19",
            value: 0,
            views: 33440582,
          },
          {
            date: "2023-04-21",
            value: 0,
            views: 33440583,
          },
          {
            date: "2023-04-23",
            value: 0,
            views: 33440590,
          },
          {
            date: "2023-05-05",
            value: 0,
            views: 33440607,
          },
          {
            date: "2023-05-12",
            value: 0,
            views: 33440619,
          },
          {
            date: "2023-05-19",
            value: 0,
            views: 33440637,
          },
          {
            date: "2023-05-30",
            value: 0,
            views: 33440642,
          },
          {
            date: "2023-06-03",
            value: 0,
            views: 33440648,
          },
          {
            date: "2023-06-05",
            value: 0,
            views: 33440650,
          },
          {
            date: "2023-06-12",
            value: 0,
            views: 33440675,
          },
          {
            date: "2023-07-25",
            value: 0,
            views: 33440751,
          },
          {
            date: "2023-07-29",
            value: 0,
            views: 33440760,
          },
          {
            date: "2023-07-31",
            value: 0,
            views: 33440760,
          },
          {
            date: "2023-08-01",
            value: 0,
            views: 33440762,
          },
          {
            date: "2023-08-04",
            value: 0,
            views: 33440769,
          },
          {
            date: "2023-08-04",
            value: 0,
            views: 33440769,
          },
          {
            date: "2023-08-07",
            value: 0,
            views: 33440773,
          },
          {
            date: "2023-08-09",
            value: 0,
            views: 33440777,
          },
          {
            date: "2023-08-11",
            value: 0,
            views: 33440783,
          },
          {
            date: "2023-08-16",
            value: 0,
            views: 33440786,
          },
          {
            date: "2023-08-18",
            value: 0,
            views: 33440787,
          },
          {
            date: "2023-08-22",
            value: 0,
            views: 33440792,
          },
          {
            date: "2023-08-23",
            value: 0,
            views: 33440795,
          },
          {
            date: "2023-08-25",
            value: 0,
            views: 33440796,
          },
          {
            date: "2023-08-28",
            value: 0,
            views: 33440796,
          },
          {
            date: "2023-08-30",
            value: 0,
            views: 33440798,
          },
          {
            date: "2023-09-04",
            value: 0,
            views: 33440804,
          },
          {
            date: "2023-09-06",
            value: 0,
            views: 33440805,
          },
          {
            date: "2023-09-13",
            value: 0,
            views: 33440815,
          },
          {
            date: "2023-09-15",
            value: 0,
            views: 33440825,
          },
          {
            date: "2023-09-20",
            value: 0,
            views: 33440828,
          },
          {
            date: "2023-09-22",
            value: 0,
            views: 33440832,
          },
          {
            date: "2023-09-25",
            value: 0,
            views: 33440834,
          },
          {
            date: "2023-09-27",
            value: 0,
            views: 33440838,
          },
          {
            date: "2023-09-29",
            value: 0,
            views: 33440839,
          },
          {
            date: "2023-10-02",
            value: 0,
            views: 33440843,
          },
          {
            date: "2023-10-04",
            value: 0,
            views: 33440850,
          },
          {
            date: "2023-10-09",
            value: 0,
            views: 33440857,
          },
          {
            date: "2023-10-11",
            value: 0,
            views: 33440859,
          },
          {
            date: "2023-10-13",
            value: 0,
            views: 33440862,
          },
          {
            date: "2023-10-16",
            value: 0,
            views: 33440862,
          },
          {
            date: "2023-10-18",
            value: 0,
            views: 33440865,
          },
          {
            date: "2023-10-20",
            value: 0,
            views: 33440868,
          },
          {
            date: "2023-10-23",
            value: 0,
            views: 33440875,
          },
          {
            date: "2023-10-23",
            value: 0,
            views: 33440877,
          },
          {
            date: "2023-10-25",
            value: 0,
            views: 33440878,
          },
          {
            date: "2023-10-27",
            value: 0,
            views: 33440879,
          },
          {
            date: "2023-10-30",
            value: 0,
            views: 33440882,
          },
          {
            date: "2023-11-01",
            value: 0,
            views: 33440883,
          },
          {
            date: "2023-11-03",
            value: 0,
            views: 33440888,
          },
          {
            date: "2023-11-06",
            value: 0,
            views: 33440889,
          },
          {
            date: "2023-11-08",
            value: 0,
            views: 33440891,
          },
        ],
      },
      {
        videoId: "k-wkdkyGlwg",
        dataPoints: [
          {
            date: "2020-09-08",
            value: 0,
            views: 0,
          },
          {
            date: "2021-10-29",
            value: 0,
            views: 32738672,
          },
          {
            date: "2021-10-30",
            value: 0,
            views: 32738672,
          },
          {
            date: "2021-10-31",
            value: 0,
            views: 32738673,
          },
          {
            date: "2021-11-01",
            value: 0,
            views: 32738673,
          },
          {
            date: "2021-11-02",
            value: 0,
            views: 32738673,
          },
          {
            date: "2021-11-03",
            value: 0,
            views: 32738673,
          },
          {
            date: "2021-11-04",
            value: 0,
            views: 32738673,
          },
          {
            date: "2021-11-04",
            value: 0,
            views: 32738675,
          },
          {
            date: "2021-11-04",
            value: 0,
            views: 32738675,
          },
          {
            date: "2021-11-04",
            value: 0,
            views: 32738675,
          },
          {
            date: "2021-11-05",
            value: 0,
            views: 32738675,
          },
          {
            date: "2021-11-06",
            value: 0,
            views: 32738675,
          },
          {
            date: "2021-11-07",
            value: 0,
            views: 32738675,
          },
          {
            date: "2021-11-08",
            value: 0,
            views: 32738676,
          },
          {
            date: "2021-11-09",
            value: 0,
            views: 32738677,
          },
          {
            date: "2021-11-10",
            value: 0,
            views: 32738677,
          },
          {
            date: "2021-11-11",
            value: 0,
            views: 32738677,
          },
          {
            date: "2021-11-12",
            value: 0,
            views: 32738678,
          },
          {
            date: "2021-11-13",
            value: 0,
            views: 32738680,
          },
          {
            date: "2021-11-14",
            value: 0,
            views: 32738681,
          },
          {
            date: "2021-11-15",
            value: 0,
            views: 32738681,
          },
          {
            date: "2021-11-16",
            value: 0,
            views: 32738681,
          },
          {
            date: "2021-11-17",
            value: 0,
            views: 32738682,
          },
          {
            date: "2021-11-18",
            value: 0,
            views: 32738682,
          },
          {
            date: "2021-11-19",
            value: 0,
            views: 32738682,
          },
          {
            date: "2021-11-20",
            value: 0,
            views: 32738683,
          },
          {
            date: "2021-11-21",
            value: 0,
            views: 32738683,
          },
          {
            date: "2021-11-22",
            value: 0,
            views: 32738683,
          },
          {
            date: "2021-11-23",
            value: 0,
            views: 32738687,
          },
          {
            date: "2021-11-24",
            value: 0,
            views: 32738687,
          },
          {
            date: "2021-11-25",
            value: 0,
            views: 32738687,
          },
          {
            date: "2021-11-26",
            value: 0,
            views: 32738689,
          },
          {
            date: "2021-11-27",
            value: 0,
            views: 32738689,
          },
          {
            date: "2021-11-28",
            value: 0,
            views: 32738689,
          },
          {
            date: "2021-11-29",
            value: 0,
            views: 32738695,
          },
          {
            date: "2021-12-01",
            value: 0,
            views: 32738699,
          },
          {
            date: "2021-12-02",
            value: 0,
            views: 32738700,
          },
          {
            date: "2021-12-03",
            value: 0,
            views: 32738700,
          },
          {
            date: "2021-12-04",
            value: 0,
            views: 32738700,
          },
          {
            date: "2021-12-05",
            value: 0,
            views: 32738704,
          },
          {
            date: "2021-12-06",
            value: 0,
            views: 32738704,
          },
          {
            date: "2021-12-07",
            value: 0,
            views: 32738706,
          },
          {
            date: "2021-12-09",
            value: 0,
            views: 32738707,
          },
          {
            date: "2021-12-10",
            value: 0,
            views: 32738708,
          },
          {
            date: "2021-12-12",
            value: 0,
            views: 32738708,
          },
          {
            date: "2021-12-13",
            value: 0,
            views: 32738708,
          },
          {
            date: "2021-12-15",
            value: 0,
            views: 32738712,
          },
          {
            date: "2021-12-17",
            value: 0,
            views: 32738716,
          },
          {
            date: "2021-12-18",
            value: 0,
            views: 32738717,
          },
          {
            date: "2021-12-19",
            value: 0,
            views: 32738717,
          },
          {
            date: "2021-12-20",
            value: 0,
            views: 32738717,
          },
          {
            date: "2021-12-21",
            value: 0,
            views: 32738718,
          },
          {
            date: "2021-12-22",
            value: 0,
            views: 32738718,
          },
          {
            date: "2021-12-23",
            value: 0,
            views: 32738718,
          },
          {
            date: "2021-12-24",
            value: 0,
            views: 32738718,
          },
          {
            date: "2021-12-25",
            value: 0,
            views: 32738718,
          },
          {
            date: "2021-12-26",
            value: 0,
            views: 32738718,
          },
          {
            date: "2021-12-27",
            value: 0,
            views: 32738718,
          },
          {
            date: "2021-12-28",
            value: 0,
            views: 32738719,
          },
          {
            date: "2021-12-29",
            value: 0,
            views: 32738719,
          },
          {
            date: "2021-12-30",
            value: 0,
            views: 32738722,
          },
          {
            date: "2021-12-31",
            value: 0,
            views: 32738725,
          },
          {
            date: "2022-01-01",
            value: 0,
            views: 32738726,
          },
          {
            date: "2022-01-03",
            value: 0,
            views: 32738728,
          },
          {
            date: "2022-01-04",
            value: 0,
            views: 32738729,
          },
          {
            date: "2022-01-05",
            value: 0,
            views: 32738729,
          },
          {
            date: "2022-01-06",
            value: 0,
            views: 32738729,
          },
          {
            date: "2022-01-09",
            value: 0,
            views: 32738731,
          },
          {
            date: "2022-01-10",
            value: 0,
            views: 32738731,
          },
          {
            date: "2022-01-15",
            value: 0,
            views: 32738743,
          },
          {
            date: "2022-01-17",
            value: 0,
            views: 32738745,
          },
          {
            date: "2022-04-29",
            value: 0,
            views: 32738830,
          },
          {
            date: "2022-05-02",
            value: 0,
            views: 32738830,
          },
          {
            date: "2022-05-06",
            value: 0,
            views: 32738834,
          },
          {
            date: "2022-05-11",
            value: 0,
            views: 32738836,
          },
          {
            date: "2022-05-13",
            value: 0,
            views: 32738839,
          },
          {
            date: "2022-05-17",
            value: 0,
            views: 32738839,
          },
          {
            date: "2022-05-19",
            value: 0,
            views: 32738841,
          },
          {
            date: "2022-05-20",
            value: 0,
            views: 32738841,
          },
          {
            date: "2022-05-21",
            value: 0,
            views: 32738842,
          },
          {
            date: "2022-05-22",
            value: 0,
            views: 32738842,
          },
          {
            date: "2022-05-23",
            value: 0,
            views: 32738843,
          },
          {
            date: "2022-05-24",
            value: 0,
            views: 32738843,
          },
          {
            date: "2022-05-25",
            value: 0,
            views: 32738843,
          },
          {
            date: "2022-05-26",
            value: 0,
            views: 32738845,
          },
          {
            date: "2022-05-27",
            value: 0,
            views: 32738845,
          },
          {
            date: "2022-05-28",
            value: 0,
            views: 32738845,
          },
          {
            date: "2022-05-29",
            value: 0,
            views: 32738846,
          },
          {
            date: "2022-05-30",
            value: 0,
            views: 32738846,
          },
          {
            date: "2022-05-31",
            value: 0,
            views: 32738846,
          },
          {
            date: "2022-06-01",
            value: 0,
            views: 32738847,
          },
          {
            date: "2022-06-02",
            value: 0,
            views: 32738849,
          },
          {
            date: "2022-06-03",
            value: 0,
            views: 32738850,
          },
          {
            date: "2022-06-04",
            value: 0,
            views: 32738850,
          },
          {
            date: "2022-06-05",
            value: 0,
            views: 32738850,
          },
          {
            date: "2022-06-06",
            value: 0,
            views: 32738850,
          },
          {
            date: "2022-06-07",
            value: 0,
            views: 32738850,
          },
          {
            date: "2022-06-08",
            value: 0,
            views: 32738851,
          },
          {
            date: "2022-06-09",
            value: 0,
            views: 32738852,
          },
          {
            date: "2022-06-10",
            value: 0,
            views: 32738853,
          },
          {
            date: "2022-06-11",
            value: 0,
            views: 32738853,
          },
          {
            date: "2022-06-12",
            value: 0,
            views: 32738853,
          },
          {
            date: "2022-06-13",
            value: 0,
            views: 32738854,
          },
          {
            date: "2022-06-14",
            value: 0,
            views: 32738854,
          },
          {
            date: "2022-06-15",
            value: 0,
            views: 32738854,
          },
          {
            date: "2022-06-16",
            value: 0,
            views: 32738854,
          },
          {
            date: "2022-06-17",
            value: 0,
            views: 32738854,
          },
          {
            date: "2022-06-18",
            value: 0,
            views: 32738854,
          },
          {
            date: "2022-06-19",
            value: 0,
            views: 32738855,
          },
          {
            date: "2022-06-20",
            value: 0,
            views: 32738858,
          },
          {
            date: "2022-06-21",
            value: 0,
            views: 32738860,
          },
          {
            date: "2022-06-22",
            value: 0,
            views: 32738861,
          },
          {
            date: "2022-06-23",
            value: 0,
            views: 32738861,
          },
          {
            date: "2022-06-24",
            value: 0,
            views: 32738861,
          },
          {
            date: "2022-06-25",
            value: 0,
            views: 32738861,
          },
          {
            date: "2022-06-26",
            value: 0,
            views: 32738861,
          },
          {
            date: "2022-06-27",
            value: 0,
            views: 32738861,
          },
          {
            date: "2022-06-28",
            value: 0,
            views: 32738861,
          },
          {
            date: "2022-06-29",
            value: 0,
            views: 32738861,
          },
          {
            date: "2022-06-30",
            value: 0,
            views: 32738861,
          },
          {
            date: "2022-07-01",
            value: 0,
            views: 32738861,
          },
          {
            date: "2022-07-02",
            value: 0,
            views: 32738862,
          },
          {
            date: "2022-07-03",
            value: 0,
            views: 32738862,
          },
          {
            date: "2022-07-04",
            value: 0,
            views: 32738871,
          },
          {
            date: "2022-07-05",
            value: 0,
            views: 32738874,
          },
          {
            date: "2022-07-06",
            value: 0,
            views: 32738874,
          },
          {
            date: "2022-07-07",
            value: 0,
            views: 32738874,
          },
          {
            date: "2022-07-08",
            value: 0,
            views: 32738874,
          },
          {
            date: "2022-07-09",
            value: 0,
            views: 32738874,
          },
          {
            date: "2022-07-10",
            value: 0,
            views: 32738875,
          },
          {
            date: "2022-07-11",
            value: 0,
            views: 32738876,
          },
          {
            date: "2022-07-12",
            value: 0,
            views: 32738877,
          },
          {
            date: "2022-07-13",
            value: 0,
            views: 32738881,
          },
          {
            date: "2022-07-14",
            value: 0,
            views: 32738881,
          },
          {
            date: "2022-07-15",
            value: 0,
            views: 32738881,
          },
          {
            date: "2022-07-16",
            value: 0,
            views: 32738881,
          },
          {
            date: "2022-07-17",
            value: 0,
            views: 32738882,
          },
          {
            date: "2022-07-18",
            value: 0,
            views: 32738882,
          },
          {
            date: "2022-07-19",
            value: 0,
            views: 32738882,
          },
          {
            date: "2022-07-20",
            value: 0,
            views: 32738882,
          },
          {
            date: "2022-07-21",
            value: 0,
            views: 32738882,
          },
          {
            date: "2022-07-22",
            value: 0,
            views: 32738888,
          },
          {
            date: "2022-07-23",
            value: 0,
            views: 32738888,
          },
          {
            date: "2022-07-24",
            value: 0,
            views: 32738888,
          },
          {
            date: "2022-07-25",
            value: 0,
            views: 32738890,
          },
          {
            date: "2022-07-26",
            value: 0,
            views: 32738891,
          },
          {
            date: "2022-07-27",
            value: 0,
            views: 32738891,
          },
          {
            date: "2022-07-28",
            value: 0,
            views: 32738893,
          },
          {
            date: "2022-07-29",
            value: 0,
            views: 32738895,
          },
          {
            date: "2022-07-30",
            value: 0,
            views: 32738895,
          },
          {
            date: "2022-07-31",
            value: 0,
            views: 32738897,
          },
          {
            date: "2022-08-01",
            value: 0,
            views: 32738898,
          },
          {
            date: "2022-08-02",
            value: 0,
            views: 32738900,
          },
          {
            date: "2022-08-03",
            value: 0,
            views: 32738900,
          },
          {
            date: "2022-08-04",
            value: 0,
            views: 32738900,
          },
          {
            date: "2022-08-05",
            value: 0,
            views: 32738902,
          },
          {
            date: "2022-08-06",
            value: 0,
            views: 32738903,
          },
          {
            date: "2022-08-07",
            value: 0,
            views: 32738904,
          },
          {
            date: "2022-08-08",
            value: 0,
            views: 32738905,
          },
          {
            date: "2022-08-09",
            value: 0,
            views: 32738905,
          },
          {
            date: "2022-08-10",
            value: 0,
            views: 32738907,
          },
          {
            date: "2022-08-11",
            value: 0,
            views: 32738908,
          },
          {
            date: "2022-08-12",
            value: 0,
            views: 32738908,
          },
          {
            date: "2022-08-13",
            value: 0,
            views: 32738908,
          },
          {
            date: "2022-08-14",
            value: 0,
            views: 32738908,
          },
          {
            date: "2022-08-15",
            value: 0,
            views: 32738909,
          },
          {
            date: "2022-08-16",
            value: 0,
            views: 32738909,
          },
          {
            date: "2022-08-17",
            value: 0,
            views: 32738911,
          },
          {
            date: "2022-08-18",
            value: 0,
            views: 32738913,
          },
          {
            date: "2022-08-19",
            value: 0,
            views: 32738913,
          },
          {
            date: "2022-08-20",
            value: 0,
            views: 32738914,
          },
          {
            date: "2022-08-21",
            value: 0,
            views: 32738915,
          },
          {
            date: "2022-08-22",
            value: 0,
            views: 32738915,
          },
          {
            date: "2022-08-23",
            value: 0,
            views: 32738916,
          },
          {
            date: "2022-08-24",
            value: 0,
            views: 32738918,
          },
          {
            date: "2022-08-25",
            value: 0,
            views: 32738920,
          },
          {
            date: "2022-08-26",
            value: 0,
            views: 32738922,
          },
          {
            date: "2022-08-27",
            value: 0,
            views: 32738922,
          },
          {
            date: "2022-08-28",
            value: 0,
            views: 32738923,
          },
          {
            date: "2022-08-29",
            value: 0,
            views: 32738925,
          },
          {
            date: "2022-08-30",
            value: 0,
            views: 32738925,
          },
          {
            date: "2022-08-31",
            value: 0,
            views: 32738932,
          },
          {
            date: "2022-09-01",
            value: 0,
            views: 32738938,
          },
          {
            date: "2022-09-02",
            value: 0,
            views: 32738939,
          },
          {
            date: "2022-09-03",
            value: 0,
            views: 32738940,
          },
          {
            date: "2022-09-04",
            value: 0,
            views: 32738940,
          },
          {
            date: "2022-09-05",
            value: 0,
            views: 32738942,
          },
          {
            date: "2022-09-06",
            value: 0,
            views: 32738945,
          },
          {
            date: "2022-09-07",
            value: 0,
            views: 32738945,
          },
          {
            date: "2022-09-08",
            value: 0,
            views: 32738946,
          },
          {
            date: "2022-09-09",
            value: 0,
            views: 32738946,
          },
          {
            date: "2022-09-10",
            value: 0,
            views: 32738946,
          },
          {
            date: "2022-09-11",
            value: 0,
            views: 32738951,
          },
          {
            date: "2022-09-12",
            value: 0,
            views: 32738951,
          },
          {
            date: "2022-09-13",
            value: 0,
            views: 32738951,
          },
          {
            date: "2022-09-14",
            value: 0,
            views: 32738953,
          },
          {
            date: "2022-09-15",
            value: 0,
            views: 32738953,
          },
          {
            date: "2022-09-16",
            value: 0,
            views: 32738954,
          },
          {
            date: "2022-09-17",
            value: 0,
            views: 32738957,
          },
          {
            date: "2022-09-18",
            value: 0,
            views: 32738957,
          },
          {
            date: "2022-09-19",
            value: 0,
            views: 32738960,
          },
          {
            date: "2022-09-20",
            value: 0,
            views: 32738960,
          },
          {
            date: "2022-09-21",
            value: 0,
            views: 32738962,
          },
          {
            date: "2022-09-22",
            value: 0,
            views: 32738964,
          },
          {
            date: "2022-09-23",
            value: 0,
            views: 32738964,
          },
          {
            date: "2022-09-24",
            value: 0,
            views: 32738964,
          },
          {
            date: "2022-09-25",
            value: 0,
            views: 32738965,
          },
          {
            date: "2022-09-26",
            value: 0,
            views: 32738967,
          },
          {
            date: "2022-09-27",
            value: 0,
            views: 32738967,
          },
          {
            date: "2022-09-28",
            value: 0,
            views: 32738967,
          },
          {
            date: "2022-09-29",
            value: 0,
            views: 32738970,
          },
          {
            date: "2022-09-30",
            value: 0,
            views: 32738971,
          },
          {
            date: "2022-10-01",
            value: 0,
            views: 32738971,
          },
          {
            date: "2022-10-02",
            value: 0,
            views: 32738971,
          },
          {
            date: "2022-10-03",
            value: 0,
            views: 32738971,
          },
          {
            date: "2022-10-04",
            value: 0,
            views: 32738971,
          },
          {
            date: "2022-10-05",
            value: 0,
            views: 32738971,
          },
          {
            date: "2022-10-06",
            value: 0,
            views: 32738971,
          },
          {
            date: "2022-10-07",
            value: 0,
            views: 32738971,
          },
          {
            date: "2022-10-08",
            value: 0,
            views: 32738971,
          },
          {
            date: "2022-10-09",
            value: 0,
            views: 32738971,
          },
          {
            date: "2022-10-10",
            value: 0,
            views: 32738971,
          },
          {
            date: "2022-10-11",
            value: 0,
            views: 32738973,
          },
          {
            date: "2022-10-12",
            value: 0,
            views: 32738976,
          },
          {
            date: "2022-10-13",
            value: 0,
            views: 32738976,
          },
          {
            date: "2022-10-14",
            value: 0,
            views: 32738977,
          },
          {
            date: "2022-10-15",
            value: 0,
            views: 32738977,
          },
          {
            date: "2022-10-16",
            value: 0,
            views: 32738977,
          },
          {
            date: "2022-10-17",
            value: 0,
            views: 32738977,
          },
          {
            date: "2022-10-18",
            value: 0,
            views: 32738977,
          },
          {
            date: "2022-10-19",
            value: 0,
            views: 32738978,
          },
          {
            date: "2022-10-20",
            value: 0,
            views: 32738979,
          },
          {
            date: "2022-10-21",
            value: 0,
            views: 32738980,
          },
          {
            date: "2022-10-22",
            value: 0,
            views: 32738980,
          },
          {
            date: "2022-10-23",
            value: 0,
            views: 32738983,
          },
          {
            date: "2022-10-24",
            value: 0,
            views: 32738986,
          },
          {
            date: "2022-10-25",
            value: 0,
            views: 32738986,
          },
          {
            date: "2022-10-26",
            value: 0,
            views: 32738987,
          },
          {
            date: "2022-10-27",
            value: 0,
            views: 32738988,
          },
          {
            date: "2022-10-28",
            value: 0,
            views: 32738988,
          },
          {
            date: "2022-10-29",
            value: 0,
            views: 32738988,
          },
          {
            date: "2022-10-30",
            value: 0,
            views: 32738988,
          },
          {
            date: "2022-10-31",
            value: 0,
            views: 32738988,
          },
          {
            date: "2022-11-01",
            value: 0,
            views: 32738990,
          },
          {
            date: "2022-11-02",
            value: 0,
            views: 32738992,
          },
          {
            date: "2022-11-03",
            value: 0,
            views: 32738992,
          },
          {
            date: "2022-11-04",
            value: 0,
            views: 32738992,
          },
          {
            date: "2022-11-05",
            value: 0,
            views: 32738992,
          },
          {
            date: "2022-11-06",
            value: 0,
            views: 32738992,
          },
          {
            date: "2022-11-07",
            value: 0,
            views: 32738992,
          },
          {
            date: "2022-11-08",
            value: 0,
            views: 32738992,
          },
          {
            date: "2022-11-09",
            value: 0,
            views: 32738995,
          },
          {
            date: "2022-11-15",
            value: 0,
            views: 32738998,
          },
          {
            date: "2022-11-16",
            value: 0,
            views: 32739001,
          },
          {
            date: "2022-11-19",
            value: 0,
            views: 32739004,
          },
          {
            date: "2022-11-20",
            value: 0,
            views: 32739005,
          },
          {
            date: "2022-11-21",
            value: 0,
            views: 32739005,
          },
          {
            date: "2022-11-22",
            value: 0,
            views: 32739005,
          },
          {
            date: "2022-11-23",
            value: 0,
            views: 32739005,
          },
          {
            date: "2022-11-25",
            value: 0,
            views: 32739006,
          },
          {
            date: "2022-11-26",
            value: 0,
            views: 32739006,
          },
          {
            date: "2022-11-27",
            value: 0,
            views: 32739006,
          },
          {
            date: "2022-11-28",
            value: 0,
            views: 32739006,
          },
          {
            date: "2022-11-29",
            value: 0,
            views: 32739007,
          },
          {
            date: "2022-11-30",
            value: 0,
            views: 32739008,
          },
          {
            date: "2022-12-01",
            value: 0,
            views: 32739008,
          },
          {
            date: "2022-12-02",
            value: 0,
            views: 32739009,
          },
          {
            date: "2022-12-03",
            value: 0,
            views: 32739009,
          },
          {
            date: "2022-12-04",
            value: 0,
            views: 32739009,
          },
          {
            date: "2022-12-05",
            value: 0,
            views: 32739009,
          },
          {
            date: "2022-12-06",
            value: 0,
            views: 32739010,
          },
          {
            date: "2022-12-08",
            value: 0,
            views: 32739020,
          },
          {
            date: "2022-12-09",
            value: 0,
            views: 32739023,
          },
          {
            date: "2022-12-10",
            value: 0,
            views: 32739023,
          },
          {
            date: "2022-12-11",
            value: 0,
            views: 32739023,
          },
          {
            date: "2022-12-12",
            value: 0,
            views: 32739023,
          },
          {
            date: "2022-12-13",
            value: 0,
            views: 32739023,
          },
          {
            date: "2022-12-14",
            value: 0,
            views: 32739024,
          },
          {
            date: "2022-12-15",
            value: 0,
            views: 32739028,
          },
          {
            date: "2022-12-16",
            value: 0,
            views: 32739029,
          },
          {
            date: "2022-12-17",
            value: 0,
            views: 32739030,
          },
          {
            date: "2022-12-18",
            value: 0,
            views: 32739032,
          },
          {
            date: "2022-12-19",
            value: 0,
            views: 32739036,
          },
          {
            date: "2022-12-20",
            value: 0,
            views: 32739038,
          },
          {
            date: "2022-12-21",
            value: 0,
            views: 32739039,
          },
          {
            date: "2022-12-22",
            value: 0,
            views: 32739039,
          },
          {
            date: "2022-12-23",
            value: 0,
            views: 32739039,
          },
          {
            date: "2022-12-25",
            value: 0,
            views: 32739039,
          },
          {
            date: "2022-12-26",
            value: 0,
            views: 32739039,
          },
          {
            date: "2022-12-27",
            value: 0,
            views: 32739039,
          },
          {
            date: "2022-12-28",
            value: 0,
            views: 32739040,
          },
          {
            date: "2022-12-29",
            value: 0,
            views: 32739040,
          },
          {
            date: "2022-12-30",
            value: 0,
            views: 32739042,
          },
          {
            date: "2022-12-31",
            value: 0,
            views: 32739043,
          },
          {
            date: "2023-01-01",
            value: 0,
            views: 32739044,
          },
          {
            date: "2023-01-02",
            value: 0,
            views: 32739045,
          },
          {
            date: "2023-01-03",
            value: 0,
            views: 32739045,
          },
          {
            date: "2023-01-04",
            value: 0,
            views: 32739046,
          },
          {
            date: "2023-01-05",
            value: 0,
            views: 32739046,
          },
          {
            date: "2023-01-06",
            value: 0,
            views: 32739048,
          },
          {
            date: "2023-01-07",
            value: 0,
            views: 32739048,
          },
          {
            date: "2023-01-08",
            value: 0,
            views: 32739048,
          },
          {
            date: "2023-01-09",
            value: 0,
            views: 32739048,
          },
          {
            date: "2023-01-10",
            value: 0,
            views: 32739048,
          },
          {
            date: "2023-01-11",
            value: 0,
            views: 32739049,
          },
          {
            date: "2023-01-12",
            value: 0,
            views: 32739051,
          },
          {
            date: "2023-01-13",
            value: 0,
            views: 32739052,
          },
          {
            date: "2023-01-14",
            value: 0,
            views: 32739052,
          },
          {
            date: "2023-01-15",
            value: 0,
            views: 32739052,
          },
          {
            date: "2023-01-16",
            value: 0,
            views: 32739053,
          },
          {
            date: "2023-01-17",
            value: 0,
            views: 32739057,
          },
          {
            date: "2023-01-18",
            value: 0,
            views: 32739057,
          },
          {
            date: "2023-01-19",
            value: 0,
            views: 32739057,
          },
          {
            date: "2023-01-20",
            value: 0,
            views: 32739057,
          },
          {
            date: "2023-01-21",
            value: 0,
            views: 32739058,
          },
          {
            date: "2023-01-22",
            value: 0,
            views: 32739059,
          },
          {
            date: "2023-01-23",
            value: 0,
            views: 32739061,
          },
          {
            date: "2023-01-24",
            value: 0,
            views: 32739065,
          },
          {
            date: "2023-01-25",
            value: 0,
            views: 32739066,
          },
          {
            date: "2023-01-26",
            value: 0,
            views: 32739067,
          },
          {
            date: "2023-01-27",
            value: 0,
            views: 32739073,
          },
          {
            date: "2023-01-29",
            value: 0,
            views: 32739075,
          },
          {
            date: "2023-01-30",
            value: 0,
            views: 32739076,
          },
          {
            date: "2023-01-31",
            value: 0,
            views: 32739076,
          },
          {
            date: "2023-02-01",
            value: 0,
            views: 32739078,
          },
          {
            date: "2023-02-02",
            value: 0,
            views: 32739078,
          },
          {
            date: "2023-02-03",
            value: 0,
            views: 32739079,
          },
          {
            date: "2023-02-04",
            value: 0,
            views: 32739079,
          },
          {
            date: "2023-02-05",
            value: 0,
            views: 32739081,
          },
          {
            date: "2023-02-06",
            value: 0,
            views: 32739083,
          },
          {
            date: "2023-02-08",
            value: 0,
            views: 32739085,
          },
          {
            date: "2023-02-09",
            value: 0,
            views: 32739086,
          },
          {
            date: "2023-02-10",
            value: 0,
            views: 32739087,
          },
          {
            date: "2023-02-12",
            value: 0,
            views: 32739090,
          },
          {
            date: "2023-02-14",
            value: 0,
            views: 32739092,
          },
          {
            date: "2023-02-16",
            value: 0,
            views: 32739097,
          },
          {
            date: "2023-02-18",
            value: 0,
            views: 32739100,
          },
          {
            date: "2023-02-20",
            value: 0,
            views: 32739104,
          },
          {
            date: "2023-02-22",
            value: 0,
            views: 32739105,
          },
          {
            date: "2023-02-24",
            value: 0,
            views: 32739106,
          },
          {
            date: "2023-02-26",
            value: 0,
            views: 32739109,
          },
          {
            date: "2023-02-28",
            value: 0,
            views: 32739113,
          },
          {
            date: "2023-03-02",
            value: 0,
            views: 32739114,
          },
          {
            date: "2023-03-07",
            value: 0,
            views: 32739115,
          },
          {
            date: "2023-03-09",
            value: 0,
            views: 32739115,
          },
          {
            date: "2023-03-15",
            value: 0,
            views: 32739125,
          },
          {
            date: "2023-03-17",
            value: 0,
            views: 32739128,
          },
          {
            date: "2023-03-19",
            value: 0,
            views: 32739132,
          },
          {
            date: "2023-03-21",
            value: 0,
            views: 32739133,
          },
          {
            date: "2023-03-23",
            value: 0,
            views: 32739134,
          },
          {
            date: "2023-03-25",
            value: 0,
            views: 32739135,
          },
          {
            date: "2023-03-28",
            value: 0,
            views: 32739139,
          },
          {
            date: "2023-03-31",
            value: 0,
            views: 32739140,
          },
          {
            date: "2023-04-01",
            value: 0,
            views: 32739140,
          },
          {
            date: "2023-04-03",
            value: 0,
            views: 32739142,
          },
          {
            date: "2023-04-05",
            value: 0,
            views: 32739144,
          },
          {
            date: "2023-04-07",
            value: 0,
            views: 32739144,
          },
          {
            date: "2023-04-09",
            value: 0,
            views: 32739144,
          },
          {
            date: "2023-04-11",
            value: 0,
            views: 32739145,
          },
          {
            date: "2023-04-13",
            value: 0,
            views: 32739146,
          },
          {
            date: "2023-04-15",
            value: 0,
            views: 32739147,
          },
          {
            date: "2023-04-17",
            value: 0,
            views: 32739149,
          },
          {
            date: "2023-04-19",
            value: 0,
            views: 32739152,
          },
          {
            date: "2023-04-21",
            value: 0,
            views: 32739152,
          },
          {
            date: "2023-04-23",
            value: 0,
            views: 32739155,
          },
          {
            date: "2023-05-06",
            value: 0,
            views: 32739160,
          },
          {
            date: "2023-05-13",
            value: 0,
            views: 32739169,
          },
          {
            date: "2023-05-31",
            value: 0,
            views: 32739195,
          },
          {
            date: "2023-06-04",
            value: 0,
            views: 32739199,
          },
          {
            date: "2023-06-06",
            value: 0,
            views: 32739202,
          },
          {
            date: "2023-06-13",
            value: 0,
            views: 32739217,
          },
          {
            date: "2023-07-26",
            value: 0,
            views: 32739260,
          },
          {
            date: "2023-07-29",
            value: 0,
            views: 32739264,
          },
          {
            date: "2023-08-02",
            value: 0,
            views: 32739267,
          },
          {
            date: "2023-08-05",
            value: 0,
            views: 32739270,
          },
          {
            date: "2023-08-07",
            value: 0,
            views: 32739274,
          },
          {
            date: "2023-08-09",
            value: 0,
            views: 32739278,
          },
          {
            date: "2023-08-12",
            value: 0,
            views: 32739279,
          },
          {
            date: "2023-08-16",
            value: 0,
            views: 32739281,
          },
          {
            date: "2023-08-18",
            value: 0,
            views: 32739281,
          },
          {
            date: "2023-08-23",
            value: 0,
            views: 32739286,
          },
          {
            date: "2023-08-23",
            value: 0,
            views: 32739287,
          },
          {
            date: "2023-08-25",
            value: 0,
            views: 32739287,
          },
          {
            date: "2023-08-28",
            value: 0,
            views: 32739290,
          },
          {
            date: "2023-08-30",
            value: 0,
            views: 32739291,
          },
          {
            date: "2023-09-05",
            value: 0,
            views: 32739293,
          },
          {
            date: "2023-09-07",
            value: 0,
            views: 32739295,
          },
          {
            date: "2023-09-10",
            value: 0,
            views: 32739297,
          },
          {
            date: "2023-09-13",
            value: 0,
            views: 32739300,
          },
          {
            date: "2023-09-16",
            value: 0,
            views: 32739300,
          },
          {
            date: "2023-09-18",
            value: 0,
            views: 32739300,
          },
          {
            date: "2023-09-20",
            value: 0,
            views: 32739300,
          },
          {
            date: "2023-09-22",
            value: 0,
            views: 32739303,
          },
          {
            date: "2023-09-25",
            value: 0,
            views: 32739305,
          },
          {
            date: "2023-09-27",
            value: 0,
            views: 32739306,
          },
          {
            date: "2023-09-29",
            value: 0,
            views: 32739307,
          },
          {
            date: "2023-10-02",
            value: 0,
            views: 32739312,
          },
          {
            date: "2023-10-04",
            value: 0,
            views: 32739313,
          },
          {
            date: "2023-10-06",
            value: 0,
            views: 32739311,
          },
          {
            date: "2023-10-10",
            value: 0,
            views: 32739311,
          },
          {
            date: "2023-10-11",
            value: 0,
            views: 32739311,
          },
          {
            date: "2023-10-11",
            value: 0,
            views: 32739311,
          },
          {
            date: "2023-10-13",
            value: 0,
            views: 32739317,
          },
          {
            date: "2023-10-17",
            value: 0,
            views: 32739317,
          },
          {
            date: "2023-10-18",
            value: 0,
            views: 32739317,
          },
          {
            date: "2023-10-20",
            value: 0,
            views: 32739318,
          },
          {
            date: "2023-10-25",
            value: 0,
            views: 32739322,
          },
          {
            date: "2023-10-26",
            value: 0,
            views: 32739323,
          },
          {
            date: "2023-10-27",
            value: 0,
            views: 32739324,
          },
          {
            date: "2023-10-31",
            value: 0,
            views: 32739331,
          },
          {
            date: "2023-11-04",
            value: 0,
            views: 32739332,
          },
        ],
      },
      {
        videoId: "aLbwMEv45HE",
        dataPoints: [
          {
            date: "2020-06-08",
            value: 0,
            views: 0,
          },
          {
            date: "2021-12-16",
            value: 0,
            views: 31603203,
          },
          {
            date: "2021-12-17",
            value: 0,
            views: 31603205,
          },
          {
            date: "2021-12-18",
            value: 0,
            views: 31603205,
          },
          {
            date: "2021-12-19",
            value: 0,
            views: 31603205,
          },
          {
            date: "2021-12-20",
            value: 0,
            views: 31603205,
          },
          {
            date: "2021-12-21",
            value: 0,
            views: 31603206,
          },
          {
            date: "2021-12-22",
            value: 0,
            views: 31603206,
          },
          {
            date: "2021-12-23",
            value: 0,
            views: 31603206,
          },
          {
            date: "2021-12-24",
            value: 0,
            views: 31603206,
          },
          {
            date: "2021-12-25",
            value: 0,
            views: 31603206,
          },
          {
            date: "2021-12-26",
            value: 0,
            views: 31603206,
          },
          {
            date: "2021-12-27",
            value: 0,
            views: 31603206,
          },
          {
            date: "2021-12-28",
            value: 0,
            views: 31603206,
          },
          {
            date: "2021-12-29",
            value: 0,
            views: 31603206,
          },
          {
            date: "2021-12-30",
            value: 0,
            views: 31603210,
          },
          {
            date: "2021-12-31",
            value: 0,
            views: 31603213,
          },
          {
            date: "2022-01-01",
            value: 0,
            views: 31603213,
          },
          {
            date: "2022-01-02",
            value: 0,
            views: 31603214,
          },
          {
            date: "2022-01-03",
            value: 0,
            views: 31603215,
          },
          {
            date: "2022-01-04",
            value: 0,
            views: 31603218,
          },
          {
            date: "2022-01-05",
            value: 0,
            views: 31603218,
          },
          {
            date: "2022-01-06",
            value: 0,
            views: 31603218,
          },
          {
            date: "2022-01-07",
            value: 0,
            views: 31603219,
          },
          {
            date: "2022-01-08",
            value: 0,
            views: 31603219,
          },
          {
            date: "2022-01-09",
            value: 0,
            views: 31603219,
          },
          {
            date: "2022-01-10",
            value: 0,
            views: 31603220,
          },
          {
            date: "2022-01-11",
            value: 0,
            views: 31603221,
          },
          {
            date: "2022-01-12",
            value: 0,
            views: 31603223,
          },
          {
            date: "2022-01-13",
            value: 0,
            views: 31603230,
          },
          {
            date: "2022-01-14",
            value: 0,
            views: 31603233,
          },
          {
            date: "2022-01-15",
            value: 0,
            views: 31603234,
          },
          {
            date: "2022-01-16",
            value: 0,
            views: 31603234,
          },
          {
            date: "2022-01-17",
            value: 0,
            views: 31603234,
          },
          {
            date: "2022-01-18",
            value: 0,
            views: 31603235,
          },
          {
            date: "2022-01-19",
            value: 0,
            views: 31603237,
          },
          {
            date: "2022-01-23",
            value: 0,
            views: 31603239,
          },
          {
            date: "2022-01-24",
            value: 0,
            views: 31603240,
          },
          {
            date: "2022-01-25",
            value: 0,
            views: 31603242,
          },
          {
            date: "2022-01-27",
            value: 0,
            views: 31603249,
          },
          {
            date: "2022-01-28",
            value: 0,
            views: 31603251,
          },
          {
            date: "2022-01-29",
            value: 0,
            views: 31603252,
          },
          {
            date: "2022-01-30",
            value: 0,
            views: 31603252,
          },
          {
            date: "2022-01-31",
            value: 0,
            views: 31603252,
          },
          {
            date: "2022-02-01",
            value: 0,
            views: 31603252,
          },
          {
            date: "2022-02-02",
            value: 0,
            views: 31603253,
          },
          {
            date: "2022-02-04",
            value: 0,
            views: 31603256,
          },
          {
            date: "2022-02-07",
            value: 0,
            views: 31603257,
          },
          {
            date: "2022-02-08",
            value: 0,
            views: 31603258,
          },
          {
            date: "2022-02-09",
            value: 0,
            views: 31603258,
          },
          {
            date: "2022-02-11",
            value: 0,
            views: 31603259,
          },
          {
            date: "2022-02-13",
            value: 0,
            views: 31603259,
          },
          {
            date: "2022-02-15",
            value: 0,
            views: 31603265,
          },
          {
            date: "2022-02-17",
            value: 0,
            views: 31603268,
          },
          {
            date: "2022-02-19",
            value: 0,
            views: 31603268,
          },
          {
            date: "2022-02-21",
            value: 0,
            views: 31603268,
          },
          {
            date: "2022-02-28",
            value: 0,
            views: 31603272,
          },
          {
            date: "2022-03-02",
            value: 0,
            views: 31603279,
          },
          {
            date: "2022-03-07",
            value: 0,
            views: 31603280,
          },
          {
            date: "2022-03-09",
            value: 0,
            views: 31603284,
          },
          {
            date: "2022-03-11",
            value: 0,
            views: 31603284,
          },
          {
            date: "2022-03-13",
            value: 0,
            views: 31603286,
          },
          {
            date: "2022-03-14",
            value: 0,
            views: 31603287,
          },
          {
            date: "2022-03-18",
            value: 0,
            views: 31603291,
          },
          {
            date: "2022-03-19",
            value: 0,
            views: 31603291,
          },
          {
            date: "2022-03-20",
            value: 0,
            views: 31603292,
          },
          {
            date: "2022-03-25",
            value: 0,
            views: 31603302,
          },
          {
            date: "2022-04-11",
            value: 0,
            views: 31603302,
          },
          {
            date: "2022-04-13",
            value: 0,
            views: 31603302,
          },
          {
            date: "2022-04-27",
            value: 0,
            views: 31603319,
          },
          {
            date: "2022-04-29",
            value: 0,
            views: 31603324,
          },
          {
            date: "2022-05-02",
            value: 0,
            views: 31603324,
          },
          {
            date: "2022-05-05",
            value: 0,
            views: 31603328,
          },
          {
            date: "2022-05-06",
            value: 0,
            views: 31603328,
          },
          {
            date: "2022-05-11",
            value: 0,
            views: 31603330,
          },
          {
            date: "2022-05-13",
            value: 0,
            views: 31603330,
          },
          {
            date: "2022-05-17",
            value: 0,
            views: 31603331,
          },
          {
            date: "2022-05-19",
            value: 0,
            views: 31603332,
          },
          {
            date: "2022-05-20",
            value: 0,
            views: 31603332,
          },
          {
            date: "2022-05-21",
            value: 0,
            views: 31603332,
          },
          {
            date: "2022-05-22",
            value: 0,
            views: 31603332,
          },
          {
            date: "2022-05-23",
            value: 0,
            views: 31603334,
          },
          {
            date: "2022-05-24",
            value: 0,
            views: 31603335,
          },
          {
            date: "2022-05-25",
            value: 0,
            views: 31603335,
          },
          {
            date: "2022-05-26",
            value: 0,
            views: 31603335,
          },
          {
            date: "2022-05-27",
            value: 0,
            views: 31603335,
          },
          {
            date: "2022-05-28",
            value: 0,
            views: 31603335,
          },
          {
            date: "2022-05-29",
            value: 0,
            views: 31603335,
          },
          {
            date: "2022-05-30",
            value: 0,
            views: 31603335,
          },
          {
            date: "2022-05-31",
            value: 0,
            views: 31603335,
          },
          {
            date: "2022-06-01",
            value: 0,
            views: 31603336,
          },
          {
            date: "2022-06-02",
            value: 0,
            views: 31603336,
          },
          {
            date: "2022-06-03",
            value: 0,
            views: 31603336,
          },
          {
            date: "2022-06-04",
            value: 0,
            views: 31603337,
          },
          {
            date: "2022-06-05",
            value: 0,
            views: 31603337,
          },
          {
            date: "2022-06-06",
            value: 0,
            views: 31603337,
          },
          {
            date: "2022-06-07",
            value: 0,
            views: 31603344,
          },
          {
            date: "2022-06-08",
            value: 0,
            views: 31603344,
          },
          {
            date: "2022-06-09",
            value: 0,
            views: 31603345,
          },
          {
            date: "2022-06-10",
            value: 0,
            views: 31603345,
          },
          {
            date: "2022-06-11",
            value: 0,
            views: 31603345,
          },
          {
            date: "2022-06-12",
            value: 0,
            views: 31603345,
          },
          {
            date: "2022-06-13",
            value: 0,
            views: 31603345,
          },
          {
            date: "2022-06-14",
            value: 0,
            views: 31603345,
          },
          {
            date: "2022-06-15",
            value: 0,
            views: 31603345,
          },
          {
            date: "2022-06-16",
            value: 0,
            views: 31603345,
          },
          {
            date: "2022-06-17",
            value: 0,
            views: 31603345,
          },
          {
            date: "2022-06-18",
            value: 0,
            views: 31603345,
          },
          {
            date: "2022-06-19",
            value: 0,
            views: 31603345,
          },
          {
            date: "2022-06-20",
            value: 0,
            views: 31603346,
          },
          {
            date: "2022-06-21",
            value: 0,
            views: 31603346,
          },
          {
            date: "2022-06-22",
            value: 0,
            views: 31603347,
          },
          {
            date: "2022-06-23",
            value: 0,
            views: 31603349,
          },
          {
            date: "2022-06-24",
            value: 0,
            views: 31603349,
          },
          {
            date: "2022-06-25",
            value: 0,
            views: 31603349,
          },
          {
            date: "2022-06-26",
            value: 0,
            views: 31603350,
          },
          {
            date: "2022-06-27",
            value: 0,
            views: 31603350,
          },
          {
            date: "2022-06-28",
            value: 0,
            views: 31603350,
          },
          {
            date: "2022-06-29",
            value: 0,
            views: 31603352,
          },
          {
            date: "2022-06-30",
            value: 0,
            views: 31603352,
          },
          {
            date: "2022-07-01",
            value: 0,
            views: 31603352,
          },
          {
            date: "2022-07-02",
            value: 0,
            views: 31603352,
          },
          {
            date: "2022-07-03",
            value: 0,
            views: 31603352,
          },
          {
            date: "2022-07-04",
            value: 0,
            views: 31603352,
          },
          {
            date: "2022-07-05",
            value: 0,
            views: 31603352,
          },
          {
            date: "2022-07-06",
            value: 0,
            views: 31603352,
          },
          {
            date: "2022-07-07",
            value: 0,
            views: 31603352,
          },
          {
            date: "2022-07-08",
            value: 0,
            views: 31603353,
          },
          {
            date: "2022-07-09",
            value: 0,
            views: 31603354,
          },
          {
            date: "2022-07-10",
            value: 0,
            views: 31603354,
          },
          {
            date: "2022-07-11",
            value: 0,
            views: 31603354,
          },
          {
            date: "2022-07-12",
            value: 0,
            views: 31603354,
          },
          {
            date: "2022-07-13",
            value: 0,
            views: 31603361,
          },
          {
            date: "2022-07-14",
            value: 0,
            views: 31603362,
          },
          {
            date: "2022-07-15",
            value: 0,
            views: 31603362,
          },
          {
            date: "2022-07-16",
            value: 0,
            views: 31603362,
          },
          {
            date: "2022-07-17",
            value: 0,
            views: 31603362,
          },
          {
            date: "2022-07-18",
            value: 0,
            views: 31603362,
          },
          {
            date: "2022-07-19",
            value: 0,
            views: 31603363,
          },
          {
            date: "2022-07-20",
            value: 0,
            views: 31603363,
          },
          {
            date: "2022-07-21",
            value: 0,
            views: 31603363,
          },
          {
            date: "2022-07-22",
            value: 0,
            views: 31603364,
          },
          {
            date: "2022-07-23",
            value: 0,
            views: 31603364,
          },
          {
            date: "2022-07-24",
            value: 0,
            views: 31603364,
          },
          {
            date: "2022-07-25",
            value: 0,
            views: 31603365,
          },
          {
            date: "2022-07-26",
            value: 0,
            views: 31603368,
          },
          {
            date: "2022-07-27",
            value: 0,
            views: 31603369,
          },
          {
            date: "2022-07-28",
            value: 0,
            views: 31603371,
          },
          {
            date: "2022-07-29",
            value: 0,
            views: 31603371,
          },
          {
            date: "2022-07-30",
            value: 0,
            views: 31603371,
          },
          {
            date: "2022-07-31",
            value: 0,
            views: 31603372,
          },
          {
            date: "2022-08-01",
            value: 0,
            views: 31603372,
          },
          {
            date: "2022-08-02",
            value: 0,
            views: 31603372,
          },
          {
            date: "2022-08-03",
            value: 0,
            views: 31603372,
          },
          {
            date: "2022-08-04",
            value: 0,
            views: 31603372,
          },
          {
            date: "2022-08-05",
            value: 0,
            views: 31603372,
          },
          {
            date: "2022-08-06",
            value: 0,
            views: 31603372,
          },
          {
            date: "2022-08-07",
            value: 0,
            views: 31603372,
          },
          {
            date: "2022-08-08",
            value: 0,
            views: 31603372,
          },
          {
            date: "2022-08-09",
            value: 0,
            views: 31603376,
          },
          {
            date: "2022-08-10",
            value: 0,
            views: 31603380,
          },
          {
            date: "2022-08-11",
            value: 0,
            views: 31603381,
          },
          {
            date: "2022-08-12",
            value: 0,
            views: 31603381,
          },
          {
            date: "2022-08-13",
            value: 0,
            views: 31603381,
          },
          {
            date: "2022-08-14",
            value: 0,
            views: 31603381,
          },
          {
            date: "2022-08-15",
            value: 0,
            views: 31603381,
          },
          {
            date: "2022-08-16",
            value: 0,
            views: 31603382,
          },
          {
            date: "2022-08-17",
            value: 0,
            views: 31603384,
          },
          {
            date: "2022-08-18",
            value: 0,
            views: 31603385,
          },
          {
            date: "2022-08-19",
            value: 0,
            views: 31603385,
          },
          {
            date: "2022-08-20",
            value: 0,
            views: 31603385,
          },
          {
            date: "2022-08-21",
            value: 0,
            views: 31603386,
          },
          {
            date: "2022-08-22",
            value: 0,
            views: 31603386,
          },
          {
            date: "2022-08-23",
            value: 0,
            views: 31603386,
          },
          {
            date: "2022-08-24",
            value: 0,
            views: 31603388,
          },
          {
            date: "2022-08-25",
            value: 0,
            views: 31603390,
          },
          {
            date: "2022-08-26",
            value: 0,
            views: 31603390,
          },
          {
            date: "2022-08-27",
            value: 0,
            views: 31603390,
          },
          {
            date: "2022-08-28",
            value: 0,
            views: 31603390,
          },
          {
            date: "2022-08-29",
            value: 0,
            views: 31603390,
          },
          {
            date: "2022-08-30",
            value: 0,
            views: 31603390,
          },
          {
            date: "2022-08-31",
            value: 0,
            views: 31603392,
          },
          {
            date: "2022-09-01",
            value: 0,
            views: 31603392,
          },
          {
            date: "2022-09-02",
            value: 0,
            views: 31603394,
          },
          {
            date: "2022-09-03",
            value: 0,
            views: 31603394,
          },
          {
            date: "2022-09-04",
            value: 0,
            views: 31603394,
          },
          {
            date: "2022-09-05",
            value: 0,
            views: 31603397,
          },
          {
            date: "2022-09-06",
            value: 0,
            views: 31603398,
          },
          {
            date: "2022-09-07",
            value: 0,
            views: 31603398,
          },
          {
            date: "2022-09-08",
            value: 0,
            views: 31603399,
          },
          {
            date: "2022-09-09",
            value: 0,
            views: 31603399,
          },
          {
            date: "2022-09-10",
            value: 0,
            views: 31603399,
          },
          {
            date: "2022-09-11",
            value: 0,
            views: 31603400,
          },
          {
            date: "2022-09-12",
            value: 0,
            views: 31603404,
          },
          {
            date: "2022-09-13",
            value: 0,
            views: 31603404,
          },
          {
            date: "2022-09-14",
            value: 0,
            views: 31603406,
          },
          {
            date: "2022-09-15",
            value: 0,
            views: 31603408,
          },
          {
            date: "2022-09-16",
            value: 0,
            views: 31603408,
          },
          {
            date: "2022-09-17",
            value: 0,
            views: 31603409,
          },
          {
            date: "2022-09-18",
            value: 0,
            views: 31603410,
          },
          {
            date: "2022-09-19",
            value: 0,
            views: 31603410,
          },
          {
            date: "2022-09-20",
            value: 0,
            views: 31603413,
          },
          {
            date: "2022-09-21",
            value: 0,
            views: 31603413,
          },
          {
            date: "2022-09-22",
            value: 0,
            views: 31603413,
          },
          {
            date: "2022-09-23",
            value: 0,
            views: 31603413,
          },
          {
            date: "2022-09-24",
            value: 0,
            views: 31603413,
          },
          {
            date: "2022-09-25",
            value: 0,
            views: 31603413,
          },
          {
            date: "2022-09-26",
            value: 0,
            views: 31603413,
          },
          {
            date: "2022-09-27",
            value: 0,
            views: 31603413,
          },
          {
            date: "2022-09-28",
            value: 0,
            views: 31603413,
          },
          {
            date: "2022-09-29",
            value: 0,
            views: 31603413,
          },
          {
            date: "2022-09-30",
            value: 0,
            views: 31603413,
          },
          {
            date: "2022-10-01",
            value: 0,
            views: 31603413,
          },
          {
            date: "2022-10-02",
            value: 0,
            views: 31603413,
          },
          {
            date: "2022-10-03",
            value: 0,
            views: 31603413,
          },
          {
            date: "2022-10-04",
            value: 0,
            views: 31603413,
          },
          {
            date: "2022-10-05",
            value: 0,
            views: 31603413,
          },
          {
            date: "2022-10-06",
            value: 0,
            views: 31603413,
          },
          {
            date: "2022-10-07",
            value: 0,
            views: 31603413,
          },
          {
            date: "2022-10-08",
            value: 0,
            views: 31603413,
          },
          {
            date: "2022-10-09",
            value: 0,
            views: 31603413,
          },
          {
            date: "2022-10-10",
            value: 0,
            views: 31603414,
          },
          {
            date: "2022-10-11",
            value: 0,
            views: 31603417,
          },
          {
            date: "2022-10-12",
            value: 0,
            views: 31603417,
          },
          {
            date: "2022-10-13",
            value: 0,
            views: 31603417,
          },
          {
            date: "2022-10-14",
            value: 0,
            views: 31603417,
          },
          {
            date: "2022-10-15",
            value: 0,
            views: 31603417,
          },
          {
            date: "2022-10-16",
            value: 0,
            views: 31603417,
          },
          {
            date: "2022-10-17",
            value: 0,
            views: 31603417,
          },
          {
            date: "2022-10-18",
            value: 0,
            views: 31603417,
          },
          {
            date: "2022-10-19",
            value: 0,
            views: 31603417,
          },
          {
            date: "2022-10-20",
            value: 0,
            views: 31603417,
          },
          {
            date: "2022-10-21",
            value: 0,
            views: 31603417,
          },
          {
            date: "2022-10-22",
            value: 0,
            views: 31603417,
          },
          {
            date: "2022-10-23",
            value: 0,
            views: 31603417,
          },
          {
            date: "2022-10-24",
            value: 0,
            views: 31603419,
          },
          {
            date: "2022-10-25",
            value: 0,
            views: 31603421,
          },
          {
            date: "2022-10-26",
            value: 0,
            views: 31603422,
          },
          {
            date: "2022-10-27",
            value: 0,
            views: 31603422,
          },
          {
            date: "2022-10-28",
            value: 0,
            views: 31603422,
          },
          {
            date: "2022-10-29",
            value: 0,
            views: 31603422,
          },
          {
            date: "2022-10-30",
            value: 0,
            views: 31603422,
          },
          {
            date: "2022-10-31",
            value: 0,
            views: 31603422,
          },
          {
            date: "2022-11-01",
            value: 0,
            views: 31603424,
          },
          {
            date: "2022-11-02",
            value: 0,
            views: 31603425,
          },
          {
            date: "2022-11-03",
            value: 0,
            views: 31603425,
          },
          {
            date: "2022-11-04",
            value: 0,
            views: 31603425,
          },
          {
            date: "2022-11-05",
            value: 0,
            views: 31603426,
          },
          {
            date: "2022-11-06",
            value: 0,
            views: 31603426,
          },
          {
            date: "2022-11-07",
            value: 0,
            views: 31603426,
          },
          {
            date: "2022-11-08",
            value: 0,
            views: 31603426,
          },
          {
            date: "2022-11-09",
            value: 0,
            views: 31603427,
          },
          {
            date: "2022-11-15",
            value: 0,
            views: 31603428,
          },
          {
            date: "2022-11-16",
            value: 0,
            views: 31603429,
          },
          {
            date: "2022-11-19",
            value: 0,
            views: 31603432,
          },
          {
            date: "2022-11-20",
            value: 0,
            views: 31603433,
          },
          {
            date: "2022-11-21",
            value: 0,
            views: 31603434,
          },
          {
            date: "2022-11-22",
            value: 0,
            views: 31603437,
          },
          {
            date: "2022-11-23",
            value: 0,
            views: 31603439,
          },
          {
            date: "2022-11-25",
            value: 0,
            views: 31603443,
          },
          {
            date: "2022-11-26",
            value: 0,
            views: 31603443,
          },
          {
            date: "2022-11-27",
            value: 0,
            views: 31603444,
          },
          {
            date: "2022-11-28",
            value: 0,
            views: 31603445,
          },
          {
            date: "2022-11-29",
            value: 0,
            views: 31603449,
          },
          {
            date: "2022-11-30",
            value: 0,
            views: 31603449,
          },
          {
            date: "2022-12-01",
            value: 0,
            views: 31603449,
          },
          {
            date: "2022-12-02",
            value: 0,
            views: 31603451,
          },
          {
            date: "2022-12-03",
            value: 0,
            views: 31603451,
          },
          {
            date: "2022-12-04",
            value: 0,
            views: 31603451,
          },
          {
            date: "2022-12-05",
            value: 0,
            views: 31603451,
          },
          {
            date: "2022-12-06",
            value: 0,
            views: 31603453,
          },
          {
            date: "2022-12-08",
            value: 0,
            views: 31603458,
          },
          {
            date: "2022-12-09",
            value: 0,
            views: 31603458,
          },
          {
            date: "2022-12-10",
            value: 0,
            views: 31603458,
          },
          {
            date: "2022-12-11",
            value: 0,
            views: 31603458,
          },
          {
            date: "2022-12-12",
            value: 0,
            views: 31603458,
          },
          {
            date: "2022-12-13",
            value: 0,
            views: 31603459,
          },
          {
            date: "2022-12-14",
            value: 0,
            views: 31603459,
          },
          {
            date: "2022-12-15",
            value: 0,
            views: 31603461,
          },
          {
            date: "2022-12-16",
            value: 0,
            views: 31603461,
          },
          {
            date: "2022-12-17",
            value: 0,
            views: 31603461,
          },
          {
            date: "2022-12-18",
            value: 0,
            views: 31603461,
          },
          {
            date: "2022-12-19",
            value: 0,
            views: 31603463,
          },
          {
            date: "2022-12-20",
            value: 0,
            views: 31603463,
          },
          {
            date: "2022-12-21",
            value: 0,
            views: 31603464,
          },
          {
            date: "2022-12-22",
            value: 0,
            views: 31603465,
          },
          {
            date: "2022-12-23",
            value: 0,
            views: 31603465,
          },
          {
            date: "2022-12-25",
            value: 0,
            views: 31603465,
          },
          {
            date: "2022-12-26",
            value: 0,
            views: 31603465,
          },
          {
            date: "2022-12-27",
            value: 0,
            views: 31603465,
          },
          {
            date: "2022-12-28",
            value: 0,
            views: 31603465,
          },
          {
            date: "2022-12-29",
            value: 0,
            views: 31603465,
          },
          {
            date: "2022-12-30",
            value: 0,
            views: 31603465,
          },
          {
            date: "2022-12-31",
            value: 0,
            views: 31603465,
          },
          {
            date: "2023-01-01",
            value: 0,
            views: 31603465,
          },
          {
            date: "2023-01-02",
            value: 0,
            views: 31603465,
          },
          {
            date: "2023-01-03",
            value: 0,
            views: 31603466,
          },
          {
            date: "2023-01-04",
            value: 0,
            views: 31603467,
          },
          {
            date: "2023-01-05",
            value: 0,
            views: 31603467,
          },
          {
            date: "2023-01-06",
            value: 0,
            views: 31603467,
          },
          {
            date: "2023-01-07",
            value: 0,
            views: 31603467,
          },
          {
            date: "2023-01-08",
            value: 0,
            views: 31603468,
          },
          {
            date: "2023-01-09",
            value: 0,
            views: 31603468,
          },
          {
            date: "2023-01-10",
            value: 0,
            views: 31603468,
          },
          {
            date: "2023-01-11",
            value: 0,
            views: 31603468,
          },
          {
            date: "2023-01-12",
            value: 0,
            views: 31603468,
          },
          {
            date: "2023-01-13",
            value: 0,
            views: 31603469,
          },
          {
            date: "2023-01-14",
            value: 0,
            views: 31603469,
          },
          {
            date: "2023-01-15",
            value: 0,
            views: 31603470,
          },
          {
            date: "2023-01-16",
            value: 0,
            views: 31603473,
          },
          {
            date: "2023-01-17",
            value: 0,
            views: 31603473,
          },
          {
            date: "2023-01-18",
            value: 0,
            views: 31603473,
          },
          {
            date: "2023-01-19",
            value: 0,
            views: 31603473,
          },
          {
            date: "2023-01-20",
            value: 0,
            views: 31603474,
          },
          {
            date: "2023-01-21",
            value: 0,
            views: 31603474,
          },
          {
            date: "2023-01-22",
            value: 0,
            views: 31603474,
          },
          {
            date: "2023-01-23",
            value: 0,
            views: 31603475,
          },
          {
            date: "2023-01-24",
            value: 0,
            views: 31603475,
          },
          {
            date: "2023-01-25",
            value: 0,
            views: 31603477,
          },
          {
            date: "2023-01-26",
            value: 0,
            views: 31603477,
          },
          {
            date: "2023-01-27",
            value: 0,
            views: 31603477,
          },
          {
            date: "2023-01-29",
            value: 0,
            views: 31603478,
          },
          {
            date: "2023-01-30",
            value: 0,
            views: 31603478,
          },
          {
            date: "2023-01-31",
            value: 0,
            views: 31603478,
          },
          {
            date: "2023-02-01",
            value: 0,
            views: 31603478,
          },
          {
            date: "2023-02-02",
            value: 0,
            views: 31603478,
          },
          {
            date: "2023-02-03",
            value: 0,
            views: 31603478,
          },
          {
            date: "2023-02-04",
            value: 0,
            views: 31603478,
          },
          {
            date: "2023-02-05",
            value: 0,
            views: 31603478,
          },
          {
            date: "2023-02-06",
            value: 0,
            views: 31603479,
          },
          {
            date: "2023-02-08",
            value: 0,
            views: 31603479,
          },
          {
            date: "2023-02-09",
            value: 0,
            views: 31603479,
          },
          {
            date: "2023-02-10",
            value: 0,
            views: 31603479,
          },
          {
            date: "2023-02-12",
            value: 0,
            views: 31603479,
          },
          {
            date: "2023-02-14",
            value: 0,
            views: 31603479,
          },
          {
            date: "2023-02-16",
            value: 0,
            views: 31603479,
          },
          {
            date: "2023-02-18",
            value: 0,
            views: 31603479,
          },
          {
            date: "2023-02-20",
            value: 0,
            views: 31603479,
          },
          {
            date: "2023-02-22",
            value: 0,
            views: 31603479,
          },
          {
            date: "2023-02-24",
            value: 0,
            views: 31603480,
          },
          {
            date: "2023-02-26",
            value: 0,
            views: 31603480,
          },
          {
            date: "2023-02-28",
            value: 0,
            views: 31603480,
          },
          {
            date: "2023-03-02",
            value: 0,
            views: 31603480,
          },
          {
            date: "2023-03-07",
            value: 0,
            views: 31603482,
          },
          {
            date: "2023-03-09",
            value: 0,
            views: 31603482,
          },
          {
            date: "2023-03-15",
            value: 0,
            views: 31603484,
          },
          {
            date: "2023-03-17",
            value: 0,
            views: 31603485,
          },
          {
            date: "2023-03-19",
            value: 0,
            views: 31603485,
          },
          {
            date: "2023-03-21",
            value: 0,
            views: 31603485,
          },
          {
            date: "2023-03-23",
            value: 0,
            views: 31603486,
          },
          {
            date: "2023-03-25",
            value: 0,
            views: 31603486,
          },
          {
            date: "2023-03-28",
            value: 0,
            views: 31603488,
          },
          {
            date: "2023-03-30",
            value: 0,
            views: 31603488,
          },
          {
            date: "2023-03-31",
            value: 0,
            views: 31603488,
          },
          {
            date: "2023-04-01",
            value: 0,
            views: 31603488,
          },
          {
            date: "2023-04-03",
            value: 0,
            views: 31603488,
          },
          {
            date: "2023-04-05",
            value: 0,
            views: 31603488,
          },
          {
            date: "2023-04-07",
            value: 0,
            views: 31603488,
          },
          {
            date: "2023-04-09",
            value: 0,
            views: 31603488,
          },
          {
            date: "2023-04-11",
            value: 0,
            views: 31603489,
          },
          {
            date: "2023-04-13",
            value: 0,
            views: 31603489,
          },
          {
            date: "2023-04-15",
            value: 0,
            views: 31603489,
          },
          {
            date: "2023-04-17",
            value: 0,
            views: 31603489,
          },
          {
            date: "2023-04-19",
            value: 0,
            views: 31603489,
          },
          {
            date: "2023-04-21",
            value: 0,
            views: 31603491,
          },
          {
            date: "2023-04-23",
            value: 0,
            views: 31603492,
          },
          {
            date: "2023-05-06",
            value: 0,
            views: 31603498,
          },
          {
            date: "2023-05-12",
            value: 0,
            views: 31603500,
          },
          {
            date: "2023-05-19",
            value: 0,
            views: 31603504,
          },
          {
            date: "2023-05-30",
            value: 0,
            views: 31603523,
          },
          {
            date: "2023-06-04",
            value: 0,
            views: 31603603,
          },
          {
            date: "2023-06-05",
            value: 0,
            views: 31603607,
          },
          {
            date: "2023-06-12",
            value: 0,
            views: 31603642,
          },
          {
            date: "2023-07-29",
            value: 0,
            views: 31603693,
          },
          {
            date: "2023-08-01",
            value: 0,
            views: 31603693,
          },
          {
            date: "2023-08-05",
            value: 0,
            views: 31603695,
          },
          {
            date: "2023-08-07",
            value: 0,
            views: 31603696,
          },
          {
            date: "2023-08-09",
            value: 0,
            views: 31603706,
          },
          {
            date: "2023-08-11",
            value: 0,
            views: 31603708,
          },
          {
            date: "2023-08-16",
            value: 0,
            views: 31603714,
          },
          {
            date: "2023-08-18",
            value: 0,
            views: 31603716,
          },
          {
            date: "2023-08-22",
            value: 0,
            views: 31603717,
          },
          {
            date: "2023-08-23",
            value: 0,
            views: 31603719,
          },
          {
            date: "2023-08-25",
            value: 0,
            views: 31603717,
          },
          {
            date: "2023-08-28",
            value: 0,
            views: 31603717,
          },
          {
            date: "2023-08-30",
            value: 0,
            views: 31603717,
          },
          {
            date: "2023-09-04",
            value: 0,
            views: 31603722,
          },
          {
            date: "2023-09-06",
            value: 0,
            views: 31603725,
          },
          {
            date: "2023-09-13",
            value: 0,
            views: 31603727,
          },
          {
            date: "2023-09-15",
            value: 0,
            views: 31603730,
          },
          {
            date: "2023-09-18",
            value: 0,
            views: 31603732,
          },
          {
            date: "2023-09-20",
            value: 0,
            views: 31603732,
          },
          {
            date: "2023-09-25",
            value: 0,
            views: 31603735,
          },
          {
            date: "2023-09-27",
            value: 0,
            views: 31603735,
          },
          {
            date: "2023-09-29",
            value: 0,
            views: 31603735,
          },
          {
            date: "2023-10-02",
            value: 0,
            views: 31603739,
          },
          {
            date: "2023-10-04",
            value: 0,
            views: 31603735,
          },
          {
            date: "2023-10-06",
            value: 0,
            views: 31603737,
          },
          {
            date: "2023-10-11",
            value: 0,
            views: 31603739,
          },
          {
            date: "2023-10-13",
            value: 0,
            views: 31603741,
          },
          {
            date: "2023-10-16",
            value: 0,
            views: 31603742,
          },
          {
            date: "2023-10-18",
            value: 0,
            views: 31603743,
          },
          {
            date: "2023-10-20",
            value: 0,
            views: 31603744,
          },
          {
            date: "2023-10-25",
            value: 0,
            views: 31603748,
          },
          {
            date: "2023-10-26",
            value: 0,
            views: 31603748,
          },
          {
            date: "2023-10-27",
            value: 0,
            views: 31603748,
          },
          {
            date: "2023-10-30",
            value: 0,
            views: 31603749,
          },
          {
            date: "2023-11-01",
            value: 0,
            views: 31603751,
          },
          {
            date: "2023-11-03",
            value: 0,
            views: 31603751,
          },
          {
            date: "2023-11-08",
            value: 0,
            views: 31603755,
          },
        ],
      },
      {
        videoId: "nc5wmgZ6UvM",
        dataPoints: [
          {
            date: "2020-12-14",
            value: 0,
            views: 0,
          },
          {
            date: "2021-10-18",
            value: 0,
            views: 23906514,
          },
          {
            date: "2021-10-19",
            value: 0,
            views: 24019445,
          },
          {
            date: "2021-10-20",
            value: 0,
            views: 24102977,
          },
          {
            date: "2021-10-21",
            value: 0,
            views: 24187899,
          },
          {
            date: "2021-10-22",
            value: 0,
            views: 24254139,
          },
          {
            date: "2021-10-23",
            value: 0,
            views: 24338227,
          },
          {
            date: "2021-10-24",
            value: 0,
            views: 24470108,
          },
          {
            date: "2021-10-25",
            value: 0,
            views: 24587504,
          },
          {
            date: "2021-10-26",
            value: 0,
            views: 24668453,
          },
          {
            date: "2021-10-27",
            value: 0,
            views: 24743854,
          },
          {
            date: "2021-10-28",
            value: 0,
            views: 24816851,
          },
          {
            date: "2021-10-29",
            value: 0,
            views: 24889134,
          },
          {
            date: "2021-10-30",
            value: 0,
            views: 24967757,
          },
          {
            date: "2021-10-31",
            value: 0,
            views: 25089423,
          },
          {
            date: "2021-11-01",
            value: 0,
            views: 25181883,
          },
          {
            date: "2021-11-02",
            value: 0,
            views: 25239753,
          },
          {
            date: "2021-11-03",
            value: 0,
            views: 25312484,
          },
          {
            date: "2021-11-04",
            value: 0,
            views: 25374983,
          },
          {
            date: "2021-11-04",
            value: 0,
            views: 25403488,
          },
          {
            date: "2021-11-04",
            value: 0,
            views: 25405945,
          },
          {
            date: "2021-11-04",
            value: 0,
            views: 25409662,
          },
          {
            date: "2021-11-05",
            value: 0,
            views: 25431933,
          },
          {
            date: "2021-11-06",
            value: 0,
            views: 25499205,
          },
          {
            date: "2021-11-07",
            value: 0,
            views: 25608914,
          },
          {
            date: "2021-11-08",
            value: 0,
            views: 25705337,
          },
          {
            date: "2021-11-09",
            value: 0,
            views: 25773671,
          },
          {
            date: "2021-11-10",
            value: 0,
            views: 25826505,
          },
          {
            date: "2021-11-11",
            value: 0,
            views: 25886371,
          },
          {
            date: "2021-11-12",
            value: 0,
            views: 25923795,
          },
          {
            date: "2021-11-13",
            value: 0,
            views: 25970815,
          },
          {
            date: "2021-11-14",
            value: 0,
            views: 26035760,
          },
          {
            date: "2021-11-15",
            value: 0,
            views: 26091582,
          },
          {
            date: "2021-11-16",
            value: 0,
            views: 26131075,
          },
          {
            date: "2021-11-17",
            value: 0,
            views: 26162048,
          },
          {
            date: "2021-11-18",
            value: 0,
            views: 26189121,
          },
          {
            date: "2021-11-19",
            value: 0,
            views: 26213865,
          },
          {
            date: "2021-11-20",
            value: 0,
            views: 26236837,
          },
          {
            date: "2021-11-21",
            value: 0,
            views: 26255866,
          },
          {
            date: "2021-11-22",
            value: 0,
            views: 26272931,
          },
          {
            date: "2021-11-23",
            value: 0,
            views: 26283411,
          },
          {
            date: "2021-11-24",
            value: 0,
            views: 26290182,
          },
          {
            date: "2021-11-25",
            value: 0,
            views: 26298862,
          },
          {
            date: "2021-11-26",
            value: 0,
            views: 26308016,
          },
          {
            date: "2021-11-27",
            value: 0,
            views: 26315497,
          },
          {
            date: "2021-11-28",
            value: 0,
            views: 26328754,
          },
          {
            date: "2021-11-29",
            value: 0,
            views: 26337620,
          },
          {
            date: "2021-12-02",
            value: 0,
            views: 26355797,
          },
          {
            date: "2021-12-03",
            value: 0,
            views: 26364235,
          },
          {
            date: "2021-12-05",
            value: 0,
            views: 26367038,
          },
          {
            date: "2021-12-06",
            value: 0,
            views: 26367707,
          },
          {
            date: "2021-12-09",
            value: 0,
            views: 26373496,
          },
          {
            date: "2021-12-10",
            value: 0,
            views: 26374646,
          },
          {
            date: "2021-12-12",
            value: 0,
            views: 26376592,
          },
          {
            date: "2021-12-15",
            value: 0,
            views: 26384352,
          },
          {
            date: "2021-12-17",
            value: 0,
            views: 26388817,
          },
          {
            date: "2021-12-18",
            value: 0,
            views: 26389783,
          },
          {
            date: "2021-12-19",
            value: 0,
            views: 26391133,
          },
          {
            date: "2021-12-21",
            value: 0,
            views: 26394141,
          },
          {
            date: "2021-12-22",
            value: 0,
            views: 26395963,
          },
          {
            date: "2021-12-23",
            value: 0,
            views: 26397280,
          },
          {
            date: "2021-12-24",
            value: 0,
            views: 26398345,
          },
          {
            date: "2021-12-25",
            value: 0,
            views: 26399107,
          },
          {
            date: "2021-12-26",
            value: 0,
            views: 26400325,
          },
          {
            date: "2021-12-27",
            value: 0,
            views: 26401591,
          },
          {
            date: "2021-12-29",
            value: 0,
            views: 26405489,
          },
          {
            date: "2021-12-30",
            value: 0,
            views: 26406670,
          },
          {
            date: "2021-12-31",
            value: 0,
            views: 26407795,
          },
          {
            date: "2022-01-01",
            value: 0,
            views: 26409319,
          },
          {
            date: "2022-01-03",
            value: 0,
            views: 26417059,
          },
          {
            date: "2022-01-04",
            value: 0,
            views: 26419342,
          },
          {
            date: "2022-01-05",
            value: 0,
            views: 26421023,
          },
          {
            date: "2022-01-06",
            value: 0,
            views: 26422462,
          },
          {
            date: "2022-04-29",
            value: 0,
            views: 26470758,
          },
          {
            date: "2022-05-02",
            value: 0,
            views: 26470759,
          },
          {
            date: "2022-05-06",
            value: 0,
            views: 26470766,
          },
          {
            date: "2022-05-11",
            value: 0,
            views: 26470772,
          },
          {
            date: "2022-05-13",
            value: 0,
            views: 26470773,
          },
          {
            date: "2022-05-17",
            value: 0,
            views: 26470773,
          },
          {
            date: "2022-05-19",
            value: 0,
            views: 26470774,
          },
          {
            date: "2022-05-20",
            value: 0,
            views: 26470774,
          },
          {
            date: "2022-05-21",
            value: 0,
            views: 26470774,
          },
          {
            date: "2022-05-22",
            value: 0,
            views: 26470774,
          },
          {
            date: "2022-05-23",
            value: 0,
            views: 26470775,
          },
          {
            date: "2022-05-24",
            value: 0,
            views: 26470775,
          },
          {
            date: "2022-05-25",
            value: 0,
            views: 26470775,
          },
          {
            date: "2022-05-26",
            value: 0,
            views: 26470778,
          },
          {
            date: "2022-05-27",
            value: 0,
            views: 26470778,
          },
          {
            date: "2022-05-28",
            value: 0,
            views: 26470779,
          },
          {
            date: "2022-05-29",
            value: 0,
            views: 26470779,
          },
          {
            date: "2022-05-30",
            value: 0,
            views: 26470779,
          },
          {
            date: "2022-05-31",
            value: 0,
            views: 26470779,
          },
          {
            date: "2022-06-01",
            value: 0,
            views: 26470779,
          },
          {
            date: "2022-06-02",
            value: 0,
            views: 26470779,
          },
          {
            date: "2022-06-03",
            value: 0,
            views: 26470779,
          },
          {
            date: "2022-06-04",
            value: 0,
            views: 26470779,
          },
          {
            date: "2022-06-05",
            value: 0,
            views: 26470779,
          },
          {
            date: "2022-06-06",
            value: 0,
            views: 26470780,
          },
          {
            date: "2022-06-07",
            value: 0,
            views: 26470780,
          },
          {
            date: "2022-06-08",
            value: 0,
            views: 26470780,
          },
          {
            date: "2022-06-09",
            value: 0,
            views: 26470780,
          },
          {
            date: "2022-06-10",
            value: 0,
            views: 26470781,
          },
          {
            date: "2022-06-11",
            value: 0,
            views: 26470781,
          },
          {
            date: "2022-06-12",
            value: 0,
            views: 26470782,
          },
          {
            date: "2022-06-13",
            value: 0,
            views: 26470782,
          },
          {
            date: "2022-06-14",
            value: 0,
            views: 26470785,
          },
          {
            date: "2022-06-15",
            value: 0,
            views: 26470786,
          },
          {
            date: "2022-06-16",
            value: 0,
            views: 26470787,
          },
          {
            date: "2022-06-17",
            value: 0,
            views: 26470787,
          },
          {
            date: "2022-06-18",
            value: 0,
            views: 26470787,
          },
          {
            date: "2022-06-19",
            value: 0,
            views: 26470787,
          },
          {
            date: "2022-06-20",
            value: 0,
            views: 26470788,
          },
          {
            date: "2022-06-21",
            value: 0,
            views: 26470788,
          },
          {
            date: "2022-06-22",
            value: 0,
            views: 26470790,
          },
          {
            date: "2022-06-23",
            value: 0,
            views: 26470795,
          },
          {
            date: "2022-06-24",
            value: 0,
            views: 26470795,
          },
          {
            date: "2022-06-25",
            value: 0,
            views: 26470796,
          },
          {
            date: "2022-06-26",
            value: 0,
            views: 26470798,
          },
          {
            date: "2022-06-27",
            value: 0,
            views: 26470798,
          },
          {
            date: "2022-06-28",
            value: 0,
            views: 26470798,
          },
          {
            date: "2022-06-29",
            value: 0,
            views: 26470799,
          },
          {
            date: "2022-06-30",
            value: 0,
            views: 26470799,
          },
          {
            date: "2022-07-01",
            value: 0,
            views: 26470799,
          },
          {
            date: "2022-07-02",
            value: 0,
            views: 26470799,
          },
          {
            date: "2022-07-03",
            value: 0,
            views: 26470799,
          },
          {
            date: "2022-07-04",
            value: 0,
            views: 26470801,
          },
          {
            date: "2022-07-05",
            value: 0,
            views: 26470802,
          },
          {
            date: "2022-07-06",
            value: 0,
            views: 26470802,
          },
          {
            date: "2022-07-07",
            value: 0,
            views: 26470802,
          },
          {
            date: "2022-07-08",
            value: 0,
            views: 26470804,
          },
          {
            date: "2022-07-09",
            value: 0,
            views: 26470805,
          },
          {
            date: "2022-07-10",
            value: 0,
            views: 26470805,
          },
          {
            date: "2022-07-11",
            value: 0,
            views: 26470806,
          },
          {
            date: "2022-07-12",
            value: 0,
            views: 26470806,
          },
          {
            date: "2022-07-13",
            value: 0,
            views: 26470810,
          },
          {
            date: "2022-07-14",
            value: 0,
            views: 26470812,
          },
          {
            date: "2022-07-15",
            value: 0,
            views: 26470812,
          },
          {
            date: "2022-07-16",
            value: 0,
            views: 26470812,
          },
          {
            date: "2022-07-17",
            value: 0,
            views: 26470813,
          },
          {
            date: "2022-07-18",
            value: 0,
            views: 26470814,
          },
          {
            date: "2022-07-19",
            value: 0,
            views: 26470816,
          },
          {
            date: "2022-07-20",
            value: 0,
            views: 26470816,
          },
          {
            date: "2022-07-21",
            value: 0,
            views: 26470822,
          },
          {
            date: "2022-07-22",
            value: 0,
            views: 26470825,
          },
          {
            date: "2022-07-23",
            value: 0,
            views: 26470825,
          },
          {
            date: "2022-07-24",
            value: 0,
            views: 26470825,
          },
          {
            date: "2022-07-25",
            value: 0,
            views: 26470826,
          },
          {
            date: "2022-07-26",
            value: 0,
            views: 26470826,
          },
          {
            date: "2022-07-27",
            value: 0,
            views: 26470826,
          },
          {
            date: "2022-07-28",
            value: 0,
            views: 26470827,
          },
          {
            date: "2022-07-29",
            value: 0,
            views: 26470829,
          },
          {
            date: "2022-07-30",
            value: 0,
            views: 26470829,
          },
          {
            date: "2022-07-31",
            value: 0,
            views: 26470829,
          },
          {
            date: "2022-08-01",
            value: 0,
            views: 26470831,
          },
          {
            date: "2022-08-02",
            value: 0,
            views: 26470832,
          },
          {
            date: "2022-08-03",
            value: 0,
            views: 26470832,
          },
          {
            date: "2022-08-04",
            value: 0,
            views: 26470832,
          },
          {
            date: "2022-08-05",
            value: 0,
            views: 26470836,
          },
          {
            date: "2022-08-06",
            value: 0,
            views: 26470837,
          },
          {
            date: "2022-08-07",
            value: 0,
            views: 26470837,
          },
          {
            date: "2022-08-08",
            value: 0,
            views: 26470837,
          },
          {
            date: "2022-08-09",
            value: 0,
            views: 26470837,
          },
          {
            date: "2022-08-10",
            value: 0,
            views: 26470839,
          },
          {
            date: "2022-08-11",
            value: 0,
            views: 26470840,
          },
          {
            date: "2022-08-12",
            value: 0,
            views: 26470843,
          },
          {
            date: "2022-08-13",
            value: 0,
            views: 26470843,
          },
          {
            date: "2022-08-14",
            value: 0,
            views: 26470843,
          },
          {
            date: "2022-08-15",
            value: 0,
            views: 26470843,
          },
          {
            date: "2022-08-16",
            value: 0,
            views: 26470843,
          },
          {
            date: "2022-08-17",
            value: 0,
            views: 26470843,
          },
          {
            date: "2022-08-18",
            value: 0,
            views: 26470844,
          },
          {
            date: "2022-08-19",
            value: 0,
            views: 26470844,
          },
          {
            date: "2022-08-20",
            value: 0,
            views: 26470844,
          },
          {
            date: "2022-08-21",
            value: 0,
            views: 26470846,
          },
          {
            date: "2022-08-22",
            value: 0,
            views: 26470847,
          },
          {
            date: "2022-08-23",
            value: 0,
            views: 26470849,
          },
          {
            date: "2022-08-24",
            value: 0,
            views: 26470850,
          },
          {
            date: "2022-08-25",
            value: 0,
            views: 26470850,
          },
          {
            date: "2022-08-26",
            value: 0,
            views: 26470850,
          },
          {
            date: "2022-08-27",
            value: 0,
            views: 26470850,
          },
          {
            date: "2022-08-28",
            value: 0,
            views: 26470850,
          },
          {
            date: "2022-08-29",
            value: 0,
            views: 26470850,
          },
          {
            date: "2022-08-30",
            value: 0,
            views: 26470850,
          },
          {
            date: "2022-08-31",
            value: 0,
            views: 26470850,
          },
          {
            date: "2022-09-01",
            value: 0,
            views: 26470850,
          },
          {
            date: "2022-09-02",
            value: 0,
            views: 26470851,
          },
          {
            date: "2022-09-03",
            value: 0,
            views: 26470851,
          },
          {
            date: "2022-09-04",
            value: 0,
            views: 26470851,
          },
          {
            date: "2022-09-05",
            value: 0,
            views: 26470851,
          },
          {
            date: "2022-09-06",
            value: 0,
            views: 26470862,
          },
          {
            date: "2022-09-07",
            value: 0,
            views: 26470862,
          },
          {
            date: "2022-09-08",
            value: 0,
            views: 26470862,
          },
          {
            date: "2022-09-09",
            value: 0,
            views: 26470863,
          },
          {
            date: "2022-09-10",
            value: 0,
            views: 26470863,
          },
          {
            date: "2022-09-11",
            value: 0,
            views: 26470864,
          },
          {
            date: "2022-09-12",
            value: 0,
            views: 26470866,
          },
          {
            date: "2022-09-13",
            value: 0,
            views: 26470866,
          },
          {
            date: "2022-09-14",
            value: 0,
            views: 26470866,
          },
          {
            date: "2022-09-15",
            value: 0,
            views: 26470868,
          },
          {
            date: "2022-09-16",
            value: 0,
            views: 26470868,
          },
          {
            date: "2022-09-17",
            value: 0,
            views: 26470869,
          },
          {
            date: "2022-09-18",
            value: 0,
            views: 26470869,
          },
          {
            date: "2022-09-19",
            value: 0,
            views: 26470869,
          },
          {
            date: "2022-09-20",
            value: 0,
            views: 26470869,
          },
          {
            date: "2022-09-21",
            value: 0,
            views: 26470870,
          },
          {
            date: "2022-09-22",
            value: 0,
            views: 26470873,
          },
          {
            date: "2022-09-23",
            value: 0,
            views: 26470873,
          },
          {
            date: "2022-09-24",
            value: 0,
            views: 26470873,
          },
          {
            date: "2022-09-25",
            value: 0,
            views: 26470874,
          },
          {
            date: "2022-09-26",
            value: 0,
            views: 26470874,
          },
          {
            date: "2022-09-27",
            value: 0,
            views: 26470875,
          },
          {
            date: "2022-09-28",
            value: 0,
            views: 26470875,
          },
          {
            date: "2022-09-29",
            value: 0,
            views: 26470876,
          },
          {
            date: "2022-09-30",
            value: 0,
            views: 26470877,
          },
          {
            date: "2022-10-01",
            value: 0,
            views: 26470881,
          },
          {
            date: "2022-10-02",
            value: 0,
            views: 26470881,
          },
          {
            date: "2022-10-03",
            value: 0,
            views: 26470881,
          },
          {
            date: "2022-10-04",
            value: 0,
            views: 26470884,
          },
          {
            date: "2022-10-05",
            value: 0,
            views: 26470884,
          },
          {
            date: "2022-10-06",
            value: 0,
            views: 26470884,
          },
          {
            date: "2022-10-07",
            value: 0,
            views: 26470884,
          },
          {
            date: "2022-10-08",
            value: 0,
            views: 26470884,
          },
          {
            date: "2022-10-09",
            value: 0,
            views: 26470884,
          },
          {
            date: "2022-10-10",
            value: 0,
            views: 26470885,
          },
          {
            date: "2022-10-11",
            value: 0,
            views: 26470888,
          },
          {
            date: "2022-10-12",
            value: 0,
            views: 26470891,
          },
          {
            date: "2022-10-13",
            value: 0,
            views: 26470891,
          },
          {
            date: "2022-10-14",
            value: 0,
            views: 26470891,
          },
          {
            date: "2022-10-15",
            value: 0,
            views: 26470891,
          },
          {
            date: "2022-10-16",
            value: 0,
            views: 26470891,
          },
          {
            date: "2022-10-17",
            value: 0,
            views: 26470891,
          },
          {
            date: "2022-10-18",
            value: 0,
            views: 26470891,
          },
          {
            date: "2022-10-19",
            value: 0,
            views: 26470891,
          },
          {
            date: "2022-10-20",
            value: 0,
            views: 26470891,
          },
          {
            date: "2022-10-21",
            value: 0,
            views: 26470892,
          },
          {
            date: "2022-10-22",
            value: 0,
            views: 26470892,
          },
          {
            date: "2022-10-23",
            value: 0,
            views: 26470892,
          },
          {
            date: "2022-10-24",
            value: 0,
            views: 26470893,
          },
          {
            date: "2022-10-25",
            value: 0,
            views: 26470893,
          },
          {
            date: "2022-10-26",
            value: 0,
            views: 26470893,
          },
          {
            date: "2022-10-27",
            value: 0,
            views: 26470893,
          },
          {
            date: "2022-10-28",
            value: 0,
            views: 26470893,
          },
          {
            date: "2022-10-29",
            value: 0,
            views: 26470893,
          },
          {
            date: "2022-10-30",
            value: 0,
            views: 26470893,
          },
          {
            date: "2022-10-31",
            value: 0,
            views: 26470893,
          },
          {
            date: "2022-11-01",
            value: 0,
            views: 26470894,
          },
          {
            date: "2022-11-02",
            value: 0,
            views: 26470894,
          },
          {
            date: "2022-11-03",
            value: 0,
            views: 26470894,
          },
          {
            date: "2022-11-04",
            value: 0,
            views: 26470895,
          },
          {
            date: "2022-11-05",
            value: 0,
            views: 26470895,
          },
          {
            date: "2022-11-06",
            value: 0,
            views: 26470895,
          },
          {
            date: "2022-11-07",
            value: 0,
            views: 26470895,
          },
          {
            date: "2022-11-08",
            value: 0,
            views: 26470895,
          },
          {
            date: "2022-11-09",
            value: 0,
            views: 26470895,
          },
          {
            date: "2022-11-15",
            value: 0,
            views: 26470896,
          },
          {
            date: "2022-11-16",
            value: 0,
            views: 26470896,
          },
          {
            date: "2022-11-19",
            value: 0,
            views: 26470897,
          },
          {
            date: "2022-11-20",
            value: 0,
            views: 26470898,
          },
          {
            date: "2022-11-21",
            value: 0,
            views: 26470899,
          },
          {
            date: "2022-11-22",
            value: 0,
            views: 26470899,
          },
          {
            date: "2022-11-23",
            value: 0,
            views: 26470901,
          },
          {
            date: "2022-11-25",
            value: 0,
            views: 26470901,
          },
          {
            date: "2022-11-26",
            value: 0,
            views: 26470901,
          },
          {
            date: "2022-11-27",
            value: 0,
            views: 26470902,
          },
          {
            date: "2022-11-28",
            value: 0,
            views: 26470902,
          },
          {
            date: "2022-11-30",
            value: 0,
            views: 26470903,
          },
          {
            date: "2022-12-01",
            value: 0,
            views: 26470904,
          },
          {
            date: "2022-12-02",
            value: 0,
            views: 26470905,
          },
          {
            date: "2022-12-03",
            value: 0,
            views: 26470906,
          },
          {
            date: "2022-12-04",
            value: 0,
            views: 26470906,
          },
          {
            date: "2022-12-05",
            value: 0,
            views: 26470907,
          },
          {
            date: "2022-12-06",
            value: 0,
            views: 26470907,
          },
          {
            date: "2022-12-08",
            value: 0,
            views: 26470925,
          },
          {
            date: "2022-12-09",
            value: 0,
            views: 26470932,
          },
          {
            date: "2022-12-10",
            value: 0,
            views: 26470955,
          },
          {
            date: "2022-12-11",
            value: 0,
            views: 26470968,
          },
          {
            date: "2022-12-12",
            value: 0,
            views: 26470984,
          },
          {
            date: "2022-12-13",
            value: 0,
            views: 26470996,
          },
          {
            date: "2022-12-14",
            value: 0,
            views: 26470996,
          },
          {
            date: "2022-12-15",
            value: 0,
            views: 26470997,
          },
          {
            date: "2022-12-16",
            value: 0,
            views: 26470997,
          },
          {
            date: "2022-12-17",
            value: 0,
            views: 26470999,
          },
          {
            date: "2022-12-18",
            value: 0,
            views: 26470999,
          },
          {
            date: "2022-12-19",
            value: 0,
            views: 26471000,
          },
          {
            date: "2022-12-20",
            value: 0,
            views: 26471001,
          },
          {
            date: "2022-12-21",
            value: 0,
            views: 26471001,
          },
          {
            date: "2022-12-22",
            value: 0,
            views: 26471001,
          },
          {
            date: "2022-12-23",
            value: 0,
            views: 26471001,
          },
          {
            date: "2022-12-25",
            value: 0,
            views: 26471001,
          },
          {
            date: "2022-12-26",
            value: 0,
            views: 26471001,
          },
          {
            date: "2022-12-27",
            value: 0,
            views: 26471001,
          },
          {
            date: "2022-12-28",
            value: 0,
            views: 26471001,
          },
          {
            date: "2022-12-29",
            value: 0,
            views: 26471001,
          },
          {
            date: "2022-12-30",
            value: 0,
            views: 26471001,
          },
          {
            date: "2022-12-31",
            value: 0,
            views: 26471001,
          },
          {
            date: "2023-01-01",
            value: 0,
            views: 26471001,
          },
          {
            date: "2023-01-02",
            value: 0,
            views: 26471001,
          },
          {
            date: "2023-01-03",
            value: 0,
            views: 26471001,
          },
          {
            date: "2023-01-04",
            value: 0,
            views: 26471002,
          },
          {
            date: "2023-01-05",
            value: 0,
            views: 26471004,
          },
          {
            date: "2023-01-06",
            value: 0,
            views: 26471006,
          },
          {
            date: "2023-01-07",
            value: 0,
            views: 26471006,
          },
          {
            date: "2023-01-08",
            value: 0,
            views: 26471007,
          },
          {
            date: "2023-01-09",
            value: 0,
            views: 26471007,
          },
          {
            date: "2023-01-10",
            value: 0,
            views: 26471008,
          },
          {
            date: "2023-01-11",
            value: 0,
            views: 26471009,
          },
          {
            date: "2023-01-12",
            value: 0,
            views: 26471013,
          },
          {
            date: "2023-01-13",
            value: 0,
            views: 26471014,
          },
          {
            date: "2023-01-14",
            value: 0,
            views: 26471015,
          },
          {
            date: "2023-01-15",
            value: 0,
            views: 26471016,
          },
          {
            date: "2023-01-16",
            value: 0,
            views: 26471020,
          },
          {
            date: "2023-01-17",
            value: 0,
            views: 26471020,
          },
          {
            date: "2023-01-18",
            value: 0,
            views: 26471022,
          },
          {
            date: "2023-01-19",
            value: 0,
            views: 26471022,
          },
          {
            date: "2023-01-20",
            value: 0,
            views: 26471023,
          },
          {
            date: "2023-01-21",
            value: 0,
            views: 26471023,
          },
          {
            date: "2023-01-22",
            value: 0,
            views: 26471024,
          },
          {
            date: "2023-01-23",
            value: 0,
            views: 26471025,
          },
          {
            date: "2023-01-24",
            value: 0,
            views: 26471026,
          },
          {
            date: "2023-01-25",
            value: 0,
            views: 26471029,
          },
          {
            date: "2023-01-26",
            value: 0,
            views: 26471030,
          },
          {
            date: "2023-01-27",
            value: 0,
            views: 26471039,
          },
          {
            date: "2023-01-29",
            value: 0,
            views: 26471040,
          },
          {
            date: "2023-01-30",
            value: 0,
            views: 26471040,
          },
          {
            date: "2023-01-31",
            value: 0,
            views: 26471040,
          },
          {
            date: "2023-02-01",
            value: 0,
            views: 26471041,
          },
          {
            date: "2023-02-02",
            value: 0,
            views: 26471043,
          },
          {
            date: "2023-02-03",
            value: 0,
            views: 26471044,
          },
          {
            date: "2023-02-04",
            value: 0,
            views: 26471044,
          },
          {
            date: "2023-02-05",
            value: 0,
            views: 26471045,
          },
          {
            date: "2023-02-06",
            value: 0,
            views: 26471046,
          },
          {
            date: "2023-02-08",
            value: 0,
            views: 26471047,
          },
          {
            date: "2023-02-09",
            value: 0,
            views: 26471048,
          },
          {
            date: "2023-02-10",
            value: 0,
            views: 26471048,
          },
          {
            date: "2023-02-12",
            value: 0,
            views: 26471049,
          },
          {
            date: "2023-02-14",
            value: 0,
            views: 26471049,
          },
          {
            date: "2023-02-16",
            value: 0,
            views: 26471049,
          },
          {
            date: "2023-02-18",
            value: 0,
            views: 26471049,
          },
          {
            date: "2023-02-20",
            value: 0,
            views: 26471049,
          },
          {
            date: "2023-02-22",
            value: 0,
            views: 26471050,
          },
          {
            date: "2023-02-24",
            value: 0,
            views: 26471050,
          },
          {
            date: "2023-02-26",
            value: 0,
            views: 26471050,
          },
          {
            date: "2023-02-28",
            value: 0,
            views: 26471051,
          },
          {
            date: "2023-03-02",
            value: 0,
            views: 26471052,
          },
          {
            date: "2023-03-07",
            value: 0,
            views: 26471052,
          },
          {
            date: "2023-03-09",
            value: 0,
            views: 26471052,
          },
          {
            date: "2023-03-15",
            value: 0,
            views: 26471054,
          },
          {
            date: "2023-03-17",
            value: 0,
            views: 26471056,
          },
          {
            date: "2023-03-19",
            value: 0,
            views: 26471057,
          },
          {
            date: "2023-03-21",
            value: 0,
            views: 26471057,
          },
          {
            date: "2023-03-23",
            value: 0,
            views: 26471057,
          },
          {
            date: "2023-03-25",
            value: 0,
            views: 26471057,
          },
          {
            date: "2023-03-28",
            value: 0,
            views: 26471058,
          },
          {
            date: "2023-03-31",
            value: 0,
            views: 26471058,
          },
          {
            date: "2023-04-01",
            value: 0,
            views: 26471058,
          },
          {
            date: "2023-04-03",
            value: 0,
            views: 26471058,
          },
          {
            date: "2023-04-05",
            value: 0,
            views: 26471058,
          },
          {
            date: "2023-04-07",
            value: 0,
            views: 26471059,
          },
          {
            date: "2023-04-09",
            value: 0,
            views: 26471059,
          },
          {
            date: "2023-04-11",
            value: 0,
            views: 26471062,
          },
          {
            date: "2023-04-13",
            value: 0,
            views: 26471063,
          },
          {
            date: "2023-04-15",
            value: 0,
            views: 26471063,
          },
          {
            date: "2023-04-17",
            value: 0,
            views: 26471064,
          },
          {
            date: "2023-04-19",
            value: 0,
            views: 26471064,
          },
          {
            date: "2023-04-21",
            value: 0,
            views: 26471064,
          },
          {
            date: "2023-04-23",
            value: 0,
            views: 26471064,
          },
          {
            date: "2023-05-06",
            value: 0,
            views: 26471067,
          },
          {
            date: "2023-05-07",
            value: 0,
            views: 26471067,
          },
          {
            date: "2023-05-13",
            value: 0,
            views: 26471068,
          },
          {
            date: "2023-05-20",
            value: 0,
            views: 26471070,
          },
          {
            date: "2023-05-31",
            value: 0,
            views: 26471073,
          },
          {
            date: "2023-06-04",
            value: 0,
            views: 26471077,
          },
          {
            date: "2023-06-06",
            value: 0,
            views: 26471079,
          },
          {
            date: "2023-06-13",
            value: 0,
            views: 26471085,
          },
          {
            date: "2023-07-30",
            value: 0,
            views: 26471115,
          },
          {
            date: "2023-08-02",
            value: 0,
            views: 26471116,
          },
          {
            date: "2023-08-05",
            value: 0,
            views: 26471115,
          },
          {
            date: "2023-08-07",
            value: 0,
            views: 26471119,
          },
          {
            date: "2023-08-09",
            value: 0,
            views: 26471120,
          },
          {
            date: "2023-08-13",
            value: 0,
            views: 26471121,
          },
          {
            date: "2023-08-16",
            value: 0,
            views: 26471122,
          },
          {
            date: "2023-08-18",
            value: 0,
            views: 26471122,
          },
          {
            date: "2023-08-23",
            value: 0,
            views: 26471123,
          },
          {
            date: "2023-08-23",
            value: 0,
            views: 26471124,
          },
          {
            date: "2023-08-25",
            value: 0,
            views: 26471124,
          },
          {
            date: "2023-08-28",
            value: 0,
            views: 26471124,
          },
          {
            date: "2023-08-30",
            value: 0,
            views: 26471124,
          },
          {
            date: "2023-09-07",
            value: 0,
            views: 26471128,
          },
          {
            date: "2023-09-11",
            value: 0,
            views: 26471128,
          },
          {
            date: "2023-09-13",
            value: 0,
            views: 26471129,
          },
          {
            date: "2023-09-16",
            value: 0,
            views: 26471129,
          },
          {
            date: "2023-09-18",
            value: 0,
            views: 26471132,
          },
          {
            date: "2023-09-22",
            value: 0,
            views: 26471132,
          },
          {
            date: "2023-09-25",
            value: 0,
            views: 26471132,
          },
          {
            date: "2023-09-27",
            value: 0,
            views: 26471135,
          },
          {
            date: "2023-09-29",
            value: 0,
            views: 26471136,
          },
          {
            date: "2023-10-02",
            value: 0,
            views: 26471139,
          },
          {
            date: "2023-10-05",
            value: 0,
            views: 26471141,
          },
          {
            date: "2023-10-06",
            value: 0,
            views: 26471141,
          },
          {
            date: "2023-10-10",
            value: 0,
            views: 26471142,
          },
          {
            date: "2023-10-11",
            value: 0,
            views: 26471142,
          },
          {
            date: "2023-10-13",
            value: 0,
            views: 26471143,
          },
          {
            date: "2023-10-17",
            value: 0,
            views: 26471144,
          },
          {
            date: "2023-10-18",
            value: 0,
            views: 26471144,
          },
          {
            date: "2023-10-21",
            value: 0,
            views: 26471144,
          },
          {
            date: "2023-10-24",
            value: 0,
            views: 26471148,
          },
          {
            date: "2023-10-25",
            value: 0,
            views: 26471153,
          },
          {
            date: "2023-10-26",
            value: 0,
            views: 26471153,
          },
          {
            date: "2023-10-27",
            value: 0,
            views: 26471154,
          },
          {
            date: "2023-10-31",
            value: 0,
            views: 26471157,
          },
          {
            date: "2023-11-02",
            value: 0,
            views: 26471158,
          },
          {
            date: "2023-11-07",
            value: 0,
            views: 26471159,
          },
          {
            date: "2023-11-09",
            value: 0,
            views: 26471159,
          },
        ],
      },
      {
        videoId: "Ry_wwC7AzuU",
        dataPoints: [
          {
            date: "2020-08-02",
            value: 0,
            views: 0,
          },
          {
            date: "2021-12-25",
            value: 0,
            views: 25559246,
          },
          {
            date: "2021-12-26",
            value: 0,
            views: 25559246,
          },
          {
            date: "2021-12-27",
            value: 0,
            views: 25559246,
          },
          {
            date: "2021-12-28",
            value: 0,
            views: 25559246,
          },
          {
            date: "2021-12-29",
            value: 0,
            views: 25559246,
          },
          {
            date: "2021-12-30",
            value: 0,
            views: 25559247,
          },
          {
            date: "2021-12-31",
            value: 0,
            views: 25559248,
          },
          {
            date: "2022-01-01",
            value: 0,
            views: 25559248,
          },
          {
            date: "2022-01-02",
            value: 0,
            views: 25559248,
          },
          {
            date: "2022-01-03",
            value: 0,
            views: 25559248,
          },
          {
            date: "2022-01-04",
            value: 0,
            views: 25559249,
          },
          {
            date: "2022-01-05",
            value: 0,
            views: 25559253,
          },
          {
            date: "2022-01-06",
            value: 0,
            views: 25559253,
          },
          {
            date: "2022-01-07",
            value: 0,
            views: 25559253,
          },
          {
            date: "2022-01-08",
            value: 0,
            views: 25559253,
          },
          {
            date: "2022-01-09",
            value: 0,
            views: 25559253,
          },
          {
            date: "2022-01-10",
            value: 0,
            views: 25559253,
          },
          {
            date: "2022-01-11",
            value: 0,
            views: 25559253,
          },
          {
            date: "2022-01-12",
            value: 0,
            views: 25559253,
          },
          {
            date: "2022-01-13",
            value: 0,
            views: 25559253,
          },
          {
            date: "2022-01-14",
            value: 0,
            views: 25559253,
          },
          {
            date: "2022-01-15",
            value: 0,
            views: 25559253,
          },
          {
            date: "2022-01-16",
            value: 0,
            views: 25559253,
          },
          {
            date: "2022-01-17",
            value: 0,
            views: 25559253,
          },
          {
            date: "2022-01-18",
            value: 0,
            views: 25559253,
          },
          {
            date: "2022-01-19",
            value: 0,
            views: 25559253,
          },
          {
            date: "2022-01-20",
            value: 0,
            views: 25559253,
          },
          {
            date: "2022-01-21",
            value: 0,
            views: 25559253,
          },
          {
            date: "2022-01-22",
            value: 0,
            views: 25559253,
          },
          {
            date: "2022-01-23",
            value: 0,
            views: 25559253,
          },
          {
            date: "2022-01-24",
            value: 0,
            views: 25559253,
          },
          {
            date: "2022-01-25",
            value: 0,
            views: 25559254,
          },
          {
            date: "2022-01-26",
            value: 0,
            views: 25559254,
          },
          {
            date: "2022-01-27",
            value: 0,
            views: 25559254,
          },
          {
            date: "2022-01-28",
            value: 0,
            views: 25559254,
          },
          {
            date: "2022-01-29",
            value: 0,
            views: 25559255,
          },
          {
            date: "2022-01-30",
            value: 0,
            views: 25559256,
          },
          {
            date: "2022-01-31",
            value: 0,
            views: 25559256,
          },
          {
            date: "2022-02-01",
            value: 0,
            views: 25559257,
          },
          {
            date: "2022-02-02",
            value: 0,
            views: 25559257,
          },
          {
            date: "2022-02-03",
            value: 0,
            views: 25559257,
          },
          {
            date: "2022-02-04",
            value: 0,
            views: 25559257,
          },
          {
            date: "2022-02-05",
            value: 0,
            views: 25559257,
          },
          {
            date: "2022-02-06",
            value: 0,
            views: 25559257,
          },
          {
            date: "2022-02-07",
            value: 0,
            views: 25559257,
          },
          {
            date: "2022-02-08",
            value: 0,
            views: 25559259,
          },
          {
            date: "2022-02-09",
            value: 0,
            views: 25559259,
          },
          {
            date: "2022-02-10",
            value: 0,
            views: 25559261,
          },
          {
            date: "2022-02-11",
            value: 0,
            views: 25559261,
          },
          {
            date: "2022-02-12",
            value: 0,
            views: 25559261,
          },
          {
            date: "2022-02-13",
            value: 0,
            views: 25559261,
          },
          {
            date: "2022-02-14",
            value: 0,
            views: 25559261,
          },
          {
            date: "2022-02-15",
            value: 0,
            views: 25559261,
          },
          {
            date: "2022-02-16",
            value: 0,
            views: 25559261,
          },
          {
            date: "2022-02-17",
            value: 0,
            views: 25559261,
          },
          {
            date: "2022-02-18",
            value: 0,
            views: 25559261,
          },
          {
            date: "2022-02-19",
            value: 0,
            views: 25559261,
          },
          {
            date: "2022-02-20",
            value: 0,
            views: 25559261,
          },
          {
            date: "2022-02-21",
            value: 0,
            views: 25559261,
          },
          {
            date: "2022-02-22",
            value: 0,
            views: 25559261,
          },
          {
            date: "2022-02-23",
            value: 0,
            views: 25559261,
          },
          {
            date: "2022-02-24",
            value: 0,
            views: 25559261,
          },
          {
            date: "2022-02-25",
            value: 0,
            views: 25559262,
          },
          {
            date: "2022-02-26",
            value: 0,
            views: 25559262,
          },
          {
            date: "2022-02-27",
            value: 0,
            views: 25559262,
          },
          {
            date: "2022-02-28",
            value: 0,
            views: 25559262,
          },
          {
            date: "2022-03-01",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-02",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-03",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-04",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-05",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-06",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-07",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-08",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-09",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-10",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-11",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-12",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-13",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-14",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-15",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-16",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-17",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-18",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-19",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-20",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-21",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-22",
            value: 0,
            views: 25559264,
          },
          {
            date: "2022-03-23",
            value: 0,
            views: 25559265,
          },
          {
            date: "2022-03-24",
            value: 0,
            views: 25559265,
          },
          {
            date: "2022-03-25",
            value: 0,
            views: 25559265,
          },
          {
            date: "2022-04-08",
            value: 0,
            views: 25559265,
          },
          {
            date: "2022-04-15",
            value: 0,
            views: 25559265,
          },
          {
            date: "2022-04-27",
            value: 0,
            views: 25559270,
          },
          {
            date: "2022-04-28",
            value: 0,
            views: 25559270,
          },
          {
            date: "2022-04-29",
            value: 0,
            views: 25559270,
          },
          {
            date: "2022-05-02",
            value: 0,
            views: 25559270,
          },
          {
            date: "2022-05-05",
            value: 0,
            views: 25559271,
          },
          {
            date: "2022-05-06",
            value: 0,
            views: 25559271,
          },
          {
            date: "2022-05-11",
            value: 0,
            views: 25559271,
          },
          {
            date: "2022-05-13",
            value: 0,
            views: 25559271,
          },
          {
            date: "2022-05-17",
            value: 0,
            views: 25559273,
          },
          {
            date: "2022-05-19",
            value: 0,
            views: 25559273,
          },
          {
            date: "2022-05-20",
            value: 0,
            views: 25559273,
          },
          {
            date: "2022-05-21",
            value: 0,
            views: 25559273,
          },
          {
            date: "2022-05-22",
            value: 0,
            views: 25559273,
          },
          {
            date: "2022-05-23",
            value: 0,
            views: 25559273,
          },
          {
            date: "2022-05-24",
            value: 0,
            views: 25559274,
          },
          {
            date: "2022-05-25",
            value: 0,
            views: 25559274,
          },
          {
            date: "2022-05-26",
            value: 0,
            views: 25559274,
          },
          {
            date: "2022-05-27",
            value: 0,
            views: 25559274,
          },
          {
            date: "2022-05-28",
            value: 0,
            views: 25559276,
          },
          {
            date: "2022-05-29",
            value: 0,
            views: 25559276,
          },
          {
            date: "2022-05-30",
            value: 0,
            views: 25559276,
          },
          {
            date: "2022-05-31",
            value: 0,
            views: 25559276,
          },
          {
            date: "2022-06-01",
            value: 0,
            views: 25559277,
          },
          {
            date: "2022-06-02",
            value: 0,
            views: 25559277,
          },
          {
            date: "2022-06-03",
            value: 0,
            views: 25559277,
          },
          {
            date: "2022-06-04",
            value: 0,
            views: 25559277,
          },
          {
            date: "2022-06-05",
            value: 0,
            views: 25559277,
          },
          {
            date: "2022-06-06",
            value: 0,
            views: 25559277,
          },
          {
            date: "2022-06-07",
            value: 0,
            views: 25559277,
          },
          {
            date: "2022-06-08",
            value: 0,
            views: 25559277,
          },
          {
            date: "2022-06-09",
            value: 0,
            views: 25559277,
          },
          {
            date: "2022-06-10",
            value: 0,
            views: 25559279,
          },
          {
            date: "2022-06-11",
            value: 0,
            views: 25559279,
          },
          {
            date: "2022-06-12",
            value: 0,
            views: 25559279,
          },
          {
            date: "2022-06-13",
            value: 0,
            views: 25559279,
          },
          {
            date: "2022-06-14",
            value: 0,
            views: 25559279,
          },
          {
            date: "2022-06-15",
            value: 0,
            views: 25559279,
          },
          {
            date: "2022-06-16",
            value: 0,
            views: 25559280,
          },
          {
            date: "2022-06-17",
            value: 0,
            views: 25559280,
          },
          {
            date: "2022-06-18",
            value: 0,
            views: 25559280,
          },
          {
            date: "2022-06-19",
            value: 0,
            views: 25559280,
          },
          {
            date: "2022-06-20",
            value: 0,
            views: 25559280,
          },
          {
            date: "2022-06-21",
            value: 0,
            views: 25559280,
          },
          {
            date: "2022-06-22",
            value: 0,
            views: 25559281,
          },
          {
            date: "2022-06-23",
            value: 0,
            views: 25559281,
          },
          {
            date: "2022-06-24",
            value: 0,
            views: 25559281,
          },
          {
            date: "2022-06-25",
            value: 0,
            views: 25559281,
          },
          {
            date: "2022-06-26",
            value: 0,
            views: 25559281,
          },
          {
            date: "2022-06-27",
            value: 0,
            views: 25559281,
          },
          {
            date: "2022-06-28",
            value: 0,
            views: 25559281,
          },
          {
            date: "2022-06-29",
            value: 0,
            views: 25559281,
          },
          {
            date: "2022-06-30",
            value: 0,
            views: 25559281,
          },
          {
            date: "2022-07-01",
            value: 0,
            views: 25559281,
          },
          {
            date: "2022-07-02",
            value: 0,
            views: 25559281,
          },
          {
            date: "2022-07-03",
            value: 0,
            views: 25559281,
          },
          {
            date: "2022-07-04",
            value: 0,
            views: 25559281,
          },
          {
            date: "2022-07-05",
            value: 0,
            views: 25559281,
          },
          {
            date: "2022-07-06",
            value: 0,
            views: 25559281,
          },
          {
            date: "2022-07-07",
            value: 0,
            views: 25559281,
          },
          {
            date: "2022-07-08",
            value: 0,
            views: 25559281,
          },
          {
            date: "2022-07-09",
            value: 0,
            views: 25559281,
          },
          {
            date: "2022-07-10",
            value: 0,
            views: 25559282,
          },
          {
            date: "2022-07-11",
            value: 0,
            views: 25559282,
          },
          {
            date: "2022-07-12",
            value: 0,
            views: 25559282,
          },
          {
            date: "2022-07-13",
            value: 0,
            views: 25559289,
          },
          {
            date: "2022-07-14",
            value: 0,
            views: 25559289,
          },
          {
            date: "2022-07-15",
            value: 0,
            views: 25559289,
          },
          {
            date: "2022-07-16",
            value: 0,
            views: 25559289,
          },
          {
            date: "2022-07-17",
            value: 0,
            views: 25559289,
          },
          {
            date: "2022-07-18",
            value: 0,
            views: 25559289,
          },
          {
            date: "2022-07-19",
            value: 0,
            views: 25559289,
          },
          {
            date: "2022-07-20",
            value: 0,
            views: 25559289,
          },
          {
            date: "2022-07-21",
            value: 0,
            views: 25559289,
          },
          {
            date: "2022-07-22",
            value: 0,
            views: 25559292,
          },
          {
            date: "2022-07-23",
            value: 0,
            views: 25559292,
          },
          {
            date: "2022-07-24",
            value: 0,
            views: 25559292,
          },
          {
            date: "2022-07-25",
            value: 0,
            views: 25559293,
          },
          {
            date: "2022-07-26",
            value: 0,
            views: 25559293,
          },
          {
            date: "2022-07-27",
            value: 0,
            views: 25559293,
          },
          {
            date: "2022-07-28",
            value: 0,
            views: 25559293,
          },
          {
            date: "2022-07-29",
            value: 0,
            views: 25559293,
          },
          {
            date: "2022-07-30",
            value: 0,
            views: 25559294,
          },
          {
            date: "2022-07-31",
            value: 0,
            views: 25559294,
          },
          {
            date: "2022-08-01",
            value: 0,
            views: 25559294,
          },
          {
            date: "2022-08-02",
            value: 0,
            views: 25559295,
          },
          {
            date: "2022-08-03",
            value: 0,
            views: 25559295,
          },
          {
            date: "2022-08-04",
            value: 0,
            views: 25559295,
          },
          {
            date: "2022-08-05",
            value: 0,
            views: 25559295,
          },
          {
            date: "2022-08-06",
            value: 0,
            views: 25559295,
          },
          {
            date: "2022-08-07",
            value: 0,
            views: 25559295,
          },
          {
            date: "2022-08-08",
            value: 0,
            views: 25559295,
          },
          {
            date: "2022-08-09",
            value: 0,
            views: 25559295,
          },
          {
            date: "2022-08-10",
            value: 0,
            views: 25559295,
          },
          {
            date: "2022-08-11",
            value: 0,
            views: 25559295,
          },
          {
            date: "2022-08-12",
            value: 0,
            views: 25559295,
          },
          {
            date: "2022-08-13",
            value: 0,
            views: 25559295,
          },
          {
            date: "2022-08-14",
            value: 0,
            views: 25559295,
          },
          {
            date: "2022-08-15",
            value: 0,
            views: 25559295,
          },
          {
            date: "2022-08-16",
            value: 0,
            views: 25559296,
          },
          {
            date: "2022-08-17",
            value: 0,
            views: 25559296,
          },
          {
            date: "2022-08-18",
            value: 0,
            views: 25559297,
          },
          {
            date: "2022-08-19",
            value: 0,
            views: 25559297,
          },
          {
            date: "2022-08-20",
            value: 0,
            views: 25559297,
          },
          {
            date: "2022-08-21",
            value: 0,
            views: 25559297,
          },
          {
            date: "2022-08-22",
            value: 0,
            views: 25559297,
          },
          {
            date: "2022-08-23",
            value: 0,
            views: 25559297,
          },
          {
            date: "2022-08-24",
            value: 0,
            views: 25559297,
          },
          {
            date: "2022-08-25",
            value: 0,
            views: 25559298,
          },
          {
            date: "2022-08-26",
            value: 0,
            views: 25559298,
          },
          {
            date: "2022-08-27",
            value: 0,
            views: 25559298,
          },
          {
            date: "2022-08-28",
            value: 0,
            views: 25559298,
          },
          {
            date: "2022-08-29",
            value: 0,
            views: 25559298,
          },
          {
            date: "2022-08-30",
            value: 0,
            views: 25559299,
          },
          {
            date: "2022-08-31",
            value: 0,
            views: 25559299,
          },
          {
            date: "2022-09-01",
            value: 0,
            views: 25559299,
          },
          {
            date: "2022-09-02",
            value: 0,
            views: 25559300,
          },
          {
            date: "2022-09-03",
            value: 0,
            views: 25559300,
          },
          {
            date: "2022-09-04",
            value: 0,
            views: 25559300,
          },
          {
            date: "2022-09-05",
            value: 0,
            views: 25559302,
          },
          {
            date: "2022-09-06",
            value: 0,
            views: 25559303,
          },
          {
            date: "2022-09-07",
            value: 0,
            views: 25559303,
          },
          {
            date: "2022-09-08",
            value: 0,
            views: 25559303,
          },
          {
            date: "2022-09-09",
            value: 0,
            views: 25559303,
          },
          {
            date: "2022-09-10",
            value: 0,
            views: 25559303,
          },
          {
            date: "2022-09-11",
            value: 0,
            views: 25559303,
          },
          {
            date: "2022-09-12",
            value: 0,
            views: 25559304,
          },
          {
            date: "2022-09-13",
            value: 0,
            views: 25559304,
          },
          {
            date: "2022-09-14",
            value: 0,
            views: 25559304,
          },
          {
            date: "2022-09-15",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-09-16",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-09-17",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-09-18",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-09-19",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-09-20",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-09-21",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-09-22",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-09-23",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-09-24",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-09-25",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-09-26",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-09-27",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-09-28",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-09-29",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-09-30",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-10-01",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-10-02",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-10-03",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-10-04",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-10-05",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-10-06",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-10-07",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-10-08",
            value: 0,
            views: 25559306,
          },
          {
            date: "2022-10-09",
            value: 0,
            views: 25559307,
          },
          {
            date: "2022-10-10",
            value: 0,
            views: 25559308,
          },
          {
            date: "2022-10-11",
            value: 0,
            views: 25559311,
          },
          {
            date: "2022-10-12",
            value: 0,
            views: 25559311,
          },
          {
            date: "2022-10-13",
            value: 0,
            views: 25559311,
          },
          {
            date: "2022-10-14",
            value: 0,
            views: 25559311,
          },
          {
            date: "2022-10-15",
            value: 0,
            views: 25559311,
          },
          {
            date: "2022-10-16",
            value: 0,
            views: 25559311,
          },
          {
            date: "2022-10-17",
            value: 0,
            views: 25559311,
          },
          {
            date: "2022-10-18",
            value: 0,
            views: 25559311,
          },
          {
            date: "2022-10-19",
            value: 0,
            views: 25559313,
          },
          {
            date: "2022-10-20",
            value: 0,
            views: 25559313,
          },
          {
            date: "2022-10-21",
            value: 0,
            views: 25559313,
          },
          {
            date: "2022-10-22",
            value: 0,
            views: 25559313,
          },
          {
            date: "2022-10-23",
            value: 0,
            views: 25559313,
          },
          {
            date: "2022-10-24",
            value: 0,
            views: 25559314,
          },
          {
            date: "2022-10-25",
            value: 0,
            views: 25559314,
          },
          {
            date: "2022-10-26",
            value: 0,
            views: 25559314,
          },
          {
            date: "2022-10-27",
            value: 0,
            views: 25559314,
          },
          {
            date: "2022-10-28",
            value: 0,
            views: 25559314,
          },
          {
            date: "2022-10-29",
            value: 0,
            views: 25559314,
          },
          {
            date: "2022-10-30",
            value: 0,
            views: 25559314,
          },
          {
            date: "2022-10-31",
            value: 0,
            views: 25559314,
          },
          {
            date: "2022-11-01",
            value: 0,
            views: 25559314,
          },
          {
            date: "2022-11-02",
            value: 0,
            views: 25559314,
          },
          {
            date: "2022-11-03",
            value: 0,
            views: 25559314,
          },
          {
            date: "2022-11-04",
            value: 0,
            views: 25559314,
          },
          {
            date: "2022-11-05",
            value: 0,
            views: 25559314,
          },
          {
            date: "2022-11-06",
            value: 0,
            views: 25559314,
          },
          {
            date: "2022-11-07",
            value: 0,
            views: 25559314,
          },
          {
            date: "2022-11-08",
            value: 0,
            views: 25559314,
          },
          {
            date: "2022-11-09",
            value: 0,
            views: 25559314,
          },
          {
            date: "2022-11-15",
            value: 0,
            views: 25559314,
          },
          {
            date: "2022-11-16",
            value: 0,
            views: 25559314,
          },
          {
            date: "2022-11-19",
            value: 0,
            views: 25559316,
          },
          {
            date: "2022-11-20",
            value: 0,
            views: 25559316,
          },
          {
            date: "2022-11-21",
            value: 0,
            views: 25559317,
          },
          {
            date: "2022-11-22",
            value: 0,
            views: 25559321,
          },
          {
            date: "2022-11-23",
            value: 0,
            views: 25559321,
          },
          {
            date: "2022-11-25",
            value: 0,
            views: 25559321,
          },
          {
            date: "2022-11-26",
            value: 0,
            views: 25559321,
          },
          {
            date: "2022-11-27",
            value: 0,
            views: 25559321,
          },
          {
            date: "2022-11-28",
            value: 0,
            views: 25559321,
          },
          {
            date: "2022-11-29",
            value: 0,
            views: 25559321,
          },
          {
            date: "2022-11-30",
            value: 0,
            views: 25559321,
          },
          {
            date: "2022-12-01",
            value: 0,
            views: 25559321,
          },
          {
            date: "2022-12-02",
            value: 0,
            views: 25559321,
          },
          {
            date: "2022-12-03",
            value: 0,
            views: 25559321,
          },
          {
            date: "2022-12-04",
            value: 0,
            views: 25559321,
          },
          {
            date: "2022-12-05",
            value: 0,
            views: 25559321,
          },
          {
            date: "2022-12-06",
            value: 0,
            views: 25559321,
          },
          {
            date: "2022-12-08",
            value: 0,
            views: 25559327,
          },
          {
            date: "2022-12-09",
            value: 0,
            views: 25559327,
          },
          {
            date: "2022-12-10",
            value: 0,
            views: 25559327,
          },
          {
            date: "2022-12-11",
            value: 0,
            views: 25559327,
          },
          {
            date: "2022-12-12",
            value: 0,
            views: 25559327,
          },
          {
            date: "2022-12-13",
            value: 0,
            views: 25559327,
          },
          {
            date: "2022-12-14",
            value: 0,
            views: 25559327,
          },
          {
            date: "2022-12-15",
            value: 0,
            views: 25559328,
          },
          {
            date: "2022-12-16",
            value: 0,
            views: 25559329,
          },
          {
            date: "2022-12-17",
            value: 0,
            views: 25559329,
          },
          {
            date: "2022-12-18",
            value: 0,
            views: 25559329,
          },
          {
            date: "2022-12-19",
            value: 0,
            views: 25559331,
          },
          {
            date: "2022-12-20",
            value: 0,
            views: 25559331,
          },
          {
            date: "2022-12-21",
            value: 0,
            views: 25559332,
          },
          {
            date: "2022-12-22",
            value: 0,
            views: 25559332,
          },
          {
            date: "2022-12-23",
            value: 0,
            views: 25559332,
          },
          {
            date: "2022-12-25",
            value: 0,
            views: 25559332,
          },
          {
            date: "2022-12-26",
            value: 0,
            views: 25559332,
          },
          {
            date: "2022-12-28",
            value: 0,
            views: 25559332,
          },
          {
            date: "2022-12-29",
            value: 0,
            views: 25559332,
          },
          {
            date: "2022-12-30",
            value: 0,
            views: 25559332,
          },
          {
            date: "2022-12-31",
            value: 0,
            views: 25559332,
          },
          {
            date: "2023-01-01",
            value: 0,
            views: 25559332,
          },
          {
            date: "2023-01-02",
            value: 0,
            views: 25559332,
          },
          {
            date: "2023-01-03",
            value: 0,
            views: 25559332,
          },
          {
            date: "2023-01-04",
            value: 0,
            views: 25559333,
          },
          {
            date: "2023-01-05",
            value: 0,
            views: 25559333,
          },
          {
            date: "2023-01-06",
            value: 0,
            views: 25559334,
          },
          {
            date: "2023-01-07",
            value: 0,
            views: 25559334,
          },
          {
            date: "2023-01-08",
            value: 0,
            views: 25559334,
          },
          {
            date: "2023-01-09",
            value: 0,
            views: 25559334,
          },
          {
            date: "2023-01-10",
            value: 0,
            views: 25559334,
          },
          {
            date: "2023-01-11",
            value: 0,
            views: 25559334,
          },
          {
            date: "2023-01-12",
            value: 0,
            views: 25559334,
          },
          {
            date: "2023-01-13",
            value: 0,
            views: 25559334,
          },
          {
            date: "2023-01-14",
            value: 0,
            views: 25559334,
          },
          {
            date: "2023-01-15",
            value: 0,
            views: 25559334,
          },
          {
            date: "2023-01-16",
            value: 0,
            views: 25559335,
          },
          {
            date: "2023-01-17",
            value: 0,
            views: 25559335,
          },
          {
            date: "2023-01-18",
            value: 0,
            views: 25559335,
          },
          {
            date: "2023-01-19",
            value: 0,
            views: 25559336,
          },
          {
            date: "2023-01-20",
            value: 0,
            views: 25559338,
          },
          {
            date: "2023-01-21",
            value: 0,
            views: 25559338,
          },
          {
            date: "2023-01-22",
            value: 0,
            views: 25559338,
          },
          {
            date: "2023-01-23",
            value: 0,
            views: 25559338,
          },
          {
            date: "2023-01-24",
            value: 0,
            views: 25559338,
          },
          {
            date: "2023-01-25",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-01-26",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-01-27",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-01-29",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-01-30",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-01-31",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-02-01",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-02-02",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-02-03",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-02-04",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-02-05",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-02-06",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-02-08",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-02-09",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-02-10",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-02-12",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-02-14",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-02-16",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-02-18",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-02-20",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-02-22",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-02-24",
            value: 0,
            views: 25559341,
          },
          {
            date: "2023-03-28",
            value: 0,
            views: 25559349,
          },
          {
            date: "2023-05-08",
            value: 0,
            views: 25559353,
          },
          {
            date: "2023-05-13",
            value: 0,
            views: 25559353,
          },
          {
            date: "2023-06-04",
            value: 0,
            views: 25559370,
          },
          {
            date: "2023-06-06",
            value: 0,
            views: 25559370,
          },
          {
            date: "2023-07-13",
            value: 0,
            views: 25559391,
          },
          {
            date: "2023-07-30",
            value: 0,
            views: 25559397,
          },
          {
            date: "2023-08-02",
            value: 0,
            views: 25559397,
          },
          {
            date: "2023-08-05",
            value: 0,
            views: 25559397,
          },
          {
            date: "2023-08-08",
            value: 0,
            views: 25559398,
          },
          {
            date: "2023-08-10",
            value: 0,
            views: 25559398,
          },
          {
            date: "2023-08-13",
            value: 0,
            views: 25559400,
          },
          {
            date: "2023-08-16",
            value: 0,
            views: 25559401,
          },
          {
            date: "2023-08-18",
            value: 0,
            views: 25559401,
          },
          {
            date: "2023-08-24",
            value: 0,
            views: 25559402,
          },
          {
            date: "2023-08-25",
            value: 0,
            views: 25559402,
          },
          {
            date: "2023-08-28",
            value: 0,
            views: 25559402,
          },
          {
            date: "2023-08-31",
            value: 0,
            views: 25559402,
          },
          {
            date: "2023-09-05",
            value: 0,
            views: 25559402,
          },
          {
            date: "2023-09-07",
            value: 0,
            views: 25559402,
          },
          {
            date: "2023-09-11",
            value: 0,
            views: 25559402,
          },
          {
            date: "2023-09-16",
            value: 0,
            views: 25559404,
          },
          {
            date: "2023-09-19",
            value: 0,
            views: 25559404,
          },
          {
            date: "2023-09-20",
            value: 0,
            views: 25559404,
          },
          {
            date: "2023-09-22",
            value: 0,
            views: 25559411,
          },
          {
            date: "2023-09-25",
            value: 0,
            views: 25559411,
          },
          {
            date: "2023-09-27",
            value: 0,
            views: 25559412,
          },
          {
            date: "2023-09-29",
            value: 0,
            views: 25559412,
          },
          {
            date: "2023-10-02",
            value: 0,
            views: 25559413,
          },
          {
            date: "2023-10-05",
            value: 0,
            views: 25559413,
          },
          {
            date: "2023-10-06",
            value: 0,
            views: 25559413,
          },
          {
            date: "2023-10-10",
            value: 0,
            views: 25559415,
          },
          {
            date: "2023-10-11",
            value: 0,
            views: 25559415,
          },
          {
            date: "2023-10-13",
            value: 0,
            views: 25559415,
          },
          {
            date: "2023-10-17",
            value: 0,
            views: 25559418,
          },
          {
            date: "2023-10-18",
            value: 0,
            views: 25559419,
          },
          {
            date: "2023-10-21",
            value: 0,
            views: 25559420,
          },
          {
            date: "2023-10-25",
            value: 0,
            views: 25559422,
          },
          {
            date: "2023-10-26",
            value: 0,
            views: 25559424,
          },
          {
            date: "2023-10-27",
            value: 0,
            views: 25559423,
          },
          {
            date: "2023-10-31",
            value: 0,
            views: 25559430,
          },
          {
            date: "2023-11-02",
            value: 0,
            views: 25559431,
          },
          {
            date: "2023-11-04",
            value: 0,
            views: 25559431,
          },
          {
            date: "2023-11-07",
            value: 0,
            views: 25559431,
          },
        ],
      },
      {
        videoId: "fx1FWP9PLyU",
        dataPoints: [
          {
            date: "2020-09-24",
            value: 0,
            views: 0,
          },
          {
            date: "2021-11-07",
            value: 0,
            views: 24232521,
          },
          {
            date: "2021-11-08",
            value: 0,
            views: 24239377,
          },
          {
            date: "2021-11-09",
            value: 0,
            views: 24242935,
          },
          {
            date: "2021-11-10",
            value: 0,
            views: 24243292,
          },
          {
            date: "2021-11-11",
            value: 0,
            views: 24244883,
          },
          {
            date: "2021-11-12",
            value: 0,
            views: 24247118,
          },
          {
            date: "2021-11-13",
            value: 0,
            views: 24248729,
          },
          {
            date: "2021-11-14",
            value: 0,
            views: 24250016,
          },
          {
            date: "2021-11-15",
            value: 0,
            views: 24250980,
          },
          {
            date: "2021-11-16",
            value: 0,
            views: 24251270,
          },
          {
            date: "2021-11-17",
            value: 0,
            views: 24251298,
          },
          {
            date: "2021-11-18",
            value: 0,
            views: 24251378,
          },
          {
            date: "2021-11-19",
            value: 0,
            views: 24251441,
          },
          {
            date: "2021-11-20",
            value: 0,
            views: 24251482,
          },
          {
            date: "2021-11-21",
            value: 0,
            views: 24251561,
          },
          {
            date: "2021-11-22",
            value: 0,
            views: 24251933,
          },
          {
            date: "2021-11-23",
            value: 0,
            views: 24252739,
          },
          {
            date: "2021-11-24",
            value: 0,
            views: 24253648,
          },
          {
            date: "2021-11-25",
            value: 0,
            views: 24253855,
          },
          {
            date: "2021-11-26",
            value: 0,
            views: 24253872,
          },
          {
            date: "2021-11-27",
            value: 0,
            views: 24253898,
          },
          {
            date: "2021-11-28",
            value: 0,
            views: 24253911,
          },
          {
            date: "2021-11-29",
            value: 0,
            views: 24253958,
          },
          {
            date: "2021-11-30",
            value: 0,
            views: 24253981,
          },
          {
            date: "2021-12-01",
            value: 0,
            views: 24253987,
          },
          {
            date: "2021-12-02",
            value: 0,
            views: 24253991,
          },
          {
            date: "2021-12-03",
            value: 0,
            views: 24253998,
          },
          {
            date: "2021-12-04",
            value: 0,
            views: 24254009,
          },
          {
            date: "2021-12-05",
            value: 0,
            views: 24254017,
          },
          {
            date: "2021-12-06",
            value: 0,
            views: 24254017,
          },
          {
            date: "2021-12-07",
            value: 0,
            views: 24254017,
          },
          {
            date: "2021-12-09",
            value: 0,
            views: 24257208,
          },
          {
            date: "2021-12-10",
            value: 0,
            views: 24257495,
          },
          {
            date: "2021-12-12",
            value: 0,
            views: 24258137,
          },
          {
            date: "2021-12-13",
            value: 0,
            views: 24258357,
          },
          {
            date: "2021-12-14",
            value: 0,
            views: 24258460,
          },
          {
            date: "2021-12-15",
            value: 0,
            views: 24258497,
          },
          {
            date: "2021-12-17",
            value: 0,
            views: 24259235,
          },
          {
            date: "2021-12-18",
            value: 0,
            views: 24259538,
          },
          {
            date: "2021-12-19",
            value: 0,
            views: 24259800,
          },
          {
            date: "2021-12-20",
            value: 0,
            views: 24260006,
          },
          {
            date: "2021-12-21",
            value: 0,
            views: 24260232,
          },
          {
            date: "2021-12-22",
            value: 0,
            views: 24260623,
          },
          {
            date: "2021-12-23",
            value: 0,
            views: 24260897,
          },
          {
            date: "2021-12-24",
            value: 0,
            views: 24261323,
          },
          {
            date: "2021-12-25",
            value: 0,
            views: 24261757,
          },
          {
            date: "2021-12-26",
            value: 0,
            views: 24262623,
          },
          {
            date: "2021-12-27",
            value: 0,
            views: 24264126,
          },
          {
            date: "2021-12-28",
            value: 0,
            views: 24265491,
          },
          {
            date: "2021-12-29",
            value: 0,
            views: 24266422,
          },
          {
            date: "2021-12-30",
            value: 0,
            views: 24267354,
          },
          {
            date: "2021-12-31",
            value: 0,
            views: 24268027,
          },
          {
            date: "2022-01-01",
            value: 0,
            views: 24268790,
          },
          {
            date: "2022-01-02",
            value: 0,
            views: 24270437,
          },
          {
            date: "2022-01-03",
            value: 0,
            views: 24276037,
          },
          {
            date: "2022-01-04",
            value: 0,
            views: 24282194,
          },
          {
            date: "2022-01-05",
            value: 0,
            views: 24282842,
          },
          {
            date: "2022-01-06",
            value: 0,
            views: 24282875,
          },
          {
            date: "2022-01-07",
            value: 0,
            views: 24282930,
          },
          {
            date: "2022-01-09",
            value: 0,
            views: 24283515,
          },
          {
            date: "2022-01-10",
            value: 0,
            views: 24284296,
          },
          {
            date: "2022-01-11",
            value: 0,
            views: 24285409,
          },
          {
            date: "2022-01-12",
            value: 0,
            views: 24285989,
          },
          {
            date: "2022-01-13",
            value: 0,
            views: 24286186,
          },
          {
            date: "2022-01-15",
            value: 0,
            views: 24286385,
          },
          {
            date: "2022-01-16",
            value: 0,
            views: 24286755,
          },
          {
            date: "2022-01-17",
            value: 0,
            views: 24287090,
          },
          {
            date: "2022-01-23",
            value: 0,
            views: 24287973,
          },
          {
            date: "2022-01-27",
            value: 0,
            views: 24288010,
          },
          {
            date: "2022-01-30",
            value: 0,
            views: 24288011,
          },
          {
            date: "2022-02-15",
            value: 0,
            views: 24288024,
          },
          {
            date: "2022-04-29",
            value: 0,
            views: 24288052,
          },
          {
            date: "2022-05-02",
            value: 0,
            views: 24288052,
          },
          {
            date: "2022-05-06",
            value: 0,
            views: 24288053,
          },
          {
            date: "2022-05-11",
            value: 0,
            views: 24288054,
          },
          {
            date: "2022-05-13",
            value: 0,
            views: 24288055,
          },
          {
            date: "2022-05-17",
            value: 0,
            views: 24288055,
          },
          {
            date: "2022-05-19",
            value: 0,
            views: 24288055,
          },
          {
            date: "2022-05-20",
            value: 0,
            views: 24288055,
          },
          {
            date: "2022-05-21",
            value: 0,
            views: 24288055,
          },
          {
            date: "2022-05-22",
            value: 0,
            views: 24288055,
          },
          {
            date: "2022-05-23",
            value: 0,
            views: 24288055,
          },
          {
            date: "2022-05-24",
            value: 0,
            views: 24288056,
          },
          {
            date: "2022-05-25",
            value: 0,
            views: 24288056,
          },
          {
            date: "2022-05-26",
            value: 0,
            views: 24288056,
          },
          {
            date: "2022-05-27",
            value: 0,
            views: 24288056,
          },
          {
            date: "2022-05-28",
            value: 0,
            views: 24288059,
          },
          {
            date: "2022-05-29",
            value: 0,
            views: 24288059,
          },
          {
            date: "2022-05-30",
            value: 0,
            views: 24288059,
          },
          {
            date: "2022-05-31",
            value: 0,
            views: 24288059,
          },
          {
            date: "2022-06-01",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-02",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-03",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-04",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-05",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-06",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-07",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-08",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-09",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-10",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-11",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-12",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-13",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-14",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-15",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-16",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-17",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-18",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-19",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-20",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-21",
            value: 0,
            views: 24288060,
          },
          {
            date: "2022-06-22",
            value: 0,
            views: 24288061,
          },
          {
            date: "2022-06-23",
            value: 0,
            views: 24288061,
          },
          {
            date: "2022-06-24",
            value: 0,
            views: 24288061,
          },
          {
            date: "2022-06-25",
            value: 0,
            views: 24288061,
          },
          {
            date: "2022-06-26",
            value: 0,
            views: 24288061,
          },
          {
            date: "2022-06-27",
            value: 0,
            views: 24288061,
          },
          {
            date: "2022-06-28",
            value: 0,
            views: 24288062,
          },
          {
            date: "2022-06-29",
            value: 0,
            views: 24288062,
          },
          {
            date: "2022-06-30",
            value: 0,
            views: 24288062,
          },
          {
            date: "2022-07-01",
            value: 0,
            views: 24288063,
          },
          {
            date: "2022-07-02",
            value: 0,
            views: 24288063,
          },
          {
            date: "2022-07-03",
            value: 0,
            views: 24288063,
          },
          {
            date: "2022-07-04",
            value: 0,
            views: 24288064,
          },
          {
            date: "2022-07-05",
            value: 0,
            views: 24288064,
          },
          {
            date: "2022-07-06",
            value: 0,
            views: 24288064,
          },
          {
            date: "2022-07-07",
            value: 0,
            views: 24288064,
          },
          {
            date: "2022-07-08",
            value: 0,
            views: 24288066,
          },
          {
            date: "2022-07-09",
            value: 0,
            views: 24288067,
          },
          {
            date: "2022-07-10",
            value: 0,
            views: 24288067,
          },
          {
            date: "2022-07-11",
            value: 0,
            views: 24288068,
          },
          {
            date: "2022-07-12",
            value: 0,
            views: 24288068,
          },
          {
            date: "2022-07-13",
            value: 0,
            views: 24288075,
          },
          {
            date: "2022-07-14",
            value: 0,
            views: 24288075,
          },
          {
            date: "2022-07-15",
            value: 0,
            views: 24288075,
          },
          {
            date: "2022-07-16",
            value: 0,
            views: 24288075,
          },
          {
            date: "2022-07-17",
            value: 0,
            views: 24288075,
          },
          {
            date: "2022-07-18",
            value: 0,
            views: 24288075,
          },
          {
            date: "2022-07-19",
            value: 0,
            views: 24288075,
          },
          {
            date: "2022-07-20",
            value: 0,
            views: 24288075,
          },
          {
            date: "2022-07-21",
            value: 0,
            views: 24288076,
          },
          {
            date: "2022-07-22",
            value: 0,
            views: 24288077,
          },
          {
            date: "2022-07-23",
            value: 0,
            views: 24288077,
          },
          {
            date: "2022-07-24",
            value: 0,
            views: 24288077,
          },
          {
            date: "2022-07-25",
            value: 0,
            views: 24288077,
          },
          {
            date: "2022-07-26",
            value: 0,
            views: 24288077,
          },
          {
            date: "2022-07-27",
            value: 0,
            views: 24288077,
          },
          {
            date: "2022-07-28",
            value: 0,
            views: 24288077,
          },
          {
            date: "2022-07-29",
            value: 0,
            views: 24288079,
          },
          {
            date: "2022-07-30",
            value: 0,
            views: 24288079,
          },
          {
            date: "2022-07-31",
            value: 0,
            views: 24288079,
          },
          {
            date: "2022-08-01",
            value: 0,
            views: 24288080,
          },
          {
            date: "2022-08-02",
            value: 0,
            views: 24288081,
          },
          {
            date: "2022-08-03",
            value: 0,
            views: 24288081,
          },
          {
            date: "2022-08-04",
            value: 0,
            views: 24288083,
          },
          {
            date: "2022-08-05",
            value: 0,
            views: 24288083,
          },
          {
            date: "2022-08-06",
            value: 0,
            views: 24288083,
          },
          {
            date: "2022-08-07",
            value: 0,
            views: 24288083,
          },
          {
            date: "2022-08-08",
            value: 0,
            views: 24288083,
          },
          {
            date: "2022-08-09",
            value: 0,
            views: 24288083,
          },
          {
            date: "2022-08-10",
            value: 0,
            views: 24288083,
          },
          {
            date: "2022-08-11",
            value: 0,
            views: 24288083,
          },
          {
            date: "2022-08-12",
            value: 0,
            views: 24288084,
          },
          {
            date: "2022-08-13",
            value: 0,
            views: 24288084,
          },
          {
            date: "2022-08-14",
            value: 0,
            views: 24288084,
          },
          {
            date: "2022-08-15",
            value: 0,
            views: 24288084,
          },
          {
            date: "2022-08-16",
            value: 0,
            views: 24288084,
          },
          {
            date: "2022-08-17",
            value: 0,
            views: 24288084,
          },
          {
            date: "2022-08-18",
            value: 0,
            views: 24288085,
          },
          {
            date: "2022-08-19",
            value: 0,
            views: 24288085,
          },
          {
            date: "2022-08-20",
            value: 0,
            views: 24288085,
          },
          {
            date: "2022-08-21",
            value: 0,
            views: 24288085,
          },
          {
            date: "2022-08-22",
            value: 0,
            views: 24288085,
          },
          {
            date: "2022-08-23",
            value: 0,
            views: 24288088,
          },
          {
            date: "2022-08-24",
            value: 0,
            views: 24288088,
          },
          {
            date: "2022-08-25",
            value: 0,
            views: 24288088,
          },
          {
            date: "2022-08-26",
            value: 0,
            views: 24288088,
          },
          {
            date: "2022-08-27",
            value: 0,
            views: 24288088,
          },
          {
            date: "2022-08-28",
            value: 0,
            views: 24288088,
          },
          {
            date: "2022-08-29",
            value: 0,
            views: 24288088,
          },
          {
            date: "2022-08-30",
            value: 0,
            views: 24288088,
          },
          {
            date: "2022-08-31",
            value: 0,
            views: 24288088,
          },
          {
            date: "2022-09-01",
            value: 0,
            views: 24288088,
          },
          {
            date: "2022-09-02",
            value: 0,
            views: 24288089,
          },
          {
            date: "2022-09-03",
            value: 0,
            views: 24288089,
          },
          {
            date: "2022-09-04",
            value: 0,
            views: 24288089,
          },
          {
            date: "2022-09-05",
            value: 0,
            views: 24288089,
          },
          {
            date: "2022-09-06",
            value: 0,
            views: 24288090,
          },
          {
            date: "2022-09-07",
            value: 0,
            views: 24288090,
          },
          {
            date: "2022-09-08",
            value: 0,
            views: 24288090,
          },
          {
            date: "2022-09-09",
            value: 0,
            views: 24288090,
          },
          {
            date: "2022-09-10",
            value: 0,
            views: 24288090,
          },
          {
            date: "2022-09-11",
            value: 0,
            views: 24288090,
          },
          {
            date: "2022-09-12",
            value: 0,
            views: 24288091,
          },
          {
            date: "2022-09-13",
            value: 0,
            views: 24288091,
          },
          {
            date: "2022-09-14",
            value: 0,
            views: 24288091,
          },
          {
            date: "2022-09-15",
            value: 0,
            views: 24288095,
          },
          {
            date: "2022-09-16",
            value: 0,
            views: 24288095,
          },
          {
            date: "2022-09-17",
            value: 0,
            views: 24288095,
          },
          {
            date: "2022-09-18",
            value: 0,
            views: 24288095,
          },
          {
            date: "2022-09-19",
            value: 0,
            views: 24288095,
          },
          {
            date: "2022-09-20",
            value: 0,
            views: 24288095,
          },
          {
            date: "2022-09-21",
            value: 0,
            views: 24288095,
          },
          {
            date: "2022-09-22",
            value: 0,
            views: 24288095,
          },
          {
            date: "2022-09-23",
            value: 0,
            views: 24288095,
          },
          {
            date: "2022-09-24",
            value: 0,
            views: 24288095,
          },
          {
            date: "2022-09-25",
            value: 0,
            views: 24288095,
          },
          {
            date: "2022-09-26",
            value: 0,
            views: 24288095,
          },
          {
            date: "2022-09-27",
            value: 0,
            views: 24288095,
          },
          {
            date: "2022-09-28",
            value: 0,
            views: 24288095,
          },
          {
            date: "2022-09-29",
            value: 0,
            views: 24288095,
          },
          {
            date: "2022-09-30",
            value: 0,
            views: 24288096,
          },
          {
            date: "2022-10-01",
            value: 0,
            views: 24288096,
          },
          {
            date: "2022-10-02",
            value: 0,
            views: 24288096,
          },
          {
            date: "2022-10-03",
            value: 0,
            views: 24288096,
          },
          {
            date: "2022-10-04",
            value: 0,
            views: 24288097,
          },
          {
            date: "2022-10-05",
            value: 0,
            views: 24288097,
          },
          {
            date: "2022-10-06",
            value: 0,
            views: 24288097,
          },
          {
            date: "2022-10-07",
            value: 0,
            views: 24288097,
          },
          {
            date: "2022-10-08",
            value: 0,
            views: 24288097,
          },
          {
            date: "2022-10-09",
            value: 0,
            views: 24288097,
          },
          {
            date: "2022-10-10",
            value: 0,
            views: 24288098,
          },
          {
            date: "2022-10-11",
            value: 0,
            views: 24288101,
          },
          {
            date: "2022-10-12",
            value: 0,
            views: 24288102,
          },
          {
            date: "2022-10-13",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-10-14",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-10-15",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-10-16",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-10-17",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-10-18",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-10-19",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-10-20",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-10-21",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-10-22",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-10-23",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-10-24",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-10-25",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-10-26",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-10-27",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-10-28",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-10-29",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-10-30",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-10-31",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-11-01",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-11-02",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-11-03",
            value: 0,
            views: 24288103,
          },
          {
            date: "2022-11-04",
            value: 0,
            views: 24288104,
          },
          {
            date: "2022-11-05",
            value: 0,
            views: 24288104,
          },
          {
            date: "2022-11-06",
            value: 0,
            views: 24288104,
          },
          {
            date: "2022-11-07",
            value: 0,
            views: 24288104,
          },
          {
            date: "2022-11-08",
            value: 0,
            views: 24288104,
          },
          {
            date: "2022-11-09",
            value: 0,
            views: 24288107,
          },
          {
            date: "2022-11-15",
            value: 0,
            views: 24288108,
          },
          {
            date: "2022-11-16",
            value: 0,
            views: 24288109,
          },
          {
            date: "2022-11-19",
            value: 0,
            views: 24288110,
          },
          {
            date: "2022-11-20",
            value: 0,
            views: 24288110,
          },
          {
            date: "2022-11-21",
            value: 0,
            views: 24288110,
          },
          {
            date: "2022-11-22",
            value: 0,
            views: 24288114,
          },
          {
            date: "2022-11-23",
            value: 0,
            views: 24288114,
          },
          {
            date: "2022-11-25",
            value: 0,
            views: 24288114,
          },
          {
            date: "2022-11-26",
            value: 0,
            views: 24288114,
          },
          {
            date: "2022-11-27",
            value: 0,
            views: 24288114,
          },
          {
            date: "2022-11-28",
            value: 0,
            views: 24288114,
          },
          {
            date: "2022-11-29",
            value: 0,
            views: 24288114,
          },
          {
            date: "2022-11-30",
            value: 0,
            views: 24288115,
          },
          {
            date: "2022-12-01",
            value: 0,
            views: 24288116,
          },
          {
            date: "2022-12-02",
            value: 0,
            views: 24288116,
          },
          {
            date: "2022-12-03",
            value: 0,
            views: 24288118,
          },
          {
            date: "2022-12-04",
            value: 0,
            views: 24288118,
          },
          {
            date: "2022-12-05",
            value: 0,
            views: 24288119,
          },
          {
            date: "2022-12-06",
            value: 0,
            views: 24288119,
          },
          {
            date: "2022-12-08",
            value: 0,
            views: 24288121,
          },
          {
            date: "2022-12-09",
            value: 0,
            views: 24288121,
          },
          {
            date: "2022-12-10",
            value: 0,
            views: 24288121,
          },
          {
            date: "2022-12-11",
            value: 0,
            views: 24288121,
          },
          {
            date: "2022-12-12",
            value: 0,
            views: 24288121,
          },
          {
            date: "2022-12-13",
            value: 0,
            views: 24288121,
          },
          {
            date: "2022-12-14",
            value: 0,
            views: 24288121,
          },
          {
            date: "2022-12-15",
            value: 0,
            views: 24288123,
          },
          {
            date: "2022-12-16",
            value: 0,
            views: 24288123,
          },
          {
            date: "2022-12-17",
            value: 0,
            views: 24288123,
          },
          {
            date: "2022-12-18",
            value: 0,
            views: 24288123,
          },
          {
            date: "2022-12-19",
            value: 0,
            views: 24288124,
          },
          {
            date: "2022-12-20",
            value: 0,
            views: 24288125,
          },
          {
            date: "2022-12-21",
            value: 0,
            views: 24288126,
          },
          {
            date: "2022-12-22",
            value: 0,
            views: 24288126,
          },
          {
            date: "2022-12-23",
            value: 0,
            views: 24288126,
          },
          {
            date: "2022-12-25",
            value: 0,
            views: 24288128,
          },
          {
            date: "2022-12-26",
            value: 0,
            views: 24288129,
          },
          {
            date: "2022-12-27",
            value: 0,
            views: 24288129,
          },
          {
            date: "2022-12-28",
            value: 0,
            views: 24288130,
          },
          {
            date: "2022-12-29",
            value: 0,
            views: 24288130,
          },
          {
            date: "2022-12-30",
            value: 0,
            views: 24288130,
          },
          {
            date: "2022-12-31",
            value: 0,
            views: 24288130,
          },
          {
            date: "2023-01-01",
            value: 0,
            views: 24288130,
          },
          {
            date: "2023-01-02",
            value: 0,
            views: 24288130,
          },
          {
            date: "2023-01-03",
            value: 0,
            views: 24288131,
          },
          {
            date: "2023-01-04",
            value: 0,
            views: 24288132,
          },
          {
            date: "2023-01-05",
            value: 0,
            views: 24288132,
          },
          {
            date: "2023-01-06",
            value: 0,
            views: 24288133,
          },
          {
            date: "2023-01-07",
            value: 0,
            views: 24288134,
          },
          {
            date: "2023-01-08",
            value: 0,
            views: 24288134,
          },
          {
            date: "2023-01-09",
            value: 0,
            views: 24288134,
          },
          {
            date: "2023-01-10",
            value: 0,
            views: 24288134,
          },
          {
            date: "2023-01-11",
            value: 0,
            views: 24288134,
          },
          {
            date: "2023-01-12",
            value: 0,
            views: 24288134,
          },
          {
            date: "2023-01-13",
            value: 0,
            views: 24288134,
          },
          {
            date: "2023-01-14",
            value: 0,
            views: 24288135,
          },
          {
            date: "2023-01-15",
            value: 0,
            views: 24288135,
          },
          {
            date: "2023-01-16",
            value: 0,
            views: 24288136,
          },
          {
            date: "2023-01-17",
            value: 0,
            views: 24288137,
          },
          {
            date: "2023-01-18",
            value: 0,
            views: 24288138,
          },
          {
            date: "2023-01-19",
            value: 0,
            views: 24288138,
          },
          {
            date: "2023-01-20",
            value: 0,
            views: 24288138,
          },
          {
            date: "2023-01-21",
            value: 0,
            views: 24288139,
          },
          {
            date: "2023-01-22",
            value: 0,
            views: 24288139,
          },
          {
            date: "2023-01-23",
            value: 0,
            views: 24288140,
          },
          {
            date: "2023-01-24",
            value: 0,
            views: 24288140,
          },
          {
            date: "2023-01-25",
            value: 0,
            views: 24288141,
          },
          {
            date: "2023-01-26",
            value: 0,
            views: 24288143,
          },
          {
            date: "2023-01-27",
            value: 0,
            views: 24288147,
          },
          {
            date: "2023-01-29",
            value: 0,
            views: 24288152,
          },
          {
            date: "2023-01-30",
            value: 0,
            views: 24288152,
          },
          {
            date: "2023-01-31",
            value: 0,
            views: 24288152,
          },
          {
            date: "2023-02-01",
            value: 0,
            views: 24288152,
          },
          {
            date: "2023-02-02",
            value: 0,
            views: 24288152,
          },
          {
            date: "2023-02-03",
            value: 0,
            views: 24288152,
          },
          {
            date: "2023-02-04",
            value: 0,
            views: 24288152,
          },
          {
            date: "2023-02-05",
            value: 0,
            views: 24288152,
          },
          {
            date: "2023-02-06",
            value: 0,
            views: 24288152,
          },
          {
            date: "2023-02-08",
            value: 0,
            views: 24288152,
          },
          {
            date: "2023-02-09",
            value: 0,
            views: 24288152,
          },
          {
            date: "2023-02-10",
            value: 0,
            views: 24288152,
          },
          {
            date: "2023-02-12",
            value: 0,
            views: 24288152,
          },
          {
            date: "2023-02-14",
            value: 0,
            views: 24288153,
          },
          {
            date: "2023-02-16",
            value: 0,
            views: 24288153,
          },
          {
            date: "2023-02-18",
            value: 0,
            views: 24288153,
          },
          {
            date: "2023-02-20",
            value: 0,
            views: 24288153,
          },
          {
            date: "2023-02-22",
            value: 0,
            views: 24288154,
          },
          {
            date: "2023-02-24",
            value: 0,
            views: 24288154,
          },
          {
            date: "2023-02-26",
            value: 0,
            views: 24288154,
          },
          {
            date: "2023-02-28",
            value: 0,
            views: 24288154,
          },
          {
            date: "2023-03-02",
            value: 0,
            views: 24288159,
          },
          {
            date: "2023-03-07",
            value: 0,
            views: 24288163,
          },
          {
            date: "2023-03-09",
            value: 0,
            views: 24288164,
          },
          {
            date: "2023-03-15",
            value: 0,
            views: 24288164,
          },
          {
            date: "2023-03-17",
            value: 0,
            views: 24288165,
          },
          {
            date: "2023-03-19",
            value: 0,
            views: 24288166,
          },
          {
            date: "2023-03-21",
            value: 0,
            views: 24288166,
          },
          {
            date: "2023-03-23",
            value: 0,
            views: 24288167,
          },
          {
            date: "2023-03-25",
            value: 0,
            views: 24288167,
          },
          {
            date: "2023-03-28",
            value: 0,
            views: 24288168,
          },
          {
            date: "2023-03-31",
            value: 0,
            views: 24288169,
          },
          {
            date: "2023-04-01",
            value: 0,
            views: 24288169,
          },
          {
            date: "2023-04-03",
            value: 0,
            views: 24288172,
          },
          {
            date: "2023-04-05",
            value: 0,
            views: 24288172,
          },
          {
            date: "2023-04-07",
            value: 0,
            views: 24288172,
          },
          {
            date: "2023-04-09",
            value: 0,
            views: 24288172,
          },
          {
            date: "2023-04-11",
            value: 0,
            views: 24288172,
          },
          {
            date: "2023-04-13",
            value: 0,
            views: 24288173,
          },
          {
            date: "2023-04-15",
            value: 0,
            views: 24288173,
          },
          {
            date: "2023-04-17",
            value: 0,
            views: 24288174,
          },
          {
            date: "2023-04-19",
            value: 0,
            views: 24288175,
          },
          {
            date: "2023-04-21",
            value: 0,
            views: 24288175,
          },
          {
            date: "2023-04-23",
            value: 0,
            views: 24288175,
          },
          {
            date: "2023-05-06",
            value: 0,
            views: 24288180,
          },
          {
            date: "2023-05-12",
            value: 0,
            views: 24288183,
          },
          {
            date: "2023-05-26",
            value: 0,
            views: 24288191,
          },
          {
            date: "2023-05-31",
            value: 0,
            views: 24288192,
          },
          {
            date: "2023-06-04",
            value: 0,
            views: 24288194,
          },
          {
            date: "2023-06-05",
            value: 0,
            views: 24288194,
          },
          {
            date: "2023-06-13",
            value: 0,
            views: 24288202,
          },
          {
            date: "2023-07-29",
            value: 0,
            views: 24288241,
          },
          {
            date: "2023-08-02",
            value: 0,
            views: 24288241,
          },
          {
            date: "2023-08-05",
            value: 0,
            views: 24288244,
          },
          {
            date: "2023-08-07",
            value: 0,
            views: 24288246,
          },
          {
            date: "2023-08-09",
            value: 0,
            views: 24288247,
          },
          {
            date: "2023-08-12",
            value: 0,
            views: 24288248,
          },
          {
            date: "2023-08-16",
            value: 0,
            views: 24288249,
          },
          {
            date: "2023-08-18",
            value: 0,
            views: 24288250,
          },
          {
            date: "2023-08-22",
            value: 0,
            views: 24288251,
          },
          {
            date: "2023-08-23",
            value: 0,
            views: 24288253,
          },
          {
            date: "2023-08-28",
            value: 0,
            views: 24288254,
          },
          {
            date: "2023-08-30",
            value: 0,
            views: 24288254,
          },
          {
            date: "2023-09-05",
            value: 0,
            views: 24288255,
          },
          {
            date: "2023-09-06",
            value: 0,
            views: 24288255,
          },
          {
            date: "2023-09-10",
            value: 0,
            views: 24288255,
          },
          {
            date: "2023-09-13",
            value: 0,
            views: 24288257,
          },
          {
            date: "2023-09-15",
            value: 0,
            views: 24288257,
          },
          {
            date: "2023-09-18",
            value: 0,
            views: 24288258,
          },
          {
            date: "2023-09-20",
            value: 0,
            views: 24288259,
          },
          {
            date: "2023-09-22",
            value: 0,
            views: 24288259,
          },
          {
            date: "2023-09-25",
            value: 0,
            views: 24288259,
          },
          {
            date: "2023-09-27",
            value: 0,
            views: 24288262,
          },
          {
            date: "2023-09-29",
            value: 0,
            views: 24288262,
          },
          {
            date: "2023-10-02",
            value: 0,
            views: 24288264,
          },
          {
            date: "2023-10-04",
            value: 0,
            views: 24288264,
          },
          {
            date: "2023-10-06",
            value: 0,
            views: 24288264,
          },
          {
            date: "2023-10-09",
            value: 0,
            views: 24288264,
          },
          {
            date: "2023-10-11",
            value: 0,
            views: 24288265,
          },
          {
            date: "2023-10-11",
            value: 0,
            views: 24288265,
          },
          {
            date: "2023-10-13",
            value: 0,
            views: 24288266,
          },
          {
            date: "2023-10-17",
            value: 0,
            views: 24288267,
          },
          {
            date: "2023-10-18",
            value: 0,
            views: 24288267,
          },
          {
            date: "2023-10-20",
            value: 0,
            views: 24288267,
          },
          {
            date: "2023-10-24",
            value: 0,
            views: 24288268,
          },
          {
            date: "2023-10-25",
            value: 0,
            views: 24288273,
          },
          {
            date: "2023-10-26",
            value: 0,
            views: 24288273,
          },
          {
            date: "2023-10-27",
            value: 0,
            views: 24288273,
          },
          {
            date: "2023-10-30",
            value: 0,
            views: 24288275,
          },
          {
            date: "2023-11-01",
            value: 0,
            views: 24288275,
          },
          {
            date: "2023-11-06",
            value: 0,
            views: 24288276,
          },
          {
            date: "2023-11-08",
            value: 0,
            views: 24288276,
          },
        ],
      },
      {
        videoId: "chKFB7eSUgA",
        dataPoints: [
          {
            date: "2020-06-08",
            value: 0,
            views: 0,
          },
          {
            date: "2021-11-23",
            value: 0,
            views: 22518059,
          },
          {
            date: "2021-11-24",
            value: 0,
            views: 22518059,
          },
          {
            date: "2021-11-25",
            value: 0,
            views: 22518059,
          },
          {
            date: "2021-11-26",
            value: 0,
            views: 22518059,
          },
          {
            date: "2021-11-27",
            value: 0,
            views: 22518059,
          },
          {
            date: "2021-11-28",
            value: 0,
            views: 22518059,
          },
          {
            date: "2021-11-29",
            value: 0,
            views: 22518059,
          },
          {
            date: "2021-11-30",
            value: 0,
            views: 22518059,
          },
          {
            date: "2021-12-01",
            value: 0,
            views: 22518059,
          },
          {
            date: "2021-12-02",
            value: 0,
            views: 22518059,
          },
          {
            date: "2021-12-03",
            value: 0,
            views: 22518059,
          },
          {
            date: "2021-12-04",
            value: 0,
            views: 22518059,
          },
          {
            date: "2021-12-05",
            value: 0,
            views: 22518059,
          },
          {
            date: "2021-12-06",
            value: 0,
            views: 22518059,
          },
          {
            date: "2021-12-07",
            value: 0,
            views: 22518059,
          },
          {
            date: "2021-12-09",
            value: 0,
            views: 22518059,
          },
          {
            date: "2021-12-10",
            value: 0,
            views: 22518059,
          },
          {
            date: "2021-12-12",
            value: 0,
            views: 22518060,
          },
          {
            date: "2021-12-13",
            value: 0,
            views: 22518060,
          },
          {
            date: "2021-12-14",
            value: 0,
            views: 22518060,
          },
          {
            date: "2021-12-15",
            value: 0,
            views: 22518060,
          },
          {
            date: "2021-12-16",
            value: 0,
            views: 22518060,
          },
          {
            date: "2021-12-17",
            value: 0,
            views: 22518061,
          },
          {
            date: "2021-12-18",
            value: 0,
            views: 22518061,
          },
          {
            date: "2021-12-19",
            value: 0,
            views: 22518061,
          },
          {
            date: "2021-12-20",
            value: 0,
            views: 22518061,
          },
          {
            date: "2021-12-21",
            value: 0,
            views: 22518061,
          },
          {
            date: "2021-12-22",
            value: 0,
            views: 22518061,
          },
          {
            date: "2021-12-23",
            value: 0,
            views: 22518061,
          },
          {
            date: "2021-12-24",
            value: 0,
            views: 22518061,
          },
          {
            date: "2021-12-25",
            value: 0,
            views: 22518061,
          },
          {
            date: "2021-12-26",
            value: 0,
            views: 22518061,
          },
          {
            date: "2021-12-27",
            value: 0,
            views: 22518061,
          },
          {
            date: "2021-12-28",
            value: 0,
            views: 22518061,
          },
          {
            date: "2021-12-29",
            value: 0,
            views: 22518061,
          },
          {
            date: "2021-12-30",
            value: 0,
            views: 22518062,
          },
          {
            date: "2021-12-31",
            value: 0,
            views: 22518062,
          },
          {
            date: "2022-01-01",
            value: 0,
            views: 22518062,
          },
          {
            date: "2022-01-02",
            value: 0,
            views: 22518062,
          },
          {
            date: "2022-01-03",
            value: 0,
            views: 22518062,
          },
          {
            date: "2022-01-04",
            value: 0,
            views: 22518062,
          },
          {
            date: "2022-01-05",
            value: 0,
            views: 22518062,
          },
          {
            date: "2022-01-06",
            value: 0,
            views: 22518062,
          },
          {
            date: "2022-01-07",
            value: 0,
            views: 22518062,
          },
          {
            date: "2022-01-08",
            value: 0,
            views: 22518062,
          },
          {
            date: "2022-01-09",
            value: 0,
            views: 22518062,
          },
          {
            date: "2022-01-10",
            value: 0,
            views: 22518062,
          },
          {
            date: "2022-01-11",
            value: 0,
            views: 22518062,
          },
          {
            date: "2022-01-12",
            value: 0,
            views: 22518062,
          },
          {
            date: "2022-01-13",
            value: 0,
            views: 22518063,
          },
          {
            date: "2022-01-15",
            value: 0,
            views: 22518063,
          },
          {
            date: "2022-01-16",
            value: 0,
            views: 22518063,
          },
          {
            date: "2022-01-17",
            value: 0,
            views: 22518063,
          },
          {
            date: "2022-01-23",
            value: 0,
            views: 22518063,
          },
          {
            date: "2022-01-25",
            value: 0,
            views: 22518063,
          },
          {
            date: "2022-01-27",
            value: 0,
            views: 22518063,
          },
          {
            date: "2022-01-30",
            value: 0,
            views: 22518063,
          },
          {
            date: "2022-01-31",
            value: 0,
            views: 22518063,
          },
          {
            date: "2022-02-01",
            value: 0,
            views: 22518063,
          },
          {
            date: "2022-02-02",
            value: 0,
            views: 22518063,
          },
          {
            date: "2022-02-04",
            value: 0,
            views: 22518064,
          },
          {
            date: "2022-02-07",
            value: 0,
            views: 22518064,
          },
          {
            date: "2022-02-09",
            value: 0,
            views: 22518065,
          },
          {
            date: "2022-02-11",
            value: 0,
            views: 22518065,
          },
          {
            date: "2022-02-13",
            value: 0,
            views: 22518065,
          },
          {
            date: "2022-02-15",
            value: 0,
            views: 22518065,
          },
          {
            date: "2022-02-21",
            value: 0,
            views: 22518066,
          },
          {
            date: "2022-03-07",
            value: 0,
            views: 22518066,
          },
          {
            date: "2022-03-13",
            value: 0,
            views: 22518066,
          },
          {
            date: "2022-03-18",
            value: 0,
            views: 22518067,
          },
          {
            date: "2022-04-29",
            value: 0,
            views: 22518078,
          },
          {
            date: "2022-05-02",
            value: 0,
            views: 22518078,
          },
          {
            date: "2022-05-06",
            value: 0,
            views: 22518078,
          },
          {
            date: "2022-05-11",
            value: 0,
            views: 22518078,
          },
          {
            date: "2022-05-13",
            value: 0,
            views: 22518078,
          },
          {
            date: "2022-05-17",
            value: 0,
            views: 22518078,
          },
          {
            date: "2022-05-19",
            value: 0,
            views: 22518078,
          },
          {
            date: "2022-05-20",
            value: 0,
            views: 22518078,
          },
          {
            date: "2022-05-21",
            value: 0,
            views: 22518078,
          },
          {
            date: "2022-05-22",
            value: 0,
            views: 22518078,
          },
          {
            date: "2022-05-23",
            value: 0,
            views: 22518078,
          },
          {
            date: "2022-05-24",
            value: 0,
            views: 22518078,
          },
          {
            date: "2022-05-25",
            value: 0,
            views: 22518078,
          },
          {
            date: "2022-05-26",
            value: 0,
            views: 22518078,
          },
          {
            date: "2022-05-27",
            value: 0,
            views: 22518078,
          },
          {
            date: "2022-05-28",
            value: 0,
            views: 22518080,
          },
          {
            date: "2022-05-29",
            value: 0,
            views: 22518080,
          },
          {
            date: "2022-05-30",
            value: 0,
            views: 22518080,
          },
          {
            date: "2022-05-31",
            value: 0,
            views: 22518080,
          },
          {
            date: "2022-06-01",
            value: 0,
            views: 22518081,
          },
          {
            date: "2022-06-02",
            value: 0,
            views: 22518083,
          },
          {
            date: "2022-06-03",
            value: 0,
            views: 22518083,
          },
          {
            date: "2022-06-04",
            value: 0,
            views: 22518083,
          },
          {
            date: "2022-06-05",
            value: 0,
            views: 22518083,
          },
          {
            date: "2022-06-06",
            value: 0,
            views: 22518083,
          },
          {
            date: "2022-06-07",
            value: 0,
            views: 22518083,
          },
          {
            date: "2022-06-08",
            value: 0,
            views: 22518083,
          },
          {
            date: "2022-06-09",
            value: 0,
            views: 22518083,
          },
          {
            date: "2022-06-10",
            value: 0,
            views: 22518083,
          },
          {
            date: "2022-06-11",
            value: 0,
            views: 22518083,
          },
          {
            date: "2022-06-12",
            value: 0,
            views: 22518083,
          },
          {
            date: "2022-06-13",
            value: 0,
            views: 22518083,
          },
          {
            date: "2022-06-14",
            value: 0,
            views: 22518083,
          },
          {
            date: "2022-06-15",
            value: 0,
            views: 22518083,
          },
          {
            date: "2022-06-16",
            value: 0,
            views: 22518083,
          },
          {
            date: "2022-06-17",
            value: 0,
            views: 22518083,
          },
          {
            date: "2022-06-18",
            value: 0,
            views: 22518084,
          },
          {
            date: "2022-06-19",
            value: 0,
            views: 22518084,
          },
          {
            date: "2022-06-20",
            value: 0,
            views: 22518084,
          },
          {
            date: "2022-06-21",
            value: 0,
            views: 22518084,
          },
          {
            date: "2022-06-22",
            value: 0,
            views: 22518084,
          },
          {
            date: "2022-06-23",
            value: 0,
            views: 22518084,
          },
          {
            date: "2022-06-24",
            value: 0,
            views: 22518084,
          },
          {
            date: "2022-06-25",
            value: 0,
            views: 22518084,
          },
          {
            date: "2022-06-26",
            value: 0,
            views: 22518084,
          },
          {
            date: "2022-06-27",
            value: 0,
            views: 22518084,
          },
          {
            date: "2022-06-28",
            value: 0,
            views: 22518084,
          },
          {
            date: "2022-06-29",
            value: 0,
            views: 22518084,
          },
          {
            date: "2022-06-30",
            value: 0,
            views: 22518085,
          },
          {
            date: "2022-07-01",
            value: 0,
            views: 22518085,
          },
          {
            date: "2022-07-02",
            value: 0,
            views: 22518085,
          },
          {
            date: "2022-07-03",
            value: 0,
            views: 22518085,
          },
          {
            date: "2022-07-04",
            value: 0,
            views: 22518086,
          },
          {
            date: "2022-07-05",
            value: 0,
            views: 22518086,
          },
          {
            date: "2022-07-06",
            value: 0,
            views: 22518086,
          },
          {
            date: "2022-07-07",
            value: 0,
            views: 22518086,
          },
          {
            date: "2022-07-08",
            value: 0,
            views: 22518086,
          },
          {
            date: "2022-07-09",
            value: 0,
            views: 22518087,
          },
          {
            date: "2022-07-10",
            value: 0,
            views: 22518087,
          },
          {
            date: "2022-07-11",
            value: 0,
            views: 22518087,
          },
          {
            date: "2022-07-12",
            value: 0,
            views: 22518087,
          },
          {
            date: "2022-07-13",
            value: 0,
            views: 22518093,
          },
          {
            date: "2022-07-14",
            value: 0,
            views: 22518093,
          },
          {
            date: "2022-07-15",
            value: 0,
            views: 22518093,
          },
          {
            date: "2022-07-16",
            value: 0,
            views: 22518093,
          },
          {
            date: "2022-07-17",
            value: 0,
            views: 22518093,
          },
          {
            date: "2022-07-18",
            value: 0,
            views: 22518093,
          },
          {
            date: "2022-07-19",
            value: 0,
            views: 22518093,
          },
          {
            date: "2022-07-20",
            value: 0,
            views: 22518093,
          },
          {
            date: "2022-07-21",
            value: 0,
            views: 22518093,
          },
          {
            date: "2022-07-22",
            value: 0,
            views: 22518097,
          },
          {
            date: "2022-07-23",
            value: 0,
            views: 22518097,
          },
          {
            date: "2022-07-24",
            value: 0,
            views: 22518097,
          },
          {
            date: "2022-07-25",
            value: 0,
            views: 22518098,
          },
          {
            date: "2022-07-26",
            value: 0,
            views: 22518098,
          },
          {
            date: "2022-07-27",
            value: 0,
            views: 22518098,
          },
          {
            date: "2022-07-28",
            value: 0,
            views: 22518098,
          },
          {
            date: "2022-07-29",
            value: 0,
            views: 22518098,
          },
          {
            date: "2022-07-30",
            value: 0,
            views: 22518098,
          },
          {
            date: "2022-07-31",
            value: 0,
            views: 22518098,
          },
          {
            date: "2022-08-01",
            value: 0,
            views: 22518098,
          },
          {
            date: "2022-08-02",
            value: 0,
            views: 22518098,
          },
          {
            date: "2022-08-03",
            value: 0,
            views: 22518098,
          },
          {
            date: "2022-08-04",
            value: 0,
            views: 22518098,
          },
          {
            date: "2022-08-05",
            value: 0,
            views: 22518098,
          },
          {
            date: "2022-08-06",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-07",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-08",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-09",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-10",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-11",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-12",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-13",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-14",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-15",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-16",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-17",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-18",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-19",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-20",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-21",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-22",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-23",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-24",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-25",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-26",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-27",
            value: 0,
            views: 22518099,
          },
          {
            date: "2022-08-28",
            value: 0,
            views: 22518101,
          },
          {
            date: "2022-08-29",
            value: 0,
            views: 22518101,
          },
          {
            date: "2022-08-30",
            value: 0,
            views: 22518101,
          },
          {
            date: "2022-08-31",
            value: 0,
            views: 22518101,
          },
          {
            date: "2022-09-01",
            value: 0,
            views: 22518101,
          },
          {
            date: "2022-09-02",
            value: 0,
            views: 22518102,
          },
          {
            date: "2022-09-03",
            value: 0,
            views: 22518102,
          },
          {
            date: "2022-09-04",
            value: 0,
            views: 22518102,
          },
          {
            date: "2022-09-05",
            value: 0,
            views: 22518102,
          },
          {
            date: "2022-09-06",
            value: 0,
            views: 22518103,
          },
          {
            date: "2022-09-07",
            value: 0,
            views: 22518103,
          },
          {
            date: "2022-09-08",
            value: 0,
            views: 22518103,
          },
          {
            date: "2022-09-09",
            value: 0,
            views: 22518103,
          },
          {
            date: "2022-09-10",
            value: 0,
            views: 22518103,
          },
          {
            date: "2022-09-11",
            value: 0,
            views: 22518103,
          },
          {
            date: "2022-09-12",
            value: 0,
            views: 22518104,
          },
          {
            date: "2022-09-13",
            value: 0,
            views: 22518104,
          },
          {
            date: "2022-09-14",
            value: 0,
            views: 22518104,
          },
          {
            date: "2022-09-15",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-09-16",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-09-17",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-09-18",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-09-19",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-09-20",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-09-21",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-09-22",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-09-23",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-09-24",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-09-25",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-09-26",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-09-27",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-09-28",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-09-29",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-09-30",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-10-01",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-10-02",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-10-03",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-10-04",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-10-05",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-10-06",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-10-07",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-10-08",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-10-09",
            value: 0,
            views: 22518107,
          },
          {
            date: "2022-10-10",
            value: 0,
            views: 22518109,
          },
          {
            date: "2022-10-11",
            value: 0,
            views: 22518111,
          },
          {
            date: "2022-10-12",
            value: 0,
            views: 22518111,
          },
          {
            date: "2022-10-13",
            value: 0,
            views: 22518111,
          },
          {
            date: "2022-10-14",
            value: 0,
            views: 22518111,
          },
          {
            date: "2022-10-15",
            value: 0,
            views: 22518111,
          },
          {
            date: "2022-10-16",
            value: 0,
            views: 22518111,
          },
          {
            date: "2022-10-17",
            value: 0,
            views: 22518111,
          },
          {
            date: "2022-10-18",
            value: 0,
            views: 22518111,
          },
          {
            date: "2022-10-19",
            value: 0,
            views: 22518111,
          },
          {
            date: "2022-10-20",
            value: 0,
            views: 22518111,
          },
          {
            date: "2022-10-21",
            value: 0,
            views: 22518111,
          },
          {
            date: "2022-10-22",
            value: 0,
            views: 22518111,
          },
          {
            date: "2022-10-23",
            value: 0,
            views: 22518111,
          },
          {
            date: "2022-10-24",
            value: 0,
            views: 22518111,
          },
          {
            date: "2022-10-25",
            value: 0,
            views: 22518111,
          },
          {
            date: "2022-10-26",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-10-27",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-10-28",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-10-29",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-10-30",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-10-31",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-11-01",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-11-02",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-11-03",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-11-04",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-11-05",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-11-06",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-11-07",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-11-08",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-11-09",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-11-15",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-11-16",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-11-19",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-11-20",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-11-21",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-11-22",
            value: 0,
            views: 22518112,
          },
          {
            date: "2022-11-23",
            value: 0,
            views: 22518114,
          },
          {
            date: "2022-11-25",
            value: 0,
            views: 22518114,
          },
          {
            date: "2022-11-26",
            value: 0,
            views: 22518114,
          },
          {
            date: "2022-11-27",
            value: 0,
            views: 22518114,
          },
          {
            date: "2022-11-28",
            value: 0,
            views: 22518114,
          },
          {
            date: "2022-11-29",
            value: 0,
            views: 22518114,
          },
          {
            date: "2022-11-30",
            value: 0,
            views: 22518114,
          },
          {
            date: "2022-12-01",
            value: 0,
            views: 22518114,
          },
          {
            date: "2022-12-02",
            value: 0,
            views: 22518114,
          },
          {
            date: "2022-12-03",
            value: 0,
            views: 22518114,
          },
          {
            date: "2022-12-04",
            value: 0,
            views: 22518114,
          },
          {
            date: "2022-12-05",
            value: 0,
            views: 22518114,
          },
          {
            date: "2022-12-06",
            value: 0,
            views: 22518114,
          },
          {
            date: "2022-12-08",
            value: 0,
            views: 22518115,
          },
          {
            date: "2022-12-09",
            value: 0,
            views: 22518115,
          },
          {
            date: "2022-12-10",
            value: 0,
            views: 22518115,
          },
          {
            date: "2022-12-11",
            value: 0,
            views: 22518115,
          },
          {
            date: "2022-12-12",
            value: 0,
            views: 22518115,
          },
          {
            date: "2022-12-13",
            value: 0,
            views: 22518115,
          },
          {
            date: "2022-12-14",
            value: 0,
            views: 22518115,
          },
          {
            date: "2022-12-15",
            value: 0,
            views: 22518115,
          },
          {
            date: "2022-12-16",
            value: 0,
            views: 22518115,
          },
          {
            date: "2022-12-17",
            value: 0,
            views: 22518115,
          },
          {
            date: "2022-12-18",
            value: 0,
            views: 22518115,
          },
          {
            date: "2022-12-19",
            value: 0,
            views: 22518116,
          },
          {
            date: "2022-12-20",
            value: 0,
            views: 22518116,
          },
          {
            date: "2022-12-21",
            value: 0,
            views: 22518116,
          },
          {
            date: "2022-12-22",
            value: 0,
            views: 22518116,
          },
          {
            date: "2022-12-23",
            value: 0,
            views: 22518116,
          },
          {
            date: "2022-12-25",
            value: 0,
            views: 22518116,
          },
          {
            date: "2022-12-26",
            value: 0,
            views: 22518116,
          },
          {
            date: "2022-12-27",
            value: 0,
            views: 22518116,
          },
          {
            date: "2022-12-28",
            value: 0,
            views: 22518116,
          },
          {
            date: "2022-12-29",
            value: 0,
            views: 22518116,
          },
          {
            date: "2022-12-30",
            value: 0,
            views: 22518116,
          },
          {
            date: "2022-12-31",
            value: 0,
            views: 22518116,
          },
          {
            date: "2023-01-01",
            value: 0,
            views: 22518116,
          },
          {
            date: "2023-01-02",
            value: 0,
            views: 22518116,
          },
          {
            date: "2023-01-03",
            value: 0,
            views: 22518116,
          },
          {
            date: "2023-01-04",
            value: 0,
            views: 22518117,
          },
          {
            date: "2023-01-05",
            value: 0,
            views: 22518117,
          },
          {
            date: "2023-01-06",
            value: 0,
            views: 22518117,
          },
          {
            date: "2023-01-07",
            value: 0,
            views: 22518117,
          },
          {
            date: "2023-01-08",
            value: 0,
            views: 22518117,
          },
          {
            date: "2023-01-09",
            value: 0,
            views: 22518117,
          },
          {
            date: "2023-01-10",
            value: 0,
            views: 22518119,
          },
          {
            date: "2023-01-11",
            value: 0,
            views: 22518120,
          },
          {
            date: "2023-01-12",
            value: 0,
            views: 22518120,
          },
          {
            date: "2023-01-13",
            value: 0,
            views: 22518120,
          },
          {
            date: "2023-01-14",
            value: 0,
            views: 22518120,
          },
          {
            date: "2023-01-15",
            value: 0,
            views: 22518120,
          },
          {
            date: "2023-01-16",
            value: 0,
            views: 22518121,
          },
          {
            date: "2023-01-17",
            value: 0,
            views: 22518121,
          },
          {
            date: "2023-01-18",
            value: 0,
            views: 22518121,
          },
          {
            date: "2023-01-19",
            value: 0,
            views: 22518121,
          },
          {
            date: "2023-01-20",
            value: 0,
            views: 22518122,
          },
          {
            date: "2023-01-21",
            value: 0,
            views: 22518122,
          },
          {
            date: "2023-01-22",
            value: 0,
            views: 22518122,
          },
          {
            date: "2023-01-23",
            value: 0,
            views: 22518122,
          },
          {
            date: "2023-01-24",
            value: 0,
            views: 22518122,
          },
          {
            date: "2023-01-25",
            value: 0,
            views: 22518123,
          },
          {
            date: "2023-01-26",
            value: 0,
            views: 22518123,
          },
          {
            date: "2023-01-27",
            value: 0,
            views: 22518123,
          },
          {
            date: "2023-01-29",
            value: 0,
            views: 22518123,
          },
          {
            date: "2023-01-30",
            value: 0,
            views: 22518123,
          },
          {
            date: "2023-01-31",
            value: 0,
            views: 22518123,
          },
          {
            date: "2023-02-01",
            value: 0,
            views: 22518123,
          },
          {
            date: "2023-02-02",
            value: 0,
            views: 22518123,
          },
          {
            date: "2023-02-03",
            value: 0,
            views: 22518123,
          },
          {
            date: "2023-02-04",
            value: 0,
            views: 22518123,
          },
          {
            date: "2023-02-05",
            value: 0,
            views: 22518123,
          },
          {
            date: "2023-02-06",
            value: 0,
            views: 22518124,
          },
          {
            date: "2023-02-08",
            value: 0,
            views: 22518124,
          },
          {
            date: "2023-02-09",
            value: 0,
            views: 22518124,
          },
          {
            date: "2023-02-10",
            value: 0,
            views: 22518124,
          },
          {
            date: "2023-02-12",
            value: 0,
            views: 22518124,
          },
          {
            date: "2023-02-14",
            value: 0,
            views: 22518124,
          },
          {
            date: "2023-02-16",
            value: 0,
            views: 22518124,
          },
          {
            date: "2023-02-18",
            value: 0,
            views: 22518124,
          },
          {
            date: "2023-02-20",
            value: 0,
            views: 22518124,
          },
          {
            date: "2023-02-22",
            value: 0,
            views: 22518124,
          },
          {
            date: "2023-02-24",
            value: 0,
            views: 22518124,
          },
          {
            date: "2023-02-26",
            value: 0,
            views: 22518124,
          },
          {
            date: "2023-02-28",
            value: 0,
            views: 22518124,
          },
          {
            date: "2023-03-02",
            value: 0,
            views: 22518124,
          },
          {
            date: "2023-03-07",
            value: 0,
            views: 22518125,
          },
          {
            date: "2023-03-09",
            value: 0,
            views: 22518125,
          },
          {
            date: "2023-03-15",
            value: 0,
            views: 22518126,
          },
          {
            date: "2023-03-17",
            value: 0,
            views: 22518126,
          },
          {
            date: "2023-03-19",
            value: 0,
            views: 22518126,
          },
          {
            date: "2023-03-21",
            value: 0,
            views: 22518126,
          },
          {
            date: "2023-03-23",
            value: 0,
            views: 22518126,
          },
          {
            date: "2023-03-25",
            value: 0,
            views: 22518126,
          },
          {
            date: "2023-03-28",
            value: 0,
            views: 22518126,
          },
          {
            date: "2023-03-30",
            value: 0,
            views: 22518126,
          },
          {
            date: "2023-03-31",
            value: 0,
            views: 22518126,
          },
          {
            date: "2023-04-01",
            value: 0,
            views: 22518126,
          },
          {
            date: "2023-04-03",
            value: 0,
            views: 22518126,
          },
          {
            date: "2023-04-05",
            value: 0,
            views: 22518126,
          },
          {
            date: "2023-04-07",
            value: 0,
            views: 22518127,
          },
          {
            date: "2023-04-09",
            value: 0,
            views: 22518127,
          },
          {
            date: "2023-04-11",
            value: 0,
            views: 22518127,
          },
          {
            date: "2023-04-13",
            value: 0,
            views: 22518127,
          },
          {
            date: "2023-04-15",
            value: 0,
            views: 22518127,
          },
          {
            date: "2023-04-17",
            value: 0,
            views: 22518127,
          },
          {
            date: "2023-04-19",
            value: 0,
            views: 22518128,
          },
          {
            date: "2023-04-21",
            value: 0,
            views: 22518128,
          },
          {
            date: "2023-04-23",
            value: 0,
            views: 22518128,
          },
          {
            date: "2023-05-06",
            value: 0,
            views: 22518129,
          },
          {
            date: "2023-05-12",
            value: 0,
            views: 22518129,
          },
          {
            date: "2023-05-30",
            value: 0,
            views: 22518130,
          },
          {
            date: "2023-06-04",
            value: 0,
            views: 22518130,
          },
          {
            date: "2023-06-05",
            value: 0,
            views: 22518130,
          },
          {
            date: "2023-06-12",
            value: 0,
            views: 22518132,
          },
          {
            date: "2023-07-29",
            value: 0,
            views: 22518136,
          },
          {
            date: "2023-08-01",
            value: 0,
            views: 22518136,
          },
          {
            date: "2023-08-05",
            value: 0,
            views: 22518136,
          },
          {
            date: "2023-08-07",
            value: 0,
            views: 22518138,
          },
          {
            date: "2023-08-09",
            value: 0,
            views: 22518139,
          },
          {
            date: "2023-08-11",
            value: 0,
            views: 22518140,
          },
          {
            date: "2023-08-16",
            value: 0,
            views: 22518141,
          },
          {
            date: "2023-08-18",
            value: 0,
            views: 22518143,
          },
          {
            date: "2023-08-22",
            value: 0,
            views: 22518144,
          },
          {
            date: "2023-08-23",
            value: 0,
            views: 22518145,
          },
          {
            date: "2023-08-25",
            value: 0,
            views: 22518145,
          },
          {
            date: "2023-08-28",
            value: 0,
            views: 22518145,
          },
          {
            date: "2023-08-30",
            value: 0,
            views: 22518145,
          },
          {
            date: "2023-09-04",
            value: 0,
            views: 22518149,
          },
          {
            date: "2023-09-06",
            value: 0,
            views: 22518149,
          },
          {
            date: "2023-09-10",
            value: 0,
            views: 22518149,
          },
          {
            date: "2023-09-13",
            value: 0,
            views: 22518150,
          },
          {
            date: "2023-09-15",
            value: 0,
            views: 22518152,
          },
          {
            date: "2023-09-18",
            value: 0,
            views: 22518152,
          },
          {
            date: "2023-09-20",
            value: 0,
            views: 22518152,
          },
          {
            date: "2023-09-22",
            value: 0,
            views: 22518153,
          },
          {
            date: "2023-09-25",
            value: 0,
            views: 22518153,
          },
          {
            date: "2023-09-27",
            value: 0,
            views: 22518153,
          },
          {
            date: "2023-09-29",
            value: 0,
            views: 22518153,
          },
          {
            date: "2023-10-02",
            value: 0,
            views: 22518153,
          },
          {
            date: "2023-10-06",
            value: 0,
            views: 22518153,
          },
          {
            date: "2023-10-09",
            value: 0,
            views: 22518153,
          },
          {
            date: "2023-10-11",
            value: 0,
            views: 22518153,
          },
          {
            date: "2023-10-13",
            value: 0,
            views: 22518153,
          },
          {
            date: "2023-10-16",
            value: 0,
            views: 22518153,
          },
          {
            date: "2023-10-18",
            value: 0,
            views: 22518155,
          },
          {
            date: "2023-10-20",
            value: 0,
            views: 22518155,
          },
          {
            date: "2023-10-23",
            value: 0,
            views: 22518156,
          },
          {
            date: "2023-10-25",
            value: 0,
            views: 22518156,
          },
          {
            date: "2023-10-27",
            value: 0,
            views: 22518156,
          },
          {
            date: "2023-10-30",
            value: 0,
            views: 22518157,
          },
          {
            date: "2023-11-01",
            value: 0,
            views: 22518157,
          },
          {
            date: "2023-11-03",
            value: 0,
            views: 22518157,
          },
          {
            date: "2023-11-06",
            value: 0,
            views: 22518157,
          },
          {
            date: "2023-11-08",
            value: 0,
            views: 22518158,
          },
        ],
      },
    ],
  },
  errorMessage: "",
};

export const brandCompaniesDummyData = {
  data: {
    results: [
      {
        companyId: 1244975,
        legalName: "Test company 1, LLC",
        countryId: 236,
        categoryId: 237,
        totalSpend: 45440143.0,
        headquarters: "",
        numberOfEmployees: "",
        revenue: "",
        swipeBoardIds: [],
        yearFounded: "2010",
        swiped: true,
        country: "",
        category: "",
        isSwiped: false,
        spend: {
          today: 0,
          last7Days: 0,
          last14Days: 0,
          last30Days: 0,
          last60Days: 0,
          last90Days: 0,
          last180Days: 0,
          last365Days: 0,
          last720Days: 0,
        },
      },
    ],
    hasMore: true,
  },
  errorMessage: "",
};

export const companyBrandsDummyData = {
  data: {
    results: [
      {
        brandId: 227406,
        name: "V Shred",
        thumbnail:
          "https://yt3.ggpht.com/q4mphxafQvYTdQ1qRFOumLRsogp127KRA211_IVO0XvLYMF-a0liYC8r2o3gqGHhfKi2s0gApDw=s88-c-k-c0x00ffffff-no-rj",
        countryId: 236,
        categoryId: 237,
        totalSpend: "80683460.00",
        swiped: true,
        country: "",
        swipeBoardIds: [],
        isSwiped: false,
        category: "",
        description: "",
        averageVideoDuration: 0,
        spend: {
          today: 0,
          last7Days: 0,
          last14Days: 0,
          last30Days: 0,
          last60Days: 0,
          last90Days: 0,
          last180Days: 0,
          last365Days: 0,
          last720Days: 0,
        },
        views: {
          today: 0,
          last7Days: 0,
          last14Days: 0,
          last30Days: 0,
          last60Days: 0,
          last90Days: 0,
          last180Days: 0,
          last365Days: 0,
          last720Days: 0,
        },
      },
      {
        brandId: 577289,
        name: "The Lost Ways",
        thumbnail:
          "https://yt3.ggpht.com/ytc/AOPolaTVv9EVegkjITbXzA6t6hwrQg4KMY9AdcrmK_Au=s88-c-k-c0x00ffffff-no-rj",
        countryId: 236,
        swipeBoardIds: [],
        categoryId: 93,
        totalSpend: "2600116.00",
        swiped: true,
        country: "",
        category: "",
        isSwiped: false,
        views: {
          today: 0,
          last7Days: 0,
          last14Days: 0,
          last30Days: 0,
          last60Days: 0,
          last90Days: 0,
          last180Days: 0,
          last365Days: 0,
          last720Days: 0,
        },
        description: "",
        averageVideoDuration: 0,
        spend: {
          today: 0,
          last7Days: 0,
          last14Days: 0,
          last30Days: 0,
          last60Days: 0,
          last90Days: 0,
          last180Days: 0,
          last365Days: 0,
          last720Days: 0,
        },
      },
      {
        brandId: 64682,
        name: "Video ad upload channel for 507-927-0776",
        thumbnail:
          "https://yt3.ggpht.com/ytc/AOPolaTqvXY7Vr8tvDwls7FiOHLl2WPaOdNStxe89sameQqIMrLzKzlxTla2F8Ailzrd=s88-c-k-c0x00ffffff-no-rj",
        countryId: 236,
        categoryId: 93,
        totalSpend: "351.00",
        swiped: true,
        swipeBoardIds: [],
        country: "",
        category: "",
        isSwiped: false,
        views: {
          today: 0,
          last7Days: 0,
          last14Days: 0,
          last30Days: 0,
          last60Days: 0,
          last90Days: 0,
          last180Days: 0,
          last365Days: 0,
          last720Days: 0,
        },
        description: "",
        averageVideoDuration: 0,
        spend: {
          today: 0,
          last7Days: 0,
          last14Days: 0,
          last30Days: 0,
          last60Days: 0,
          last90Days: 0,
          last180Days: 0,
          last365Days: 0,
          last720Days: 0,
        },
      },
      {
        brandId: 478915,
        name: "Video ad upload channel for 468-445-1399",
        thumbnail:
          "https://yt3.ggpht.com/ytc/AOPolaSYACjKHqPjBo7LFDu7jW3UBMBjhmUVLvGkQhQNhGKWVrWuUBlByUgYHcrTY9zt=s88-c-k-c0x00ffffff-no-rj",
        countryId: 236,
        categoryId: 93,
        swipeBoardIds: [],
        totalSpend: "231.00",
        swiped: true,
        country: "",
        category: "",
        isSwiped: false,
        views: {
          today: 0,
          last7Days: 0,
          last14Days: 0,
          last30Days: 0,
          last60Days: 0,
          last90Days: 0,
          last180Days: 0,
          last365Days: 0,
          last720Days: 0,
        },
        description: "",
        averageVideoDuration: 0,
        spend: {
          today: 0,
          last7Days: 0,
          last14Days: 0,
          last30Days: 0,
          last60Days: 0,
          last90Days: 0,
          last180Days: 0,
          last365Days: 0,
          last720Days: 0,
        },
      },
    ],
    hasMore: true,
  },
  errorMessage: "",
};
