import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { Category } from "src/app/models/category";
import { DEBOUNCE_TIME2 } from "src/app/models/consts";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";
import { StaticDataService } from "src/app/services/shared/static.data.service";

@Component({
  selector: "app-category-select",
  templateUrl: "./category-select.component.html",
  styleUrls: ["./category-select.component.scss"],
})
export class CategorySelectComponent implements OnInit {
  @Input() selectedCategoryId: number = 0;
  @Output() selectedCategoryChanged: EventEmitter<number> =
    new EventEmitter<number>();

  @Input() selectedCategoryIds: number[] = [];
  @Output() selectedCategoriesChanged: EventEmitter<number[]> =
    new EventEmitter<number[]>();

  @Input() nestingRequired!: boolean;
  @Input() placeholder: string = "";
  @Input() isMultipleSelection: boolean = false;

  public searchControl: FormControl = new FormControl();
  public categoriesControl: FormControl = new FormControl("");

  categories!: Category[];
  filteredCategories: Category[] = [];

  constructor(
    public stateService: StateService,
    public staticDataService: StaticDataService,
    public fb: FormBuilder,
    private popupService: PopupService,
    private apiService: ApiService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.LoadCategories();
    this.SubscribeFormControl();
  }

  private async LoadCategories() {
    const result = await this.apiService.GetCategories();

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }
    if (result?.data) {
      this.categories = result.data.categories;
      if (!this.isMultipleSelection)
        this.categories.unshift(this.staticDataService.AddDefaultCategory());
      this.filteredCategories = [...this.categories];
    }
  }
  private SubscribeFormControl() {
    this.searchControl.valueChanges
      .pipe(debounceTime(DEBOUNCE_TIME2))
      .subscribe(() => {
        this.FilterCategories();
      });
  }

  CategorySelected() {
    this.selectedCategoryChanged.emit(this.selectedCategoryId);
  }

  CategoriesSelected(category: Category) {
    if (this.selectedCategoryIds.includes(category.categoryId)) {
      this.selectedCategoryIds = Array.from(
        new Set([...this.selectedCategoryIds, ...this.GetChildIds(category)])
      );
    } else
      this.selectedCategoryIds = this.selectedCategoryIds.filter(
        (c) => !this.GetChildIds(category).includes(c)
      );
    this.selectedCategoriesChanged.emit(this.selectedCategoryIds);
  }

  public get getCategories() {
    return this.categoriesControl.value.map(
      (e: number) => this.staticDataService.categoriesMap.get(e)?.name
    );
  }

  public IsOptionDisabled(categoryId: number) {
    return this.categoriesControl.value.includes(0) && categoryId != 0;
  }

  private FilterCategories() {
    let keyword = this.searchControl.value;
    this.filteredCategories = this.FilterRecursive(this.categories, keyword);
  }
  private FilterRecursive(
    categories: Category[],
    searchInput: string
  ): Category[] {
    const filteredCategories: Category[] = [];
    categories.forEach((category) => {
      const categoryClone: Category = { ...category };

      if (category.name.toLowerCase().includes(searchInput.toLowerCase())) {
        filteredCategories.push(categoryClone);
      } else if (category.children && this.nestingRequired) {
        const filteredChildren = this.FilterRecursive(
          category.children,
          searchInput
        );
        if (filteredChildren.length > 0) {
          categoryClone.children = filteredChildren;
          filteredCategories.push(categoryClone);
        }
      }
    });

    return filteredCategories;
  }

  public GetChildIds(
    node: Category | undefined,
    result: number[] = []
  ): number[] {
    if (node) {
      result.push(node.categoryId);
      if (node.children) {
        for (const child of node.children) {
          this.GetChildIds(child, result);
        }
      }
    }
    return result;
  }
}
