<div class="d-flex flex-wrap p-5">
    <ng-container *ngFor="let ad of nonVideoAds?.results">
        <div (click)="OpenNewTab(ad.src)" class="image-container mr-2 mb-2">
            <img [src]="ad.src | safeUrl" />
        </div>
    </ng-container>
</div>
<div class="pagination-div">
    <app-paginator [hasMore]="nonVideoAds.hasMore" [resultsPerPage]="resultsPerPage"
        [currentPage]="currentPageCompanies" (pageSizeChanged)="onPageSizeChange($event)"
        (pageNumChanged)="ChangePageCompanies($event)">
    </app-paginator>
</div>