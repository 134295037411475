import { Component, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-tawk',
  templateUrl: './tawk.component.html'
})
export class TawkComponent {

  constructor() { }
  ngOnInit() {
    // const script = `
    // var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    // (function () {
    //   var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
    //   s1.async = true;
    //   s1.src = 'https://embed.tawk.to/5c7969f6a726ff2eea5a2752/default';
    //   s1.charset = 'UTF-8';
    //   s1.setAttribute('crossorigin', '*');
    //   s0.parentNode.insertBefore(s1, s0);
    // })();`;
    // const el = this.renderer.createElement('script');
    // el.text = script;
    // this.renderer.appendChild(document.body, el);
  }
}
