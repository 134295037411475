import { Component, Input, OnInit } from "@angular/core";
import { PercentageByCountry } from "src/app/models/percentageByCountry";
import { StateService } from "src/app/services/shared/state.service";
import { StaticDataService } from "src/app/services/shared/static.data.service";

@Component({
  selector: "app-ads-per-country",
  templateUrl: "./ads-per-country.component.html",
  styleUrls: ["../../entity-info.component.scss"],
})
export class AdsPerCountryComponent implements OnInit {
  @Input() top5Countries: PercentageByCountry[] = [];
  constructor(
    public stateService: StateService,
    public staticDataService: StaticDataService
  ) {}

  ngOnInit(): void {}
}
