import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { StateService } from "src/app/services/shared/state.service";
import { PopupService } from "src/app/services/shared/popup.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  currentUser$!: Observable<any>;
  constructor(
    private router: Router,
    private popupService: PopupService,
    public stateService: StateService,
    private afAuth: AngularFireAuth
  ) {}

  ngOnInit(): void {
    this.currentUser$ = this.stateService.user$;
  }

  public OpenPricingPage() {
    this.router.navigate(["/pricing"]);
  }

  public OpenAboutPage() {
    this.router.navigate(["/about"]);
  }

  public OpenContactPage() {
    this.router.navigate(["/contact"]);
  }

  public OpenBlogPage() {
    this.router.navigate(["/blog"]);
  }

  public OpenLoginModal(): void {
    this.stateService.goToDashboard = true;
    this.popupService.openLoginModal();
  }

  public OpenSignUpModal(): void {
    this.stateService.goToDashboard = true;
    this.popupService.openRegisterModal();
  }

  public async SignOut() {
    this.stateService.SignOut();
  }
}
