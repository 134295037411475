<div class="container-fluid p-1">
    <h3 class="section-heading mb-5 pt-5 pl-3 pl-md-4 pl-xl-5">Companies</h3>
    <div class="container search-container">
        <div #inputContainer style="margin-top: 20px;" class="d-flex min-container position-relative">
            <div class="search-icon d-flex align-items-center px-3">
                <i class="fa fa-search fa-lg"></i>
            </div>
            <input #Input [formControl]="inputControl" name="searchInput" spellcheck="false"
                class="input-field input-field1 border-right form-control pl-1 pr-0" autocomplete="off"
                placeholder="Use search and filters to find advertisers..." type="text" />
        </div>
    </div>

    <div class="container container-1400 my-5">
        <div class="row">
            <div class="col-12 col-md-4 col-lg-3 mb-3">
                <app-category-tree-select [type]="'checkbox'" placeholder="-Select categories-"
                    (selectedCategoriesChanged)="SelectedCategoriesChanged($event)"
                    [selectedCategoryIds]="selectedCategoryIds">
                </app-category-tree-select>
            </div>
            <div class="col-12 col-md-4 col-lg-3 mb-3">
                <app-country-picker [alpha2Code]="'ALL'" (selectedCountryChanged)="SelectedCountryChanged($event)"
                    [selectedCountryId]="selectedCountryId"></app-country-picker>
            </div>
            <div class="col-12 col-md-4 col-lg-3 mb-3">
                <app-software-select placeholder="-Select software-"
                    (selectedSoftwareChanged)="SelectedSoftwareChanged($event)"
                    [selectedSoftwareIds]="selectedSoftwareIds"></app-software-select>
            </div>
        </div>
        <app-filter-chips [isAffiliate]="false" [selectedCategoryIds]="selectedCategoryIds"
            [selectedCountryId]="selectedCountryId" [selectedSoftwareIds]="selectedSoftwareIds"
            (removeSoftwareChanged)="RemoveSoftware($event)" (removeCategoryChanged)="RemoveCategory($event)"
            (removeCountryChanged)="RemoveCountry($event)" (removeAllCategoriesChanged)="RemoveAllCategories()">
        </app-filter-chips>
        <div class="table-container">
            <div class="filters-div"></div>
            <app-companies-list (resultsPerPageChanged)="ResultsPerPageChanged($event)"
                (currentPageChanged)="CurrentPageChanged($event)" [searchByKeyword]="true" [loadedParams]="loaded"
                [isPayingUser]="isPayingUser" [isAffiliate]="false" [softwareIds]="selectedSoftwareIds"
                [categoryIds]="selectedCategoryIds" [currentPageCompanies]="page" [resultsPerPage]="resultsPerPage"
                [countryId]="selectedCountryId" [keyword]="keyword"></app-companies-list>
        </div>
    </div>

</div>