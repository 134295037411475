import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchType } from 'src/app/models/enums';

@Component({
  selector: 'app-entity-select',
  templateUrl: './entity-select.component.html',
  styleUrls: ['./entity-select.component.scss']
})
export class EntitySelectComponent {
  searchType = SearchType;

  @Output() brandsSelected: EventEmitter<string> = new EventEmitter<string>();
  @Output() companiesSelected: EventEmitter<string> = new EventEmitter<string>();

  @Input() selectedType: string = ''

  BrandsSelected() {
    this.brandsSelected.emit('brands');
  }

  CompaniesSelected() {
    this.companiesSelected.emit('companies');
  }
}
