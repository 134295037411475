import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable, pipe } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { StateService } from "src/app/services/shared/state.service";

@Injectable({
  providedIn: "root",
})
export class ExternalLinkGuard {
  constructor(private stateService: StateService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const currentRoutePath = route.url.map((segment) => segment.path).join("/"); // Get the current route path
    document.location.href = "https://blog.vidtao.com/" + currentRoutePath;
    return true;
  }
}
