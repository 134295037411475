<div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
    <!-- <button mat-menu-item>
        <mat-icon class="icon" [svgIcon]="'share-icon'"></mat-icon>
        <span style="color: black;">Share</span></button> -->
    <ng-container *ngIf="!board.isShared">
        <button [matMenuTriggerFor]="menu" #trigger="matMenuTrigger" mat-menu-item>
            <mat-icon class="icon" [svgIcon]="'edit-icon'"></mat-icon>
            <span style="color: #344054;">Edit name</span></button>
        <mat-menu #menu="matMenu">
            <app-upsert-swipe-board (menuClosed)="trigger.closeMenu()" (editBoard)="EditBoard($event)"
                [boardName]="board.name" [boardId]="board.swipeBoardId"></app-upsert-swipe-board>
        </mat-menu>
    </ng-container>
    <button (click)="DeleteBoard()" mat-menu-item>
        <mat-icon class="icon" [svgIcon]="'delete-icon'"></mat-icon>
        <span style="color: #D92D20;">Delete</span></button>
</div>