import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs";
import { AffiliateNetwork } from "src/app/models/affiliateNetwork";
import { AffiliateOffer } from "src/app/models/affiliateOffer";
import { DEBOUNCE_TIME2 } from "src/app/models/consts";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";
import { StaticDataService } from "src/app/services/shared/static.data.service";

@Component({
  selector: "app-offer-select",
  templateUrl: "./offer-select.component.html",
  styleUrl: "./offer-select.component.scss",
})
export class OfferSelectComponent {
  @Input() selectedOfferIds: number[] = [];
  @Output() selectedOffersChanged: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();

  offers: AffiliateOffer[] = [];
  filteredOffers: AffiliateOffer[] = [];
  searchControl: FormControl = new FormControl();
  offersControl: FormControl = new FormControl("");

  constructor(
    public apiService: ApiService,
    public stateService: StateService,
    public staticDataService: StaticDataService,
    public popupService: PopupService
  ) {}

  async ngOnInit(): Promise<void> {
    //await this.LoadAffiliateNetworks();
    this.SubscribeFormControl();
    this.SubscribeToUser();
  }

  private SubscribeToUser() {
    this.stateService.user$.subscribe((u) => {
      this.LoadStaticOffers();
    });
  }

  private async LoadStaticOffers() {
    const offers = await this.apiService.GetAllOffers();
    if (offers?.data?.results) {
      this.offers = offers.data.results;
      this.filteredOffers = [...this.offers];
    }
  }

  private SubscribeFormControl() {
    this.searchControl.valueChanges
      .pipe(debounceTime(DEBOUNCE_TIME2))
      .subscribe(() => {
        this.FilterNetworks();
      });
  }

  private FilterNetworks() {
    let keyword = this.searchControl.value;
    this.filteredOffers = this.offers.filter((n) =>
      n.networkOfferId.toLowerCase().includes(keyword.toLowerCase())
    );
  }

  public OfferSelected() {
    if (this.selectedOfferIds.includes(0))
      //if all included, only all should be toggled
      this.selectedOfferIds = [0];
    this.selectedOffersChanged.emit(this.selectedOfferIds);
  }

  public get selectedOfferName() {
    return this.offersControl.value.map((id: number) => {
      return this.offers.find((o) => o.affiliateOfferId == id)?.networkOfferId;
    });
  }

  public IsOptionDisabled(networkId: number) {
    return this.offersControl.value.includes(0) && networkId != 0;
  }
}
