<!-- <div id="sidebar-toggle" (click)="ToggleSidebar()" class="toggler-position">
    <i class='bx bx-menu'></i>
</div> -->
<nav id="sidebar-main" class="sidebar d-flex flex-column">
    <app-module-select [isPayingUser]="isPayingUser" (moduleTypeChanged)="ModuleTypeChanged($event)"
        class="mb-4"></app-module-select>
    <ul *ngIf="stateService.moduleType == moduleType.Marketer" class="navbar-nav">
        <li [class.selected]="selectedItem === 'search'" (click)="SwitchTab('search')"
            class="nav-item selected my-auto d-flex align-items-center">
            <div class="img-div">
                <mat-icon class="mr-2" [svgIcon]="'menu-1'"></mat-icon>
            </div>
            <a class="nav-link" href="#/dashboard">Search</a>
        </li>
        <li [class.selected]="selectedItem === 'ads'" (click)="SwitchTab('ads')"
            class="nav-item my-auto d-flex align-items-center">
            <div class="img-div">
                <mat-icon class="mr-2" [svgIcon]="'menu-5'"></mat-icon>
            </div>
            <a class="nav-link" href="#/dashboard/ads">Ads</a>
        </li>
        <li [class.selected]="selectedItem === 'brands'" (click)="SwitchTab('brands')"
            class="nav-item my-auto d-flex align-items-center">
            <div class="img-div">
                <mat-icon class="mr-2" [svgIcon]="'menu-6'"></mat-icon>
            </div>
            <a class="nav-link" href="#/dashboard/brands">Brands</a>
        </li>
        <li [class.selected]="selectedItem === 'companies'" (click)="SwitchTab('companies')"
            class="nav-item my-auto d-flex align-items-center">
            <div class="img-div">
                <mat-icon class="mr-2" [svgIcon]="'menu-7'"></mat-icon>
            </div>
            <a class="nav-link" href="#/dashboard/companies">Companies</a>
        </li>
        <li [class.selected]="selectedItem === 'creatives'" (click)="SwitchTab('creatives')"
            class="nav-item my-auto d-flex align-items-center">
            <div class="img-div">
                <mat-icon class="mr-2" [svgIcon]="'menu-8'"></mat-icon>
            </div>
            <a class="nav-link" href="#/dashboard/creatives">Creative</a>
        </li>
        <li [class.selected]="selectedItem === 'trainings'" (click)="SwitchTab('trainings')"
            class="nav-item my-auto d-flex align-items-center">
            <div class="img-div">
                <mat-icon class="mr-2" [svgIcon]="'menu-4'"></mat-icon>
            </div>
            <a class="nav-link" href="#/dashboard/trainings">Trainings</a>
        </li>
        <!-- <li *ngIf="!isProduction" [ngStyle]="DisableIfNotPayingUser()" [class.selected]="selectedItem === 'rankings'"
            (click)="SwitchTab('rankings')" class="nav-item my-auto d-flex align-items-center">
            <div class="img-div">
                <mat-icon class="mr-2" [svgIcon]="'menu-2'"></mat-icon>
            </div>
            <a class="nav-link" href="#/dashboard/rankings">Rankings</a>
        </li> -->
    </ul>
    <ul *ngIf="stateService.moduleType == moduleType.Affiliate" class="navbar-nav">
        <li [class.selected]="selectedItem === 'affiliate-ads'" (click)="SwitchTab('affiliate-ads')"
            class="nav-item selected my-auto d-flex align-items-center">
            <div class="img-div">
                <mat-icon class="mr-2" [svgIcon]="'menu-5'"></mat-icon>
            </div>
            <a class="nav-link" href="#/dashboard/affiliate-ads">Ads</a>
        </li>
        <li [class.selected]="selectedItem === 'offers'" (click)="SwitchTab('offers')"
            class="nav-item selected my-auto d-flex align-items-center">
            <div class="img-div">
                <mat-icon class="mr-2" [svgIcon]="'menu-16'"></mat-icon>
            </div>
            <a class="nav-link" href="#/dashboard/affiliate-offers">Offers</a>
        </li>
        <li [class.selected]="selectedItem === 'affiliates'" (click)="SwitchTab('affiliates')"
            class="nav-item selected my-auto d-flex align-items-center">
            <div class="img-div">
                <mat-icon class="mr-2" [svgIcon]="'affiliate-menu-icon'"></mat-icon>
            </div>
            <a class="nav-link" href="#/dashboard/affiliate-companies">Affiliates</a>
        </li>
        <li [class.selected]="selectedItem === 'networks'" (click)="SwitchTab('networks')"
            class="nav-item selected my-auto d-flex align-items-center">
            <div class="img-div">
                <mat-icon class="mr-2" [svgIcon]="'networks-icon'"></mat-icon>
            </div>
            <a class="nav-link" href="#/dashboard/affiliate-networks">Networks<span class="ml-2">📣</span></a>
        </li>
    </ul>
    <ul class="navbar-nav mt-auto">
        <li [class.selected]="selectedItem === 'swipes'" class="nav-item my-auto d-flex align-items-center"
            (click)="SwitchTab('swipes')">
            <div class="img-div">
                <mat-icon class="mr-2" [svgIcon]="'menu-3'"></mat-icon>
            </div>
            <a class="nav-link" href="#/dashboard/swipes">Swipes</a>
        </li>
        <li [class.selected]="selectedItem === 'settings'" (click)="SwitchTab('settings')"
            class="nav-item my-auto d-flex align-items-center">
            <div class="img-div">
                <mat-icon class="mr-2" [svgIcon]="'menu-10'"></mat-icon>
            </div>
            <a class="nav-link" href="#/dashboard/settings">Settings</a>
        </li>
        <li (click)="SignOut()" class="nav-item my-auto d-flex align-items-center">
            <div class="img-div">
                <mat-icon class="mr-2" [svgIcon]="'menu-11'"></mat-icon>
            </div>
            <a class="nav-link" href="javascript:void(0);">Log out</a>
        </li>
    </ul>
</nav>