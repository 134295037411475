<ng-container *ngIf="contentLoaded">
    <table>
        <thead>
            <tr>
                <th class="pl-4">Thumbnail</th>
                <th>Company</th>
                <th>Category</th>
                <th>Country</th>
                <th>
                    Total spend
                    <ng-container *ngIf="isPayingUser && type !== 'brand-companies' && type !== 'offer-companies'">
                        <mat-icon (click)="SetSorting(1, 'total', false)" *ngIf="sortingIndex != 1"
                            class="mr-2 order-icon" [svgIcon]="'arrow-up-down'">
                        </mat-icon>
                        <mat-icon (click)="SetSorting(1, 'total', true)" *ngIf="sortingIndex == 1 && !orderAsc"
                            class="mr-2 order-icon" [svgIcon]="'arrow-down-black'">
                        </mat-icon>
                        <mat-icon (click)="SetSorting(1, 'total', false)" *ngIf="sortingIndex == 1 && orderAsc"
                            class="mr-2 order-icon" [svgIcon]="'arrow-up-black'">
                        </mat-icon>
                    </ng-container>
                </th>
                <th *ngIf="isSwipes && !shareBoardId">Boards</th>
                <th *ngIf="type == 'companies' || type == 'affiliate-companies'">Swipe</th>
            </tr>
        </thead>
        <tbody>
            <tr [ngStyle]="getBlurIfNeeded(i)" class="list-option" (click)="OpenSelectedCompany(result)"
                *ngFor="let result of displayedCompanies; let i = index">
                <td class="logo-display" data-label="Frame">
                    <div class="icon-container ml-4 thumbnail">
                        <div class="rounded-icon">
                            <span class="icon-letter">{{ result.legalName | uppercase | slice:0:1 }}</span>
                        </div>
                    </div>
                </td>
                <td data-label="Company">
                    <div class="d-block">
                        <div class="company-name">
                            {{result.legalName}}
                        </div>
                        <div class="company-domain">{{result.country}}</div>
                    </div>
                </td>
                <td data-label="Category">
                    <a [href]="GetCategoryLink(result.categoryId)"
                        class="inline-block content-end text-decoration-none">
                        <div class="tag tag2 inline-block">
                            <span (click)="OpenSelectedCategory($event, result.categoryId)" class="clickable-link">
                                {{staticDataService.categoriesMap.get(result.categoryId)?.name}}
                            </span>
                        </div>
                    </a>
                </td>
                <td data-label="Country">
                    <div class="adv-description">
                        {{staticDataService.countriesMap.get(result.countryId)?.name}}
                    </div>
                </td>
                <td data-label="Total spend">
                    <div [ngStyle]="{'filter': !isPayingUser ? 'blur(4px)' : 'blur(0)', 'user-select': !isPayingUser ? 'none' : 'auto'}"
                        class="adv-description">
                        ${{result.totalSpend | valueFormat}}
                    </div>
                </td>
                <td (click)="$event.stopPropagation()" *ngIf="isSwipes && !shareBoardId" data-label="Boards">
                    <app-swipe-board-select [isAdding]="false" (deleteSwipe)="DeleteCompanySwipe(result.companyId)"
                        (boardsSelected)="BoardsSelected($event,result.companyId)"
                        [selectedSwipeBoardIds]="result.swipeBoardIds" [isMultiple]="true"
                        [swipeBoards]="swipeBoards"></app-swipe-board-select>
                </td>
                <td (click)="$event.stopPropagation()" *ngIf="type == 'companies' || type == 'affiliate-companies'">
                    <div style="max-width: 110px;">
                        <swipe-button [swipeBoards]="swipeBoards" (swipeRemoved)="RemoveCompanySwipe(result)"
                            class="w-auto mb-2" [isSwiped]="result.isSwiped"
                            (swipeSaved)="CompanySwipeSaved($event, result)"></swipe-button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <app-paginator [hasMore]="searchResultsCompanies.hasMore" [resultsPerPage]="resultsPerPage"
        [currentPage]="currentPageCompanies" (pageSizeChanged)="onPageSizeChange($event)"
        (pageNumChanged)="ChangePageCompanies($event)">
    </app-paginator>
</ng-container>
<div *ngIf="!contentLoaded" class="p-3">
    <app-skeleton-loader [linesNum]="resultsPerPage"></app-skeleton-loader>
</div>