import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { ModuleType } from "src/app/models/enums";
import { Training } from "src/app/models/training";
import { TrainingVideo } from "src/app/models/training-video";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-training-content",
  templateUrl: "./training-content.component.html",
  styleUrls: ["../trainings/trainings.component.scss"],
})
export class TrainingContentComponent implements OnInit {
  id: string = "";
  isYt: boolean = false;

  ytTraining: Training = new Training();
  vexpowerTraining: any;

  selectedVideo: TrainingVideo = new TrainingVideo();

  optionsArray: any[] = [];
  wrongAnswers: any[] = [];
  textArray: any[] = [];
  answerArray: any[] = [];

  transcriptShowall: boolean = false;
  constructor(
    public route: ActivatedRoute,
    public popupService: PopupService,
    public apiService: ApiService,
    public stateService: StateService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.stateService.TabSelected("trainings");
    this.stateService.ModuleSelected(ModuleType.Marketer);
    this.GetRouteInfo();
  }

  private async GetRouteInfo() {
    combineLatest([this.route.params, this.route.queryParams])
      .pipe(
        map(([params, queryParams]) => {
          this.id = params["id"];
          if (queryParams["isYt"] == "true") {
            this.isYt = true;
            this.LoadYtTrainingInfo();
            return;
          }
          this.isYt = false;
          this.LoadVexpowerTrainingInfo();
        })
      )
      .subscribe();
  }

  public async LoadYtTrainingInfo() {
    const result = await this.apiService.GetYtTrainingInfo(this.id);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
    }
    if (result?.data) {
      this.ytTraining = result?.data;
      this.selectedVideo = this.ytTraining.videos[0];
    }
  }

  public async LoadVexpowerTrainingInfo() {
    const result = await this.apiService.GetVexpowerTrainingInfo(this.id);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
    }
    this.vexpowerTraining = result?.data;
  }

  public SelectVideo(video: TrainingVideo) {
    this.selectedVideo = video;
  }

  public ParseMessageToSentences(message: string): string[] {
    let ret = message.split("\n");
    return ret;
  }

  public ToggleTranscript() {
    this.transcriptShowall = !this.transcriptShowall;
  }

  public OpenFileInNewTab(url: string) {
    window.open(url, "_blank");
  }

  public RadioChange(option: string, correctOptions: any, sectionID: string) {
    const index = this.optionsArray.findIndex((x) => x === sectionID);

    if (index === -1 && correctOptions.includes(option)) {
      this.optionsArray.push(sectionID);
    }

    if (index !== -1 && !correctOptions.includes(option)) {
      this.optionsArray.splice(index, 1);
    }

    if (!correctOptions.includes(option)) {
      this.wrongAnswers.push(sectionID);
    }
  }

  public TagData(sectionID: string) {
    const index = this.textArray.findIndex((x) => x === sectionID);
    index === -1
      ? this.textArray.push(sectionID)
      : this.textArray.splice(index, 1);
  }

  public TagAnswers(sectionID: string) {
    const index = this.answerArray.findIndex((x) => x === sectionID);
    index === -1
      ? this.answerArray.push(sectionID)
      : this.answerArray.splice(index, 1);
  }

  public ContainsNonWhitespace(input: string): boolean {
    for (let i = 0; i < input.length; i++) {
      if (input[i] !== " ") {
        return true;
      }
    }
    return false;
  }
}
