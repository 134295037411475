<div class="bs-container d-flex p-2 align-items-center">
    <div class="bs-icon-container p-2 d-flex align-items-center">
        <mat-icon style="font-size: 12px; width: 19px; height: 19px;" [svgIcon]="'bs-icon'"></mat-icon>
    </div>
    <p class="field-label mb-0 ml-3 d-none d-sm-block">
        Get 1-on-1 help with YouTube ads. This week only!
    </p>
    <p class="field-label d-block d-sm-none mb-0 ml-3">
        Get 1-on-1 help with YouTube ads.
    </p>
    <button (click)="GetStarted()" style="white-space: nowrap;" class="btn transparent-button h-auto ml-3 mr-3">Get
        started</button>
    <button (click)="HideBanner()" class="btn d-flex px-0">
        <i style="font-size: 25px" class='bx bx-x'></i>
    </button>
</div>