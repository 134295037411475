import { Component, Input } from "@angular/core";
import { FeedDataService } from "src/app/services/shared/feed-data.service";
import { RudderStackService } from "src/app/services/shared/rudderstack.service";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-share-social",
  templateUrl: "./share-social.component.html",
  styleUrls: ["./share-social.component.scss"],
})
export class ShareSocialComponent {
  constructor(
    private rudderStackService: RudderStackService,
    private stateService: StateService,
    private feedService: FeedDataService
  ) {}

  @Input() copyUrl: string = "";
  @Input() type: string = "";
  @Input() ytVideoId: string = "";
  @Input() brandId: number = 0;
  @Input() categoryId: number = 0;

  public Clicked(event: Event) {
    if (this.type == "video") {
      this.rudderStackService.ShareVideoClicked(
        this.stateService.user?.userId,
        this.stateService.user?.email
      );
      this.feedService.SharingVideo({
        ytVideoId: this.ytVideoId,
        brandId: this.brandId,
        categoryId: this.categoryId,
      });
    } else if (this.type == "swipe-board")
      this.rudderStackService.ShareSwipeBoardClicked(
        this.stateService.user?.userId,
        this.stateService.user?.email
      );
    else
      this.rudderStackService.ShareEntityClicked(
        this.stateService.user?.userId,
        this.stateService.user?.email
      );

    event.stopPropagation();
  }
}
