import { Spends } from "./spends";
import { Views } from "./views";

export class Brand {
  public brandId: number = 0;
  public countryId: number = 0;
  public country: string = "";
  public categoryId: number = 0;
  public category: string = "";
  public name: string = "";
  public thumbnail: string = "";
  public totalSpend: string = "";
  public views: Views = new Views();
  public spend: Spends = new Spends();
  public description: string = "";
  public swiped: boolean = false;
  public averageVideoDuration: number = 0;
  public swipeBoardIds: number[] = [];
  public isSwiped: boolean = false;
}
