<div class="wrapper" [ngClass]="isOpened ? '': 'closed'">
    <div class="d-flex title-div mb-2" (click)=ToggleDropdown()>
        <p class="title mb-0">{{selectName}}</p>
        <div class="ml-auto">
            <i style="font-size: 20px;" class='bx bx-chevron-down'></i>
        </div>
    </div>
    <div class="values-div d-flex justify-content-center mb-2">
        <p class="title mb-0">
            <ng-container *ngIf="rangeType == 'time'">
                {{sliderOneValue | durationFormat}} - {{sliderTwoValue | durationFormat}}
            </ng-container>
        </p>
    </div>
    <div class="slider-container">
        <div class="slider-track"></div>
        <input type="range" [(ngModel)]="sliderOneValue" [step]="step" [min]="sliderMinValue" [max]="sliderMaxValue"
            id="slider-1" (ngModelChange)="SlideOne()">
        <input type="range" [(ngModel)]="sliderTwoValue" [step]="step" [min]="sliderMinValue" [max]="sliderMaxValue"
            id="slider-2" (ngModelChange)="SlideTwo()">
    </div>
</div>