import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-spend-select",
  templateUrl: "./spend-select.component.html",
  styleUrl: "./spend-select.component.scss",
})
export class SpendSelectComponent {
  @Input() placeholder: string = "";
  @Input() spendValues: string[] = [];
  @Output() selectedSpendChanged: EventEmitter<string> =
    new EventEmitter<string>();
  selectedSpendValue: string = "";

  public SpendSelected() {
    this.selectedSpendChanged.emit(this.selectedSpendValue);
  }
}
