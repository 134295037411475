import { Component, OnInit } from "@angular/core";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-terms-of-service",
  templateUrl: "./terms-of-service.component.html",
  styleUrls: ["./terms-of-service.component.scss"],
})
export class TermsOfServiceComponent implements OnInit {
  constructor(public stateService: StateService) {}

  ngOnInit(): void {}
}
