<div class="container container-1400 pt-5 pl-5 pr-5">
    <div class="d-flex align-items-center">
        <h3 class=" section-heading mb-0">Affiliate Networks</h3>
        <app-network-select class="ml-3" (selectedNetworkChanged)="SelectedNetworkChanged($event)"
            [selectedNetworkId]="selectedNetworkId" [isMultiple]="false"></app-network-select>
    </div>
    <div style="text-align: end; margin-top: -35px" class="container container-1400">
        <i (click)="OpenAffiliateTutorial()" class='bx bx-info-circle mt-1 ml-auto see-more-button'></i>
    </div>
</div>


<div class="container container-1400 p-5" style="padding-top: 10px !important;">
    <div class="row">
        <div class="col-12 col-md-6">
            <h3 class="plan-subtitle mb-3">Network overview</h3>
            <div class="row mt-4">
                <div class="col-12 col-md-6">
                    <app-entity-stat [isSpend]="false" [statName]="'Total ads'" [statValue]="networkAdsStats.adsCount">
                    </app-entity-stat>
                </div>
                <div class="col-12 col-md-6">
                    <app-entity-stat [isSpend]="false" [statName]="'Offers'"
                        [statValue]="networkAffiliateStats.offersCount">
                    </app-entity-stat>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <app-entity-stat [isSpend]="false" [statName]="'Active ads'"
                        [statValue]="networkAdsStats.activeAdsCount">
                    </app-entity-stat>
                </div>
                <div class="col-12 col-md-6">
                    <app-entity-stat [isSpend]="false" [statName]="'Active affiliates'"
                        [statValue]="networkAffiliateStats.activeAffiliatesCount">
                    </app-entity-stat>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <app-entity-stat [isSpend]="false" [statName]="'Primary languages'"
                        [statValue]="topLanguagesString">
                    </app-entity-stat>
                </div>
            </div>
        </div>
        <div *ngIf="xValues.length > 1" class="col-12 col-md-6">
            <h3 class="plan-subtitle">Top offers by ad spend</h3>
            <div class="p-1" id="success">
                <app-doughnut-chart [isSpend]="true" [yValues]="yValues"
                    [colors]="['#2D3282', '#2D31A6','#3538CD', '#444CE7', '#6172F3', '#8098F9', '#A4BCFD', '#C7D7FE',  '#E0EAFF']"
                    [xValues]="xValues" chartId="11ffas" type="doughnut">
                </app-doughnut-chart>
            </div>
            <!-- <app-skeleton-loader *ngIf="!distributionGraphLoaded" [linesNum]="4"></app-skeleton-loader> -->
        </div>
    </div>
    <h3 class="plan-subtitle mb-3 mt-5">All offers</h3>
    <div class="table-container">
        <div class="filters-div"></div>
        <app-offers-list [displayAdditionalInfo]="true" [affiliateNetworkIds]="[selectedNetworkId]"
            [isMainDisplay]="true" [loadedParams]="true" (resultsPerPageChanged)="ResultsPerPageChanged($event)"
            (currentPageChanged)="CurrentPageChanged($event)" [currentPage]="page"
            [resultsPerPage]="resultsPerPage"></app-offers-list>
    </div>
</div>