import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs";
import { AffiliateNetwork } from "src/app/models/affiliateNetwork";
import { DEBOUNCE_TIME2 } from "src/app/models/consts";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";
import { StaticDataService } from "src/app/services/shared/static.data.service";

@Component({
  selector: "app-network-select",
  templateUrl: "./network-select.component.html",
  styleUrls: ["./network-select.component.scss"],
})
export class NetworkSelectComponent implements OnInit {
  @Input() selectedNetworkIds: number[] = [];
  @Input() isMultiple: boolean = true;
  @Input() selectedNetworkId: number = 0;
  @Output() selectedNetworksChanged: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();
  @Output() selectedNetworkChanged: EventEmitter<number> =
    new EventEmitter<number>();

  networks: AffiliateNetwork[] = [];
  filteredNetworks: AffiliateNetwork[] = [];
  searchControl: FormControl = new FormControl();
  networksControl: FormControl = new FormControl("");

  constructor(
    public apiService: ApiService,
    public stateService: StateService,
    public staticDataService: StaticDataService,
    public popupService: PopupService
  ) {}

  async ngOnInit(): Promise<void> {
    this.LoadStaticAffiliateNetworks();
    this.SubscribeFormControl();
  }

  private LoadStaticAffiliateNetworks() {
    this.networks = [...this.staticDataService.affiliateNetworks];
    if (this.isMultiple) this.networks.shift();
    this.filteredNetworks = [...this.networks];
  }

  private SubscribeFormControl() {
    this.searchControl.valueChanges
      .pipe(debounceTime(DEBOUNCE_TIME2))
      .subscribe(() => {
        this.FilterNetworks();
      });
  }

  private FilterNetworks() {
    let keyword = this.searchControl.value;
    this.filteredNetworks = this.networks.filter((n) =>
      n.name.toLowerCase().includes(keyword.toLowerCase())
    );
  }

  public NetworkSelected() {
    if (this.selectedNetworkIds.includes(0))
      //if all included, only all should be toggled
      this.selectedNetworkIds = [0];
    this.selectedNetworksChanged.emit(this.selectedNetworkIds);
    this.selectedNetworkChanged.emit(this.selectedNetworkId);
  }

  public get selectedNetworkName() {
    if (this.isMultiple)
      return this.networksControl.value.map((id: number) => {
        return this.networks.find((n) => n.affiliateNetworkId == id)?.name;
      });
    else
      return this.networks.find(
        (n) => n.affiliateNetworkId == this.selectedNetworkId
      )?.name;
  }

  public IsOptionDisabled(networkId: number) {
    return this.networksControl.value.includes(0) && networkId != 0;
  }
}
