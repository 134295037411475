<mat-form-field appearance="outline" class="no-line">
    <mat-select placeholder="-Select entity-" class="select" [(ngModel)]="type">
        <mat-select-trigger [ngClass]="type == moduleType.Marketer ? 'marketer-color': 'affiliate-color'"
            class="d-flex">
            <ng-container *ngIf="type == moduleType.Marketer">
                <mat-icon class="mr-2" [svgIcon]="'marketer-icon'"></mat-icon>
                Marketer
            </ng-container>
            <ng-container *ngIf="type == moduleType.Affiliate">
                <mat-icon class="mr-2" [svgIcon]="'affiliate-icon'"></mat-icon>
                Affiliate
            </ng-container>
        </mat-select-trigger>
        <mat-option class="marketer-color" (click)="MarketerSelected()" value="marketer">
            <mat-icon [svgIcon]="'marketer-icon'"></mat-icon>
            Marketer
        </mat-option>
        <mat-option class="affiliate-color" (click)="AffiliateSelected()" value="affiliate">
            <mat-icon [svgIcon]="'affiliate-icon'"></mat-icon>
            Affiliate
        </mat-option>
    </mat-select>
</mat-form-field>