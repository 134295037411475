<div class="container-fluid custom-bckgr p-1">
    <div class="d-flex pt-5 pl-3 pl-md-4 pl-xl-5">
        <h3 class="section-heading">Affiliate companies</h3>
    </div>
    <div style="text-align: end; margin-top: -35px" class="container container-1400 mb-5">
        <i (click)="OpenAffiliateTutorial()" class='bx bx-info-circle mt-1 ml-auto see-more-button'></i>
    </div>
    <div class="container search-container">
        <div #inputContainer style="margin-top: 20px;" class="d-flex min-container position-relative">
            <div class="search-icon d-flex align-items-center px-3">
                <i class="fa fa-search fa-lg"></i>
            </div>
            <input #Input [formControl]="inputControl" name="searchInput" spellcheck="false"
                class="input-field input-field1 border-right form-control pl-1 pr-0" autocomplete="off"
                placeholder="Use search and filters to find affiliate advertisers..." type="text" />
        </div>
    </div>

    <div class="container container-1400 my-5">
        <div class="row">
            <div class="col-12 col-md-4 col-lg-3 mb-3">
                <app-category-tree-select [type]="'checkbox'" placeholder="-Select categories-"
                    (selectedCategoriesChanged)="SelectedCategoriesChanged($event)"
                    [selectedCategoryIds]="selectedCategoryIds">
                </app-category-tree-select>
            </div>
            <div class="col-12 col-md-4 col-lg-3 mb-3">
                <app-network-select placeholder="-Select category-"
                    (selectedNetworksChanged)="SelectedNetworksChanged($event)"
                    [selectedNetworkIds]="selectedNetworkIds"></app-network-select>
            </div>
            <div class="col-12 col-md-4 col-lg-3 mb-3">
                <app-country-picker [alpha2Code]="'ALL'" (selectedCountryChanged)="SelectedCountryChanged($event)"
                    [selectedCountryId]="selectedCountryId"></app-country-picker>
            </div>
            <div class="col-12 col-md-4 col-lg-3 mb-3">
                <app-language-select (selectedLanguageChanged)="SelectedLanguageChanged($event)"
                    [selectedLanguageId]="selectedLanguageId"></app-language-select>
            </div>
            <div class="col-12 col-md-4 col-lg-3 mb-3">
                <app-software-select placeholder="-Select software-"
                    (selectedSoftwareChanged)="SelectedSoftwareChanged($event)"
                    [selectedSoftwareIds]="selectedSoftwareIds"></app-software-select>
            </div>
        </div>
        <app-filter-chips [isAffiliate]="true" [selectedNetworkIds]="selectedNetworkIds"
            [selectedCategoryIds]="selectedCategoryIds" [selectedCountryId]="selectedCountryId"
            [selectedLanguageId]="selectedLanguageId" [selectedSoftwareIds]="selectedSoftwareIds"
            (removeSoftwareChanged)="RemoveSoftware($event)" (removeNetworkChanged)="RemoveNetwork($event)"
            (removeCategoryChanged)="RemoveCategory($event)" (removeCountryChanged)="RemoveCountry($event)"
            (removeLanguageChanged)="RemoveLanguage($event)">
        </app-filter-chips>
        <div class="table-container">
            <div class="filters-div"></div>
            <app-companies-list (resultsPerPageChanged)="ResultsPerPageChanged($event)"
                (currentPageChanged)="CurrentPageChanged($event)" [searchByKeyword]="true" [isAffiliate]="true"
                [type]="'affiliate-companies'" [affiliateNetworkIds]="selectedNetworkIds" [loadedParams]="loaded"
                [softwareIds]="selectedSoftwareIds" [currentPageCompanies]="page" [resultsPerPage]="resultsPerPage"
                [categoryIds]="selectedCategoryIds" [countryId]="selectedCountryId" [languageId]="selectedLanguageId"
                [keyword]="keyword"></app-companies-list>
        </div>
    </div>

</div>