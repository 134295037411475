import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { SwipeBoard } from "src/app/models/swipeBoard";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";

@Component({
  selector: "app-swipe-board-select",
  templateUrl: "./swipe-board-select.component.html",
  styleUrls: ["./swipe-board-select.component.scss"],
})
export class SwipeBoardSelectComponent implements OnInit, OnChanges {
  constructor(
    public apiService: ApiService,
    public popupService: PopupService
  ) {}
  @Input() swipeBoards: SwipeBoard[] = [];
  @Input() placeholder: string = "";
  @Input() isMultiple: boolean = false;
  @Output() editBoard: EventEmitter<object> = new EventEmitter<object>();
  @Output() deleteBoard: EventEmitter<SwipeBoard> =
    new EventEmitter<SwipeBoard>();
  @Output() boardSelected: EventEmitter<SwipeBoard> =
    new EventEmitter<SwipeBoard>();
  @Output() boardsSelected: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();
  @Output() deleteSwipe: EventEmitter<void> = new EventEmitter<void>();
  selectedSwipeBoardId: number = 0;
  @Input() selectedSwipeBoardIds: number[] = []; //for multiple
  @Input() isAdding: boolean = true;

  ngOnInit(): void {}
  public BoardsSelected(board: SwipeBoard, index: number) {
    if (index == 0 && !this.selectedSwipeBoardIds.includes(0)) {
      this.popupService.AskToConfirm(
        "Swipe deletion",
        "Are you sure that you want to delete selected swipe?",
        () => {
          this.deleteSwipe.emit();
        },
        () => {
          this.selectedSwipeBoardIds = [0, ...this.selectedSwipeBoardIds];
        }
      );
      return;
    }
    this.boardsSelected.emit(this.selectedSwipeBoardIds);
  }

  public BoardSelected(board: SwipeBoard) {
    this.boardSelected.emit(board);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.selectedSwipeBoardIds.includes(0))
      this.selectedSwipeBoardIds.push(0);
  }

  public Clicked(event: Event) {
    event.stopPropagation();
  }

  public EditBoard(boardName: string, boardId: number) {
    this.editBoard.emit({ boardName, boardId });
  }

  public DeleteBoard(swipeBoard: SwipeBoard) {
    this.deleteBoard.emit(swipeBoard);
  }
}
