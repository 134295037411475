import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Chart, Legend } from 'chart.js';
import 'chartjs-adapter-moment';
import 'chartjs-plugin-annotation';
import { VideoRanking } from 'src/app/models/videoRanking';
import { TOP_VIDEOS_COLORS } from 'src/app/models/consts';


@Component({
  selector: 'app-multi-line-chart',
  templateUrl: './multi-line-chart.component.html',
  styleUrls: ['./multi-line-chart.component.scss']
})
export class MultiLineChartComponent implements OnInit, OnChanges {

  lineChart: any;
  allowedChartTypes = ['line']
  displayedYvalues: string[] = [];

  isGraphInitialized: boolean = false;
  //@Input() yValues: string[] = [];
  //@Input() xValues: number[] = [];

  @Input() yTitle!: string;
  @Input() xTitle!: string;
  @Input() type!: any;
  @Input() chartId!: string;


  @Input() datasets: VideoRanking[] = [];
  @Output() videoClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }


  ngOnInit(): void {

  }

  ngOnChanges(): void {
    this.GraphXyChanged();
  }


  private InitializeGraph() {
    const data = {
      datasets: this.CreateDatasets()
    };
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          type: "time",
          time: {
            unit: "month"
          },
          ticks: {
            display: true,
            displayMin: true,
            maxTicksLimit: 3,
            maxRotation: 0,
          },
          title: {
            display: true,
            text: this.xTitle,
          },
          grid: {
            display: false
          }
        },
        y: {
          ticks: {
            stepSize: function (context: any) {
              // var min = Math.min(...context.chart.data.datasets[0].data);
              var max = Math.max(...context.chart.data.datasets[0].data);
              return max * 0.4;
              // var difference = max - min;
              // return Math.ceil(difference / 2);
            },
            callback: (value: any, index: any, values: any) => {
              // if (index == 0 || index == values.length - 1) {
              if (value >= 1000000) {
                return Math.floor(value / 1000000) + '.' + (value % 1000000).toString().substring(0, 1) + 'M';
              }
              if (value >= 1000) {
                return Math.floor(value / 1000) + 'k'; // Display in thousands
              }
              return value; // Display original value
              // }
            },
            font: {

            }
          },
          title: {
            display: true,
            text: this.yTitle
          }
        }
      },

      plugins: {
        legend: {
          onClick: (event: any, legendItem: any, legend: any) => {
            this.OpenSelectedVideo(legendItem.text.split(':')[1]);
          },
          position: 'bottom',
          display: true
        },
        tooltip: {
          enabled: true,
          intersect: false,
        }
      },
      interaction: {
        intersect: false
      }

    };
    const ctx = document.getElementById(this.chartId) as HTMLCanvasElement;

    let chartStatus = Chart.getChart(this.chartId); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    this.lineChart = new Chart(ctx, {
      type: this.type,
      data: data,
      options: options,
      plugins: [Legend]
    });


  }


  private GraphXyChanged() {
    if (this.datasets.length < 1)
      return;
    this.InitializeGraph();
  }

  private CreateDatasets() {
    return this.datasets.map((entry, i) => {
      return {
        borderWidth: 2,
        label: "Video ID:" + entry.videoId,
        pointRadius: 0,
        borderColor: TOP_VIDEOS_COLORS[i],
        data: entry.dataPoints.map((point) => {
          return { x: point.date, y: point.views }
        })
      };
    });
  }


  private OpenSelectedVideo(videoId: string) {
    this.videoClicked.emit(videoId);
  }

}
