import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BrandRanking } from "src/app/models/brandRanking";
import { PageSizes } from "src/app/models/consts";
import { SearchResponse } from "src/app/models/searchResponse";
import { ApiService } from "src/app/services/api/api.service";
import { PaginatorService } from "src/app/services/shared/paginator.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";
import { StaticDataService } from "src/app/services/shared/static.data.service";

@Component({
  selector: "app-brands-ranking-list",
  templateUrl: "./brands-ranking-list.component.html",
  styleUrls: ["./brands-ranking-list.component.scss"],
})
export class BrandsRankingListComponent implements OnChanges {
  selectedIndex: number = 0;
  activeAscenderIndex: number = 0;

  brands!: SearchResponse<BrandRanking>;

  totalPages = 0;
  pages: string[] = [];
  pageSizes = PageSizes;

  @Input() @Input() resultsPerPage = this.pageSizes[3];
  @Input() currentPage = 1;
  @Input() selectedInterval: string = "last30Days";
  @Input() selectedCategoryId: number = 0;
  @Input() selectedCountryId: number = 0;
  @Input() loadedParams = false;
  requiresTotal: boolean = true;

  contentLoaded: boolean = false;

  @Output() currentPageChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() resultsPerPageChanged: EventEmitter<number> =
    new EventEmitter<number>();

  constructor(
    private apiService: ApiService,
    public stateService: StateService,
    public staticDataService: StaticDataService,
    private popupService: PopupService
  ) {}

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.loadedParams) {
      await this.LoadTopBrands();
    }
  }

  private async LoadTopBrands() {
    const result = await this.apiService.LoadTopBrands(
      this.selectedCategoryId,
      this.selectedCountryId,
      this.selectedInterval,
      this.currentPage,
      this.resultsPerPage
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }
    if (result?.data) {
      this.contentLoaded = true;
      this.brands = result.data;
    }
  }

  public async ChangePage(pageNum: number) {
    this.currentPage = pageNum;
    this.currentPageChanged.emit(this.currentPage);
  }

  async onPageSizeChange(value: string) {
    this.resultsPerPage = this.pageSizes.filter((ps) => ps == Number(value))[0];
    this.currentPage = 1;
    this.resultsPerPageChanged.emit(this.resultsPerPage);
  }

  public OpenSelectedBrand(brand: BrandRanking) {
    this.popupService.OpenBrandPreview(brand.brandId);
  }
}
