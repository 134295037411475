<div class="container-fluid p-1">
    <div class="d-flex pt-3 pt-sm-5 pl-3 pl-md-4 pl-xl-5">
        <h3 class="section-heading mb-0">Video ads</h3>
    </div>
    <div style="text-align: end; margin-top: -26px;" class="container container-1400 mb-0 mb-sm-5">
        <i (click)="OpenAdsTutorial()" class='bx bx-info-circle mt-1 ml-auto see-more-button'></i>
    </div>
    <div class="container search-container">
        <div #inputContainer style="margin-top: 12px;" class="d-flex min-container position-relative">
            <div class="search-icon d-flex align-items-center px-3">
                <i class="fa fa-search fa-lg"></i>
            </div>
            <input #Input name="searchInput" spellcheck="false" [formControl]="inputControl"
                class="input-field input-field1 border-right form-control pl-1 pr-0" autocomplete="off"
                placeholder="Use search and filters to find ads..." type="text" />
        </div>
    </div>
    <div class="container container-1400 my-0 my-sm-5 pt-2">
        <div class="row">
            <div class="col-12 col-md-4 col-lg-3 mb-0 mb-sm-3 pt-0 pb-2">
                <app-category-tree-select [type]="'checkbox'" placeholder="-Select categories-"
                    (selectedCategoriesChanged)="SelectedCategoriesChanged($event)"
                    [selectedCategoryIds]="selectedCategoryIds">
                </app-category-tree-select>
            </div>
            <div class="col-12 col-md-4 col-lg-3 mb-0 mb-sm-3 pt-0 pb-2">
                <app-language-select (selectedLanguageChanged)="SelectedLanguageChanged($event)"
                    [selectedLanguageId]="selectedLanguageId"></app-language-select>
            </div>
            <div class="col-12 col-md-4 col-lg-3 mb-0 mb-sm-3 pt-0 pb-2">
                <app-video-status-select [selectedStatus]="selectedStatusId"
                    (statusSelected)="SelectedStatusChanged($event)"></app-video-status-select>
            </div>
            <div class="col-12 col-md-4 col-lg-3 mb-0 mb-sm-3 pt-0 pb-2">
                <app-duration-select [placeholder]="'Duration'" (durationSelected)="SelectedDurationChanged($event)"
                    [selectedDuration]="selectedDurationId"></app-duration-select>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-10">
                <app-filter-chips [isAffiliate]="false" [displayingAds]="true"
                    [selectedCategoryIds]="selectedCategoryIds" [selectedLanguageId]="selectedLanguageId"
                    [selectedDurationId]="selectedDurationId" (removeCategoryChanged)="RemoveCategory($event)"
                    (removeLanguageChanged)="RemoveLanguage($event)" (removeDurationChanged)="RemoveDuration($event)"
                    (removeAllCategoriesChanged)="RemoveAllCategories()">
                </app-filter-chips>
            </div>
            <div class="col-12 col-md-2 align-items-center justify-content-end">
                <app-interval-select [selectedInterval]="selectedInterval"
                    (intervalSelected)="IntervalSelected($event)"></app-interval-select>
            </div>
        </div>
        <div class="table-container">
            <div class="filters-div"></div>
            <app-videos-list (resultsPerPageChanged)="ResultsPerPageChanged($event)"
                (currentPageChanged)="CurrentPageChanged($event)" (sortingChanged)="SortingChanged($event)"
                (videoModalOpened)="VideoModalOpened($event)" (videoModalClosed)="VideoModalClosed()"
                [loadedParams]="loaded" [sortingIndex]="sortingIndex" [sortProp]="sortProp" [orderAsc]="orderAsc"
                [isPayingUser]="isPayingUser" [keyword]="keyword" [categoryIds]="selectedCategoryIds"
                [searchByKeyword]="true" [videoStatusId]="selectedStatusId" [languageId]="selectedLanguageId"
                [durationId]="selectedDurationId" [resultsPerPage]="resultsPerPage" [currentPage]="page"
                [isMainDisplay]="true" [selectedInterval]="selectedInterval">
            </app-videos-list>
        </div>
    </div>
</div>