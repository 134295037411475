import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  constructor(public router: Router) { }

  public OpenTermsOfServices() {
    this.router.navigate(['/terms-of-service']);
  }

  public OpenPrivacyPolicy() {
    this.router.navigate(['/privacy-policy']);
  }
}
