import { Component, Inject, ViewContainerRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { combineLatest, map } from "rxjs";
import { VideoDetailComponent } from "src/app/components/modals/video-detail/video-detail.component";
import {
  ChartPerformancePeriod,
  DateRangeOptions,
  ModuleType,
} from "src/app/models/enums";
import { SwipeBoard } from "src/app/models/swipeBoard";
import { TranscriptUnit } from "src/app/models/transcriptUnit";
import { User } from "src/app/models/user";
import { Video } from "src/app/models/video";
import { VideoCreativeStats } from "src/app/models/videosCreativeStats";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { RudderStackService } from "src/app/services/shared/rudderstack.service";
import { StateService } from "src/app/services/shared/state.service";
import { StaticDataService } from "src/app/services/shared/static.data.service";

@Component({
  selector: "app-ad-info",
  templateUrl: "./ad-info.component.html",
  styleUrls: ["./ad-info.component.scss"],
})
export class AdInfoComponent {
  ytVideoId: string = "";
  yValues: string[] = [];
  xValues: number[] = [];

  video: Video = new Video();
  videoStats: VideoCreativeStats = new VideoCreativeStats();
  showTextChecked: boolean = false;
  isPayingUser: boolean = false;
  isExpanded: boolean = false;
  isSharingMode: boolean = false;
  swipeBoardIds: number[] | undefined = [];

  chartPeriod: string = ChartPerformancePeriod.Year;
  selectedRangeIndex: number = DateRangeOptions.Year;
  daysBackwardsArray: number[] = [-30, -365, 0];
  periodMap: Map<string, number> = new Map<string, number>([
    [ChartPerformancePeriod.Month, -30],
    [ChartPerformancePeriod.Year, -365],
    [ChartPerformancePeriod.Total, 0],
  ]);

  shareUrl: string = "";
  urlOptionalExtension: string = "";
  encryptedData: string = "";
  swipeBoards: SwipeBoard[] = [];

  videoTranscripts: TranscriptUnit[] = [];
  startTime: number = 0;
  jumpTime: string = "";
  displayTranscript: boolean = false;
  displayTopSection: boolean = true;
  user: User | null | undefined = null;
  constructor(
    public apiService: ApiService,
    public stateService: StateService,
    public staticDataService: StaticDataService,
    private route: ActivatedRoute,
    public router: Router,
    public sanitizer: DomSanitizer,
    private popupService: PopupService,
    public rudderStackService: RudderStackService
  ) {}

  async ngOnInit() {
    this.LoadSwipeBoards();
    this.GetRouteInfo();
  }

  private GetRouteInfo(): void {
    combineLatest([
      this.route.params,
      this.route.queryParams,
      this.stateService.user$,
    ])
      .pipe(
        map(async ([routeParams, params, user]) => {
          this.user = user;
          this.isPayingUser = this.stateService.IsPayingUserState(user);
          if (params["isAffiliate"]) {
            this.stateService.TabSelected("affiliate-ads");
            this.stateService.ModuleSelected(ModuleType.Affiliate);
          } else {
            this.stateService.TabSelected("ads");
            this.stateService.ModuleSelected(ModuleType.Marketer);
          }
          if (params["videoId"]) {
            if (params["videoId"].length < 15 && user) {
              //ako je pravi videoId a ne kriptovani dolazi sa ovim podijeljenih blogova
              this.encryptedData = await this.CreateKey({
                ytVideoId: params["videoId"],
              });
              this.Redirect();
              return;
            } else if (params["videoId"].length > 15)
              await this.TryResolveKey(params["videoId"]);
          } else if (routeParams["key"]) {
            //if shared video openned
            this.isSharingMode = true;
            this.stateService.sharedUserType = params["t"];
            this.urlOptionalExtension = "Shared";
            this.encryptedData = routeParams["key"];
            if (!(await this.TryResolveKey(routeParams["key"]))) return; //if incorrect encrrypted key
            if (user) {
              //if user has account and is logged in, we redirect him to the real page
              this.Redirect();
              return;
            } else {
              this.stateService.verificationRedirectUrl = this.router.url;
              // this.popupService.openRegisterModal(true);
              this.displayTopSection = false;

              await this.CreateShareUrl();
            }
          } else await this.CreateShareUrl();

          Promise.all([
            this.LoadVideoInfo(),
            this.LoadVideoStats(),
            this.LoadVideoTranscript(),
          ]);
        })
      )
      .subscribe();
  }

  ConvertTimeToSeconds(time: string): number {
    const [minutes, seconds] = time.split(":").map(Number);
    return minutes * 60 + seconds;
  }

  JumpToVideoSeconds(time: string) {
    this.jumpTime = time;
  }

  private async LoadVideoInfo() {
    const result = await this.apiService.GetVideoInfo(
      this.ytVideoId,
      this.urlOptionalExtension,
      this.encryptedData
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) {
      this.video = result.data;
      this.PopulateGraphvalues(
        this.daysBackwardsArray[this.selectedRangeIndex]
      );
    }
  }

  private async LoadVideoTranscript() {
    const result = await this.apiService.GetVideoTranscript(this.ytVideoId);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) {
      this.videoTranscripts = result.data;
    }
  }

  private async LoadVideoStats() {
    const result = await this.apiService.GetVideoStatistics(
      this.ytVideoId,
      this.urlOptionalExtension,
      this.encryptedData
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) {
      this.videoStats = result.data;
      this.videoStats.clickUrls.forEach(
        (cu) => (cu.urlDisplay = cu.url.substring(8))
      );
    }
  }

  private PopulateGraphvalues(daysBackward: number) {
    this.xValues = [];
    this.yValues = [];
    const filteredSpend = this.video.spend
      .sort((a, b) => {
        return new Date(a.date).getTime() - new Date(b.date).getTime();
      })
      .filter((spend) =>
        daysBackward === 0
          ? true
          : new Date(spend.date) > this.AddDays(new Date(), daysBackward)
      );

    this.xValues = filteredSpend.map((spend) => Math.floor(spend.spend));
    this.yValues = filteredSpend.map((spend) =>
      new Date(spend.date).toISOString().substring(0, 10)
    );
  }

  private AddDays(date: any, days: number) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  public ChangeGraphDateRange(period: string) {
    this.chartPeriod = period;
    var daysBackward = this.periodMap.get(this.chartPeriod);
    if (daysBackward != undefined) this.PopulateGraphvalues(daysBackward);
  }

  public OpenInNewTab(url: string) {
    window.open(url, "_blank");
  }

  public ExtractLandingPage(url: string): string {
    const urlParams = new URLSearchParams(url);
    const adurlValue = urlParams.get("adurl");

    if (adurlValue) {
      const decodedUrl = decodeURIComponent(adurlValue);
      return this.ExtractDomainFromUrl(decodedUrl);
    }
    return "";
  }

  public ExtractCleanUrl(url: string): string {
    const urlParams = new URLSearchParams(url);
    const adurlValue = urlParams.get("adurl");

    if (adurlValue) {
      const decodedUrl = decodeURIComponent(adurlValue);
      return decodedUrl;
    }
    return "";
  }

  public ExtractDomainFromUrl(url: string) {
    const urlObj = new URL(url);
    const domain = urlObj.origin;
    return domain;
  }

  public async SwipeSaved(boardsIds?: number[]) {
    this.swipeBoardIds = boardsIds;
    const result = await this.apiService.SwipeVideo(
      this.ytVideoId,
      this.swipeBoardIds
    );
    if (result?.errorMessage) {
      this.popupService.Notify(result?.errorMessage);
      return;
    }
    this.video.swiped = true;
    this.popupService.Notify("Video successfuly swiped.");
  }

  public OpenVideoBrand() {
    let isAffiliate =
      this.stateService.moduleType == ModuleType.Affiliate ? true : false;
    this.router.navigate(["/dashboard/entity-info"], {
      queryParams: {
        brandId: this.video.brandId,
        ...(isAffiliate && { isAffiliate }),
      },
    });
  }

  public async OpenSelectedCategory() {
    this.stateService.ModuleSelected(ModuleType.Marketer); // if in affiliate mode to switch to marketer before opening ranking page
    await this.router.navigate(["/dashboard/ads"], {
      queryParams: {
        selectedCategoryIds: JSON.stringify([this.video.categoryId]),
      },
    });
  }

  public get getCurrentUrl() {
    return window.location.href;
  }

  public ToggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  public getBlurIfNeeded() {
    return {
      filter: !this.isPayingUser ? "blur(7px)" : "blur(0)",
      "user-select": !this.isPayingUser ? "none" : "auto",
      "pointer-events": !this.isPayingUser ? "none" : "auto",
    };
  }

  public async RemoveSwipe() {
    this.popupService.AskToConfirm(
      "Swipe deletion",
      "Are you sure that you want to delete selected swipe?",
      async () => await this.SwipeRemoved()
    );
  }

  public async SwipeRemoved() {
    const result = await this.apiService.RemoveSwipedVideo(this.ytVideoId);

    if (result?.errorMessage) {
      this.popupService.Notify(result?.errorMessage);
      return;
    }

    this.video.swiped = false;
    this.popupService.Notify("Video successfuly removed from Swipes.");
  }

  public get ShareUrl() {
    return this.shareUrl;
  }

  public async TryResolveKey(key: string) {
    const result = await this.apiService.ResolveKey(key);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data == "unknown") {
      //it means that not correct link was provided
      this.router.navigate(["/dashboard"]);
      return false;
    }
    this.ytVideoId = result?.data;
    return true;
  }

  public async CreateShareUrl() {
    this.shareUrl = await this.GetShareUrl({
      ytVideoId: this.ytVideoId,
    });
  }

  public async GetShareUrl(data: object) {
    const key = await this.CreateKey(data);
    const userParam = this.isPayingUser ? "v-premium" : "v-free";
    return window.location.origin + `/#/shared/${key}/ad-info?t=${userParam}`;
  }

  private async CreateKey(data: object) {
    const result = await this.apiService.GetKey(data);
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data) return result?.data;
  }

  public CheckIfClickAllowed(event: Event) {
    if (this.encryptedData && !this.stateService.IsUserLoggedIn()) {
      event.stopPropagation();
      // this.popupService.openRegisterModal();
    } else if (this.encryptedData && !this.isPayingUser) {
      event.stopPropagation();
      this.popupService.AskForAccountUpgrade();
    }
  }

  public Redirect() {
    this.router.navigate(["/dashboard/ad-info"], {
      queryParams: {
        videoId: this.encryptedData,
      },
    });
  }

  async LoadSwipeBoards() {
    const result = await this.apiService.GetSwipeBoards();
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data)
      this.swipeBoards = [
        {
          name: "All swipes",
          swipeBoardId: 0,
          isChecked: true,
          shareId: "",
          isShared: false,
          email: "",
          userName: "",
        },
        ...result.data,
      ];
  }

  public FreeIntervalPassed() {
    if (!this.user) {
      this.rudderStackService.NonUserPopupAppeared();
      this.popupService.openRegisterModal(true);
    }
  }

  public VideoStarted() {
    if (!this.user) this.rudderStackService.NonUserStartedVideo();
  }
}
