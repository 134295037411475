import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-video-status-select',
  templateUrl: './video-status-select.component.html',
  styleUrls: ['./video-status-select.component.scss']
})
export class VideoStatusSelectComponent {
  @Input() selectedStatus: string = ''
  @Output() statusSelected: EventEmitter<string> = new EventEmitter<string>();

  public StatusSelected(status: string) {
    this.statusSelected.emit(status);
  }
}
