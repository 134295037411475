export class User {
  public userId: string = "";
  public email: string = "";
  public name: string = "";
  public role: string = "";
  public plan: string = "free";
  public createdAt!: Date;
  public isFreeTrialUsed: boolean = false;
  public pastDue: boolean = false;
  public showStrategySessionBanner: boolean = true;
  public expiresAt!: Date;
  public accessToken?: string = "";
  public hasWatchedAffiliate: boolean = false;
  public hasWatchedAds: boolean = false;
  public hasWatchedBrands: boolean = false;
  public hasWatchedSearch: boolean = false;
  public hasWatchedEntity: boolean = false;
}
