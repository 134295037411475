<div class="item-container">
    <ng-container *ngIf="video.isInViewport">
        <app-yt-player-controls class="w-100" [ytVideoId]="video.ytVideoId"></app-yt-player-controls>
        <div class="d-flex flex-row justify-content-between">
            <p class="mt-3 mb-0 card-text">Ad spend 30: <span class="card-title">${{video.adSpend30 |
                    valueFormat}}</span></p>
            <p (click)="OpenVideoInfoModal()" style="cursor: pointer; font-size: 16px !important;"
                class="card-title mt-3 mb-0 "><b>Details</b></p>
        </div>
    </ng-container>
    <div></div>
    <div *ngIf="!video.isInViewport" class="video-skeleton"></div>
    <div class="buttons-container">
        <app-like-dislike (videoEngaged)="VideoEngaged($event)" [isLiked]="video.isLiked"
            [isDisliked]="video.isDisliked"></app-like-dislike>
    </div>
</div>