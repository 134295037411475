import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { OverlayListenerOptions, OverlayOptions } from "primeng/api";
import { Category } from "src/app/models/category";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { StaticDataService } from "src/app/services/shared/static.data.service";

@Component({
  selector: "app-category-tree-select",
  templateUrl: "./category-tree-select.component.html",
  styleUrls: ["./category-tree-select.component.scss"],
})
export class CategoryTreeSelectComponent implements OnInit, OnChanges {
  categories: Category[] = [];
  nodes!: any[];
  selectedNodes: any;
  @Input() type: "single" | "multiple" | "checkbox" = "checkbox";
  @Input() placeholder: string = "-Select category-";

  @Input() selectedCategoryId: number = 0;
  @Output() selectedCategoryChanged: EventEmitter<number> =
    new EventEmitter<number>();

  @Input() selectedCategoryIds: number[] = [];
  @Output() selectedCategoriesChanged: EventEmitter<number[]> =
    new EventEmitter<number[]>();

  constructor(
    public apiService: ApiService,
    public popupService: PopupService,
    public staticDataService: StaticDataService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.type == "checkbox" && this.nodes?.length > 0)
      this.selectedNodes = this.FindObjectsByKeys(
        this.nodes,
        this.selectedCategoryIds
      );
    else if (this.type == "single" && this.nodes?.length > 0)
      this.selectedNodes = this.FindObjectByKey(
        this.nodes,
        this.selectedCategoryId
      );
  }

  ngOnInit(): void {
    this.LoadCategories();
  }

  public TransformDataForTreeselect = (categories: Category[]): any[] => {
    return categories.map((item) => ({
      label: item.name,
      data: item.name,
      key: item.categoryId,
      children: this.TransformDataForTreeselect(item.children),
    }));
  };

  private async LoadCategories() {
    const result = await this.apiService.GetCategories();

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }
    if (result?.data) {
      this.categories = result.data.categories;
      const otherCategory = this.categories.splice(1, 1)[0];
      this.categories.push(otherCategory); //put other category as last element in categories array

      if (this.type == "single")
        this.categories.unshift(this.staticDataService.AddDefaultCategory());
      this.nodes = this.TransformDataForTreeselect(this.categories);
      if (this.type == "single")
        this.selectedNodes = this.FindObjectByKey(
          this.nodes,
          this.selectedCategoryId
        );
      else if (this.type == "checkbox")
        this.selectedNodes = this.FindObjectsByKeys(
          this.nodes,
          this.selectedCategoryIds
        );
    }
  }

  public NodeSelectionChanged() {
    if (this.type == "single") {
      this.selectedCategoryChanged.emit(this.selectedNodes.key);
    } else {
      const ids = this.selectedNodes.map((sn: { key: any }) => sn.key);
      this.selectedCategoriesChanged.emit(ids);
    }
  }

  public FindObjectByKey = (
    categories: any[],
    keyToFind: number
  ): any | null => {
    for (const category of categories) {
      if (category.key === keyToFind) {
        return category;
      }
      if (category.children) {
        const foundInChildren = this.FindObjectByKey(
          category.children,
          keyToFind
        );
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }
    return null;
  };
  public FindObjectsByKeys = (categories: number[], keysToFind: number[]) => {
    const foundObjects: any[] = [];

    const findRecursively = (category: any) => {
      if (keysToFind.includes(category.key)) {
        foundObjects.push(category);
      }
      if (category.children) {
        for (const child of category.children) {
          findRecursively(child);
        }
      }
    };

    for (const category of categories) {
      findRecursively(category);
    }

    return foundObjects.length > 0 ? foundObjects : null;
  };

  getOverlayOptions(): OverlayOptions {
    return {
      listener: (event: Event, options?: OverlayListenerOptions) => {
        if (options?.type == "scroll") return false;
        return true;
      },
    };
  }
}
