import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuestionModalComponent } from '../question-modal/question-modal.component';

@Component({
  selector: 'app-yes-no',
  templateUrl: './yes-no.component.html',
  styleUrls: ['./yes-no.component.scss'],
})
export class YesNoComponent {
  message: string = '';
  title: string = '';
  constructor(
    private dialogRef: MatDialogRef<QuestionModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.message = data.message;
    this.title = data.title;
  }

  public Confirm() {
    this.dialogRef.close(true);
  }

  public Cancel() {
    this.dialogRef.close(false);
  }
}
