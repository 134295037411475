<div class="container p-4">
    <div class="d-flex position-relative">
        <img class="mx-auto" style="height: 30px;" src="assets/icons/logo-black-notext.png" />
        <img *ngIf="canClose" (click)="CloseModal()" class="my-auto position-absolute"
            style="height: 24px; right: 5px; cursor: pointer;" src="assets/icons/x-close.png" />
    </div>

    <ng-container *ngIf="displayType == 'recommend-login'">
        <h3 class="title mb-2 mt-3">Register</h3>
        <p class="welcome-text">This content is only for users that have account.
            Create account to proceed and explore new features of <b>VidTao 2.0</b>!</p>
        <button (click)="Register()" class="btn sign-in-button mt-3">Sign up</button>
    </ng-container>
    <ng-container *ngIf="displayType == 'recommend-upgrade'">
        <h3 class="title mb-2 mt-3"> Upgrade your account</h3>
        <p class="welcome-text">This content is only for users with paid accounts.
            Upgrade your account and explore new features of <b>VidTao</b>!</p>
        <button (click)="Upgrade()" class="btn sign-in-button mt-3">Upgrade</button>
    </ng-container>

</div>