import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs";
import { DEBOUNCE_TIME2 } from "src/app/models/consts";
import { Language } from "src/app/models/language";
import { StateService } from "src/app/services/shared/state.service";
import { StaticDataService } from "src/app/services/shared/static.data.service";

@Component({
  selector: "app-language-select",
  templateUrl: "./language-select.component.html",
  styleUrls: ["./language-select.component.scss"],
})
export class LanguageSelectComponent implements OnInit {
  languages: Language[] = [];
  filteredLanguages: Language[] = [];

  searchControl: FormControl = new FormControl();
  languageControl: FormControl = new FormControl("");

  @Input() selectedLanguageId: string = "";
  @Output() selectedLanguageChanged: EventEmitter<string> =
    new EventEmitter<string>();

  constructor(
    public stateService: StateService,
    public staticDataService: StaticDataService
  ) {}

  ngOnInit(): void {
    this.LoadStaticLanguages();
    this.SubscribeFormControl();
  }

  private LoadStaticLanguages() {
    this.languages = [...this.staticDataService.languages];
    this.filteredLanguages = [...this.languages];
  }

  private SubscribeFormControl() {
    this.searchControl.valueChanges
      .pipe(debounceTime(DEBOUNCE_TIME2))
      .subscribe(() => {
        this.FilterLanguages();
      });
  }

  private FilterLanguages() {
    let keyword = this.searchControl.value;
    this.filteredLanguages = this.languages.filter((n) =>
      n.name.toLowerCase().includes(keyword.toLowerCase())
    );
  }

  public LanguageSelected() {
    this.selectedLanguageChanged.emit(this.selectedLanguageId);
  }

  public get selectedLanguageName() {
    return this.languages.find((n) => n.code == this.selectedLanguageId)?.name;
  }

  public IsOptionDisabled(code: string) {
    return this.languageControl.value.includes("all") && code != "all";
  }
}
