import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import firebase from "firebase/compat/app";

import { StateService } from "src/app/services/shared/state.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public afAuth: AngularFireAuth,
    public fb: FormBuilder,
    public popupService: PopupService,
    public stateService: StateService,
    public dialogRef: MatDialogRef<LoginComponent>
  ) {
    this.canClose = this.data.canClose;
  }

  loginForm!: FormGroup;

  @ViewChild("password", { static: true })
  passwordField!: ElementRef;
  recaptchaVerifier!: firebase.auth.RecaptchaVerifier;
  isRecaptchaVerified: boolean = false;
  canClose: boolean = true;
  ngOnInit(): void {
    this.InitializeLoginForm();
    this.InitializeRecaptcha();
  }

  public CloseModal() {
    this.dialogRef.close();
  }

  public async Login() {
    const data = this.loginForm.getRawValue();
    this.stateService.SignInEmailAndPassword(
      data.emailLogin,
      data.passwordLogin,
      this.recaptchaVerifier,
      () => this.CloseModal()
    );
  }

  public async LoginWithGoogle() {
    this.stateService.SignInWithGoogle(this.recaptchaVerifier, () =>
      this.CloseModal()
    );
  }

  private InitializeLoginForm() {
    this.loginForm = this.fb.group({
      emailLogin: ["", [Validators.required, Validators.email]],
      passwordLogin: ["", Validators.required],
    });
  }

  private InitializeRecaptcha() {
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      { size: "invisible" }
    );
    this.recaptchaVerifier.render();
    this.recaptchaVerifier
      .verify()
      .then(() => {
        this.isRecaptchaVerified = true;
      })
      .catch((error) => {
        this.isRecaptchaVerified = false;
      });
  }

  public PasswordForgotten() {
    if (this.loginForm.get("emailLogin")?.valid) {
      this.popupService.Notify("Reset link sent to your email.");
      const email = this.loginForm.get("emailLogin")?.value;
      this.stateService.SendPasswordResetEmail(email, environment.domain);
      return;
    }
  }
}
