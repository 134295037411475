import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-verify-check",
  templateUrl: "./verify-check.component.html",
  styleUrls: ["../login/login.component.scss"],
})
export class VerifyCheckComponent {
  emailSentAgain: boolean = false;

  constructor(
    public stateService: StateService,
    public dialogRef: MatDialogRef<VerifyCheckComponent>
  ) {}

  public SendVerificationMail() {
    this.stateService.SendVerificationEmail();
    this.emailSentAgain = true;
  }

  public Logout() {
    this.dialogRef.close();
    this.stateService.SignOut();
  }
}
