<form [formGroup]="resetForm" class="container p-4 mt-5 reset-form">
    <div class="d-flex position-relative">
        <img class="mx-auto" style="height: 30px;" src="assets/icons/logo-black-notext.png" />
    </div>

    <h3 class="title mb-1 mt-3">Reset code sent to your email</h3>
    <p class="welcome-text">Please finish setting up new password</p>
    <p class="label mb-1 mt-3">New password</p>
    <input formControlName="newPassword" type="password" #password class="form-control input-element"
        placeholder="Enter new password" />
    <p class="label mb-1 mt-3">Repeat password</p>
    <input formControlName="repeatPassword" type="password" #password class="form-control input-element"
        placeholder="Repeat password" />
    <div class="mt-2" id="recaptcha-container"></div>
    <button [disabled]="resetForm.invalid" (click)="ResetPasswordAndSignIn()" class="btn sign-in-button mt-3">Reset
        password and sign in</button>
</form>