import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";

@Directive({
  selector: "[appInView]",
})
export class InViewDirective implements OnDestroy, AfterViewInit {
  @Input() elementIndex: number = 0;
  @Output() inViewport: EventEmitter<{ index: number; isInViewport: boolean }> =
    new EventEmitter();

  private observer: IntersectionObserver;

  constructor(private el: ElementRef) {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        this.inViewport.emit({
          index: this.elementIndex,
          isInViewport: entry.isIntersecting,
        });
      });
    });
  }

  ngAfterViewInit(): void {
    this.observer.observe(this.el.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }
}
