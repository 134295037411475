export enum SearchType {
  Companies = "company",
  Ads = "ad",
  Categories = "category",
  Brands = "brand",
  Offers = "offers",
}

export enum VideoSortProp {
  TotalViews = "total",
  UploadDate = "date",
  Last30Days = "last30Days",
}

export enum DateRangeOptions {
  Month = 0,
  Year = 1,
  Total = 2,
}

export enum DisplayType {
  Youtube = "youtube",
  Shorts = "shorts",
  Display = "display",
  Search = "search",
}

export enum ChartPerformanceType {
  Views = "view",
  Spends = "spend",
}

export enum ChartPerformancePeriod {
  Month = "30 days",
  Year = "12 months",
  Total = "Total",
}

export enum ChartTestingType {
  Spend = "spend",
  Count = "count",
}

export enum ChartTestingPeriod {
  Year = "Year",
  Month = "Month",
}

export enum PaginationType {
  Frontend = "frontend",
  Backend = "backend",
}

export enum ModuleType {
  Affiliate = "affiliate",
  Marketer = "marketer",
}
