import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute } from "@angular/router";
import * as CryptoJS from "crypto-js";
import { Company } from "src/app/models/company";
import { Brand } from "src/app/models/brand";
import { SearchRequest } from "src/app/models/searchRequest";
import { SearchResponse } from "src/app/models/searchResponse";
import { ApiService } from "src/app/services/api/api.service";
import { SearchType } from "src/app/models/enums";
import { PageSizes } from "src/app/models/consts";
import { PaginatorService } from "src/app/services/shared/paginator.service";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-search-results",
  templateUrl: "./search-results.component.html",
  styleUrls: ["./search-results.component.scss"],
})
export class SearchResultsComponent implements OnInit {
  searchType = SearchType;
  type: SearchType = SearchType.Companies;

  isInputFocused: boolean = false;
  activeAscenderIndex: number = 0;

  keyword: string = "";
  shownKeyword: string = "";
  companies: Company[] = [];

  brandsLoaded: boolean = false;
  companiesLoaded: boolean = false;

  isPayingUser: boolean = false;

  constructor(
    private router: Router,
    public stateService: StateService,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this.SubscribeToUser();
    this.keyword = this.GetSearchKeyword();
    this.type = this.GetDisplayType();
    this.shownKeyword = this.keyword;
  }

  public SubscribeToUser() {
    this.stateService.user$.subscribe((user) => {
      this.isPayingUser = this.stateService.IsPayingUserState(user);
    });
  }

  public GetSearchKeyword(): string {
    let keyword: string = "";
    this.route.queryParams.subscribe((params) => {
      keyword = params["keyword"];
    });
    return keyword;
  }

  public GetDisplayType(): SearchType {
    let type: SearchType = SearchType.Ads;
    this.route.queryParams.subscribe((params) => {
      type = params["type"];
    });
    return type;
  }
  async SubmitSearch(): Promise<void> {
    this.router.navigate(["/dashboard/search-results"], {
      queryParams: { keyword: this.keyword },
      queryParamsHandling: "merge",
    });
    this.isInputFocused = false;
    this.shownKeyword = this.keyword;
  }

  public CompaniesLoaded(flag: boolean) {
    this.companiesLoaded = flag;
  }
  public BrandsLoaded(flag: boolean) {
    this.brandsLoaded = flag;
  }
}
