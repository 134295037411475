<div class="container-fluid p-1">
    <div class="d-flex pt-5 pl-3 pl-md-4 pl-xl-5">
        <h3 class="section-heading">Brands</h3>
    </div>
    <div style="text-align: end; margin-top: -35px;" class="container container-1400 mb-5">
        <i (click)="OpenBrandsTutorial()" class='bx bx-info-circle mt-1 ml-auto see-more-button'></i>
    </div>
    <div class="container search-container">
        <div #inputContainer style="margin-top: 20px;" class="d-flex min-container position-relative">
            <div class="search-icon d-flex align-items-center px-3">
                <i class="fa fa-search fa-lg"></i>
            </div>
            <input #Input [formControl]="inputControl" name="searchInput" spellcheck="false"
                class="input-field input-field1 border-right form-control pl-1 pr-0" autocomplete="off"
                placeholder="Use search and filters to find brands..." type="text" />
        </div>
    </div>

    <div class="container container-1400 my-5">
        <div class="row">
            <div class="col-12 col-md-4 col-lg-3 mb-3">
                <app-category-tree-select [type]="'checkbox'" placeholder="-Select categories-"
                    (selectedCategoriesChanged)="SelectedCategoriesChanged($event)"
                    [selectedCategoryIds]="selectedCategoryIds">
                </app-category-tree-select>
            </div>
            <div class="col-12 col-md-4 col-lg-3 mb-3">
                <app-country-picker [alpha2Code]="'ALL'" (selectedCountryChanged)="SelectedCountryChanged($event)"
                    [selectedCountryId]="selectedCountryId"></app-country-picker>
            </div>
            <div class="col-12 col-md-4 col-lg-3 mb-3">
                <app-software-select placeholder="-Select software-"
                    (selectedSoftwareChanged)="SelectedSoftwareChanged($event)"
                    [selectedSoftwareIds]="selectedSoftwareIds"></app-software-select>
            </div>
            <div class="col-12 col-md-4 col-lg-3 mb-3">
                <app-duration-select [placeholder]="'Avg. creative duration'"
                    (durationSelected)="SelectedDurationChanged($event)"
                    [selectedDuration]="selectedDurationId"></app-duration-select>
            </div>
        </div>
        <app-filter-chips [isAffiliate]="false" [selectedCategoryIds]="selectedCategoryIds"
            [selectedSoftwareIds]="selectedSoftwareIds" (removeSoftwareChanged)="RemoveSoftware($event)"
            [selectedCountryId]="selectedCountryId" (removeCategoryChanged)="RemoveCategory($event)"
            (removeCountryChanged)="RemoveCountry($event)" (removeAllCategoriesChanged)="RemoveAllCategories()">
        </app-filter-chips>
        <div class="table-container">
            <div class="filters-div"></div>
            <app-brands-list (resultsPerPageChanged)="ResultsPerPageChanged($event)"
                (currentPageChanged)="CurrentPageChanged($event)" [loadedParams]="loaded" [isPayingUser]="isPayingUser"
                [searchByKeyword]="true" [durationId]="selectedDurationId" [softwareIds]="selectedSoftwareIds"
                [currentPageBrands]="page" [resultsPerPage]="resultsPerPage" [categoryIds]="selectedCategoryIds"
                [countryId]="selectedCountryId" [keyword]="keyword"></app-brands-list>
        </div>
    </div>

</div>