<section class="flex-column h-100 mr-md-3 mt-3">
    <div class="row mx-0">
        <div class="col-12 col-sm-7 col-md-6">
            <canvas class="myChart" [id]="chartId"></canvas>
        </div>
        <div class="col-12 col-sm-5 col-md-6 d-flex align-items-start">
            <div>
                <div *ngFor="let yValue of yValues; let i = index" class="d-flex mb-1">
                    <span [style.background]="colors[i]" class="point my-auto mr-2"></span>
                    <p class="mb-0 chart-info">{{yValue}} : <span *ngIf="!isSpend"
                            style="font-weight: 500;">{{xValues[i]}}
                        </span>
                        <span *ngIf="isSpend" style="font-weight: 500;">${{xValues[i] | valueFormat}}
                        </span>
                    </p>
                </div>
            </div>
        </div>

    </div>
</section>