<mat-dialog-content>
    <div class="container-fluid info-text-section-company">
        <div class="container container-1400 py-4">
            <div class="d-flex justify-content-center flex-column">
                <div class="info-text-title">{{brandResponse.brand.name}}</div>
                <div class="d-flex flex-column mx-auto align-items-center">
                    <swipe-button [swipeBoards]="swipeBoards" (swipeRemoved)="RemoveBrandSwipe()"
                        class="w-auto mx-auto mb-2" [isSwiped]="brandResponse.brand.swiped"
                        (swipeSaved)="BrandSwipeSaved($event)"></swipe-button>
                    <app-share-button *ngIf="shareUrl" [copyUrl]="ShareUrl" class="mb-2 w-100"></app-share-button>
                </div>
            </div>
            <div class="info-text text-center">
                {{brandResponse.brand.description}}
            </div>
        </div>
    </div>
    <div class="row mx-0">
        <div class="col-12 col-sm-6 mt-3">
            <app-entity-stat [isSpend]="true" [statName]="'Ad spend 365'"
                [statValue]="entityStats.spend.last365Days | valueFormat">
            </app-entity-stat>
        </div>
        <div class="col-12 col-sm-6 mt-3">
            <app-entity-stat [blur]="!isPayingUser" [isSpend]="true" [statName]="'Ad spend 60'"
                [statValue]="entityStats.spend.last60Days | valueFormat">
            </app-entity-stat>
        </div>
    </div>
    <div class="row mx-0">
        <div class="col-12 col-sm-6 mt-3">
            <app-entity-stat [blur]="!isPayingUser" [isSpend]="true" [statName]="'Ad spend 30'"
                [statValue]="entityStats.spend.last30Days | valueFormat">
            </app-entity-stat>
        </div>
        <div class="col-12 col-sm-6 mt-3">
            <app-entity-stat [isSpend]="false" [statName]="'Global rank'"
                [statValue]="brandResponse.ranks?.global?.rank || '10000+'"
                [statChange]="brandResponse.ranks?.global?.change || 0">
            </app-entity-stat>
        </div>
    </div>
    <div class="row mx-0 mb-3">
        <div class="col-12 col-sm-6 mt-3">
            <app-entity-stat [isSpend]="false" [statName]="'Country rank'"
                [statValue]="brandResponse.ranks?.country?.rank || '1000+'"
                [statChange]="brandResponse.ranks?.country?.change || 0">
            </app-entity-stat>
        </div>
        <div class="col-12 col-sm-6 mt-3">
            <app-entity-stat [isSpend]="false" [statName]="'Category rank'"
                [statValue]="brandResponse.ranks?.category?.rank || '1000+'"
                [statChange]="brandResponse.ranks?.category?.change || 0">
            </app-entity-stat>
        </div>
    </div>
    <div class="d-flex justify-content-center align-items-center mb-3">
        <button (click)="OpenSelectedBrand()" class="btn light-blue-button  mt-3 mr-3">
            Proceed to full info page
        </button>
    </div>
    <div class="d-flex justify-content-start d-sm-none">
        <button (click)="CloseModal()" style="margin-bottom: 80px;" class="btn transparent-button ml-3">Back</button>
    </div>
</mat-dialog-content>