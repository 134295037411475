import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { User } from "src/app/models/user";
import { ApiService } from "src/app/services/api/api.service";
import { StateService } from "src/app/services/shared/state.service";

const tutorialData = [
  {
    name: "Tutorial: Affiliate module",
    url: "https://player.vimeo.com/video/957829249?h=fa6b7eb579&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    bulletPoints: [
      "Find best performing affiliate video ads",
      "Identify profitable offers",
      "Discover the most successful affiliates and strategies",
      "Filter by network, category, and software",
    ],
  },
  {
    name: "Tutorial: Sales funnel analysis",
    url: "https://www.youtube.com/embed/uFhXq7VHSZ0",
    bulletPoints: [
      "Webinar funnels",
      "Sales call funnels",
      "E-commerce funnels",
      "Overview of landing pages",
      "Principles of using the software filter",
    ],
  },
  {
    name: "Tutorial: Filters & direct response ads",
    url: "https://www.youtube.com/embed/HljFvzTozJA",
    bulletPoints: [
      "Advanced filtering & search",
      "Finding current best-performing ads",
      "A 'Weight loss' search example",
      "It takes 1-2 weeks to add new ads to VidTao",
    ],
  },
  {
    name: "Tutorial: How Search works",
    url: "https://www.youtube.com/embed/h8_X2PP27ao",
    bulletPoints: [
      "Search for ads, brands, and companies",
      "Explore all three and analyse them",
    ],
  },
  {
    name: "Tutorial: Winning ad strategy",
    url: "https://www.youtube.com/embed/aITv0ef_gKw",
    bulletPoints: [
      "What it takes to be very successful (volume)",
      "Analysis of top-performing ads",
      "The role of hooks in achieving success",
      "Testing and scaling strategies",
    ],
  },
];

@Component({
  selector: "app-tutorial",
  templateUrl: "./tutorial.component.html",
  styleUrl: "./tutorial.component.scss",
})
export class TutorialComponent implements OnInit {
  displayUrl!: SafeResourceUrl;
  displayBulletPoints: string[] = [];
  displayName: string = "";
  mode: string = "";
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
    public apiService: ApiService,
    public dialogRef: MatDialogRef<TutorialComponent>,
    public stateService: StateService
  ) {
    this.mode = data.mode;
    this.SwitchView(this.mode);
    this.UpdateDb(this.mode);
  }
  ngOnInit(): void {}

  public SwitchView(mode: string) {
    if (mode === "affiliate") {
      this.displayUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        tutorialData[0].url
      );
      this.displayBulletPoints = tutorialData[0].bulletPoints;
      this.displayName = tutorialData[0].name;
      return;
    }
    if (mode === "brands") {
      this.displayUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        tutorialData[1].url
      );
      this.displayBulletPoints = tutorialData[1].bulletPoints;
      this.displayName = tutorialData[1].name;
      return;
    }

    if (mode === "ads") {
      this.displayUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        tutorialData[2].url
      );
      this.displayBulletPoints = tutorialData[2].bulletPoints;
      this.displayName = tutorialData[2].name;
      return;
    }
    if (mode === "search") {
      this.displayUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        tutorialData[3].url
      );
      this.displayBulletPoints = tutorialData[3].bulletPoints;
      this.displayName = tutorialData[3].name;
      return;
    }
    if (mode === "entity") {
      this.displayUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        tutorialData[4].url
      );
      this.displayBulletPoints = tutorialData[4].bulletPoints;
      this.displayName = tutorialData[4].name;
      return;
    }
  }

  public async UpdateDb(mode: string) {
    let result;
    if (mode === "affiliate")
      result = await this.apiService.UpdateUserTutorials({ affiliate: true });
    else if (mode === "brands")
      result = await this.apiService.UpdateUserTutorials({ brands: true });
    else if (mode === "ads")
      result = await this.apiService.UpdateUserTutorials({ ads: true });
    else if (mode === "search")
      result = await this.apiService.UpdateUserTutorials({ search: true });
    else if (mode === "entity")
      result = await this.apiService.UpdateUserTutorials({ entity: true });
    if (result?.data) {
      this.stateService.UpdateUser(result.data);
    }
  }

  public OpenExternalQuickGuidePage() {
    window.open("https://quick-start.vidtao.com", "_blank");
  }

  public async Close() {
    this.dialogRef.close();
  }
}
