import { TrainingVideo } from "./training-video";

export class Training {
  public id: string = "";
  public active: boolean = true;
  public image: string = "";
  public name: string = "";
  public videos: TrainingVideo[] = [];
  public category: string = "";
  public description: string = "";
  //Only vexpower
  public slug: string = "";
}
