import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { DEBOUNCE_TIME2, PageSizes } from "src/app/models/consts";
import { ModuleType } from "src/app/models/enums";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-affiliate-offers",
  templateUrl: "./affiliate-offers.component.html",
})
export class AffiliateOffersComponent implements OnInit, AfterViewInit {
  keyword: string = "";
  selectedCategoryIds: number[] = [0];
  selectedNetworkIds: number[] = [0];
  selectedCountryId: number = 0;
  selectedLanguageId: string = "";

  pageSizes = PageSizes;
  @Input() resultsPerPage = this.pageSizes[3];
  page: number = 1;

  loaded: boolean = false;

  inputControl: FormControl = new FormControl();

  constructor(
    public stateService: StateService,
    public route: ActivatedRoute,
    public router: Router,
    public popupService: PopupService
  ) {}

  ngOnInit(): void {
    this.stateService.TabSelected("offers");
    this.stateService.ModuleSelected(ModuleType.Affiliate);
    this.SubscribeFormControl();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.SubscribeRouteParams();
      this.SubscribeToUser();
    }, 100);
  }

  public SubscribeToUser() {
    this.stateService.user$.subscribe((user) => {
      if (!user) return;
      if (!user?.hasWatchedAffiliate) this.popupService.OpenAffiliateTutorial();
    });
  }

  private SubscribeRouteParams() {
    this.route.queryParams
      .pipe(
        distinctUntilChanged(
          (prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
        )
      )
      .subscribe((params) => {
        if (params["keyword"]) {
          this.keyword = params["keyword"];
          this.inputControl.patchValue(this.keyword, { emitEvent: false });
        }

        if (params["selectedCategoryIds"])
          this.selectedCategoryIds = JSON.parse(params["selectedCategoryIds"]);

        if (params["selectedNetworkIds"])
          this.selectedNetworkIds = JSON.parse(params["selectedNetworkIds"]);

        if (params["selectedCountryId"])
          this.selectedCountryId = Number(params["selectedCountryId"]);

        if (params["selectedLanguageId"])
          this.selectedLanguageId = JSON.parse(params["selectedLanguageId"]);

        if (params["resultsPerPage"])
          this.resultsPerPage = Number(params["resultsPerPage"]);

        if (params["page"]) {
          this.page = Number(params["page"]);
        }
        this.UpdateRoute();
        this.loaded = true;
      });
  }

  public UpdateRoute() {
    const queryParams: NavigationExtras = {
      queryParams: {
        keyword: this.keyword,
        selectedCategoryIds: JSON.stringify(this.selectedCategoryIds),
        selectedNetworkIds: JSON.stringify(this.selectedNetworkIds),
        selectedCountryId: this.selectedCountryId,
        selectedLanguageId: this.selectedLanguageId,
        resultsPerPage: this.resultsPerPage,
        page: this.page,
      },
      relativeTo: this.route,
      replaceUrl: true,
    };
    this.router.navigate([], queryParams);
  }

  private SubscribeFormControl() {
    this.inputControl.valueChanges
      .pipe(debounceTime(DEBOUNCE_TIME2))
      .subscribe(() => {
        this.keyword = this.inputControl.value;
        this.page = 1;
        this.UpdateRoute();
      });
  }

  public SelectedCategoriesChanged(selectedIds: number[]) {
    this.selectedCategoryIds = selectedIds;
    this.page = 1;
    this.UpdateRoute();
  }

  public SelectedNetworksChanged(selectedIds: number[]) {
    this.selectedNetworkIds = selectedIds;
    this.page = 1;
    this.UpdateRoute();
  }

  public SelectedCountryChanged(countryId: number) {
    this.selectedCountryId = countryId;
    this.page = 1;
    this.UpdateRoute();
  }

  public SelectedLanguageChanged(languageId: string) {
    this.selectedLanguageId = languageId;
    this.page = 1;
    this.UpdateRoute();
  }

  public RemoveNetwork(id: number) {
    if (this.selectedNetworkIds.length == 1) {
      this.selectedNetworkIds = [0];
      this.page = 1;
      this.UpdateRoute();
      return;
    }
    this.selectedNetworkIds = this.selectedNetworkIds.filter((i) => i != id);
    this.page = 1;
    this.UpdateRoute();
  }

  public RemoveCategory(id: number) {
    if (this.selectedCategoryIds.length == 1) {
      this.selectedCategoryIds = [0];
      this.page = 1;
      this.UpdateRoute();
      return;
    }
    this.selectedCategoryIds = this.selectedCategoryIds.filter((i) => i != id);
    this.page = 1;
    this.UpdateRoute();
  }

  public RemoveCountry(id: number) {
    this.selectedCountryId = 0;
    this.page = 1;
    this.UpdateRoute();
  }

  public RemoveLanguage(id: string) {
    this.selectedLanguageId = "";
    this.page = 1;
    this.UpdateRoute();
  }

  public CurrentPageChanged(currentPage: number) {
    this.page = currentPage;
    this.UpdateRoute();
  }

  public ResultsPerPageChanged(resultsPerPage: number) {
    this.resultsPerPage = resultsPerPage;
    this.page = 1;
    this.UpdateRoute();
  }

  public OpenAffiliateTutorial() {
    this.popupService.OpenAffiliateTutorial();
  }
}
