import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-entity-stat',
  templateUrl: './entity-stat.component.html',
  styleUrls: ['../../entity-info.component.scss']
})
export class EntityStatComponent implements OnInit {
  @Input() statName: string = '';
  @Input() statValue: string | number = '';
  @Input() statChange: number = 0;
  @Input() isSpend: boolean = true;
  @Input() blur: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
