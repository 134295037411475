import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { ResetPasswordComponent } from "./components/modals/reset-password/reset-password.component";
import { IsLoggedInGuard } from "./utils/guards/isLoggedInGuard";
import { NotLoggedInGuard } from "./utils/guards/NotLoggedInGuard";
import { PricingComponent } from "./pages/nav-links/pricing/pricing.component";
import { AboutComponent } from "./pages/nav-links/about/about.component";
import { ContactComponent } from "./pages/nav-links/contact/contact.component";
import { TermsOfServiceComponent } from "./pages/nav-links/terms-of-service/terms-of-service.component";
import { PrivacyPolicyComponent } from "./pages/nav-links/privacy-policy/privacy-policy.component";
import { ExternalLinkGuard } from "./utils/guards/externalLinkGuard";
import { externalWpLinks } from "./utils/wordpress/old-links";
import { CreativeComponent } from "./pages/premium-content/creative/creative.component";
import { SaveStateGuard } from "./utils/guards/saveStateGuard";

const appRoutes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "home" },
  {
    path: "register",
    component: HomeComponent,
    canActivate: [NotLoggedInGuard],
  },
  {
    path: "home",
    component: HomeComponent,
    canActivate: [NotLoggedInGuard],
  },
  {
    path: "pricing",
    component: PricingComponent,
  },
  {
    path: "about",
    component: AboutComponent,
  },
  {
    path: "terms-of-service",
    component: TermsOfServiceComponent,
    canActivate: [SaveStateGuard],
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
    canActivate: [SaveStateGuard],
  },
  {
    path: "contact",
    component: ContactComponent,
  },
  { path: "reset-password", component: ResetPasswordComponent },
  {
    path: "dashboard",
    loadChildren: () =>
      import(`./pages/dashboard/dashboard.routing.module`).then(
        (m) => m.DashboardRoutingModule
      ),
    canActivate: [IsLoggedInGuard],
  },
  {
    path: "shared",
    loadChildren: () =>
      import(`./pages/dashboard/dashboard.sharing.routing.module`).then(
        (m) => m.DashboardSharingRoutingModule
      ),
  },
  ...externalWpLinks,
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: "enabled",
      useHash: true,
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [],
})
export class AppRoutingModule {}
