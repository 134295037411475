<form [formGroup]="registerForm" (ngSubmit)="Register()" class="container p-4">
    <div class="d-flex position-relative">
        <img class="mx-auto" style="height: 30px;" src="assets/icons/logo-black-notext.png" />
        <img *ngIf="canClose" (click)="CloseModal()" class="my-auto position-absolute"
            style="height: 24px; right: 5px; cursor: pointer;" src="assets/icons/x-close.png" />
    </div>

    <h3 class="title mb-1 mt-3">Account setup</h3>
    <p class="welcome-text">Complete your profile information.</p>


    <p class="label mb-1">First name *</p>
    <input autocomplete="off" formControlName="firstName" type="text" class="form-control input-element"
        placeholder="Enter your first name" />
    <p class="label mb-1 mt-3">Last name</p>
    <input autocomplete="off" formControlName="lastName" type="text" class="form-control input-element"
        placeholder="Enter your last name" />
    <p class="label mb-1 mt-3">Password *</p>
    <input autocomplete="off" formControlName="password" type="password" #password class="form-control input-element"
        placeholder="Enter your password" />
    <!-- <ng-container *ngIf="submitted && registerForm.get('password')?.errors">

    </ng-container> -->

    <p class="label mb-1 mt-3">Repeat password *</p>
    <input autocomplete="off" formControlName="repeatPassword" type="password" #password
        class="form-control input-element" placeholder="Enter your password" />
    <div class="mt-2" id="recaptcha-container"></div>

    <button type="submit" [disabled]="registerForm.invalid" class="btn sign-in-button mt-3">Sign up</button>
</form>