import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "matchingLetter",
})
export class MatchingLetterPipe implements PipeTransform {
  // transform(value: string, searchText: string): string {
  //   if (!searchText || searchText === '') {
  //     return value;
  //   }

  //   const regex = new RegExp(`(${searchText.replace(/\s/g, '').split('').join('.*?')})`, 'gi');
  //   return value.replace(regex, '<span class="emphasize">$1</span>');
  // }

  transform(value: string, searchText: string): string {
    if (!searchText) {
      return value;
    }

    // Escape special characters in the searchText
    const escapedSearchText = searchText.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

    // Create the regex pattern
    const regex = new RegExp(`(${escapedSearchText})`, "gi");

    // Perform the replacement and return the result
    return value.replace(regex, '<span class="emphasize">$1</span>');
  }
}
