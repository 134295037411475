import { Component, Inject, Input } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-show-description",
  templateUrl: "./show-description.component.html",
  styleUrls: ["./show-description.component.scss"],
})
export class ShowDescriptionComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ShowDescriptionComponent>
  ) {
    this.description = this.data.description;
  }
  description: string = "";

  public Close() {
    this.dialogRef.close();
  }
}
