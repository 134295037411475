<ng-container *ngIf="!isMultipleSelection">
    <p-dropdown multiple [autofocusFilter]="false" [class]="'custom-dropdown'" styleClass="w-100"
        [style]="{'width': '100%','height' : '38px', 'align-items' : 'center', 'max-width' : '100%', 'border-radius' : '4px'}"
        [options]="countries" [(ngModel)]="selectedCountry" (ngModelChange)="CountrySelected($event)" optionLabel="name"
        [filter]="true" filterBy="name" [showClear]="false" placeholder="-Select country-">
        <ng-template pTemplate="selectedItem">
            <div class="d-flex align-items-center regular-fs" *ngIf="selectedCountry">
                <img *ngIf="selectedCountry.name != 'Worldwide'"
                    src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                    [class]="'flag mr-2 flag-' + selectedCountry.alpha2Code.toLowerCase()" style="width: 18px" />

                <!-- <img *ngIf="selectedCountry.name == 'Worldwide'" src="assets/icons/worldwide.png" class="mr-2"
                    style="width: 24px" /> -->
                <mat-icon *ngIf="selectedCountry.name == 'Worldwide'" class="icon mr-2"
                    [svgIcon]="'worldwide'"></mat-icon>
                <div>{{ selectedCountry.name }}</div>
            </div>
        </ng-template>

        <ng-template let-country pTemplate="item">
            <div class="d-flex align-items-center">
                <img *ngIf="country.name != 'Worldwide'"
                    src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                    [class]="'flag mr-2 flag-' + country.alpha2Code.toLowerCase()" style="width: 18px" />
                <!-- <img *ngIf="country.name == 'Worldwide'" src="assets/icons/worldwide.png" class="mr-2"
                    style="width: 18px" /> -->
                <mat-icon *ngIf="country.name == 'Worldwide'" class="icon mr-2" [svgIcon]="'worldwide'"></mat-icon>
                <div>{{ country.name }}</div>
            </div>
        </ng-template>
    </p-dropdown>
</ng-container>
<ng-container *ngIf="isMultipleSelection">
    <p-multiSelect multiple [autofocusFilter]="false" [showToggleAll]="false" [class]="'custom-dropdown'"
        styleClass="w-100"
        [style]="{'width': '100%','height' : '38px', 'align-items' : 'center', 'max-width' : '100%', 'border-radius' : '4px'}"
        [options]="countries" [(ngModel)]="selectedCountries" (ngModelChange)="CountriesSelected()" optionLabel="name"
        [filter]="true" filterBy="name" [showClear]="false" placeholder="-Select country-">
        <ng-template style="pointer-events: none !important;" let-country pTemplate="item">
            <div class="d-flex align-items-center">
                <img *ngIf="country.name != 'Worldwide'"
                    src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png"
                    [class]="'flag mr-2 flag-' + country.alpha2Code.toLowerCase()" style="width: 18px" />
                <!-- <img *ngIf="country.name == 'Worldwide'" src="assets/icons/worldwide.png" class="mr-2"
                    style="width: 18px" /> -->
                <mat-icon *ngIf="country.name == 'Worldwide'" class="icon mr-2" [svgIcon]="'worldwide'"></mat-icon>
                <div>{{ country.name }}</div>
            </div>
        </ng-template>
    </p-multiSelect>
</ng-container>