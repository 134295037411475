<!-- <div style="max-width: 660px;" class="container">
    <h1 class="entry-title">Privacy Policy</h1>
    <h2>Who we are</h2>
    <p>Our website address is: https://vidtao.com/</p>
    <h2>What personal data we collect and why we collect it</h2>
    <p>We collect your personal data e.g. name, email, address, telephone number in the process of scheduling
        consultations and follow up communication for working together to grow your business.</p>
    <h2>Who we share your data with</h2>
    <p>We never give your information to anyone or any third party.</p>
    <h2>How long we retain your data</h2>
    <p>For users that register on our website (if any), we also store the personal information they provide in their
        user profile. All users can see, edit, or delete their personal information at any time (except they cannot
        change their username). Website administrators can also see and edit that information.</p>
    <h2>What rights you have over your data</h2>
    <p>If you have an account on this site you can request to receive an exported file of the personal data we hold
        about you, including any data you have provided to us. You can also request that we erase any personal data we
        hold about you. This does not include any data we are obliged to keep for administrative, legal, or security
        purposes.</p>
    <h2>How we protect your data</h2>
    <p>To keep the website safe from any kind of threat we do install firewall & security scanner e.g. malware scanner
        blocks requests that include malicious code or content, protection from brute force attack by limiting login
        attempts, enforcing strong passwords and other login security measures.</p>
</div> -->
<div class="container">
    <div name="termly-embed" data-id="d2627333-b931-458f-acef-a81e2b717226"></div>
</div>