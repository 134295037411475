import { Component, HostBinding, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ModuleType } from "src/app/models/enums";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-in-app-sidebar",
  templateUrl: "./in-app-sidebar.component.html",
  styleUrls: ["./in-app-sidebar.component.scss"],
})
export class InAppSidebarComponent implements OnInit {
  selectedItem: string | null = "";
  moduleType = ModuleType;
  isPayingUser: boolean = false;
  isProduction = environment.production;
  constructor(public stateService: StateService, public router: Router) {}
  @HostBinding("style.min-width.px")
  public width: number = 200;

  ngOnInit(): void {
    this.SubscribeToUser();
  }

  public SubscribeToUser() {
    this.stateService.user$.subscribe((user) => {
      this.isPayingUser = this.stateService.IsPayingUserState(user);
    });

    this.stateService.selectedTabSelected$.subscribe((value) => {
      this.selectedItem = value;
    });
  }

  public SignOut() {
    this.stateService.SignOut();
  }

  ToggleSidebar() {
    let sidebar = document.getElementById("sidebar-main");
    sidebar?.classList.toggle("sidebar-close");

    let toggle = document.getElementById("sidebar-toggle");
    toggle?.classList.toggle("toggle-close");

    this.width == 200 ? (this.width = 0) : (this.width = 200);
  }

  async ModuleTypeChanged(moduleType: ModuleType) {
    if (moduleType == ModuleType.Affiliate) this.SwitchTab("affiliate-ads");
    else this.SwitchTab("search");
  }

  async SwitchTab(item: string) {
    switch (item) {
      case "search":
        await this.router.navigate(["/dashboard"]);
        break;
      case "settings":
        await this.router.navigate(["/dashboard/settings"]);
        break;
      case "trainings":
        await this.router.navigate(["/dashboard/trainings"]);
        break;
      case "rankings":
        await this.router.navigate(["/dashboard/rankings"]);
        break;
      case "swipes":
        await this.router.navigate(["/dashboard/swipes"]);
        break;
      case "affiliate-swipes":
        await this.router.navigate(["/dashboard/swipes"], {
          queryParams: { isAffiliate: true },
        });
        break;
      case "affiliate-ads":
        await this.router.navigate(["/dashboard/affiliate-ads"]);
        break;
      case "offers":
        await this.router.navigate(["/dashboard/affiliate-offers"]);
        break;
      case "affiliates":
        await this.router.navigate(["/dashboard/affiliate-companies"]);
        break;
      case "landers":
        await this.router.navigate(["/dashboard/affiliate-landers"]);
        break;
      case "networks":
        await this.router.navigate(["/dashboard/affiliate-networks"]);
        break;
      case "ads":
        await this.router.navigate(["/dashboard/ads"]);
        break;
      case "brands":
        await this.router.navigate(["/dashboard/brands"]);
        break;
      case "companies":
        await this.router.navigate(["/dashboard/companies"]);
        break;
      case "creatives":
        await this.router.navigate(["/dashboard/creatives"]);
        break;
      default:
        await this.router.navigate(["/dashboard"]);
    }
  }

  public DisableIfNotPayingUser() {
    return {
      opacity: !this.isPayingUser ? "0.5" : "1",
      "user-select": !this.isPayingUser ? "none" : "auto",
      "pointer-events": !this.isPayingUser ? "none" : "auto",
    };
  }
}
