<section class="flex-column h-100 mr-md-3">
    <div class="d-flex justify-content-end mb-4" [ngStyle]="{'margin-top': isVideoChart ? '0px' : '-50px'}">
        <div class="options d-flex justify-content-end">
            <div style="border-radius: 12px;" class="options-div">
                <button class="option-button chart-option" *ngFor="let option of dateRangeOptions"
                    (click)="ChangeGraphDateRange(option)"
                    [ngClass]="selectedChartPeriod == option ? 'option-selected': ''">
                    {{ option === 'Total' ? '2 years' : option }}
                </button>
            </div>
        </div>
    </div>
    <canvas [ngStyle]="getBlurIfNeeded()" class="myChart" [id]="chartId"></canvas>
</section>