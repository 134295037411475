<!-- <mat-form-field appearance="outline" class="no-line">
    <mat-select multiple class="select" [placeholder]="placeholder" [(ngModel)]="selectedSoftwareIds"
        disableOptionCentering>
        <mat-option (click)="SoftwareSelected()" [value]="software.softwareId" *ngFor="let software of softwares">
            {{ software.name }}
        </mat-option>
    </mat-select>
</mat-form-field> -->
<div class="flex justify-content-center">
    <p-treeSelect (onNodeSelect)="NodeSelectionChanged()" (onNodeUnselect)="NodeSelectionChanged()" class="w-full"
        containerStyleClass="w-full" [(ngModel)]="selectedNodes" [options]="nodes" [metaKeySelection]="true"
        [selectionMode]="type" [propagateSelectionUp]="false" [filter]="true" [overlayOptions]="getOverlayOptions()"
        [placeholder]="placeholder"></p-treeSelect>
</div>