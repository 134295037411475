<div class="container-fluid top-section">
    <p class="header mb-1">Rankings</p>
</div>

<div class="container container-1400">
    <div class="row mt-4 align-items-center">
        <div class="col-12 col-md-4 col-lg-3 mb-3">
            <app-entity-select [selectedType]="selectedType" (brandsSelected)="BrandsSelected($event)"
                (companiesSelected)="CompaniesSelected($event)"></app-entity-select>
        </div>
        <div class="col-12 col-md-4 col-lg-3 mb-3">
            <app-category-tree-select [type]="'single'" placeholder="-Select categories-"
                (selectedCategoryChanged)="SelectedCategoryChanged($event)" [selectedCategoryId]="selectedCategoryId">
            </app-category-tree-select>
        </div>
        <div class="col-12 col-md-4 col-lg-3 mb-3">
            <app-country-picker (selectedCountryChanged)="SelectedCountryChanged($event)" [alpha2Code]="'ALL'"
                [selectedCountryId]="selectedCountryId"></app-country-picker>
        </div>
        <div class="col-12 col-md-3 col-lg-2 ml-lg-auto mb-3">
            <app-interval-select [selectedInterval]="selectedInterval"
                (intervalSelected)="IntervalSelected($event)"></app-interval-select>
        </div>
    </div>
</div>

<div class="container container-1400 mb-5">
    <div class="table-container">
        <div class="filters-div">
        </div>
        <ng-container *ngIf="type == searchType.Companies">
            <app-companies-ranking-list [selectedInterval]="selectedInterval" [selectedCategoryId]="selectedCategoryId"
                [selectedInterval]="selectedInterval" [selectedCountryId]="selectedCountryId" [loadedParams]="loaded"
                [currentPage]="page" [resultsPerPage]="resultsPerPage"
                (resultsPerPageChanged)="ResultsPerPageChanged($event)"
                (currentPageChanged)="CurrentPageCompaniesChanged($event)">
            </app-companies-ranking-list>
        </ng-container>
        <ng-container *ngIf="type == searchType.Brands">
            <app-brands-ranking-list [selectedInterval]="selectedInterval" [selectedCategoryId]="selectedCategoryId"
                [selectedInterval]="selectedInterval" [selectedCountryId]="selectedCountryId" [loadedParams]="loaded"
                [currentPage]="page" [resultsPerPage]="resultsPerPage"
                (resultsPerPageChanged)="ResultsPerPageChanged($event)"
                (currentPageChanged)="CurrentPageBrandsChanged($event)">
            </app-brands-ranking-list>
        </ng-container>
    </div>
</div>