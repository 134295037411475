import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { PageSizes } from "src/app/models/consts";
import { SearchRequest } from "src/app/models/searchRequest";
import { SearchResponse } from "src/app/models/searchResponse";
import { ApiService } from "src/app/services/api/api.service";
import { StateService } from "src/app/services/shared/state.service";
import { PaginatorService } from "src/app/services/shared/paginator.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { Company } from "src/app/models/company";
import { ModuleType } from "src/app/models/enums";
import { StaticDataService } from "src/app/services/shared/static.data.service";
import { SwipeBoard } from "src/app/models/swipeBoard";

@Component({
  selector: "app-companies-list",
  templateUrl: "./companies-list.component.html",
  styleUrls: ["./companies-list.component.scss"],
})
export class CompaniesListComponent implements OnInit, OnChanges {
  searchResultsCompanies!: SearchResponse<Company>;
  allCompanies: Company[] = [];
  displayedCompanies: Company[] = [];

  pageSizes = PageSizes;
  @Input() resultsPerPage = this.pageSizes[3];
  @Input() currentPageCompanies = 1;
  activeIndex: number = 1;

  contentLoaded: boolean = false;

  @Input() type: string = "companies";
  @Input() keyword: string = "";
  @Input() brandId: number = 0;
  @Input() offerId: number = 0;
  @Input() isAffiliate: boolean = false;
  @Input() searchByKeyword: boolean = false;

  @Input() affiliateNetworkIds: number[] = [];
  @Input() categoryIds: number[] = [];
  @Input() softwareIds: number[] = [];
  @Input() countryId: number = 0;
  @Input() languageId: string = "";

  @Input() isPayingUser: boolean = true;
  @Input() isSwipes: boolean = false;
  sortProp = "total";
  orderAsc = false;
  sortingIndex: number = 1;

  @Output() companiesLoaded: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() companiesCountLoaded: EventEmitter<number> =
    new EventEmitter<number>();
  @Input() swipeBoards: SwipeBoard[] = [];
  @Input() selectedBoardId: number = 0;
  @Output() boardsSelected: EventEmitter<object> = new EventEmitter<object>();
  @Input() shareBoardId: string = "";
  @Input() optionalUrl: string = "";
  @Input() encryptedId: string = "";
  @Input() loadedParams: boolean = false;

  @Output() currentPageChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() resultsPerPageChanged: EventEmitter<number> =
    new EventEmitter<number>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public stateService: StateService,
    public staticDataService: StaticDataService,
    public apiService: ApiService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
    this.SubscribeToUser();
    this.LoadSwipeBoards();
  }

  public SubscribeToUser() {
    this.stateService.user$.subscribe((user) => {
      this.isPayingUser = this.stateService.IsPayingUserState(user);
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.loadedParams) {
      await this.LoadData();
      this.companiesLoaded.emit(true);
    }
  }

  private async LoadData() {
    this.contentLoaded = false;
    if (this.type == "companies")
      await this.LoadCompanies(); //companies by keyword, regular pagination
    else if (this.type == "affiliate-companies")
      await this.LoadAffiliateCompanies(); //companies by keyword, regular pagination
    else if (this.type == "swiped-companies")
      await this.LoadSwipedCompanies(); //regular pagination
    else if (this.type == "brand-companies")
      await this.LoadCompaniesForBrand(); //retrieves all, frontend pagination
    else if (this.type == "offer-companies") await this.LoadCompaniesForOffer(); //retrieves all, frontend pagination
  }

  public async LoadCompanies() {
    const result = await this.apiService.FullSearchCompanies(
      new SearchRequest(
        this.keyword,
        this.resultsPerPage,
        this.currentPageCompanies,
        this.sortProp,
        this.orderAsc,
        this.isAffiliate,
        this.affiliateNetworkIds,
        this.categoryIds,
        this.countryId,
        undefined,
        undefined,
        undefined,
        undefined,
        this.softwareIds
      )
    );
    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data?.results) {
      this.contentLoaded = true;
      this.searchResultsCompanies = result.data;
      this.allCompanies = result.data.results;
      this.displayedCompanies = [...this.allCompanies];
    }
  }
  public async LoadAffiliateCompanies() {
    const result = await this.apiService.LoadAffiliateCompanies(
      new SearchRequest(
        this.keyword,
        this.resultsPerPage,
        this.currentPageCompanies,
        this.sortProp,
        this.orderAsc,
        this.isAffiliate,
        this.affiliateNetworkIds,
        this.categoryIds,
        this.countryId,
        this.languageId,
        undefined,
        undefined,
        undefined,
        this.softwareIds
      )
    );
    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data?.results) {
      this.contentLoaded = true;
      this.searchResultsCompanies = result.data;
      this.allCompanies = result.data.results;
      this.displayedCompanies = [...this.allCompanies];
    }
  }
  public async LoadSwipedCompanies() {
    let result;
    if (!this.shareBoardId)
      result = await this.apiService.GetSwipedCompanies(
        this.currentPageCompanies,
        this.resultsPerPage,
        this.keyword,
        this.sortProp,
        this.orderAsc,
        this.isAffiliate,
        this.selectedBoardId
      );
    else
      result = await this.apiService.GetSharedBoardCompanies(
        this.currentPageCompanies,
        this.resultsPerPage,
        this.keyword,
        this.sortProp,
        this.orderAsc,
        this.isAffiliate,
        this.shareBoardId
      );

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data?.results) {
      this.contentLoaded = true;
      this.searchResultsCompanies = result.data;
      this.allCompanies = result.data.results;
      this.displayedCompanies = [...this.allCompanies];
    }
  }
  public async LoadCompaniesForBrand() {
    const result = await this.apiService.LoadCompaniesForBrand(
      this.brandId,
      this.isPayingUser,
      this.optionalUrl,
      this.encryptedId
    );
    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data?.results) {
      this.contentLoaded = true;
      this.searchResultsCompanies = result.data;
      this.currentPageCompanies = 1;
      this.PaginateCompanies();
    }
  }
  public async LoadCompaniesForOffer() {
    const result = await this.apiService.LoadCompaniesForOffer(
      this.offerId,
      this.optionalUrl,
      this.encryptedId
    );
    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data?.results) {
      this.contentLoaded = true;
      this.searchResultsCompanies = result.data;
      this.companiesCountLoaded.emit(result.data?.results.length);
      this.currentPageCompanies = 1;
      this.PaginateCompanies();
    }
  }
  private PaginateCompanies() {
    if (this.searchResultsCompanies.results) {
      const start = (this.currentPageCompanies - 1) * this.resultsPerPage;
      const end =
        (this.currentPageCompanies - 1) * this.resultsPerPage +
        this.resultsPerPage;
      this.displayedCompanies = this.searchResultsCompanies.results?.slice(
        start,
        end
      );

      if (
        this.displayedCompanies.length <
        this.searchResultsCompanies.results?.slice(start, end + 1).length
      )
        this.searchResultsCompanies.hasMore = true;
      else this.searchResultsCompanies.hasMore = false;
    }
  }

  public async NewChunkCompanies(value: number) {
    this.currentPageCompanies = value;
    if (this.type == "companies") await this.LoadCompanies();
    else if (this.type == "affiliate-companies")
      await this.LoadAffiliateCompanies();
    else if (this.type == "swiped-companies") await this.LoadSwipedCompanies();
    else this.PaginateCompanies();
  }

  public async ChangePageCompanies(newPage: number) {
    if (!this.searchByKeyword) await this.NewChunkCompanies(newPage);
    this.currentPageChanged.emit(newPage);
  }

  async onPageSizeChange(value: string) {
    this.resultsPerPage = this.pageSizes.filter((ps) => ps == Number(value))[0];
    this.currentPageCompanies = 1;
    if (!this.searchByKeyword) {
      if (this.type == "companies") await this.LoadCompanies();
      else if (this.type == "affiliate-company")
        await this.LoadAffiliateCompanies();
      else if (this.type == "swiped-companies")
        await this.LoadSwipedCompanies();
      else this.PaginateCompanies();
    }
    this.resultsPerPageChanged.emit(this.resultsPerPage);
  }

  public async SetSorting(index: number, sortProp: string, sortOrder: boolean) {
    this.currentPageCompanies = 1;
    this.sortingIndex = index;
    this.sortProp = sortProp;
    this.orderAsc = sortOrder;
    await this.LoadData();
  }

  public OpenSelectedCompany(company: Company) {
    const currentRoute = this.route.snapshot.routeConfig?.path;
    // if (this.type == "company")
    //   this.router.navigate(['dashboard/entity-info'], { queryParams: { companyId: company.companyId } })
    // else
    // this.popupService.OpenCompanyPreview(
    //   company.companyId,
    //   currentRoute,
    //   this.isAffiliate
    // );
    this.router.navigate(["dashboard/entity-info"], {
      queryParams: {
        companyId: company.companyId,
        ...(this.isAffiliate && { isAffiliate: this.isAffiliate }),
      },
    });
  }

  public async OpenSelectedCategory(event: Event, categoryId: number) {
    event.stopPropagation();
    if (this.isPayingUser) {
      this.stateService.ModuleSelected(ModuleType.Marketer); // if in affiliate mode to switch to marketer before opening ranking page
      await this.router.navigate(["/dashboard/rankings"], {
        queryParams: { selectedCategoryId: categoryId },
      });
    } else this.popupService.AskForAccountUpgrade();
  }

  public getBlurIfNeeded(index: number) {
    let show =
      this.isAffiliate && this.currentPageCompanies > 1 && !this.isPayingUser;
    return {
      filter: show ? "blur(7px)" : "blur(0)",
      "user-select": show ? "none" : "auto",
      "pointer-events": show ? "none" : "auto",
    };
  }

  public BoardsSelected(boardIds: number[], companyId: number) {
    this.boardsSelected.emit({ boardIds, companyId });
  }

  public async DeleteCompanySwipe(companyId: number) {
    const result = await this.apiService.RemoveSwipedCompany(companyId);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    this.popupService.Notify("Company successfuly removed from Swipes.");
    await this.LoadData();
  }

  public RemoveCompanySwipe(company: Company) {
    this.popupService.AskToConfirm(
      "Swipe deletion",
      "Are you sure that you want to delete selected company?",
      async () => {
        await this.DeleteCompanySwipe(company.companyId);
        company.isSwiped = false;
      }
    );
  }

  public async CompanySwipeSaved(swipeBoardIds: number[], company: Company) {
    const result = await this.apiService.SwipeCompany(
      company.companyId,
      swipeBoardIds
    );

    if (result?.errorMessage) {
      this.popupService.Notify(result?.errorMessage);
      return;
    }

    this.popupService.Notify(
      `${company.legalName} successfuly saved to favorites!`
    );
    company.isSwiped = true;
  }

  async LoadSwipeBoards() {
    const result = await this.apiService.GetSwipeBoards();
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data)
      this.swipeBoards = [
        {
          name: "All swipes",
          swipeBoardId: 0,
          isChecked: true,
          shareId: "",
          isShared: false,
          email: "",
          userName: "",
        },
        ...result.data,
      ];
  }

  public GetCategoryLink(categoryId: number) {
    return (
      window.location.origin +
      "/#/dashboard/rankings?selectedCategoryId=" +
      categoryId
    );
  }
}
