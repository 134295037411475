import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Country } from "src/app/models/country";
import { StateService } from "src/app/services/shared/state.service";
import { StaticDataService } from "src/app/services/shared/static.data.service";

const allCountriesOption = {
  countryId: 0,
  name: "Worldwide",
  alpha2Code: "all",
  alpha3Code: "",
};

@Component({
  selector: "app-country-picker",
  templateUrl: "./country-picker.component.html",
  styleUrls: ["./country-picker.component.scss"],
  host: {
    "[class.p-inputwrapper-focus]": "focused",
  },
})
export class CountryPickerComponent implements OnChanges {
  countries: any[] = [];

  selectedCountry!: Country;
  selectedCountries: Country[] = [];

  @Input() selectedCountryId: number = 0;
  @Input() selectedCountryIds: number[] = [];

  @Input() alpha2Code!: string;
  @Input() isMultipleSelection: boolean = false;
  @Output() selectedCountryChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() selectedCountriesChanged: EventEmitter<number[]> = new EventEmitter<
    number[]
  >();

  constructor(
    private stateService: StateService,
    public staticDataService: StaticDataService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.alpha2Code != "") {
      this.countries = [...this.staticDataService.Countries];
      if (this.isMultipleSelection) {
        this.InitMultipleSelection();
        return;
      }
      this.InitSingleSelection();
    }
  }

  public InitSingleSelection() {
    if (this.alpha2Code == "ALL") {
      this.countries.unshift(allCountriesOption);
      if (this.selectedCountryId != 0)
        this.selectedCountry = this.countries.filter(
          (c) => c.countryId == this.selectedCountryId
        )[0];
      else this.selectedCountry = this.countries[0];
    } else {
      this.selectedCountry = this.countries.filter(
        (c) => c.alpha2Code.toLowerCase() == this.alpha2Code.toLowerCase()
      )[0];
    }
  }
  public InitMultipleSelection() {
    this.countries.unshift(allCountriesOption);
    this.selectedCountries = this.countries.filter((c) =>
      this.selectedCountryIds.includes(c.countryId)
    );
  }

  public CountrySelected(country: Country) {
    this.selectedCountryChanged.emit(country.countryId);
  }

  public CountriesSelected() {
    this.selectedCountriesChanged.emit(
      this.selectedCountries.map((c) => c.countryId)
    );
  }
}
