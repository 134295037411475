import { Component, Input } from "@angular/core";

@Component({
  selector: "app-skeleton-loader",
  templateUrl: "./skeleton-loader.component.html",
  styleUrls: ["./skeleton-loader.component.scss"],
})
export class SkeletonLoaderComponent {
  @Input() linesNum: number = 0;
  @Input() height: string = "60px";
}
