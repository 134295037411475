<div *ngIf="statValue" class="info-section-main">
    <div class="card-padding-big">
        <div class="stat-heading-small">
            {{statName}}
        </div>
        <div class="stat-heading d-flex">
            <div [ngStyle]="{'filter': blur ? 'blur(5px)' : 'blur(0)', 'user-select': blur ? 'none' : 'auto'}"
                class="mt-2 d-flex align-items-center flex-wrap">
                <span *ngIf="isSpend" class="mr-3">${{statValue}}</span>
                <span *ngIf="!isSpend" class="mr-3">{{statValue}}</span>
                <ng-container *ngIf="statChange != 0">
                    <app-rating-change [change]="statChange"></app-rating-change>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<div class="info-section-main-small-padd" *ngIf="!statValue">
    <app-skeleton-loader [linesNum]="1"></app-skeleton-loader>
</div>