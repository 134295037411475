import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { StateService } from "../shared/state.service";
import { ApiService } from "../api/api.service";
import { UserPurchasedGtm } from "../../utils/gtm";
import { User } from "src/app/models/user";

@Injectable({
  providedIn: "root",
})
export class PaddleService {
  constructor(
    private stateService: StateService,
    private afa: AngularFireAuth,
    private afs: AngularFirestore,
    public apiService: ApiService
  ) {}

  // @ts-ignore
  paddle = Paddle;

  OpenSubscriptionsCheckout(
    userId: string,
    email: string | null,
    subscriptionPlanId: number,
    closeModal: Function
  ) {
    this.paddle.Checkout.open({
      method: "inline",
      product: subscriptionPlanId,
      allowQuantity: false,
      disableLogout: true,
      frameTarget: "checkout-container",
      passthrough: `{"userId": "${userId}"}`,
      frameInitialHeight: 450,
      successCallback: async (event: any) => {
        var user = await this.apiService.SubscribeUser(event);
        while (user?.data?.plan === "free") {
          await this.Sleep(200);
          user = await this.apiService.SubscribeUser(event);
        }
        this.stateService.UpdateUser(user?.data);
        UserPurchasedGtm(subscriptionPlanId);
        closeModal();
      },
      frameStyle:
        "width:100%; min-width:312px; background-color: transparent; border: none;",
      ...(email && { email }),
    });
  }

  Sleep(milliseconds: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(resolve, milliseconds);
    });
  }
}
