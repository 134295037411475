import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { GetPaymentPlans } from "src/app/models/getPaymentPlans";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";

@Component({
  selector: "app-select-paying-plan",
  templateUrl: "./select-paying-plan.component.html",
  styleUrls: ["../login/login.component.scss"],
})
export class SelectPayingPlanComponent implements OnInit {
  availablePlans: GetPaymentPlans = new GetPaymentPlans();

  freeChecked: boolean = true;
  monthlyChecked: boolean = false;
  yearlyChecked: boolean = false;

  constructor(
    public apiService: ApiService,
    public popupService: PopupService,
    public dialogRef: MatDialogRef<SelectPayingPlanComponent>
  ) {}

  ngOnInit(): void {
    this.LoadPaymentPlans();
  }

  public async LoadPaymentPlans() {
    const result = await this.apiService.GetAvailablePlans();

    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);

    if (result?.data) this.availablePlans = result.data;
  }

  public async SelectPlan(plan: string) {
    if (plan === "free") {
      this.freeChecked = true;
      this.monthlyChecked = false;
      this.yearlyChecked = false;
    } else if (plan === "monthly") {
      this.freeChecked = false;
      this.monthlyChecked = true;
      this.yearlyChecked = false;
    } else if (plan === "yearly") {
      this.freeChecked = false;
      this.monthlyChecked = false;
      this.yearlyChecked = true;
    }
  }

  public CloseModal() {
    this.dialogRef.close();
  }

  public SelectPlanAndProceed() {
    let plan;
    if (this.monthlyChecked) plan = this.availablePlans.monthly;
    else if (this.yearlyChecked) plan = this.availablePlans.yearly;
    else plan = 0;
    this.dialogRef.close(plan);
  }
}
