import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { Duration } from "src/app/models/duration";
import { StateService } from "src/app/services/shared/state.service";
import { StaticDataService } from "src/app/services/shared/static.data.service";

@Component({
  selector: "app-duration-select",
  templateUrl: "./duration-select.component.html",
  styleUrls: ["./duration-select.component.scss"],
})
export class DurationSelectComponent implements OnChanges {
  @Input() selectedDuration: string = "";
  @Input() placeholder: string = "";
  @Output() durationSelected: EventEmitter<string> = new EventEmitter<string>();

  durations: Duration[] = [];
  constructor(
    public stateService: StateService,
    public staticDataService: StaticDataService
  ) {}

  ngOnChanges(): void {
    this.durations = [...this.staticDataService.durations];
  }

  public DurationSelected(interval: string) {
    this.durationSelected.emit(interval);
  }
}
