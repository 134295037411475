import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { map } from "rxjs";
import { StateService } from "src/app/services/shared/state.service";
import { UserOpenedSessionStrategyBanner } from "src/app/utils/gtm";

@Component({
  selector: "app-book-strategy-session",
  templateUrl: "./book-strategy-session.component.html",
  styleUrl: "./book-strategy-session.component.scss",
})
export class BookStrategySessionComponent implements OnInit {
  // calendlyUrl: string = "https://calendly.com/inceptly/ian-nagy-30-min";
  calendlyUrl: string =
    "https://inceptly.typeform.com/vidtaostrategy?utm_source=vidtao&utm_medium=banner&utm_campaign=consultation";
  email: string = "";
  @Output() removeSessionBanner: EventEmitter<void> = new EventEmitter();
  constructor(public stateService: StateService) {}
  ngOnInit(): void {}

  public SubscribeUser() {
    this.stateService.user$.subscribe((user) => {
      if (user) this.email = user.email;
    });
  }

  public OpenCalendlyInNewTab() {
    UserOpenedSessionStrategyBanner(this.email);
    window.open(this.calendlyUrl, "_blank");
  }

  public HideBanner() {
    this.removeSessionBanner.emit();
  }
}
