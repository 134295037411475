import { Component, EventEmitter, Input, Output } from "@angular/core";
import { StateService } from "src/app/services/shared/state.service";
import { StaticDataService } from "src/app/services/shared/static.data.service";

@Component({
  selector: "app-filter-chips",
  templateUrl: "./filter-chips.component.html",
})
export class FilterChipsComponent {
  @Input() selectedNetworkIds: number[] = [];
  @Input() selectedOfferIds: number[] = [];
  @Input() selectedCategoryIds: number[] = [];
  @Input() selectedSoftwareIds: number[] = [];
  @Input() selectedCountryId: number = 0;
  @Input() selectedLanguageId: string = "";
  @Input() selectedDurationId: string = "";
  @Input() displayingAds: boolean = false;

  @Input() isAffiliate: boolean = false;

  @Output() removeNetworkChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() removeOfferChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() removeCategoryChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() removeAllCategoriesChanged: EventEmitter<void> = new EventEmitter();
  @Output() removeSoftwareChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() removeCountryChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() removeLanguageChanged: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() removeDurationChanged: EventEmitter<string> =
    new EventEmitter<string>();

  constructor(
    public stateService: StateService,
    public staticDataService: StaticDataService
  ) {}

  public RemoveNetwork(id: number) {
    this.removeNetworkChanged.emit(id);
  }

  public RemoveOffer(id: number) {
    this.removeOfferChanged.emit(id);
  }

  public RemoveCategory(id: number) {
    this.removeCategoryChanged.emit(id);
  }

  public RemoveSoftware(id: number) {
    this.removeSoftwareChanged.emit(id);
  }

  public RemoveCountry(id: number) {
    this.removeCountryChanged.emit(id);
  }

  public RemoveLanguage(id: string) {
    this.removeLanguageChanged.emit(id);
  }

  public RemoveDuration(id: string) {
    this.removeDurationChanged.emit(id);
  }

  public RemoveAllCategories() {
    this.removeAllCategoriesChanged.emit();
  }
}
