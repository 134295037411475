import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { PageSizes } from 'src/app/models/consts';
import { NonVideoAd } from 'src/app/models/nonVideoAd';
import { SearchResponse } from 'src/app/models/searchResponse';
import { ApiService } from 'src/app/services/api/api.service';
import { PaginatorService } from 'src/app/services/shared/paginator.service';
import { PopupService } from 'src/app/services/shared/popup.service';

@Component({
  selector: 'app-non-video-ads',
  templateUrl: './non-video-ads.component.html',
  styleUrls: ['./non-video-ads.component.scss']
})
export class NonVideoAdsComponent implements OnInit, OnChanges {
  @Input() isDisplayAds: boolean = false;
  @Input() isTextAds: boolean = false;
  @Input() companyId: number = 0;

  nonVideoAds: SearchResponse<NonVideoAd> = new SearchResponse<NonVideoAd>();

  pageSizes = PageSizes;
  @Input() resultsPerPage = this.pageSizes[3];

  currentPageCompanies = 1;
  totalPagesCompanies = 0;
  pagesCompanies: string[] = [];

  constructor(private apiService: ApiService,
    private paginator: PaginatorService,
    private popupService: PopupService,
    private spinner: NgxSpinnerService) { }

  async ngOnInit(): Promise<void> {
    await this.LoadAds(true, 0)
  }

  async ngOnChanges(): Promise<void> {
    await this.LoadAds(true, 0)
  }

  private async LoadAds(requiresTotal: boolean, offset: number) {
    let result;
    if (this.isDisplayAds)
      result = await this.apiService.LoadCompaniesDisplayAds(this.companyId, this.currentPageCompanies, this.resultsPerPage)
    else
      result = await this.apiService.LoadCompaniesTextAds(this.companyId, this.currentPageCompanies, this.resultsPerPage)

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) {
      this.nonVideoAds = result.data;
      this.pagesCompanies = this.paginator.GetPagesArray(this.currentPageCompanies, this.totalPagesCompanies);
    }

  }

  async onPageSizeChange(value: string) {
    this.currentPageCompanies = 1;
    this.resultsPerPage = this.pageSizes.filter(ps => ps == Number(value))[0];
    await this.ngOnInit();
  }

  public async ChangePageCompanies(newPage: number) {
    this.currentPageCompanies = newPage;
    //this.spinner.show();
    await this.LoadAds(false, (this.currentPageCompanies - 1) * this.resultsPerPage);
    //this.spinner.hide();
  }

  public OpenNewTab(url: string) {
    window.open(url, '_blank');
  }

}
