export function UserSignedUpGtm() {
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
  //@ts-ignore
  dataLayer.push({
    event: "sign_up",
  });
}

export function UserPurchasedGtm(planId: any) {
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
  //@ts-ignore
  dataLayer.push({
    event: "purchase",
    value: planId,
  });
}

export function UserOpenedSessionStrategyBanner(email: string) {
  //@ts-ignore
  window.dataLayer = window.dataLayer || [];
  //@ts-ignore
  dataLayer.push({
    event: "sessionStrategyCTA",
    eventLabel: email,
  });
}
