import {
  Directive,
  Input,
  ElementRef,
  Renderer2,
  HostListener,
} from "@angular/core";
import { PopupService } from "src/app/services/shared/popup.service";

@Directive({
  selector: "[copyToClipboard]",
})
export class CopyClipboardTextDirective {
  constructor(public popupService: PopupService) {}
  @Input("copyToClipboard") textToCopy: string = "";
  @HostListener("click", ["$event"])
  onClick(event: Event): void {
    event.preventDefault();
    const listener = (e: ClipboardEvent) => {
      e.clipboardData?.setData("text/plain", this.textToCopy);
      e.preventDefault();
      document.removeEventListener("copy", listener);
    };
    document.addEventListener("copy", listener);
    document.execCommand("copy");
    this.popupService.Notify("Link copied to clipboard!");
  }
}
