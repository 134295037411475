import { Component, OnInit } from "@angular/core";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-newsletters-info",
  templateUrl: "./newsletters-info.component.html",
})
export class NewslettersInfoComponent implements OnInit {
  userJoined: boolean = false;
  constructor(
    public apiService: ApiService,
    public stateService: StateService,
    public popupService: PopupService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.CheckIfUserSubscribed();
  }

  public async SubscribeToNewsletter() {
    const result = await this.apiService.SubscribeToNewsletters();

    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);

    if (result?.data) this.userJoined = result.data.subscribed;
  }

  public async UnsubscribeFromNewsletter() {
    const result = await this.apiService.UnsubscribeFromNewsletters();

    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);

    if (result?.data) this.userJoined = !result.data.unsubscribed;
  }

  public async CheckIfUserSubscribed() {
    const result = await this.apiService.GetNewslettersStatus();

    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);

    if (result?.data) this.userJoined = result.data.subscribed;
  }
}
