<div class="p-3">
    <div class="iframe-div frame-width">
        <iframe class="frame-player" [src]="displayUrl" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
            allowfullscreen>
        </iframe>
    </div>
    <p class="account-section-title text-center mt-3 mb-3">{{displayName}}</p>
    <div class="px-4">
        <ul class="section-desc mb-4">
            <li *ngFor="let point of displayBulletPoints">{{point}}</li>
        </ul>
    </div>
    <div class="d-flex justify-content-evenly">
        <button (click)="OpenExternalQuickGuidePage()" style="min-width: 200px;"
            class="option-button btn custom-btn custom-btn1">Quick
            guide</button>
        <button (click)="Close()" style="min-width: 200px;" class="btn transparent-button">Skip Tutorial</button>
    </div>
</div>