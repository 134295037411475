<mat-dialog-content style="padding: 24px !important;">
    <section class="d-block d-flex justify-content-between">
        <div class="row w-100 mx-0">
            <div class="pr-2 col-12 col-sm-9">
                <h1 style="word-break: break-all;" class="title mt-0">{{video.title}}</h1>
                <p (click)="OpenVideoBrand()" class="text-desc-2 mb-0">Brand: <span [ngStyle]="getBlurIfNeeded()"
                        class="colored-text clickable-link">{{video?.brandName}}</span></p>
                <div class="d-flex flex-row flex-wrap mt-2">
                    <p class="mb-0 mr-2 text-desc-2">Category tags: </p>
                    <div class="d-flex">
                        <div [ngStyle]="getBlurIfNeeded()" (click)="OpenSelectedCategory()"
                            class="category text-desc-2 clickable-link">
                            {{staticDataService.categoriesMap.get(video.categoryId)?.name}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="buttons-div d-flex flex-column justify-content-between col-12 col-sm-3 mt-2 mt-sm-0 ">
                <div class="d-flex flex-wrap flex-column mt-2">
                    <div class="d-flex align-items-center">
                        <swipe-button [swipeBoards]="swipeBoards" class="flex-grow-1" [isSwiped]="video.swiped"
                            (swipeSaved)="SwipeSaved($event)" (swipeRemoved)="RemoveSwipe()"></swipe-button>
                    </div>
                    <app-share-button [type]="'video'" class="mt-2" *ngIf="shareUrl" [categoryId]="video.categoryId"
                        [copyUrl]="ShareUrl" [ytVideoId]="ytVideoId" [brandId]="video.brandId"></app-share-button>
                </div>
            </div>

        </div>
    </section>
    <div class="d-flex mt-4">
        <app-yt-player-controls (videoStarted)="VideoStarted()" (watching30Seconds)=" Watching30Seconds()"
            (watching80Percent)="Watching80Percent()" class="w-100" [time]="jumpTime"
            [ytVideoId]="ytVideoId"></app-yt-player-controls>
    </div>
    <div class="d-flex justify-content-center my-3">
        <button *ngIf="videoTranscripts.length > 0" (click)="ViewTranscript()" class="btn transparent-button mr-3">{{
            displayTranscript ?
            'Hide transcript' : 'View transcript' }}</button>
        <button *ngIf="video.description.length > 0" (click)="displayDescription = !displayDescription"
            class="btn transparent-button">{{ displayDescription ?
            'Hide description' : 'View description' }}</button>
    </div>
    <section [ngClass]="displayTranscript ? 'd-block': 'd-none'">
        <div class="d-flex flex-wrap">
            <ng-container *ngFor="let transcript of videoTranscripts">
                <div class="d-flex">
                    <p (click)="JumpToVideoSeconds(transcript.moment)" class="mb-1 mr-1 section-desc link-text">
                        {{transcript.moment}}</p>
                    <p class="mb-1 mr-3 section-desc">{{transcript.text}}</p>
                </div>
            </ng-container>
        </div>
    </section>

    <section [ngClass]="displayDescription ? 'd-block': 'd-none'" [ngStyle]="getBlurIfNeeded()"
        *ngIf="video.description" class="mb-2 mt-3">
        <p class="text-desc-2">
            <span class="light-dark">Video description:</span>
            {{video.description}}
        </p>
    </section>

    <section class="mt-3 p-0 overflow-hidden">
        <table>
            <thead style="border-top: none">
                <tr>
                    <th class="text-center">Thumbnail</th>
                    <th>Total ad spend</th>
                    <th>Ad spend 30</th>
                    <th>First seen</th>
                    <th>Last seen</th>
                    <th>Publish Date</th>
                    <th>Length</th>
                </tr>
            </thead>
            <tbody [ngStyle]="getBlurIfNeeded()">
                <tr>
                    <td class="logo-display" data-label="Frame">
                        <div class="icon-container ml-4 thumbnail">
                            <img style="width: 40px; height: 40px;" [src]='video.thumbnail' />
                        </div>
                    </td>
                    <td data-label="Total ad spend">${{(video.totalSpend || 0) | valueFormat}}
                    </td>
                    <td data-label="Ad spend 30">${{(video.last30Days || 0) | valueFormat}}
                    </td>
                    <td data-label="First seen">
                        <ng-container *ngIf="video.firstSeen">
                            {{video.firstSeen | dateFormat}}
                        </ng-container>
                        <ng-container *ngIf="!video.firstSeen">
                            <i>No data</i>
                        </ng-container>
                    </td>
                    <td data-label="Last seen">
                        <ng-container *ngIf="video.isActive">
                            <i>Active</i>
                        </ng-container>
                        <ng-container *ngIf="!video.isActive && video.lastSeen">
                            {{video.lastSeen | dateFormat}}
                        </ng-container>
                        <ng-container *ngIf="!video.isActive && !video.lastSeen">
                            <i>No data</i>
                        </ng-container>
                    </td>
                    <td data-label="Publish Date">{{video.publishedAt | dateFormat}}</td>
                    <td data-label="Length">{{video.duration | durationFormat}}</td>
                </tr>
            </tbody>
        </table>
    </section>

    <div class="mt-3 mb-3">
        <div class="row mx-0">
            <div class="col-12 px-0 mb-4 mb-md-0">
                <section class="flex-column h-100">
                    <app-line-chart [isPayingUser]="isPayingUser" [isVideoChart]="true" [yValues]="yValues"
                        [xValues]="xValues" [dateRangeOptions]="['30 days', '12 months', 'Total']"
                        selectedChartPeriod='12 months' chartId='videoInfo' yTitle="Estimated ad spend(USD)"
                        xTitle="Period" type="line" timeUnit="day" (dateRangeChanged)="ChangeGraphDateRange($event)">
                    </app-line-chart>
                </section>

            </div>
        </div>
    </div>


    <div class="row d-flex flex-wrap mt-0 mb-4 mx-0">
        <div class="col-12 col-md-6 px-0 mb-4 mb-md-0 p-0">
            <section *ngIf="videoStats.clickUrls.length > 0" class="d-flex mr-md-3  flex-column">
                <p class="title2 mb-0">Landing pages</p>
                <div class="underline mt-1 mb-2"></div>
                <div [ngStyle]="getBlurIfNeeded()" style="cursor: pointer;"
                    *ngFor="let clickUrl of videoStats.clickUrls" class="d-flex flex-row align-items-center mb-2">
                    <ng-container *ngIf="clickUrl.url">
                        <img class="mr-2" style="height: 25px; width: 25px;" src="assets/icons/globe.png">
                        <a (click)="OpenInNewTab(clickUrl.url)" class="text-desc-2 url mb-0 break-word restricted">
                            {{clickUrl.urlDisplay}}
                        </a>
                    </ng-container>
                </div>
            </section>
        </div>
        <div class="col-12 col-md-6 px-0 p-0">
            <section *ngIf="videoStats.countries.length > 0" class="d-flex  flex-column">
                <p class="title2 mb-0">Target countries</p>
                <div class="underline mt-1 mb-2"></div>

                <div [ngStyle]="getBlurIfNeeded()" class="text-container" [ngClass]="{ 'expanded': isExpanded }">
                    <div class="info-text video-text-content">
                        <p *ngFor="let country of videoStats.countries" class="text-desc-2 d-flex">
                            <app-flag [countryCode]="country.code"></app-flag>
                            <span class="text-desc-2"
                                style="align-self: center; margin-left: 5px;">{{country.code.toUpperCase()}}
                                - ({{country.count}}
                                campaign(s))</span>
                        </p>
                    </div>
                    <button (click)="ToggleExpand()" class="expand-button form-control mx-auto">
                        <span *ngIf="!isExpanded"><i style="font-size: 30px;" class='bx bxs-chevron-down'></i></span>
                        <span *ngIf="isExpanded"><i style="font-size: 30px;" class='bx bxs-chevron-up'></i></span>
                    </button>
                </div>
            </section>
        </div>
    </div>
    <div class="d-flex justify-content-end  margin-bottom-mobile">
        <button (click)="Close()" class="btn transparent-button ml-3">Close</button>
    </div>
</mat-dialog-content>