<ng-container *ngIf="contentLoaded">
    <table>
        <thead>
            <tr>
                <th class="text-center">Rank</th>
                <th>Thumbnail</th>
                <th>Company</th>
                <th>Category</th>
                <th>Total spend</th>
            </tr>
        </thead>
        <tbody>
            <tr class="list-option" (click)="OpenSelectedCompany(company)" *ngFor="let company of companies?.results">
                <td data-label="Rank" class="text-centered">{{company.rank}}</td>
                <td class="logo-display" data-label="Frame">
                    <div class="icon-container ml-2 thumbnail">
                        <div class="rounded-icon">
                            <span class="icon-letter">{{ company.legalName | uppercase | slice:0:1 }}</span>
                        </div>
                    </div>
                </td>
                <td data-label="Company">
                    <div class="company-name">{{company.legalName}}</div>
                </td>
                <td class="company-domain" data-label="Category">
                    {{staticDataService.categoriesMap.get(company.categoryId)?.name}}
                </td>
                <td data-label="Total spend">${{company.spend | valueFormat}}</td>
            </tr>
        </tbody>
    </table>
    <app-paginator [hasMore]="companies.hasMore" [resultsPerPage]="resultsPerPage" [currentPage]="currentPage"
        (pageSizeChanged)="onPageSizeChange($event)" (pageNumChanged)="ChangePage($event)">
    </app-paginator>
</ng-container>
<div *ngIf="!contentLoaded" class="p-3">
    <app-skeleton-loader [linesNum]="resultsPerPage"></app-skeleton-loader>
</div>