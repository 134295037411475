<ng-container *ngIf="currentUser$ | async as user">
  <nav class="navbar navbar-marketer navbar-light bg-faded">
    <div class="d-flex flex-row w-100">
      <div id="sidebar-toggle" (click)="ToggleSidebar()" class="toggler-nav mr-3">
        <i style="font-size: 40px;" class='bx bx-menu'></i>
      </div>
      <a href="/" class="navbar-brand d-flex ml-2">
        <img class="logo" src="assets/icons/logo-black.png" />
      </a>
      <div class="container container-1400">
        <ng-container *ngIf="!isPastDue">
          <ng-container *ngIf="!isPayingUser && IsTrialAlreadyUsed">
            <div class="mx-auto d-none d-lg-block">
              To unlock full capabilities, click the button and upgrade to VidTao
              Premium!
            </div>
            <a (click)="BuyPremiumMonthly(false)" class="upgrade-button">Upgrade</a>
          </ng-container>

          <ng-container *ngIf="!isPayingUser && !IsTrialAlreadyUsed">
            <div class="mx-auto d-none d-lg-block">
              Start your <span class="bold">free trial</span> and unlock full capabilities for <span class="bold">24
                hours!</span>
            </div>
            <a (click)="BuyPremiumMonthly(true)" class="upgrade-button">Start trial</a>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="isPastDue">
          <div class="mx-auto d-none d-lg-block">
            Unable to proceed payment. Please change your card or make sufficient funds on existing one.
          </div>
          <a *ngIf="paymentPlan" (click)="UpdateCard()" class="upgrade-button">Update card</a>
        </ng-container>


        <!-- <ng-container *ngIf="isPayingUser && showBanner">
          <div class="mx-auto d-none d-lg-block">
            <app-book-strategy-session (removeSessionBanner)="RemoveSessionBanner()"></app-book-strategy-session>
          </div>
        </ng-container> -->
      </div>
    </div>
  </nav>
</ng-container>