<div [ngClass]="{'affiliate-background': isAffiliate, 'marketer-background': !isAffiliate}"
    (click)="CheckIfClickAllowed($event)" id="dashboard-container">
    <div [ngClass]="{'highlight': isInputFocused}" class="container-fluid search-outter-container">
        <div class="container container-1400 search-inner-container">
            <form class="d-flex">
                <div class="iconSearchContainer">
                    <i class="fa fa-search fa-lg"></i>
                </div>
                <input #Input [focused]="isInputFocused" name="searchInput" spellcheck="false" [(ngModel)]="keyword"
                    (keyup.enter)="SubmitSearch()" (focus)="InputFieldFocused()" class="input-field form-control"
                    autocomplete="off" placeholder="Enter search keyword..." type="text" />
                <div class="search-btn-container no-border  d-flex align-items-center px-2">
                    <button (click)="SubmitSearch()" class="search-button btn">Search</button>
                </div>
            </form>
        </div>
    </div>
    <div class="container container-1400 position-relative">
        <app-search-suggestions [type]="typeSearch" (focusChanged)="InputFocusChanged($event)" [top]="'auto'"
            [keyword]="keyword"></app-search-suggestions>
    </div>
    <div (click)="InputFieldUnfocused()" [ngClass]="{'blur': isInputFocused}">
        <ng-container *ngIf="type == searchType.Companies">
            <div class="container-fluid info-text-section-company"><!--Info section-->
                <div class="container container-1400 py-4">
                    <div class="d-flex justify-content-center flex-column flex-md-row">
                        <div class="info-text-title">{{companyResponse.company.legalName}}</div>
                        <div class="d-flex flex-row ml-3 justify-content-center">
                            <swipe-button [swipeBoards]="swipeBoards" (swipeRemoved)="RemoveCompanySwipe()"
                                class="w-auto mb-2 mr-2" [isSwiped]="companyResponse.company.swiped"
                                (swipeSaved)="CompanySwipeSaved($event)"></swipe-button>
                            <app-share-button [type]="'entity'" *ngIf="shareUrl" [copyUrl]="ShareUrl"
                                class="mb-2"></app-share-button>
                            <div style="text-align: end;" class="container">
                                <i (click)="OpenEntityTutorial()"
                                    class='bx bx-info-circle mt-2 ml-auto see-more-button'></i>
                            </div>
                        </div>
                    </div>
                    <div class="info-text text-center">
                        {{staticDataService.countriesMap.get(companyResponse.company.countryId)?.name}}
                    </div>
                </div>
            </div>
            <div class="container container-1400 mt-3"><!--Stats section-->
                <div class="row h-100">
                    <div class="col-12 col-sm-6 col-xl-3 p-2">
                        <app-company-info [company]="companyResponse.company"></app-company-info>
                    </div>
                    <div class="col-12 order-sm-3 col-xl-6 order-xl-2 ">
                        <div class="row h-100">
                            <div class="col-12 col-sm-6 p-2">
                                <app-entity-stat [isSpend]="true" [statName]="'Ad spend 365'"
                                    [statValue]="companyResponse.company?.spend?.last365Days | valueFormat">
                                </app-entity-stat>
                            </div>
                            <div class="col-12 col-sm-6 p-2">
                                <app-entity-stat [statName]="'Country rank'" [isSpend]="false"
                                    [statValue]="companyResponse.ranks?.country?.rank || '1000+'"
                                    [statChange]="companyResponse.ranks?.country?.change || 0">
                                </app-entity-stat>
                            </div>
                            <div class="col-12 col-sm-6 p-2">
                                <app-entity-stat [statName]="'Global rank'" [isSpend]="false"
                                    [statValue]="companyResponse.ranks?.global?.rank || '10000+'"
                                    [statChange]="companyResponse.ranks?.global?.change || 0">
                                </app-entity-stat>
                            </div>
                            <div class="col-12 col-sm-6 p-2">
                                <app-entity-stat [statName]="'Category rank'" [isSpend]="false"
                                    [statValue]="companyResponse.ranks?.category?.rank || '1000+'"
                                    [statChange]="companyResponse.ranks?.category?.change || 0">
                                </app-entity-stat>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 order-sm-2 col-xl-3 order-xl-3 p-2">
                        <app-ads-per-country [top5Countries]="companyResponse.top5Countries"></app-ads-per-country>
                    </div>
                </div>
            </div>
            <div class="container container-1400 mt-5"><!--Performance and testing graph section-->
                <ng-container *ngTemplateOutlet="performanceAndTestingGraph"></ng-container>
            </div>
            <div class="container container-1400 mt-5"><!--Creatives section-->
                <ng-container *ngTemplateOutlet="creatives; context: { name : companyResponse.company.legalName}">
                </ng-container>
            </div>
            <div class="container container-1400 mt-5"><!--Success rate graph section-->
                <ng-container *ngTemplateOutlet="succesRateGraph">
                </ng-container>
            </div>
            <div class="container container-1400 mt-5"><!--Evolution of winners graph section-->
                <ng-container *ngTemplateOutlet="evolutionOfWinnersGraph">
                </ng-container>
            </div>
            <div class="container container-1400 mt-5"><!--Brands advertised section-->
                <h3 class="section-heading mb-3">Brands promoted by this company</h3>
                <div class="position-relative" (click)="OpenUpgradeModalIfFreeUser()">
                    <div [ngStyle]="getBlurIfNeeded()" class="table-container mb-5">
                        <div class="filters-div"></div>
                        <app-brands-list [loadedParams]="true" [isPayingUser]="isPayingUser" [resultsPerPage]="5"
                            [optionalUrl]="urlOptionalExtension" [encryptedId]="encryptedData" [companyId]="companyId"
                            [type]="'company-brands'">
                        </app-brands-list>
                    </div>
                    <app-lock-upgrade-icon class="absolute-training-position"
                        *ngIf="!isPayingUser"></app-lock-upgrade-icon>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="type == searchType.Brands">
            <div class="container-fluid info-text-section-brand"><!--Info section-->
                <div class="container container-1400 pt-4">
                    <div class="d-flex justify-content-center flex-column flex-md-row">
                        <div class="info-text-title">{{brandResponse.brand.name}}</div>
                        <div class="d-flex flex-row ml-3 justify-content-center">
                            <swipe-button [swipeBoards]="swipeBoards" (swipeRemoved)="RemoveBrandSwipe()"
                                class="w-auto mb-2 mr-2" [isSwiped]="brandResponse.brand.swiped"
                                (swipeSaved)="BrandSwipeSaved($event)"></swipe-button>
                            <app-share-button [type]="'entity'" *ngIf="shareUrl" [copyUrl]="ShareUrl"
                                class="mb-2"></app-share-button>
                            <div style="text-align: end;" class="container">
                                <i (click)="OpenEntityTutorial()"
                                    class='bx bx-info-circle mt-2 ml-auto see-more-button'></i>
                            </div>
                        </div>
                    </div>
                    <div class="text-container" [ngClass]="{ 'expanded': isExpanded }">
                        <div class="info-text text-content">
                            {{brandResponse.brand.description}}
                        </div>
                        <button (click)="ToggleExpand()" class="expand-button form-control mx-auto">
                            <span *ngIf="!isExpanded"><i style="font-size: 30px;"
                                    class='bx bxs-chevron-down'></i></span>
                            <span *ngIf="isExpanded"><i style="font-size: 30px;" class='bx bxs-chevron-up'></i></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="container container-1400 mt-3"><!--Stats section-->
                <div class="row h-100">
                    <div class="col-12 col-lg-8">
                        <div class="row h-100">
                            <div class="col-12 col-sm-6 p-2">
                                <app-entity-stat [isSpend]="true" [statName]="'Ad spend 365'"
                                    [statValue]="brandResponse.brand?.spend?.last365Days | valueFormat">
                                </app-entity-stat>
                            </div>
                            <div class="col-12 col-sm-6 p-2">
                                <app-entity-stat [isSpend]="false" [statName]="'Country rank'"
                                    [statValue]="brandResponse.ranks?.country?.rank || '1000+'"
                                    [statChange]="brandResponse.ranks?.country?.change || 0">
                                </app-entity-stat>
                            </div>
                            <div class="col-12 col-sm-6 p-2">
                                <app-entity-stat [isSpend]="false" [statName]="'Global rank'"
                                    [statValue]="brandResponse.ranks?.global?.rank || '10000+'"
                                    [statChange]="brandResponse.ranks?.global?.change || 0">
                                </app-entity-stat>
                            </div>
                            <div class="col-12 col-sm-6 p-2">
                                <app-entity-stat [isSpend]="false" [statName]="'Category rank'"
                                    [statValue]="brandResponse.ranks?.category?.rank || '1000+'"
                                    [statChange]="brandResponse.ranks?.category?.change || 0">
                                </app-entity-stat>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 p-2">
                        <app-ads-per-country [top5Countries]="brandResponse.top5Countries"></app-ads-per-country>
                    </div>
                </div>
            </div>
            <div class="container container-1400 mt-5"><!--Performance and testing graph section-->
                <ng-container *ngTemplateOutlet="performanceAndTestingGraph"></ng-container>
            </div>
            <div class="container container-1400 mt-5"><!--Creatives section-->
                <ng-container *ngTemplateOutlet="creatives; context: { name : brandResponse.brand.name}">
                </ng-container>
            </div>
            <div class="container container-1400 mt-5"><!--Success rate graph section-->
                <ng-container *ngTemplateOutlet="succesRateGraph">
                </ng-container>
            </div>
            <div class="container container-1400 mt-5"><!--Evolution of winners graph section-->
                <ng-container *ngTemplateOutlet="evolutionOfWinnersGraph">
                </ng-container>
            </div>
            <div class="container container-1400 mt-5"><!--Brands Companies section-->
                <h3 class="section-heading mb-3">Companies promoting this brand</h3>
                <div class="position-relative" (click)="OpenUpgradeModalIfFreeUser()">
                    <div [ngStyle]="getBlurIfNeeded()" class="table-container mb-5">
                        <div class="filters-div"></div>
                        <app-companies-list [loadedParams]="true" [optionalUrl]="urlOptionalExtension"
                            [encryptedId]="encryptedData" [isPayingUser]="isPayingUser" [resultsPerPage]="5"
                            [brandId]="brandId" [type]="'brand-companies'">
                        </app-companies-list>
                    </div>
                    <app-lock-upgrade-icon class="absolute-training-position"
                        *ngIf="!isPayingUser"></app-lock-upgrade-icon>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="type == searchType.Offers">
            <div class="container-fluid info-text-section-offer"><!--Info section-->
                <div class="container container-1400 pt-4">
                    <div class="d-flex justify-content-center flex-column flex-md-row">
                        <div class="info-text-title">Offer: {{offerResponse.offer.name}}</div>
                        <div class="d-flex flex-row ml-3 justify-content-center">
                            <swipe-button [swipeBoards]="swipeBoards" (swipeRemoved)="RemoveOfferSwipe()"
                                class="w-auto mb-2 mr-2" [isSwiped]="offerResponse.offer.swiped"
                                (swipeSaved)="OfferSwipeSaved($event)"></swipe-button>
                            <app-share-button [type]="'entity'" *ngIf="shareUrl" [copyUrl]="ShareUrl"
                                class="mb-2"></app-share-button>
                            <div style="text-align: end;" class="container">
                                <i (click)="OpenEntityTutorial()"
                                    class='bx bx-info-circle mt-2 ml-auto see-more-button'></i>
                            </div>
                        </div>
                    </div>
                    <div class="text-container" [ngClass]="{ 'expanded': isExpanded }">
                        <div [innerHtml]="offerResponse.offer.description" class="info-text text-content">
                        </div>
                        <button (click)="ToggleExpand()" class="expand-button form-control mx-auto">
                            <span *ngIf="!isExpanded"><i style="font-size: 30px;"
                                    class='bx bxs-chevron-down'></i></span>
                            <span *ngIf="isExpanded"><i style="font-size: 30px;" class='bx bxs-chevron-up'></i></span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="container container-1400 mt-3"><!--Stats section-->
                <div class="row h-100">
                    <div class="col-12 col-sm-6 col-xl-3 p-2">
                        <app-offer-info
                            [networkName]="staticDataService.affiliateNetworksMap.get(offerResponse.offer.affiliateNetworkId)"
                            [activeAdsNum]="videosNumber" [advertisersNum]="companiesNumber"></app-offer-info>
                    </div>
                    <div class="col-12 order-sm-3 col-xl-6 order-xl-2 ">
                        <div class="row h-100">
                            <div class="col-12 col-sm-6 p-2">
                                <app-entity-stat [isSpend]="true" [statName]="'Ad spend 365'"
                                    [statValue]="offerResponse.offer?.spend | valueFormat">
                                </app-entity-stat>
                            </div>
                            <div class="col-12 col-sm-6 p-2">
                                <app-entity-stat [statName]="'Country rank'" [isSpend]="false"
                                    [statValue]="offerResponse.ranks?.country?.rank || '1000+'"
                                    [statChange]="offerResponse.ranks?.country?.change || 0">
                                </app-entity-stat>
                            </div>
                            <div class="col-12 col-sm-6 p-2">
                                <app-entity-stat [statName]="'Global rank'" [isSpend]="false"
                                    [statValue]="offerResponse.ranks?.global?.rank || '10000+'"
                                    [statChange]="offerResponse.ranks?.global?.change || 0">
                                </app-entity-stat>
                            </div>
                            <div class="col-12 col-sm-6 p-2">
                                <app-entity-stat [statName]="'Category rank'" [isSpend]="false"
                                    [statValue]="offerResponse.ranks?.category?.rank || '1000+'"
                                    [statChange]="offerResponse.ranks?.category?.change || 0">
                                </app-entity-stat>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 order-sm-2 col-xl-3 order-xl-3 p-2">
                        <app-ads-per-country [top5Countries]="offerResponse.top5Countries"></app-ads-per-country>
                    </div>
                </div>
            </div>
            <div class="container container-1400 mt-5"><!--Performance and testing graph section-->
                <ng-container *ngTemplateOutlet="performanceAndTestingGraph"></ng-container>
            </div>
            <div class="container container-1400 mt-5"><!--Creatives section-->
                <ng-container *ngTemplateOutlet="creatives">
                </ng-container>
            </div>
            <div class="container container-1400 mt-5"><!--Success rate graph section-->
                <ng-container *ngTemplateOutlet="succesRateGraph">
                </ng-container>
            </div>
            <div class="container container-1400 mt-5"><!--Evolution of winners graph section-->
                <ng-container *ngTemplateOutlet="evolutionOfWinnersGraph">
                </ng-container>
            </div>
            <div class="container container-1400 mt-5"><!--Offers Companies section-->
                <h3 class="section-heading mb-3">Offer Companies</h3>
                <div class="position-relative" (click)="OpenUpgradeModalIfFreeUser()">
                    <div [ngStyle]="getBlurIfNeeded()" class="table-container mb-5">
                        <div class="filters-div"></div>
                        <app-companies-list [loadedParams]="true" [optionalUrl]="urlOptionalExtension"
                            [encryptedId]="encryptedData" [resultsPerPage]="5"
                            (companiesCountLoaded)="CompaniesCountCalculated($event)" [offerId]="offerId"
                            [type]="'offer-companies'">
                        </app-companies-list>
                    </div>
                    <app-lock-upgrade-icon class="absolute-training-position"
                        *ngIf="!isPayingUser"></app-lock-upgrade-icon>
                </div>
            </div>
        </ng-container>
    </div>
</div>



<!--Templates-->
<ng-template #performanceAndTestingGraph>
    <h3 class="section-heading mb-3">Performance & Testing volume</h3>
    <div (click)="OpenUpgradeModalIfFreeUser()">
        <div class="row mx-0 position-relative">
            <div [ngStyle]="getBlurIfNeeded()" class="col-12 col-lg-6 pb-3 pr-md-2 px-0">
                <div class="info-section-main">
                    <div class="d-flex">
                        <div class="stat-heading mb-3">
                            Estimated video ad spend & views
                        </div>
                        <div (click)="ScreenshotData('performance')" class="ml-auto pointer">
                            <i style="font-size: 30px;" class='bx bx-download'></i>
                        </div>
                    </div>
                    <div class="underline"></div>
                    <div class="my-3 d-flex">
                        <div [ngClass]="chartTypePerformance == chartPerformanceType.Spends ? 'graph-option-selected' : ''"
                            (click)="SwitchPerformanceChartType(chartPerformanceType.Spends)" class="graph-option mr-1">
                            Spend
                        </div>
                        <div [ngClass]="chartTypePerformance == chartPerformanceType.Views ? 'graph-option-selected': ''"
                            (click)="SwitchPerformanceChartType(chartPerformanceType.Views)" class="graph-option">
                            Views
                        </div>
                    </div>
                    <div class="p-1" id="performance"
                        [ngStyle]="{'display': performanceGraphLoaded ? 'block' : 'none'}">
                        <app-line-chart [yValues]="yValuesPerformance" [xValues]="xValuesPerformance"
                            [chartId]="idPerformanceGraph" [dateRangeOptions]="['30 days', '12 months', 'Total']"
                            [selectedChartPeriod]="chartPeriodPerformance"
                            [yTitle]="chartTypePerformance == chartPerformanceType.Spends ? 'Estimated ad spend(USD)' : 'Estimated views'"
                            xTitle="Period" type="line" timeUnit="day"
                            (dateRangeChanged)="ChangePerformanceGraphDateRange($event)">
                        </app-line-chart>
                    </div>
                    <app-skeleton-loader *ngIf="!performanceGraphLoaded" [linesNum]="3"></app-skeleton-loader>
                </div>
            </div>
            <div [ngStyle]="getBlurIfNeeded()" class="col-12 col-lg-6 pb-3 pl-md-2 px-0">
                <div class="info-section-main">
                    <div class="d-flex">
                        <div class="stat-heading mb-3">
                            Yearly testing volume
                        </div>
                        <div (click)="ScreenshotData('testing')" class="ml-auto pointer">
                            <i style="font-size: 30px;" class='bx bx-download'></i>
                        </div>
                    </div>
                    <div class="underline"></div>
                    <div class="my-3 d-flex">
                        <div [ngClass]="chartTypeTesting == chartTestingType.Spend ? 'graph-option-selected' : ''"
                            (click)="SwitchTestingChartType(chartTestingType.Spend)" class="graph-option mr-1">
                            Views
                        </div>
                        <div [ngClass]="chartTypeTesting == chartTestingType.Count ? 'graph-option-selected': ''"
                            (click)="SwitchTestingChartType(chartTestingType.Count)" class="graph-option">
                            Count
                        </div>
                    </div>
                    <div class="p-1" id="testing" [ngStyle]="{'display': testingGraphLoaded ? 'block' : 'none'}">
                        <app-bar-chart [yValues]="yValuesTesting" [xValues]="xValuesTesting" [chartId]="idTestingGraph"
                            [dateRangeOptions]="['Month', 'Year']" [selectedChartPeriod]="chartPeriodTesting"
                            [yTitle]="chartTypeTesting == chartTestingType.Spend ? 'Estimated ad views': 'Published video ads'"
                            xTitle="Period" type="bar" timeUnit="year"
                            (dateRangeChanged)="ChangeTestingGraphDateRange($event)">
                        </app-bar-chart>
                    </div>
                    <app-skeleton-loader *ngIf="!testingGraphLoaded" [linesNum]="3"></app-skeleton-loader>
                </div>
            </div>
            <app-lock-upgrade-icon class="absolute-training-position" *ngIf="!isPayingUser"></app-lock-upgrade-icon>
        </div>
    </div>
</ng-template>

<ng-template #succesRateGraph>
    <h3 class="section-heading mb-3">Success rate</h3>
    <div (click)="OpenUpgradeModalIfFreeUser()">
        <div class="row mx-0">
            <div class="col-12 col-lg-6 pb-3 pr-md-2 px-0">
                <div [ngStyle]="getBlurIfNeeded()" class="info-section-main">
                    <div class="d-flex">
                        <div class="stat-heading mb-3">
                            Number of video ads, grouped by spend
                        </div>
                        <div (click)="ScreenshotData('success')" class="ml-auto pointer">
                            <i style="font-size: 30px;" class='bx bx-download'></i>
                        </div>
                    </div>
                    <div class="underline"></div>
                    <div class="p-1" id="success" [ngStyle]="{'display': distributionGraphLoaded ? 'block' : 'none'}">
                        <app-doughnut-chart
                            [yValues]="['0-25th percentile','25-60th percentile', '60-100th percentile',]"
                            [colors]="['#E0EAFF','#8098F9','#5675f5']" [xValues]="xValuesDistribution"
                            [chartId]="idDistributionGraph" type="doughnut">
                        </app-doughnut-chart>
                    </div>
                    <app-skeleton-loader *ngIf="!distributionGraphLoaded" [linesNum]="4"></app-skeleton-loader>
                </div>
                <app-lock-upgrade-icon class="absolute-training-position" *ngIf="!isPayingUser"></app-lock-upgrade-icon>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #evolutionOfWinnersGraph>
    <h3 class="section-heading mb-3">Creative Lifespan And Evolution of Winners</h3>
    <div (click)="OpenUpgradeModalIfFreeUser()">
        <div class="row mx-0">
            <div class="col-12 px-0">
                <div [ngStyle]="getBlurIfNeeded()" class="info-section-main">
                    <div class="d-flex">
                        <div class="stat-heading mb-3">
                            Number of video ads, grouped by spend
                        </div>
                        <div (click)="ScreenshotData('winners')" class="ml-auto pointer">
                            <i style="font-size: 30px;" class='bx bx-download'></i>
                        </div>
                    </div>
                    <div class="underline"></div>
                    <div class="p-1" id="winners" [ngStyle]="{'display': evolutionGraphLoaded ? 'block' : 'none'}">
                        <app-multi-line-chart (videoClicked)="OpenVideoInfo($event)" [datasets]="topVideos"
                            yTitle="Views" [chartId]="idEvolutionOfWinnersGraph" type="line">
                        </app-multi-line-chart>
                    </div>
                    <app-skeleton-loader *ngIf="!evolutionGraphLoaded" [linesNum]="6"></app-skeleton-loader>
                </div>
                <app-lock-upgrade-icon class="absolute-training-position" *ngIf="!isPayingUser"></app-lock-upgrade-icon>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #creatives>
    <h3 class="section-heading mb-3">Creatives <span class="avg-video-info" *ngIf="type == searchType.Brands">(average
            video duration:
            {{brandResponse.brand.averageVideoDuration | durationFormat}})</span></h3>
    <div>
        <div class="table-container mb-5">
            <div class="filters-div">
                <div class="options-div">
                    <button [ngClass]=" dtype != displayType.Youtube ? 'option-not-selected': 'option-selected'"
                        (click)="dtype = displayType.Youtube; DisplayVideos()"
                        class="option-button option1 col-6 col-sm-3">
                        <mat-icon [svgIcon]="'menu-5'" class="icon mr-2"></mat-icon>
                        Youtube
                    </button>
                    <button [ngClass]=" dtype != displayType.Shorts ? 'option-not-selected': 'option-selected'"
                        (click)="dtype = displayType.Shorts; DisplayShorts()"
                        class="option-button option2 col-6 col-sm-3">
                        <mat-icon [svgIcon]="'menu-17'" class="icon mr-2"></mat-icon>
                        Shorts
                    </button>
                    <button [disabled]="type == searchType.Brands" [ngClass]="[dtype != displayType.Display ? 'option-not-selected': 'option-selected',
                                type == searchType.Brands ? 'disabled-opacity' : '']"
                        (click)="dtype = displayType.Display" class="option-button option3 col-6 col-sm-3">
                        <mat-icon [svgIcon]="'menu-18'" class="icon mr-2"></mat-icon>
                        Display
                    </button>
                    <button [disabled]="type == searchType.Brands" [ngClass]="[dtype != displayType.Search ? 'option-not-selected': 'option-selected',
                                type == searchType.Brands ? 'disabled-opacity' : '']"
                        (click)="dtype = displayType.Search" class="option-button option4 col-6 col-sm-3">
                        <mat-icon [svgIcon]="'menu-19'" class="icon mr-2"></mat-icon>
                        Text
                    </button>
                </div>
            </div>
            <ng-container *ngIf="dtype == displayType.Youtube || dtype == displayType.Shorts">
                <app-videos-list [loadedParams]="true" [isPayingUser]="isPayingUser" [resultsPerPage]="5"
                    (videosNumLoaded)="VideosCountCalculated($event)" [dtype]="dtype" [companyId]="companyId"
                    [brandId]="brandId" [offerId]="offerId" [encryptedId]="encryptedData"
                    [optionalUrl]="urlOptionalExtension"></app-videos-list>
            </ng-container>
            <ng-container *ngIf="dtype == displayType.Display">
                <app-non-video-ads [resultsPerPage]="5" [companyId]="companyId"
                    [isDisplayAds]="true"></app-non-video-ads>
            </ng-container>
            <ng-container *ngIf="dtype == displayType.Search">
                <app-non-video-ads [resultsPerPage]="5" [companyId]="companyId" [isTextAds]="true"></app-non-video-ads>
            </ng-container>
        </div>
    </div>
</ng-template>