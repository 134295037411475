import { Spends } from "./spends";

export class Company {
  public companyId: number = 0;
  public legalName: string = "";
  public country: string = "";
  public countryId: number = 0;
  public category: string = "";
  public categoryId: number = 0;
  public totalSpend: number = 0;
  public headquarters: string = "";
  public numberOfEmployees: string = "";
  public revenue: string = "";
  public yearFounded: string = "";
  public swiped: boolean = false;
  public spend: Spends = new Spends();
  public swipeBoardIds: number[] = [];
  public isSwiped: boolean = false;
}
