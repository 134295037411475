import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rating-change',
  templateUrl: './rating-change.component.html',
  styleUrls: ['./rating-change.component.scss']
})
export class RatingChangeComponent {
  @Input() change: number = 0;
  @Input() isPercentage: boolean = false;
}
