import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Company } from "src/app/models/company";
import { ModuleType } from "src/app/models/enums";
import { StateService } from "src/app/services/shared/state.service";
import { StaticDataService } from "src/app/services/shared/static.data.service";

@Component({
  selector: "app-company-info",
  templateUrl: "./company-info.component.html",
  styleUrls: ["../../entity-info.component.scss"],
})
export class CompanyInfoComponent implements OnInit {
  @Input() company = new Company();
  constructor(
    public stateService: StateService,
    public staticDataService: StaticDataService,
    public router: Router
  ) {}

  ngOnInit(): void {}

  public async OpenCompanyCategory() {
    this.stateService.ModuleSelected(ModuleType.Marketer); // if in affiliate mode to switch to marketer before opening ranking page
    await this.router.navigate(["/dashboard/rankings"], {
      queryParams: { selectedCategoryId: this.company.categoryId },
    });
  }
}
