import { Routes } from '@angular/router';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { ExternalLinkGuard } from '../guards/externalLinkGuard';

export const externalWpLinks: Routes = [
  {
    path: '1b-in-direct-response-revenue-from-this-lead-framework',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'how-to-find-sleeping-giant-affiliate-offers-using-vidtao-2-0',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'youtube-ads-step-by-step-guide-part1',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'motley-fool-youtube-ad-analysis',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'how-to-spy-on-your-competitors-youtube-ads',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'youtube-ads-scaling-case-study-how-we-scaled-this-banned-facebook-info-product-with-youtube-ads',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'earlybirdconfirm2022',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'youtube-ad-examples-for-2020-ecommerce',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'defining-youtube-ad-creative',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'how-to-create-high-performing-youtube-ads',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'affinity-in-market-life-event-custom-audiences',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'linx-scaling-methodology-e-commerce-youtube-ads',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'gpt-3-openai-new-language-generator',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'create-customer-avatar-high-performing-youtube-ads',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'this-43-year-old-ecommerce-video-ad-generated-half-a-billion-in-sales',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'the-1-way-to-beat-creative-fatigue-scale-on-youtube',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'want-to-scale-make-sure-your-youtube-ad-has-these-key-ingredients',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'how-to-control-youtube-ad-creative-fatigue-with-this-3-step-system',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'try-this-lean-approach-to-test-scale-youtube-ad-creatives',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'youtube-ads-for-physical-products-3-of-the-best-from-summer-2021',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'what-any-advertiser-can-learn-from-these-fitness-niche-youtube-ads',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'use-this-ad-template-to-create-profitable-youtube-ads',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'ad-of-the-week-1-tom-wang-2',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'ad-of-the-week-2-4patriots',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'breaking-news-google-admits-to-penalizing-long-form-video-creative',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'ad-of-the-week-3-health-and-wellness-tools',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'saas-youtube-ad-battle-part-2-of-3',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'ad-of-the-week-4-dr-squatch',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: '1-urgent-youtube-ad-update-for-october-2021-you-need-to-be-aware-of',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'are-longform-youtube-ads-dead',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'your-november-2021-youtube-ad-questions-answered',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'ad-breakdown-100-million-in-18-months-from-this-ecommerce-ad',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'how-to-scale-this-skincare-quiz-funnel-from-1k-day-to',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'what-this-felon-born-1938-can-teach-you-about-creating-youtube-ads-that-sell',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'cheat-code-for-youtube-ad-creatives',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'without-this-dashboard-you-are-flying-blind',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'saas-youtube-ad-battle-part-1-of-3',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'the-mystery-metric-driving-your-youtube-ad-traffic-costs',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'revealed-youtube-ad-traffic-cost-mystery-metric-part-2',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'saas-youtube-ad-battle-part-2-of-3',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: '2022-youtube-ecommerce-creative-checklist',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'masterclass-coms-youtube-ads-scaling-to-160m-year-with-thomas-hopkins',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: '3-lessons-from-shopifys-4-2-million-youtube-adspend-last-month',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'the-secret-weapon-behind-500m-in-sales-on-youtube',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'podcast-now-live-he-was-sixpackshortcuts-youtube-ad-secret-weapon-500m-in-sales',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'attention-span-decay-video-ads',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'podcast-with-chris-haddad-unlocking-the-power-of-emotion-in-your-sales-messaging',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'podcast-with-peter-kell-the-mindset-secrets-behind-8-figure-vsls',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'discover-the-quantified-brandformance-secret-behind-hello-freshs-6-6b-yr-youtube-ad-success-podcast-with-lili-marocsik',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'saas-youtube-ad-battle-part-3-of-3',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'interview-tiktok-organic-vs-paid-hormozis-tiktok-team-shares',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'the-2-ecommerce-product-youve-never-heard-of-hint-toilets',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'the-2nd-most-important-part-of-your-youtube-ad-why-you-should-care',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'updates-tiktok-organic-vs-paid-gopros-tv-digital-uberfunnel-and-new-youtube-creative-training',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: '2022-ecommerce-youtube-ad-checklist',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'vidtao-youtube-vsl-top-10-leaderboard-october-2022',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'vidtao-tracking-attribution-a-short-introduction-to-marketing-mix-modeling-mmmpart-1',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'your-feedback-whats-the-difference-between-these-2-youtube-ads',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'tracking-attribution-part-2-lets-do-some-modeling-marketing-mix-modeling',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'podcast-with-chris-encarnacion-direct-response-video-editing',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'the-measure-of-carry-over-and-lag-effect-of-marketing-activities-marketing-mix-modeling-mmm-part-3',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'youtube-ad-impact-beyond-the-click-clickup-vs-monday-com-case-study',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'case-study-can-ai-emotional-recognition-unlock-new-performance-marketing-opportunities',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'my-2-cents-about-ctr-in-video-campaignsdedicated-to-google-ads-apprentices-but-it-wont-hurt-the-old-foxes-either',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'unveiling-the-secrets-of-a-billion-dollar-dtc-quiz-funnel-boost-conversions-and-create-winning-offers',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: '5-youtube-ad-secrets-from-the-swiss-mega-company-behind-two-of-2023s-top-ecomm-offers',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'the-youtube-vsl-tsl-hybrid-approach-to-selling-high-ticket-without-a-sales-team',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'how-to-find-spy-on-top-affiliates-offers-ads-landing-pages-with-vidtao-2-0',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'the-kenny-rogers-secret-to-scaling-with-youtube-ads',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'he-used-dark-posting-to-scale-from-60k-mo-to-100m-in-less-than-18-months',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'breaking-down-the-1-clickbank-youtube-ad-offer',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'privacy-policy',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'about',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'contact',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: '2020-guide',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'youtube-ads-step-by-step-guide-for-2020-new',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'youtube-ads-step-by-step-guide-part-1',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'taking-you-to-the-software',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'thanks-for-interest-in-the-youtube-ads-bootcamp',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'schedule-a-time-to-chat',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'justin2020',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'depesh2020',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'depesh2020',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'schefren2020',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'depesh2020',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'geekout2021',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'blog',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'geekoutyoutube',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'flowchart-download',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'optimization-flowchart',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'making-youtube-ads-for-found-using-miro-part-1',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'making-youtube-ads-for-found-using-miro-part-2',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'vidtao-live-event-2022-early-bird-wait-list-signup',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'terms-conditions',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'blog-2',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: '2021-youtube-ads-guide-%e2%86%97',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'podcast',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'advertorial-training-replay-with-heath-wilcock',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'terms-of-service',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'ppcadlabs-youtube-show-up-bonus-expires-soon',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'unlimited-youtube-ads-whats-inside',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
  {
    path: 'direct-response-ugc-factory-replay',
    component: HomeComponent,
    canActivate: [ExternalLinkGuard],
  },
];
