<mat-dialog-content class="p-0">
    <div class="d-flex p-3">
        <img style="width: 52px; height: 52px;" class="mr-3" src="assets/icons/two-layer-icon.png" />
        <div class="block">
            <p class="mb-0 title text-left">Select plan</p>
            <p class="welcome-text mb-0 text-left">Simple and flexible per-user pricing</p>
        </div>
        <img (click)="CloseModal()" style="height: 24px; width: 24px; cursor: pointer;" class="ml-auto"
            src="assets/icons/x-close.png" />
    </div>
    <div class="underline"></div>
    <div class="container my-4">
        <div class="row px-0">
            <div class="col-12 col-lg-4">
                <div class="plan-card" (click)="SelectPlan('free')" [ngClass]=" freeChecked ? 'plan-selected': ''">
                    <div class="round mr-2 ml-auto">
                        <input type="checkbox" [(ngModel)]="freeChecked" id="checkbox1" />
                        <label for="checkbox1"></label>
                    </div>
                    <div class="d-flex">
                        <p class="plan-title mb-1">Free</p>
                        <img style="height: fit-content;" class="ml-2" src="assets/icons/check-icon.png" />
                    </div>
                    <p class="plan-subtitle">Basic</p>
                    <div class="plan-features mt-2">
                        <div class="d-block">
                            <div class="d-flex">
                                <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                                <p class="plan-feature-text">Basic search and search results</p>
                            </div>
                            <div class="d-flex">
                                <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                                <p class="plan-feature-text">100 affiliate searches</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="plan-card" (click)="SelectPlan('monthly')"
                    [ngClass]=" monthlyChecked ? 'plan-selected': ''">
                    <div class="round mr-2 ml-auto">
                        <input type="checkbox" [(ngModel)]="monthlyChecked" id="checkbox2" />
                        <label for="checkbox2"></label>
                    </div>
                    <div class="d-flex align-items-center">
                        <p class="plan-title mb-0">${{availablePlans.monthly.price}}/mth</p>
                        <img style="height: 35px;" class="ml-2" src="assets/icons/montly-icon.png" />
                    </div>
                    <p class="plan-subtitle mt-2">{{availablePlans.monthly.name}}</p>
                    <div class="plan-features mt-2">
                        <div class="d-block">
                            <div class="d-flex">
                                <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                                <p class="plan-feature-text">Advanced search, filtering, & results</p>
                            </div>
                            <div class="d-flex">
                                <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                                <p class="plan-feature-text">Unlimited affiliate advertiser module</p>
                            </div>
                            <div class="d-flex">
                                <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                                <p class="plan-feature-text">7-day free trial, no credit card needed</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="plan-card" (click)="SelectPlan('yearly')" [ngClass]=" yearlyChecked ? 'plan-selected': ''">
                    <div class="round mr-2 ml-auto">
                        <input type="checkbox" [(ngModel)]="yearlyChecked" id="checkbox3" />
                        <label for="checkbox3"></label>
                    </div>
                    <div class="d-flex align-items-center">
                        <p class="plan-title mb-0">${{availablePlans.yearly.price}}/yr</p>
                        <img style="height: 35px;" class="ml-2" src="assets/icons/yearly-icon.png" />
                    </div>
                    <p class="plan-subtitle mt-2">{{availablePlans.yearly.name}}</p>
                    <div class="plan-features mt-2">
                        <div class="d-block">
                            <div class="d-flex">
                                <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                                <p class="plan-feature-text">All features of Premium Monthly</p>
                            </div>
                            <div class="d-flex">
                                <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                                <p class="plan-feature-text">Exclusive community access</p>
                            </div>
                            <div class="d-flex">
                                <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                                <p class="plan-feature-text">Expert advice & Feedback</p>
                            </div>
                            <div class="d-flex">
                                <img style="height: fit-content;" class="mr-2" src="assets/icons/green-check.png" />
                                <p class="plan-feature-text">7-day money back guarantee</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="underline"></div>
    <div class="d-flex p-3">
        <button (click)="CloseModal()" class="option-button ml-auto mr-2 btn custom-btn">
            Cancel
        </button>
        <button (click)="SelectPlanAndProceed()" class="option-button btn custom-btn custom-btn1">
            Select plan
        </button>
    </div>
</mat-dialog-content>