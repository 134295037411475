import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { SwipeBoard } from "src/app/models/swipeBoard";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";

@Component({
  selector: "choose-board-list-menu",
  templateUrl: "./choose-boards.component.html",
  styleUrls: ["./choose-boards.component.scss"],
})
export class ChooseBoardsComponent implements OnInit {
  newBoardName: string = "";
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();
  @Output() saved: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Input() swipeBoards: SwipeBoard[] = [];
  constructor(
    public apiService: ApiService,
    public popupService: PopupService
  ) {}

  ngOnInit(): void {}

  async LoadSwipeBoards() {
    const result = await this.apiService.GetSwipeBoards();
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data)
      this.swipeBoards = [
        {
          name: "All swipes",
          swipeBoardId: 0,
          isChecked: true,
          shareId: "",
          isShared: false,
          email: "",
          userName: "",
        },
        ...result.data,
      ];
  }

  public Check(board: SwipeBoard) {
    board.isChecked = !board.isChecked;
  }

  public PreventDefault(event: Event) {
    event.stopPropagation();
  }

  public Close() {
    this.closed.emit();
  }

  public Save() {
    const ids: number[] = this.swipeBoards
      .filter((sb) => sb.isChecked)
      .map((sb) => sb.swipeBoardId);
    this.saved.emit(ids);
  }

  public async SaveNewBoard() {
    this.UpsertSwipeBoard(this.newBoardName, undefined);
    this.newBoardName = "";
  }

  async UpsertSwipeBoard(swipeBoardName: string, swipeBoardId?: number) {
    const result = await this.apiService.UpsertSwipeBoard(
      swipeBoardName,
      swipeBoardId
    );
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data) {
      this.popupService.Notify("Swipe board successfully saved.");
      await this.LoadSwipeBoards();
    }
  }
}
