import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiResponse } from "src/app/models/apiResponse";
import { PageSizes, RESULT_SUGGESTIONS } from "src/app/models/consts";
import { DisplayType, SearchType } from "src/app/models/enums";
import { GetBrandResponse } from "src/app/models/getBrandResponse";
import { SearchRequest } from "src/app/models/searchRequest";
import { SearchResponse } from "src/app/models/searchResponse";
import { SwipeBoard } from "src/app/models/swipeBoard";
import { Video } from "src/app/models/video";
import { ApiService } from "src/app/services/api/api.service";
import { PaginatorService } from "src/app/services/shared/paginator.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-videos-list",
  templateUrl: "./videos-list.component.html",
  styleUrls: ["./videos-list.component.scss"],
})
export class VideosListComponent implements OnInit, OnChanges {
  pageSizes = PageSizes;
  @Input() resultsPerPage = this.pageSizes[3];

  displayType = DisplayType;

  @Input() currentPage = 1;
  totalPages = 0;
  pages: string[] = [];

  videos!: SearchResponse<Video>;
  //brandResponse: GetBrandResponse = new GetBrandResponse();

  @Input() sortProp = "total";
  @Input() orderAsc = false;
  @Input() sortingIndex: number = 1;

  contentLoaded: boolean = false;

  @Input() brandId: number = 0;
  @Input() companyId: number = 0;
  @Input() offerId: number = 0;
  @Input() dtype: DisplayType = DisplayType.Youtube;
  @Input() keyword: string = "";
  @Input() isPayingUser: boolean = true;
  @Input() searchByKeyword: boolean = false;

  //When loading videos by keyword and filters
  @Input() categoryIds: number[] = [];
  @Input() countryId: number = 0;
  @Input() languageId: string = "";
  @Input() durationId: string = "";
  @Input() videoStatusId: string = "all";
  durationMoreThan: number | undefined = undefined;
  durationLessThan: number | undefined = undefined;
  //

  @Output() videosNumLoaded: EventEmitter<number> = new EventEmitter<number>();
  @Output() videoModalOpened: EventEmitter<string> = new EventEmitter<string>();
  @Output() videoModalClosed: EventEmitter<void> = new EventEmitter();

  @Output() currentPageChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() resultsPerPageChanged: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() sortingChanged: EventEmitter<object> = new EventEmitter<object>();

  @Input() encryptedId: string = "";
  @Input() optionalUrl: string = "";
  @Input() isMainDisplay: boolean = false;
  @Input() swipeBoards: SwipeBoard[] = [];
  @Input() loadedParams = false;
  @Input() selectedInterval: string = "last30Days";

  constructor(
    private apiService: ApiService,
    private paginator: PaginatorService,
    public stateService: StateService,
    public router: Router,
    public route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private popupService: PopupService
  ) {}

  async ngOnInit(): Promise<void> {
    this.LoadSwipeBoards();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.loadedParams) {
      // const numberOfChanges = Object.keys(changes).length;
      // if (numberOfChanges === 2 && !changes["currentPage"]) {
      //   //in case that anything else is changed that requires gathering total pages
      //   this.currentPage = 1;
      // }
      await this.LoadData();
    }
  }

  private async LoadData() {
    this.contentLoaded = false;
    if (
      this.sortProp === "last30Days" &&
      this.selectedInterval === "last90Days"
    )
      this.sortProp = "last90Days";
    else if (
      this.sortProp === "last90Days" &&
      this.selectedInterval === "last30Days"
    )
      this.sortProp = "last30Days";
    if (this.brandId != 0) {
      if (this.dtype == DisplayType.Youtube) await this.LoadBrandVideos();
      else if (this.dtype == DisplayType.Shorts) await this.LoadBrandShorts();
      return;
    } else if (this.companyId != 0) {
      if (this.dtype == DisplayType.Youtube) await this.LoadCompanyVideos();
      else if (this.dtype == DisplayType.Shorts) await this.LoadCompanyShorts();
      return;
    } else if (this.offerId != 0) {
      if (this.dtype == DisplayType.Youtube) await this.LoadOfferVideos();
      else if (this.dtype == DisplayType.Shorts) await this.LoadOfferShorts();
      return;
    }
    //When searching videos by keyword and filters
    [this.durationMoreThan, this.durationLessThan] = this.ParseDuration(
      this.durationId
    );
    await this.LoadVideosByKeyword();
  }

  private async LoadBrandVideos() {
    const result = await this.apiService.LoadBrandVideos(
      this.brandId,
      this.currentPage,
      this.resultsPerPage,
      this.sortProp,
      this.orderAsc,
      this.optionalUrl,
      this.encryptedId
    );
    this.ProcessData(result);
  }
  private async LoadBrandShorts() {
    const result = await this.apiService.LoadBrandShorts(
      this.brandId,
      this.currentPage,
      this.resultsPerPage,
      this.sortProp,
      this.orderAsc
    );
    this.ProcessData(result);
  }
  private async LoadCompanyVideos() {
    const result = await this.apiService.LoadCompaniesVideos(
      this.companyId,
      this.currentPage,
      this.resultsPerPage,
      this.sortProp,
      this.orderAsc,
      this.optionalUrl,
      this.encryptedId
    );
    this.ProcessData(result);
  }
  private async LoadCompanyShorts() {
    const result = await this.apiService.LoadCompaniesShorts(
      this.companyId,
      this.currentPage,
      this.resultsPerPage,
      this.sortProp,
      this.orderAsc
    );
    this.ProcessData(result);
  }
  private async LoadOfferVideos() {
    const result = await this.apiService.LoadOfferVideos(
      this.offerId,
      this.currentPage,
      this.resultsPerPage,
      this.sortProp,
      this.orderAsc,
      this.optionalUrl,
      this.encryptedId
    );
    this.ProcessData(result);
  }
  private async LoadOfferShorts() {
    const result = await this.apiService.LoadOfferShorts(
      this.offerId,
      this.currentPage,
      this.resultsPerPage,
      this.sortProp,
      this.orderAsc
    );
    this.ProcessData(result);
  }
  private async LoadVideosByKeyword() {
    const result = await this.apiService.FullSearchVideoAds(
      new SearchRequest(
        this.keyword,
        this.resultsPerPage,
        this.currentPage,
        this.sortProp,
        this.orderAsc,
        false,
        [],
        this.categoryIds,
        this.countryId,
        this.languageId,
        this.durationMoreThan,
        this.durationLessThan,
        this.videoStatusId
      )
    );
    this.ProcessData(result);
  }

  private ProcessData(result: ApiResponse<SearchResponse<Video>> | undefined) {
    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    if (result?.data) {
      this.videos = result.data;
      this.pages = this.paginator.GetPagesArray(
        this.currentPage,
        this.totalPages
      );
      this.contentLoaded = true;
    }
  }

  public async ChangePage(newPage: number) {
    if (!this.searchByKeyword) await this.LoadChunk(newPage);
    this.currentPageChanged.emit(newPage);
  }

  async onPageSizeChange(value: string) {
    this.currentPage = 1;
    this.resultsPerPage = this.pageSizes.filter((ps) => ps == Number(value))[0];
    this.resultsPerPageChanged.emit(this.resultsPerPage);
    if (!this.searchByKeyword) await this.LoadData();
  }

  private async LoadChunk(page: number) {
    this.currentPage = page;
    await this.LoadData();
  }

  async SetSorting(index: number, sortProp: string, sortOrder: boolean) {
    this.currentPage = 1;
    this.sortingIndex = index;
    this.sortProp = sortProp;
    this.orderAsc = sortOrder;
    if (this.brandId === 0 && this.companyId === 0 && this.offerId === 0)
      this.sortingChanged.emit({
        sortProp: this.sortProp,
        orderAsc: this.orderAsc,
        sortingIndex: this.sortingIndex,
      });
    else await this.LoadData();
  }
  public OpenVideoInfo(video: Video) {
    this.VideoModalOpened(video.ytVideoId);
    this.popupService.openVideoInfoModal(
      video.ytVideoId,
      () => this.VideoModalClosed(),
      () => {},
      () => {
        video.isSwiped = true;
      },
      () => {
        video.isSwiped = false;
      }
    );
  }

  public VideoModalOpened(ytVideoId: string) {
    this.videoModalOpened.emit(ytVideoId);
  }

  public VideoModalClosed() {
    this.videoModalClosed.emit();
  }

  public ParseDuration(duration: string) {
    return duration.split("-").map((str) => {
      return parseInt(str);
    });
  }

  public OpenVideoBrand(video: Video, event: Event) {
    event.stopPropagation();
    const currentRoute = this.route.snapshot.routeConfig?.path;
    this.popupService.OpenBrandPreview(video.brandId, currentRoute);
  }

  public RemoveVideoSwipe(video: Video) {
    this.popupService.AskToConfirm(
      "Swipe deletion",
      "Are you sure that you want to delete selected brand?",
      async () => {
        await this.DeleteVideoSwipe(video.ytVideoId);
        video.isSwiped = false;
      }
    );
  }

  public async DeleteVideoSwipe(ytVideoId: string) {
    const result = await this.apiService.RemoveSwipedVideo(ytVideoId);

    if (result?.errorMessage) {
      this.popupService.Notify(result?.errorMessage);
      return;
    }
    this.popupService.Notify("Video successfuly removed from Swipes.");
  }

  public async VideoSwipeSaved(boardsIds: number[], video: Video) {
    const result = await this.apiService.SwipeVideo(video.ytVideoId, boardsIds);
    if (result?.errorMessage) {
      this.popupService.Notify(result?.errorMessage);
      return;
    }
    video.isSwiped = true;
    this.popupService.Notify("Video successfuly swiped.");
  }

  async LoadSwipeBoards() {
    const result = await this.apiService.GetSwipeBoards();
    if (result?.errorMessage) this.popupService.Notify(result.errorMessage);
    if (result?.data)
      this.swipeBoards = [
        {
          name: "All swipes",
          swipeBoardId: 0,
          isChecked: true,
          shareId: "",
          isShared: false,
          email: "",
          userName: "",
        },
        ...result.data,
      ];
  }

  public GetBrandLink(brandId: number) {
    return (
      window.location.origin + "/#/dashboard/entity-info?brandId=" + brandId
    );
  }
}
