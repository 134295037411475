import { Component, EventEmitter, Output } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.scss"],
})
export class WelcomeComponent {
  modalData = [
    {
      videoId:
        "https://player.vimeo.com/video/885168000?h=d81cd13da2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      title: "Welcome to your dashboard!",
      desc: "We are glad to have you on board. Watch the welcome video to get a quick overview of VidTao 2.0.",
    },
    {
      videoId:
        "https://player.vimeo.com/video/885167686?h=d81cd13da2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
      title: "How the search works",
      desc: "Learn how to quickly find winning advertising strategies that are currently working on YouTube.",
    },
  ];
  slideIndex = 0;
  @Output() modalClosed: EventEmitter<string> = new EventEmitter<string>();

  constructor(public dialogRef: MatDialogRef<WelcomeComponent>) {}

  public PrevSlide() {
    this.slideIndex =
      (this.slideIndex - 1 + this.modalData.length) % this.modalData.length;
  }

  public NextSlide() {
    this.slideIndex = (this.slideIndex + 1) % this.modalData.length;
  }

  public JumpToSlide(index: number) {
    this.slideIndex = index;
  }

  public CloseModal() {
    this.dialogRef.close();
  }
}
