<div class="pagination-div">
    <div class="pages-navigator mx-auto align-items-center">
        <button [disabled]="currentPage == 1" (click)="NewChunk(currentPage - 1)" style="border-radius: 8px;"
            class="option-button option-navigator h-100">
            <mat-icon class="icon" [svgIcon]="'arrow-left'"></mat-icon>
        </button>
        {{currentPage}}
        <button [disabled]="!hasMore" (click)="NewChunk(currentPage + 1)" style="border-radius: 8px;"
            class="option-button option-navigator h-100">
            <mat-icon class="icon" [svgIcon]="'arrow-right'"></mat-icon>
        </button>
    </div>

    <select style="border-radius: 8px; padding: 2px 4px; height: auto;" class="form-control w-auto"
        [(ngModel)]="resultsPerPage" (ngModelChange)="onPageSizeChange($event)">
        <option [value]="size" *ngFor="let size of pageSizes">{{size}}</option>
    </select>
</div>