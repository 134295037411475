<div class="info-section-main">
    <div class="stat-heading mb-2">
        Campaigns per country
    </div>
    <div class="underline mb-2"></div>
    <p *ngFor="let country of top5Countries" class="text-desc-2 d-flex mb-1">
        <ng-container *ngIf="country.countryId != 0">
            <app-flag [countryCode]="staticDataService.countriesMap.get(country.countryId)?.alpha2Code">
            </app-flag>
            <span class="stat-heading-small" style="align-self: center; margin-left: 5px;">
                {{staticDataService.countriesMap.get(country.countryId)?.alpha2Code}}:
                <span class="stat-heading-bolder">
                    {{country.count}}
                    <span *ngIf="country.percentage > 1">
                        ({{country.percentage | toNearest}}%)
                    </span>
                    <span *ngIf="country.percentage <= 1">
                        (&lt;1%)
                    </span>
                </span>
            </span>
        </ng-container>
        <ng-container *ngIf="country.countryId == 0">
            <img class="mr-2 ml-1" src="assets/icons/worldwide.svg" />
            <span class="stat-heading-small" style="align-self: center; margin-left: 5px;">
                Other:
                <span class="stat-heading-bolder">
                    {{country.count}}
                    <span *ngIf="country.percentage > 1">
                        ({{country.percentage | toNearest}}%)
                    </span>
                    <span *ngIf="country.percentage <= 1">
                        (&lt;1%)
                    </span>
                </span>
            </span>
        </ng-container>
    </p>
</div>