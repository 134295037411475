<form [formGroup]="registerForm" class="container px-2 px-sm-3 px-md-4 py-4">
    <div *ngIf="canClose" class="d-flex position-relative">
        <!-- <img class="mx-auto" style="height: 30px;" src="assets/icons/logo-black-notext.png" /> -->
        <img (click)="CloseModal()" class="my-auto position-absolute" style="height: 24px; right: 5px; cursor: pointer;"
            src="assets/icons/x-close.png" />
    </div>

    <h3 class="title mb-3 ">Create your free account</h3>
    <input autocomplete="off" formControlName="email" type="email" class="form-control input-element"
        placeholder="Enter your email" />
    <button [disabled]="registerForm.invalid || !isRecaptchaVerified" (click)="RegisterWithEmailAndPassword()"
        class="btn sign-in-button mt-3">Get started</button>
    <div class="line-with-text py-2">
        <span class="line"></span>
        <span class="text">Or</span>
        <span class="line"></span>
    </div>
    <button [disabled]="!isRecaptchaVerified" (click)="RegisterWithGoogle()"
        class="btn text-center google-button w-100 d-flex align-items-center">
        <div [ngClass]="isRecaptchaVerified ? 'hide-spinner': ''" class="spinner mr-2"></div>
        <img style="height: 24px;" src="assets/icons/google-icon.png" />
        <span class="ml-2">Sign up with Google account</span>
    </button>
    <div class="mt-2" id="recaptcha-container-register"></div>
    <p style="max-width: 350px;" class="mt-3 mb-0 px-3 text-center">Already have an account? <a
            (click)="OpenLoginModal()" href="javascript:void(0)">Sign in
            here</a>.
    </p>
    <p style="max-width: 350px;" class="mt-3 mb-0 px-3 text-center">By signing up, you agree to our <a
            href="/#/terms-of-service">Terms of Service</a> and
        <a href="/#/privacy-policy"> Privacy Policy</a>.
    </p>
</form>