import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-interval-select',
  templateUrl: './interval-select.component.html',
  styleUrls: ['./interval-select.component.scss']
})
export class IntervalSelectComponent {
  @Input() selectedInterval: string = ''
  @Output() intervalSelected: EventEmitter<string> = new EventEmitter<string>();

  public IntervalSelected(interval: string) {
    this.intervalSelected.emit(interval);
  }

}
