<div class="container">
    <p class="contact-title mb-5">Contact us</p>
    <div class="row">
        <div class="col-12 col-lg-6">
            <p class="question mb-4">Want to chat?</p>
            <p class="mb-5" style="font-style: italic;">We'd love to hear from you 🙂</p>
            <p style="font-size: 14px;">Just fill out the form below and we'll get back to you asap!</p>
            <form (ngSubmit)="SendMessage()">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <p class="mb-1">First name</p>
                        <input name="firstName" [(ngModel)]="firstName" placeholder="John" type="text"
                            class="form-control" />
                    </div>
                    <div class="col-12 col-sm-6">
                        <p class="mb-1">Email address</p>
                        <input name="email" [(ngModel)]="email" placeholder="j.doe@inbox.com" type="text"
                            class="form-control" />
                    </div>
                    <div class="col-12 mt-4">
                        <p class="mb-1">Message</p>
                        <textarea name="message" [(ngModel)]="message" placeholder="j.doe@inbox.com" type="text"
                            class="form-control ta"></textarea>
                    </div>
                    <button type="submit" class="btn px-2 py-1 main-button ml-auto mr-3 my-2">Send message</button>
                </div>
            </form>

        </div>
        <div class="col-12 col-lg-6">
            <div class="map" id="map" class="match-parent">
            </div>
        </div>
    </div>
</div>