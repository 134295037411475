import { Pipe, PipeTransform } from "@angular/core";
import {
  DomSanitizer,
  SafeResourceUrl,
  SafeUrl,
} from "@angular/platform-browser";

@Pipe({
  name: "safeUrl",
})
export class SafeUrlPipe implements PipeTransform {
  // constructor(private sanitizer: DomSanitizer) { }

  // transform(videoId: string): SafeResourceUrl {
  //   const url = `https://www.youtube.com/embed/${videoId}`;
  //   const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

  //   return safeUrl;
  // }

  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
