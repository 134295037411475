import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[data-label]'
})
export class DataLabelDirective {
 @Input('data-label') label: string = "";

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit() {
    this.renderer.setAttribute(this.el.nativeElement, 'data-label', this.label);
  }

}
