import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';


@Injectable({
  providedIn: 'root'
})
export class TokenService {

  fbUser!: firebase.User | null | undefined;
  isLoaded: boolean = false;

  constructor() {
  }

  public async GetToken() {
    if (this.fbUser) {
      return await this.fbUser.getIdToken();
    }
    return "";
  }

}
