import { Component } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { ApiService } from 'src/app/services/api/api.service';
import { PopupService } from 'src/app/services/shared/popup.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
  map!: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/streets-v11';
  lat = 59.33;
  lng = 18.06;

  firstName: string = ''
  email: string = '';
  message: string = '';

  constructor(public apiService: ApiService,
    public popupService: PopupService) { }

  ngOnInit() {
    (mapboxgl as typeof mapboxgl).accessToken = environment.mapbox.accessToken;
    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: 13,
      center: [this.lng, this.lat]
    });
    // Add map controls
    this.map.addControl(new mapboxgl.NavigationControl());
  }

  public async SendMessage() {
    const result = await this.apiService.SendContactEmailMessage(this.firstName, this.email, this.message);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
    }

    if (result?.data) {
      this.popupService.Notify("Message successfully sent.")
    }
  }
}
