import { Component, OnInit } from "@angular/core";
import { ModuleType } from "src/app/models/enums";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-affiliate-landers",
  templateUrl: "./affiliate-landers.component.html",
})
export class AffiliateLandersComponent implements OnInit {
  keyword: string = "";

  constructor(public stateService: StateService) {}

  ngOnInit(): void {
    this.stateService.TabSelected("landers");
    this.stateService.ModuleSelected(ModuleType.Affiliate);
  }
}
