<div class="bs-container d-flex p-2 align-items-center">
    <div class="bs-icon-container p-2 d-flex align-items-center">
        <mat-icon style="font-size: 12px; width: 19px; height: 19px;" [svgIcon]="'bs-icon'"></mat-icon>
    </div>
    <p class="field-label mb-0 ml-3 d-none d-sm-block">
        Free Strategy Session: Enhance your YouTube ad performance
    </p>
    <p class="field-label d-block d-sm-none mb-0 ml-3">
        Free Strategy Session
    </p>
    <button (click)="OpenCalendlyInNewTab()" style="white-space: nowrap;"
        class="btn transparent-button h-auto ml-3 mr-3">Book now</button>
    <button (click)="HideBanner()" class="btn d-flex px-0">
        <i style="font-size: 25px" class='bx bx-x'></i>
    </button>
</div>