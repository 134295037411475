<div class="p-2 mt-5">
    <div *ngIf="!isSharingMode" class="d-flex ml-0 ml-md-4" style="max-width: 300px">
        <app-swipe-board-select (boardSelected)="BoardSelected($event)" (deleteBoard)="DeleteBoard($event)"
            (editBoard)="EditBoard($event)" [swipeBoards]="swipeBoards"></app-swipe-board-select>
        <button [matMenuTriggerFor]="menu" #trigger="matMenuTrigger" style="padding: 7px"
            class="option-button btn custom-btn custom-btn1 ml-2">
            <i style="font-size: 22px;" class='bx bx-plus-circle mr-1'></i>
            Add new board
        </button>
        <app-share-button [type]="'swipe-board'" class="ml-1" [copyUrl]="getCurrentUrl"
            *ngIf="selectedBoardId !== 0"></app-share-button>
        <mat-menu #menu="matMenu">
            <app-upsert-swipe-board (addBoard)="AddBoard($event)"
                (menuClosed)="trigger.closeMenu()"></app-upsert-swipe-board>
        </mat-menu>
    </div>
    <div *ngIf="sharedBoardUserEmail" class="d-flex flex-column mb-5">
        <p class="shared-email mb-2">This swipe board was provided to you by
            <span class="bold-email">{{sharedBoardUserEmail}}</span>
        </p>
        <button *ngIf="isUserLoggedIn" (click)="AcceptBoard()" class="btn light-blue-button fit-content mx-auto">Accept
            board</button>
    </div>

    <div class="row mx-0 w-100 max-w search-options-container options-container mx-auto">
        <button [ngClass]=" type != searchType.Ads ? 'option-not-selected': 'option-selected'"
            (click)="type = searchType.Ads;" class="search-option-button col-6 col-sm-3">
            <mat-icon [svgIcon]="'menu-5'" class="icon mr-2"></mat-icon>
            Ads
        </button>
        <button [ngClass]=" type != searchType.Brands ? 'option-not-selected': 'option-selected'"
            (click)="type = searchType.Brands;" class="search-option-button col-6 col-sm-3">
            <mat-icon [svgIcon]="'menu-6'" class="icon mr-2"></mat-icon>
            Brands
        </button>
        <button [ngClass]=" type != searchType.Offers ? 'option-not-selected': 'option-selected'"
            (click)="type = searchType.Offers;" class="search-option-button col-6 col-sm-3">
            <mat-icon [svgIcon]="'menu-16'" class="icon mr-2"></mat-icon>
            Affiliate offers
        </button>
        <button [ngClass]="type != searchType.Companies ?  'option-not-selected': 'option-selected'"
            (click)="type = searchType.Companies" class="search-option-button col-6 col-sm-3">
            <mat-icon [svgIcon]="'menu-7'" class="icon mr-2"></mat-icon>
            Companies
        </button>
    </div>
</div>
<div class="container">
    <div #inputContainer style="margin-top: 20px;" class="d-flex min-container position-relative">
        <div class="search-icon d-flex align-items-center px-3">
            <i class="fa fa-search fa-lg"></i>
        </div>
        <input [formControl]="swipeControl" #Input (focus)="isInputFocused = true" (blur)="isInputFocused = false"
            [(ngModel)]="keyword" name="searchInput" spellcheck="false"
            class="input-field input-field1 border-right form-control pl-1 pr-0" autocomplete="off"
            placeholder="Search your saved swipes..." type="text" />
    </div>
</div>
<ng-container *ngIf="type == searchType.Ads">
    <app-video-swipes [shareBoardId]="sharedBoardId" (boardsSelected)="UpdateVideoSwipe($event)"
        [swipeBoards]="swipeBoards" [selectedBoardId]="selectedBoardId" [keyword]="filterKeyword"></app-video-swipes>
</ng-container>

<ng-container *ngIf="type == searchType.Brands">
    <div class="container container-1400 mt-5">
        <div class="table-container mb-5">
            <div class="filters-div"></div>
            <app-brands-list [loadedParams]="true" [shareBoardId]="sharedBoardId"
                (boardsSelected)="UpdateBrandSwipe($event)" [swipeBoards]="swipeBoards"
                [selectedBoardId]="selectedBoardId" [isSwipes]="true" [keyword]="filterKeyword"
                [type]="'swiped-brands'"></app-brands-list>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="type == searchType.Companies">
    <div class="container container-1400 mt-5">
        <div class="table-container mb-5">
            <div class="filters-div"></div>
            <app-companies-list [loadedParams]="true" [shareBoardId]="sharedBoardId"
                (boardsSelected)="UpdateCompanySwipe($event)" [isSwipes]="true" [swipeBoards]="swipeBoards"
                [keyword]="filterKeyword" [selectedBoardId]="selectedBoardId"
                [type]="'swiped-companies'"></app-companies-list>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="type == searchType.Offers">
    <div class="container container-1400 mt-5">
        <div class="table-container mb-5">
            <div class="filters-div"></div>
            <app-offers-list [loadedParams]="true" [shareBoardId]="sharedBoardId"
                (boardsSelected)="UpdateOfferSwipe($event)" [isSwipes]="true" [swipeBoards]="swipeBoards"
                [keyword]="filterKeyword" [selectedBoardId]="selectedBoardId"
                [type]="'swiped-offers'"></app-offers-list>
        </div>
    </div>
</ng-container>