import { SpendEntity } from "./spendEntity";

export class Video {
  public title: string = "";
  public description: string = "";
  public duration: number = 0;
  public thumbnail: string = "";
  public category: string = "";
  public categoryId: number = 0;
  public publishedAt: Date = new Date();
  public totalSpend: number = 0;
  public last30Days: number = 0;
  public last90Days: number = 0;
  public ytVideoId: string = ""; //ovo jedno promijeniti
  public firstSeen: string = "";
  public lastSeen: string = "";
  public isActive: boolean = false;
  public spend: SpendEntity[] = [];
  public width: number = 0;
  public height: number = 0;
  public isVertical: boolean = true;
  public brandName: string = "";
  public brandId: number = 0;
  public swiped: boolean = false;
  public affiliateNetworkName: string = "";
  public affiliateNetworkId: number = 0;
  public swipeBoardIds: number[] = [];
  public isSwiped: boolean = false;
  public offerName: string = "";
  public affiliateOfferId: number = 0;
  public isInViewport: boolean = false;
}
