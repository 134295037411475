<div class="container container-1400 m-top">
    <div class="container mb-3">
        <div #inputContainer style="margin-top: 20px;" class="d-flex min-container position-relative">
            <div class="search-icon d-flex align-items-center px-3">
                <i class="fa fa-search fa-lg"></i>
            </div>
            <input [formControl]="searchControl" #Input name="searchInput" spellcheck="false"
                class="input-field input-field1 border-right form-control pl-1 pr-0" autocomplete="off"
                placeholder="What would you like to learn about?" type="text" />
        </div>
    </div>
    <div class="filter-container-div ml-auto">
        <app-select-training-category
            (trainingCategorySelected)="CategorySelected($event)"></app-select-training-category>
    </div>
    <ng-container *ngFor="let trainingSection of filteredTrainings">
        <ng-container *ngIf="isPayingUser || trainingSection.description === 'How to use VidTao'">
            <h3 *ngIf="trainingSection.trainings.length > 0" style="margin-top: 80px;" class="section-heading mb-2">
                {{trainingSection.description}}</h3>
            <div class="row">
                <div *ngFor="let training of trainingSection.trainings" class="col-12 col-md-6 col-lg-4 mt-3">
                    <div (click)="OpenTrainingPage(training)" class="training-card">
                        <div class="image-container">
                            <img [src]="training.image || '/assets/icons/vidTao-placeholder-img.png'" />
                        </div>
                        <p class="mb-0 p-2 crop">
                            <span class="card-text">{{training.name}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!isPayingUser && trainingSection.description !== 'How to use VidTao'">
            <h3 *ngIf="trainingSection.trainings.length > 0" style="margin-top: 80px;" class="section-heading mb-2">
                {{trainingSection.description}}</h3>
            <div class="row">
                <div *ngFor="let training of trainingSection.trainings" class="col-12 col-md-6 col-lg-4 mt-3">
                    <div (click)="OpenSubscriptionModal()" class="training-card">
                        <div class="image-container">
                            <img style="opacity: 0.4;"
                                [src]="training.image || '/assets/icons/vidTao-placeholder-img.png'" />
                            <app-lock-upgrade-icon class="absolute-training-position"></app-lock-upgrade-icon>
                        </div>
                        <p class="mb-0 p-2 crop">
                            <span class="card-text">{{training.name}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
</div>