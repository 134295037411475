<div class="container-fluid px-2">
    <div [ngClass]="{'blur': isInputFocused, 'opacity-low': isInputFocused}">
        <div style="text-align: end;" class="container container-1400 my-5">
            <i (click)="OpenSearchTutorial()" class='bx bx-info-circle mt-1 ml-auto see-more-button'></i>
        </div>
        <div class="container container-800 px-0">
            <div #inputContainer class="d-flex min-container position-relative margin-top">
                <div class="search-icon d-flex align-items-center px-3">
                    <i class="fa fa-search fa-lg"></i>
                </div>
                <input #Input (keyup.enter)="SubmitSearch()" (focus)="InputFieldFocused($event)" [(ngModel)]="keyword"
                    name="searchInput" spellcheck="false"
                    class="input-field input-field1 border-right form-control pl-1 pr-0" autocomplete="off"
                    placeholder="Analyze any company or video ad..." type="text" />
            </div>
            <section [ngClass]="{ 'expanded': isExpanded }" style="margin-top: 84px;" class="suggestions">
                <div class="suggest-title">
                    <p class="mb-0">Recent brands/companies</p>
                </div>
                <div (click)="OpenEntityPage(item)" *ngFor="let item of recentSearches"
                    class="suggest-item d-flex align-items-center">
                    <ng-container *ngIf="item.brandId != null">
                        <div class="thumbnail mr-3">
                            <img style=" width: 40px; height: 40px; border-radius: 50%;" [src]='item.thumbnail' />
                        </div>
                    </ng-container>
                    <ng-container *ngIf="item.companyId != null">
                        <div class="rounded-icon mr-3">
                            <span class="icon-letter">{{ item.name | uppercase | slice:0:1 }}</span>
                        </div>
                    </ng-container>
                    <p class="ml-2 mb-0">{{item.name}}</p>
                    <p class="ml-auto mr-3 mb-0">{{item.visitTime | dateFormat}}</p>
                </div>
            </section>
            <div class="d-flex justify-content-center">
                <i *ngIf="!isExpanded" (click)="isExpanded = !isExpanded"
                    class='bx bx-chevron-down expanding-icons'></i>
                <i *ngIf="isExpanded" (click)="isExpanded = !isExpanded" class='bx bx-chevron-up expanding-icons'></i>
            </div>
        </div>
        <div *ngIf="feedData.length > 0" class="container container-800 px-0 mt-5">
            <app-feed [feedData]="feedData"></app-feed>
        </div>
        <div class="container container-900">
            <div style="margin-top: 84px; margin-bottom: 50px;" class="row">
                <div class="col-12 col-md-6 justify-content-center d-flex flex-column px-3">
                    <h3 class="card-title">
                        Get comprehensive overview of any category
                    </h3>
                    <div class="tick-options">
                        <div class="tick-option d-flex align-items-center mb-3 regular-fs">
                            <mat-icon [svgIcon]="'check-circle'" class="mr-2"></mat-icon>
                            <span>See top companies, brands and ads</span>
                        </div>
                        <div class="tick-option d-flex align-items-center mb-3 regular-fs">
                            <mat-icon [svgIcon]="'check-circle'" class="mr-2"></mat-icon>
                            <span>Find trending competitors</span>
                        </div>
                        <div class="tick-option d-flex align-items-center mb-4 regular-fs">
                            <mat-icon [svgIcon]="'check-circle'" class="mr-2"></mat-icon>
                            <span>Inspire your next campaign</span>
                        </div>
                        <button (click)="OpenRankingsPage()" class="btn main-button">Analyze category</button>
                    </div>
                </div>
                <div class="col-12 col-md-6 justify-content-center d-flex px-3 pt-3 pt-md-0">
                    <img class="screen-img" src="assets/icons/preview-rankings.png" />
                </div>
            </div>
        </div>
    </div>
    <div (click)="InputFieldUnfocused($event)" #overlayContainer class="overlay-container">
        <div #overlayInnerContainer class="d-flex min-container position-absolute">
            <div class="search-icon d-flex align-items-center px-3">
                <i class="fa fa-search fa-lg"></i>
            </div>

            <input #overlayInput (keyup.enter)="SubmitSearch()" [(ngModel)]="keyword" name="searchInput"
                spellcheck="false" class="input-field input-field1 form-control pl-1 pr-0" autocomplete="off"
                placeholder="Analyze any company or video ad..." type="text" />
            <div style="background: #fff;" class="search-btn-container d-flex align-items-center px-2">
                <button (click)="SubmitSearch()" class="search-button btn">Search</button>
            </div>
            <app-search-suggestions (displayTypeChanged)="DisplayTypeChanged($event)" [top]="'50'"
                [keyword]="keyword"></app-search-suggestions>
        </div>
    </div>
</div>