
import { Rank } from "./rank";
import { Ranks } from "./ranks";
import { PercentageByCountry } from "./percentageByCountry";
import { Company } from "./company";

export class GetCompanyResponse {
    public company: Company = new Company();
    public creativeCount: number = 0;
    public ranks: Ranks = new Ranks();
    public top5Countries: PercentageByCountry[] = [];
}