<div [ngClass]="{'highlight': isInputFocused}" class="container-fluid search-outter-container">
    <div class="container container-1400 search-inner-container">
        <form class="d-flex">
            <div class="iconSearchContainer">
                <i class="fa fa-search fa-lg"></i>
            </div>
            <input [focused]="isInputFocused" name="searchInput" spellcheck="false" [(ngModel)]="keyword"
                (keyup.enter)="SubmitSearch()" (focus)="isInputFocused = true" (blur)="isInputFocused = false"
                class="input-field form-control" autocomplete="off" placeholder="Enter search keyword..." type="text" />
            <div class="search-btn-container no-border d-flex align-items-center px-2">
                <button (click)="SubmitSearch()" class="search-button btn">Search</button>
            </div>
        </form>
    </div>
</div>
<!--[ngClass]="{'blur': isInputActive}" za dio koji hocemo da blurujemo-->

<div class="container-fluid info-text-section">
    <div class="container container-1400 py-4">
        <div class="info-text-title">Search results for "{{shownKeyword}}"</div>
    </div>
</div>

<div [ngClass]="{'blur': isInputFocused}" class="container container-1400"
    style="margin-top: 32px; margin-bottom: 50px;">
    <div class="table-container">
        <div class="filters-div">
            <div class="d-flex bottom-border">
                <div class="row mx-0 w-100">
                    <button [ngClass]=" type != searchType.Companies ? 'option-not-selected': 'option-selected'"
                        (click)="type = searchType.Companies" class="option-button option1 col-6 col-sm-3">
                        <img class="mr-2" src="assets/icons/company-icon.png" />
                        Companies
                    </button>
                    <button [ngClass]=" type != searchType.Brands ? 'option-not-selected': 'option-selected'"
                        (click)="type = searchType.Brands" class="option-button option2 col-6 col-sm-3">
                        <img class="mr-2" src="assets/icons/brand-icon.png" />
                        Brands
                    </button>
                    <button [ngClass]="type != searchType.Ads ?  'option-not-selected': 'option-selected'"
                        (click)="type = searchType.Ads" class="option-button option3 col-6 col-sm-3">
                        <img class="mr-2" src="assets/icons/ads-icon.png" />
                        Ads
                    </button>
                    <button disabled
                        [ngClass]="type != searchType.Categories ? 'option-not-selected': 'option-selected'"
                        (click)="type = searchType.Categories"
                        class="option-button option4 col-6 col-sm-3 disabled-opacity">
                        <img class="mr-2" src="assets/icons/categories-icon.png" />
                        Categories
                    </button>
                </div>
            </div>

        </div>
        <ng-container *ngIf="type == searchType.Companies">
            <app-companies-list [loadedParams]="true" [isPayingUser]="isPayingUser"
                (companiesLoaded)="CompaniesLoaded($event)" [keyword]="shownKeyword"></app-companies-list>
        </ng-container>
        <ng-container *ngIf="type == searchType.Brands">
            <app-brands-list [loadedParams]="true" [isPayingUser]="isPayingUser" (brandsLoaded)="BrandsLoaded($event)"
                [keyword]="shownKeyword"></app-brands-list>
        </ng-container>
        <ng-container *ngIf="type == searchType.Ads">
            <app-videos-list [loadedParams]="true" [isPayingUser]="isPayingUser" [keyword]="shownKeyword">
            </app-videos-list>
        </ng-container>
    </div>
</div>