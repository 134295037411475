<mat-form-field appearance="outline" class="no-line">
    <mat-select placeholder="-Select entity-" class="select" [(ngModel)]="selectedType">
        <mat-select-trigger style="display: flex;">
            <ng-container *ngIf="selectedType == 'companies'">
                <mat-icon class="mr-2" [svgIcon]="'company-icon'"></mat-icon>
                Companies
            </ng-container>
            <ng-container *ngIf="selectedType == 'brands'">
                <mat-icon class="mr-2" [svgIcon]="'brand-icon'"></mat-icon>
                Brands
            </ng-container>
        </mat-select-trigger>
        <mat-option (click)="BrandsSelected()" value="brands">
            <mat-icon [svgIcon]="'brand-icon'"></mat-icon>
            Brands
        </mat-option>
        <mat-option (click)="CompaniesSelected()" value="companies">
            <mat-icon [svgIcon]="'company-icon'"></mat-icon>
            Companies
        </mat-option>
    </mat-select>
</mat-form-field>