<mat-form-field appearance="outline" class="no-line">
    <mat-select multiple [formControl]="offersControl" class="select" placeholder="-Select offer-"
        [(ngModel)]="selectedOfferIds" disableOptionCentering>
        <mat-option>
            <ngx-mat-select-search [disableInitialFocus]="true" noEntriesFoundLabel="No offers found"
                [formControl]="searchControl" [hideClearSearchButton]="true" placeholderLabel="Find offer...">
            </ngx-mat-select-search>
        </mat-option>
        <mat-select-trigger style="display: flex;">
            <mat-icon class="mr-2" [svgIcon]="'categories-icon'"></mat-icon>
            {{selectedOfferName}}
        </mat-select-trigger>
        <mat-option (click)="OfferSelected()" [value]="offer.affiliateOfferId" *ngFor="let offer of filteredOffers">
            {{offer.networkOfferId}}
        </mat-option>
    </mat-select>
</mat-form-field>