<div class="flex-container">
    <ng-container *ngIf="nestingRequired && !isMultipleSelection">
        <mat-form-field appearance="outline" class="no-line">
            <mat-select class="select" [placeholder]="placeholder" [(ngModel)]="selectedCategoryId"
                (ngModelChange)="CategorySelected()">
                <mat-option>
                    <ngx-mat-select-search [disableInitialFocus]="true" noEntriesFoundLabel="No categories found"
                        [formControl]="searchControl" [hideClearSearchButton]="true"
                        placeholderLabel="Find category..."></ngx-mat-select-search>
                </mat-option>
                <mat-select-trigger style="display: flex;">
                    <mat-icon class="mr-2" [svgIcon]="'categories-icon'"></mat-icon>
                    {{staticDataService.categoriesMap.get(selectedCategoryId)?.name}}
                </mat-select-trigger>
                <ng-container
                    *ngTemplateOutlet="recursiveOptions; context: { $implicit: filteredCategories, depth: 1 }">
                </ng-container>
                <ng-template #recursiveOptions let-list let-depth="depth">
                    <ng-container *ngFor="let category of list">
                        <mat-option [value]="category.categoryId"
                            [ngStyle]="{'padding-left.px': depth <= 1 ? null : 16 * depth}">
                            {{ category.name }}
                        </mat-option>

                        <ng-container *ngIf="!!category.children && category.children.length > 0">
                            <ng-container
                                *ngTemplateOutlet="recursiveOptions; context: { $implicit: category.children, depth: depth + 1 }">
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-template>

            </mat-select>
        </mat-form-field>
    </ng-container>
    <ng-container *ngIf="!nestingRequired && !isMultipleSelection">
        <mat-form-field appearance="outline" class="no-line">
            <mat-select class="select" [placeholder]="placeholder" [(ngModel)]="selectedCategoryId"
                (ngModelChange)="CategorySelected()" disableOptionCentering>
                <mat-option>
                    <ngx-mat-select-search noEntriesFoundLabel="No categories found" [formControl]="searchControl"
                        [hideClearSearchButton]="true" placeholderLabel="Find category..."></ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="category.categoryId" *ngFor="let category of filteredCategories">
                    {{ category.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
    <ng-container *ngIf="nestingRequired && isMultipleSelection">
        <mat-form-field appearance="outline" class="no-line">
            <mat-select [formControl]="categoriesControl" multiple class="select" [placeholder]="placeholder"
                [(ngModel)]="selectedCategoryIds">
                <mat-option>
                    <ngx-mat-select-search [disableInitialFocus]="true" noEntriesFoundLabel="No categories found"
                        [formControl]="searchControl" [hideClearSearchButton]="true"
                        placeholderLabel="Find category..."></ngx-mat-select-search>
                </mat-option>
                <mat-select-trigger style="display: flex;">
                    <mat-icon class="mr-2" [svgIcon]="'categories-icon'"></mat-icon>
                    {{getCategories}}
                </mat-select-trigger>
                <ng-container
                    *ngTemplateOutlet="recursiveOptions; context: { $implicit: filteredCategories, depth: 1 }">
                </ng-container>
                <ng-template #recursiveOptions let-list let-depth="depth">
                    <ng-container *ngFor="let category of list">
                        <mat-option (click)="CategoriesSelected(category)" [value]="category.categoryId"
                            [ngStyle]="{'padding-left.px': depth <= 1 ? null : 16 * depth}">
                            {{ category.name }}
                        </mat-option>
                        <ng-container *ngIf="!!category.children && category.children.length > 0">
                            <ng-container
                                *ngTemplateOutlet="recursiveOptions; context: { $implicit: category.children, depth: depth + 1 }">
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-template>
            </mat-select>
        </mat-form-field>
    </ng-container>
</div>