import { Component, OnInit } from "@angular/core";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  styleUrls: ["./privacy-policy.component.scss"],
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(public stateService: StateService) {}

  ngOnInit(): void {
    this.LoadTermlyScript();
  }

  LoadTermlyScript() {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "termly-jssdk";
    script.src = "https://app.termly.io/embed-policy.min.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  }
}
