<div class="container" style="padding: 20px;">
    <div *ngFor="let tab of modalData; let i = index" class="d-block">
        <div *ngIf="i === slideIndex" class="iframe-div frame-width">
            <iframe class="frame-player" [src]="tab.videoId | safeUrl" frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture" allowfullscreen>
            </iframe>
        </div>
        <ng-container *ngIf="i === slideIndex">
            <p class="account-section-title text-center mt-3 mb-1">{{tab.title}}</p>
            <p style="max-width: 400px;" class="section-desc text-center mx-auto">{{tab.desc}}</p>
        </ng-container>
    </div>
    <div class="carousel-dots">
        <span class="dot" *ngFor="let _ of modalData; let i = index" [class.active]="i === slideIndex"
            (click)="JumpToSlide(i)"></span>
    </div>
    <button *ngIf="slideIndex === 0" (click)="NextSlide()"
        class="option-button btn custom-btn custom-btn1 mx-auto mt-3">Continue</button>
    <button (click)="CloseModal()" *ngIf="slideIndex === 1"
        class="option-button btn custom-btn custom-btn1 mx-auto mt-3">Get started</button>
</div>