import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SwipeBoard } from "src/app/models/swipeBoard";

@Component({
  selector: "swipe-button",
  templateUrl: "./swipe-button.component.html",
  styleUrls: ["./swipe-button.component.scss"],
})
export class SwipeButtonComponent {
  @Input() isSwiped: boolean = false;
  @Input() swipeBoards: SwipeBoard[] = [];
  @Output() swipeSaved: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() swipeRemoved: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  public SwipeSaved(boardsIds?: number[]) {
    this.swipeSaved.emit(boardsIds);
  }

  public Remove() {
    this.swipeRemoved.emit();
  }
}
