import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class PaginatorService {
    constructor() { }

    public GetPagesArray(currentPage: number, totalPages: number): string[] {
        const visiblePageCount = 7; // Number of visible pages in the pagination bar
        const ellipsis = '...';

        if (totalPages <= visiblePageCount) {
            return Array.from({ length: totalPages }, (_, i) => (i + 1).toString());
        }

        const pages: string[] = [];

        if (currentPage <= Math.ceil(visiblePageCount / 2)) {
            // Show first pages and ellipsis
            for (let i = 1; i <= visiblePageCount - 2; i++) {
                pages.push(i.toString());
            }
            pages.push(ellipsis);
            pages.push(totalPages.toString());
        } else if (currentPage >= totalPages - Math.floor(visiblePageCount / 2)) {
            // Show ellipsis and last pages
            pages.push("1");
            pages.push(ellipsis);
            for (let i = totalPages - (visiblePageCount - 3); i <= totalPages; i++) {
                pages.push(i.toString());
            }
        } else {
            // Show ellipsis in the middle
            pages.push("1");
            pages.push(ellipsis);

            const middleStart = currentPage - Math.floor(visiblePageCount / 2) + 2;
            const middleEnd = currentPage + Math.floor(visiblePageCount / 2) - 2;

            for (let i = middleStart; i <= middleEnd; i++) {
                pages.push(i.toString());
            }

            pages.push(ellipsis);
            pages.push(totalPages.toString());
        }

        return pages;
    }
}
