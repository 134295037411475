<div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
    <div>
        <p class="field-label mb-2 px-3 pt-2">Select list of boards for placing this item:</p>
    </div>
    <ng-container *ngFor="let board of swipeBoards;let i = index">
        <button *ngIf="!board.isShared" [disabled]="i == 0" (click)="Check(board)" mat-menu-item>{{board.name}}
            <mat-checkbox color='primary' (click)="PreventDefault($event)" (ngModelChange)="Check(board)"
                [(ngModel)]="board.isChecked"></mat-checkbox>
        </button>
    </ng-container>
    <div class="d-flex">
        <input (keyup.enter)="SaveNewBoard()" [(ngModel)]="newBoardName" placeholder="Enter new board..."
            class="form-control border-0 fs-14 px-3" matInput />
        <button *ngIf="newBoardName" (click)="SaveNewBoard()"
            class="btn option-button custom-btn custom-btn1 mr-3">Save</button>
    </div>
    <div class="d-flex px-3 py-2 ml-auto justify-content-end">
        <button (click)="Close()" class="btn transparent-button">Close</button>
        <button (click)="Save()" class="btn light-blue-button ml-2">Swipe</button>
    </div>
</div>