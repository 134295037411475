import { Component, Inject, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import firebase from "firebase/compat/app";
import { PopupService } from "src/app/services/shared/popup.service";
import { RudderStackService } from "src/app/services/shared/rudderstack.service";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["../login/login.component.scss"],
})
export class RegisterComponent implements OnInit {
  canClose: boolean = true;
  constructor(
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public stateService: StateService,
    public popupService: PopupService,
    public dialogRef: MatDialogRef<RegisterComponent>,
    public rudderStackService: RudderStackService
  ) {
    this.canClose = this.data.canClose;
  }

  ngOnInit(): void {
    this.InitializeRegisterForm();
    this.InitializeRecaptcha();
  }

  registerForm!: FormGroup;
  recaptchaVerifier!: firebase.auth.RecaptchaVerifier;
  isRecaptchaVerified: boolean = false;

  private InitializeRegisterForm() {
    this.registerForm = this.fb.group(
      {
        email: ["", [Validators.required, this.emailValidator]],
      },
      { validator: this.passwordMatchValidator }
    );
  }

  private InitializeRecaptcha() {
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container-register",
      { size: "invisible" }
    );
    this.recaptchaVerifier.render();
    this.recaptchaVerifier
      .verify()
      .then(() => {
        this.isRecaptchaVerified = true;
      })
      .catch((error) => {
        this.isRecaptchaVerified = false;
      });
  }

  passwordMatchValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const password = control.get("password");
    const repeatPassword = control.get("repeatPassword");

    if (password && repeatPassword && password.value !== repeatPassword.value) {
      return { passwordMismatch: true };
    }
    return null;
  }

  public RegisterWithEmailAndPassword() {
    this.rudderStackService.RegistrationStarted();
    const data = this.registerForm.getRawValue();
    this.CloseModal();
    this.popupService.OpenBasicInfoModal(data.email, !this.canClose);
  }

  public RegisterWithGoogle() {
    this.rudderStackService.RegistrationStarted();
    const data = this.registerForm.getRawValue();
    this.stateService.SignInWithGoogle(this.recaptchaVerifier, () => {
      this.CloseModal();
    });
  }

  public OpenLoginModal() {
    this.CloseModal();
    this.popupService.openLoginModal(true);
  }

  public CloseModal() {
    this.dialogRef.close();
  }

  public emailValidator(
    control: AbstractControl
  ): { [key: string]: any } | null {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (control.value && !emailPattern.test(control.value)) {
      return { invalidEmail: true };
    }
    return null;
  }
}
