<ng-container *ngIf="contentLoaded">
    <table>
        <thead>
            <tr>
                <th class="pl-4">Thumbnail</th>
                <th>Brand</th>
                <th>Category</th>
                <th>Country</th>
                <th>
                    Total spend
                    <ng-container *ngIf="isPayingUser && type !== 'company-brands'">
                        <mat-icon (click)="SetSorting(1, 'total', false)" *ngIf="sortingIndex != 1"
                            class="mr-2 order-icon" [svgIcon]="'arrow-up-down'">
                        </mat-icon>
                        <mat-icon (click)="SetSorting(1, 'total', true)" *ngIf="sortingIndex == 1 && !orderAsc"
                            class="mr-2 order-icon" [svgIcon]="'arrow-down-black'">
                        </mat-icon>
                        <mat-icon (click)="SetSorting(1, 'total', false)" *ngIf="sortingIndex == 1 && orderAsc"
                            class="mr-2 order-icon" [svgIcon]="'arrow-up-black'">
                        </mat-icon>
                    </ng-container>
                </th>
                <th *ngIf="isSwipes && !shareBoardId">Boards</th>
                <th *ngIf="type == 'brands'">Swipe</th>
            </tr>
        </thead>
        <tbody>
            <tr class="list-option" (click)="OpenSelectedBrand(result)" *ngFor="let result of displayedBrands">
                <td class="logo-display" data-label="Frame">
                    <div class="ml-4 thumbnail">
                        <img style=" width: 40px; height: 40px; border-radius: 50%;" [src]='result.thumbnail' />
                    </div>
                </td>
                <td class="text-clipped" data-label="Title" dark>{{result.name}}</td>
                <td data-label="Category">
                    <a [href]="GetCategoryLink(result.categoryId)"
                        class="inline-block content-end text-decoration-none">
                        <div class="tag tag2 inline-block">
                            <span (click)="OpenSelectedCategory($event, result.categoryId)" class="clickable-link">
                                {{staticDataService.categoriesMap.get(result.categoryId)?.name}}
                            </span>
                        </div>
                    </a>
                </td>
                <td data-label="Country">
                    <div class="adv-description">
                        {{staticDataService.countriesMap.get(result.countryId)?.name}}
                    </div>
                </td>
                <td [ngStyle]="{'filter': !isPayingUser ? 'blur(4px)' : 'blur(0)', 'user-select': !isPayingUser ? 'none' : 'auto'}"
                    data-label="Total spend">
                    ${{result.totalSpend | valueFormat}}
                </td>
                <td (click)="$event.stopPropagation()" *ngIf="isSwipes && !shareBoardId" data-label="Boards">
                    <app-swipe-board-select [isAdding]="false" (deleteSwipe)="DeleteBrandSwipe(result.brandId)"
                        (boardsSelected)="BoardsSelected($event,result.brandId)"
                        [selectedSwipeBoardIds]="result.swipeBoardIds" [isMultiple]="true"
                        [swipeBoards]="swipeBoards"></app-swipe-board-select>
                </td>
                <td (click)="$event.stopPropagation()" *ngIf="type == 'brands'">
                    <div style="max-width: 110px;">
                        <swipe-button [swipeBoards]="swipeBoards" (swipeRemoved)="RemoveBrandSwipe(result)"
                            class="w-auto mb-2" [isSwiped]="result.isSwiped"
                            (swipeSaved)="BrandSwipeSaved($event, result)"></swipe-button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <app-paginator [hasMore]="searchResultsBrands.hasMore" [resultsPerPage]="resultsPerPage"
        [currentPage]="currentPageBrands" (pageSizeChanged)="onPageSizeChange($event)"
        (pageNumChanged)="ChangePageBrands($event)">
    </app-paginator>
</ng-container>
<div *ngIf="!contentLoaded" class="p-3">
    <app-skeleton-loader [linesNum]="resultsPerPage"></app-skeleton-loader>
</div>