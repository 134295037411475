import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Chart } from "chart.js";
import "chartjs-adapter-moment";
import "chartjs-plugin-annotation";

@Component({
  selector: "app-doughnut-chart",
  templateUrl: "./doughnut-chart.component.html",
  styleUrls: ["./doughnut-chart.component.scss"],
})
export class DoughnutChartComponent implements OnInit, OnChanges {
  @Input() yValues: string[] = [];
  @Input() xValues: number[] = [];
  @Input() chartId!: string;
  @Input() type!: any;
  @Input() colors: string[] = [];
  @Input() isSpend: boolean = false;
  doughnutChart: any;
  allowedChartTypes = ["doughnut"];
  isGraphInitialized: boolean = false;
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.GraphXyChanged();
  }

  private async GraphXyChanged() {
    if (this.xValues.length < 1 || this.yValues.length < 1) return;
    setTimeout(() => {
      this.InitializeGraph();
    }, 100);
  }

  private InitializeGraph() {
    const data = {
      labels: this.yValues,
      datasets: [
        {
          data: this.xValues,
          backgroundColor: this.colors,
          borderWidth: 2,
          fill: false,
        },
      ],
    };
    const options = {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        tooltip: {
          enabled: true,
          intersect: false,
        },
      },
      interaction: {
        intersect: false,
      },
    };
    let chartStatus = Chart.getChart(this.chartId); // <canvas> id
    if (chartStatus != undefined) {
      chartStatus.destroy();
    }

    const ctx = document.getElementById(this.chartId) as HTMLCanvasElement;

    this.doughnutChart = new Chart(ctx, {
      type: this.type,
      data: data,
      options: options,
    });
  }
}
