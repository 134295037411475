import { Injectable } from "@angular/core";
import { RudderAnalytics } from "@rudderstack/analytics-js";
import { environment } from "src/environments/environment";

enum EventType {
  UserRegistered = "customer_registered",
  UserViewedVidtao = "customer_viewed_vidtao_first_time",
  SwipeBoardCreated = "swipe_board_created",
  TrainingStarted = "training_started",
  SwipeBoardShared = "swipe_board_shared", //when shared swipe board is openned
  NavigatorSessionOpened = "navigator_session_opened",
  SignupSequenceFinished = "signup_sequence_finished",
  RegistrationStarted = "customer_registration_started",
  NonUserStartedVideo = "non_user_stared_video",
  NonUserPopupAppeared = "non_user_popup_appeared",
  ShareSwipeBoard = "share_swipe_board_clicked", //when users clicks on share swipe board
  ShareVideo = "share_video_clicked", //when users clicks on share video
  ShareEntity = "share_entity_clicked", //when users clicks on share company, brand, offer
}

@Injectable({
  providedIn: "root",
})
export class RudderStackService {
  analytics: RudderAnalytics | undefined;

  constructor() {
    this.InitializeRudderStack();
  }

  public InitializeRudderStack() {
    if (window.rudderanalytics as RudderAnalytics) {
      return;
    }
    this.analytics = new RudderAnalytics();
    this.analytics.load(environment.rudderStackId, environment.rudderStackUrl);
  }

  public get sessionKey() {
    return this.analytics?.getSessionId();
  }

  public Page(url: string) {
    this.analytics?.page(url);
  }

  public Identify(userId: string, email: string) {
    this.analytics?.identify(userId, { email });
  }

  public UserRegistered(
    userId: string = "",
    email: string | null | undefined = "",
    sharingUserType: string | null = null
  ) {
    this.analytics?.track(EventType.UserRegistered, {
      dbUserId: userId,
      dbEmail: email,
      dbSharingUserType: sharingUserType,
    });
  }

  public RegistrationStarted() {
    this.analytics?.track(EventType.RegistrationStarted, {});
  }

  public NonUserStartedVideo() {
    this.analytics?.track(EventType.NonUserStartedVideo, {});
  }

  public NonUserPopupAppeared() {
    this.analytics?.track(EventType.NonUserPopupAppeared, {});
  }

  public UserViewedVidtao() {
    this.analytics?.track(EventType.UserViewedVidtao);
  }

  public UserStartedTraining(
    userId?: string,
    trainingId?: any,
    trainingName?: string,
    trainingCategory?: string
  ) {
    this.analytics?.track(EventType.TrainingStarted, {
      dbUserId: userId,
      dbTrainingId: trainingId,
      dbTrainingName: trainingName,
      dbTrainingCategory: trainingCategory,
    });
  }

  public SwipeBoardShared(userId?: string, swipeBoardId?: string) {
    this.analytics?.track(EventType.SwipeBoardShared, {
      dbUserId: userId,
      dbSwipeBoardSharedId: swipeBoardId,
    });
  }

  public UserClickedOnNavigatorSession(
    userId?: string,
    email?: string | null | undefined
  ) {
    this.analytics?.track(EventType.NavigatorSessionOpened, {
      dbUserId: userId,
      dbEmail: email,
    });
  }

  public UserFinishedSignupSequence(
    userId?: string,
    email?: string | null | undefined
  ) {
    this.analytics?.track(EventType.SignupSequenceFinished, {
      dbUserId: userId,
      dbEmail: email,
    });
  }

  public ShareSwipeBoardClicked(
    userId?: string,
    email?: string | null | undefined
  ) {
    this.analytics?.track(EventType.ShareSwipeBoard, {
      dbUserId: userId,
      dbEmail: email,
    });
  }

  public ShareVideoClicked(userId?: string, email?: string | null | undefined) {
    this.analytics?.track(EventType.ShareVideo, {
      dbUserId: userId,
      dbEmail: email,
    });
  }

  public ShareEntityClicked(
    userId?: string,
    email?: string | null | undefined
  ) {
    this.analytics?.track(EventType.ShareEntity, {
      dbUserId: userId,
      dbEmail: email,
    });
  }
}
