import {
  animate,
  animateChild,
  group,
  query,
  style,
  transition,
  trigger,
} from "@angular/animations";

const animationDirections: string =
  "home <=> affiliate-ads," +
  "entity-info => affiliate-ads," +
  "settings <=> affiliate-ads," +
  "trainings <=> affiliate-ads," +
  "training-content <=> affiliate-ads," +
  "rankings <=> affiliate-ads, " +
  "swipes <=> affiliate-ads, " +
  "ads <=> affiliate-ads, " +
  "brands <=> affiliate-ads, " +
  "companies <=> affiliate-ads, " +
  "affiliate-companies => home," +
  "affiliate-offers => home," +
  "affiliate-networks => home," +
  "affiliate-landers => home";

export const slideInAnimation = trigger("routeAnimations", [
  transition(animationDirections, [
    style({ position: "relative" }),
    query(":enter, :leave", [
      style({
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
      }),
    ]),
    query(":enter", [style({ left: "-100%" })]),
    query(":leave", animateChild()),
    group([
      query(":leave", [animate("300ms ease-out", style({ left: "100%" }))]),
      query(":enter", [animate("300ms ease-out", style({ left: "0%" }))]),
    ]),
    query(":enter", animateChild()),
  ]),
]);
