<footer class="footer">
    <nav>
        <ul>
            <li>
                <a href="https://www.instagram.com/vidtao/">
                    <i class='bx bxl-instagram'></i>
                </a>
            </li>
            <li>
                <a href="https://www.linkedin.com/company/vidtao/">
                    <i class='bx bxl-linkedin-square'></i>
                </a>
            </li>
            <li>
                <a href="https://twitter.com/VidTao">
                    <i class='bx bxl-twitter'></i>
                </a>
            </li>
            <li>
                <a href="https://www.youtube.com/channel/UCS47ZPGcQHrWonqgn14g9gw">
                    <i class='bx bxl-youtube'></i>
                </a>
            </li>
        </ul>
    </nav>
    <div class="secondary-info mt-2">
        © VidTao. All rights Reserved &nbsp;&nbsp;|&nbsp;&nbsp;
        <a (click)="OpenPrivacyPolicy()">Privacy Policy</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a (click)="OpenTermsOfServices()">Terms of Service</a>
        &nbsp;&nbsp;|
    </div>
</footer>