import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ReplaySubject } from "rxjs";
import { User } from "src/app/models/user";
import { StateService } from "src/app/services/shared/state.service";

declare var YT: any;

@Component({
  selector: "app-yt-player-controls",
  templateUrl: "./yt-player-controls.component.html",
  styleUrl: "./yt-player-controls.component.scss",
})
export class YtPlayerControlsComponent implements OnChanges, AfterViewInit {
  player: any;
  isPlaying: boolean = false;
  currentTime: number = 0;
  isFinished: boolean = false;
  timer: any;
  @Input() time: string = "00:00";
  @Input() ytVideoId: string = "";
  @Input() duration: number = 0;
  @Output() freeIntervalPassed: EventEmitter<void> = new EventEmitter<void>();
  @Output() videoStarted: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild("player", { static: false }) playerContainer!: ElementRef;
  @ViewChild("protectedDiv", { static: false }) protectedDiv!: ElementRef;
  isInceptlyUser: boolean = false;
  errorOccured: boolean = false;
  videoSafeUrl!: SafeResourceUrl;
  videoUrl: string = "";
  accumulatedTime: number = 0;
  accumulationPassedTrigger: boolean = false;
  nonUserAllowedWatchTime = 3;
  isFirstStart: boolean = true;
  user: User | null | undefined = null;

  ytVideoIdSubject = new ReplaySubject<string>(1);
  ytVideoId$ = this.ytVideoIdSubject.asObservable();

  setVideoUrl(startTime: number = 0): void {
    this.videoSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://www.youtube.com/embed/${this.ytVideoId}?start=${startTime}&autoplay=1`
    );
  }

  private contextMenuListener = (event: MouseEvent) =>
    this.onContextMenu(event);
  private keyDownListener = (event: KeyboardEvent) => this.onKeyDown(event);

  constructor(
    private sanitizer: DomSanitizer,
    private stateService: StateService
  ) {}

  ngOnInit(): void {
    this.stateService.user$.subscribe((user) => {
      this.user = user;
      this.isInceptlyUser = this.stateService.IsInceptlyUser(user);
    });
  }

  async ngAfterViewInit(): Promise<void> {
    this.ytVideoId$.subscribe((id) => {
      if (id !== "")
        if (!this.isInceptlyUser) {
          this.loadYouTubeAPI();
          this.addEventListeners();
        } else this.setVideoUrl();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["ytVideoId"]) this.ytVideoIdSubject.next(this.ytVideoId);
    if (changes["time"] && !this.isInceptlyUser) this.JumpToVideoSeconds();
    else if (changes["time"] && this.isInceptlyUser)
      this.setVideoUrl(this.ConvertTimeToSeconds(this.time));
  }

  Sleep(milliseconds: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(resolve, milliseconds);
    });
  }

  ngOnDestroy(): void {
    if (!this.isInceptlyUser) this.removeEventListeners();
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.player) {
      this.player.destroy();
    }
  }
  async loadYouTubeAPI() {
    //@ts-ignore
    if (window["YT"]) {
      this.OnYouTubeIframeAPIReady();
    }
  }

  public async OnYouTubeIframeAPIReady() {
    this.player = new YT.Player(this.playerContainer.nativeElement, {
      height: "390",
      width: "640",
      videoId: this.ytVideoId,
      playerVars: {
        rel: 0,
        modestbranding: 1,
        showinfo: 0,
        controls: 0,
      },
      events: {
        onStateChange: this.OnPlayerStateChange.bind(this),
        onError: this.OnPlayerError.bind(this),
      },
    });
  }

  public OnPlayerError(event: any) {
    // Handle the error here
    this.errorOccured = true;
  }

  public OnPlayerStateChange(event: any) {
    if (event.data === YT.PlayerState.PLAYING) {
      this.isPlaying = true;
      this.isFinished = false;
      this.stopTimer();
      this.startTimer();
    } else if (event.data === YT.PlayerState.PAUSED) {
      this.isPlaying = false;
      this.stopTimer();
    } else if (event.data == YT.PlayerState.ENDED) {
      this.isPlaying = false;
      this.isFinished = true;
      this.currentTime = this.duration;
    }
  }

  togglePlay() {
    if (this.errorOccured) {
      window.open(
        `https://www.youtube.com/watch?v=${this.ytVideoId}`,
        "_blank"
      );
      return;
    }
    if (this.isPlaying) {
      this.player.pauseVideo();
    } else {
      this.player.playVideo();
    }
  }

  startTimer() {
    if (this.isFirstStart) {
      this.isFirstStart = false;
      this.videoStarted.emit();
    }
    this.timer = setInterval(() => {
      if (!this.isFinished) this.currentTime = this.player.getCurrentTime();
      this.accumulatedTime += 0.01;

      if (
        this.accumulatedTime > this.nonUserAllowedWatchTime &&
        !this.user &&
        !this.accumulationPassedTrigger
      ) {
        this.accumulationPassedTrigger = true;
        this.togglePlay();
        this.freeIntervalPassed.emit();
      }
    }, 10);
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  seek(event: MouseEvent) {
    const seekBar = event.currentTarget as HTMLElement; // Get the current target element
    const offsetX = event.offsetX; // Get the offset relative to the seek bar
    const newTime = (offsetX / seekBar.clientWidth) * this.duration;
    this.player.seekTo(newTime, true);
    this.player.playVideo();
    this.isPlaying = true;
  }

  formatTime(seconds: number): string {
    const minutes: number = Math.floor(seconds / 60);
    const secs: number = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  }

  ConvertTimeToSeconds(time: string): number {
    const [minutes, seconds] = time.split(":").map(Number);
    return minutes * 60 + seconds;
  }

  JumpToVideoSeconds() {
    if (this.player)
      this.player.seekTo(this.ConvertTimeToSeconds(this.time), true); // Jump to the specified time
  }

  private addEventListeners() {
    const div = this.protectedDiv.nativeElement;
    div.addEventListener("contextmenu", this.contextMenuListener);
    div.addEventListener("keydown", this.keyDownListener);
  }

  private removeEventListeners() {
    const div = this.protectedDiv.nativeElement;
    div.removeEventListener("contextmenu", this.contextMenuListener);
    div.removeEventListener("keydown", this.keyDownListener);
  }

  private onContextMenu(event: MouseEvent) {
    event.preventDefault();
  }

  private onKeyDown(event: KeyboardEvent) {
    if (
      event.key === "F12" ||
      (event.ctrlKey && event.shiftKey && ["I", "C", "J"].includes(event.key))
    ) {
      event.preventDefault();
    }
  }
}
