import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { SwipeBoard } from "src/app/models/swipeBoard";
import { SwipedAd } from "src/app/models/swipedAd";
import { Video } from "src/app/models/video";
import { ApiService } from "src/app/services/api/api.service";
import { PopupService } from "src/app/services/shared/popup.service";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-video-swipes",
  templateUrl: "./video-swipes.component.html",
  styleUrls: ["../../swipes.component.scss"],
})
export class VideoSwipesComponent implements OnChanges {
  constructor(
    private apiService: ApiService,
    private stateService: StateService,
    private popupService: PopupService
  ) {}

  @Input() keyword: string = "";
  @Input() isAffiliate: boolean = false;
  @Input() selectedBoardId: number = 0;
  @Input() swipeBoards: SwipeBoard[] = [];
  @Input() shareBoardId: string = "";
  @Output() boardsSelected: EventEmitter<object> = new EventEmitter<object>();
  @Output() deleteSwipe: EventEmitter<object> = new EventEmitter<object>();
  swipes: Video[] = [];
  filteredSwipes: Video[] = [];

  currentIndex: number = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["selectedBoardId"] ||
      changes["keyword"] ||
      changes["isAffiliate"] ||
      changes["shareBoardId"]
    )
      this.LoadSwipes();
  }

  public async LoadSwipes() {
    let result;
    if (!this.shareBoardId)
      result = await this.apiService.GetSwipedVideos(
        this.isAffiliate,
        this.keyword,
        this.selectedBoardId
      );
    else
      result = await this.apiService.GetSharedBoardVideos(
        this.isAffiliate,
        this.keyword,
        this.shareBoardId
      );

    if (result?.errorMessage) {
      this.popupService.Notify("Error loading swipes");
    }

    if (result?.data?.results) {
      this.swipes = result.data.results;
      // this.filteredSwipes = [...this.swipes];
      this.filteredSwipes = [];
      this.currentIndex = 0;
      this.DisplayIncrementally();
    }
  }

  DisplayIncrementally(): void {
    if (this.currentIndex < this.swipes.length) {
      this.filteredSwipes.push(this.swipes[this.currentIndex]);
      this.currentIndex++;
      setTimeout(() => this.DisplayIncrementally(), 10); // Adjust the timeout as needed
    }
  }

  public OpenSelectedSwipe(swipe: Video) {
    this.popupService.openVideoInfoModal(
      swipe.ytVideoId,
      () => {},
      () => this.LoadSwipes(),
      () => {},
      () => {}
    );
  }

  public BoardsSelected(boardIds: number[], ytVideoId: string) {
    this.boardsSelected.emit({ boardIds, ytVideoId });
  }

  public async DeleteSwipe(ytVideoId: string) {
    const result = await this.apiService.RemoveSwipedVideo(ytVideoId);

    if (result?.errorMessage) {
      this.popupService.Notify(result.errorMessage);
      return;
    }

    this.popupService.Notify("Video successfuly removed from Swipes.");
    this.LoadSwipes();
  }

  public onInViewport(event: { index: number; isInViewport: boolean }): void {
    this.filteredSwipes[event.index].isInViewport = event.isInViewport;
  }
}
