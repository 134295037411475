<div class="info-section-main">
    <div class="stat-heading mb-2">
        Offer info
    </div>
    <div class="underline mb-2"></div>
    <div *ngIf="networkName" class="stat-heading">Network:
        <span class="font-weight-400">{{networkName}}</span>
    </div>
    <div *ngIf="activeAdsNum" class="stat-heading">Active ads:
        <span class="font-weight-400">{{activeAdsNum}}</span>
    </div>
    <div *ngIf="advertisersNum" class="stat-heading">
        Advertisers:
        <span class="font-weight-400">{{advertisersNum}}</span>
    </div>
    <div *ngIf="primaryLanguage" class="stat-heading">
        Primary language:
        <span class="font-weight-400">{{primaryLanguage}}</span>
    </div>
</div>