import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { StateService } from "src/app/services/shared/state.service";

@Component({
  selector: "app-preview",
  templateUrl: "./preview.component.html",
  styleUrls: ["./preview.component.scss"],
})
export class PreviewComponent implements OnInit {
  companyId: number = 0;
  brandId: number = 0;
  displayMode: string = "";
  currentRoute: string = "";
  isPayingUser: boolean = false;
  isAffiliate: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public stateService: StateService,
    public dialogRef: MatDialogRef<PreviewComponent>
  ) {
    this.companyId = data.companyId;
    this.brandId = data.brandId;
    this.displayMode = data.displayMode;
    this.currentRoute = data.currentRoute;
    this.isPayingUser = data.isPayingUser;
    this.isAffiliate = data.isAffiliate;
  }

  ngOnInit(): void {
    this.SubscribeToUser();
  }

  public SubscribeToUser() {
    this.stateService.user$.subscribe((user) => {
      this.isPayingUser = this.stateService.IsPayingUserState(user);
    });
  }

  public CloseModal(event: boolean) {
    this.dialogRef.close(event);
  }
}
