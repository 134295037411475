import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { StateService } from "src/app/services/shared/state.service";
import { PopupService } from "src/app/services/shared/popup.service";
import firebase from "firebase/compat/app";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["../login/login.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  constructor(
    public fb: FormBuilder,
    public activatedRoute: ActivatedRoute,
    public popupService: PopupService,
    public router: Router,
    private stateService: StateService
  ) {}

  resetForm!: FormGroup;
  email: string = "";
  recaptchaVerifier!: firebase.auth.RecaptchaVerifier;
  emailCode: string = "";
  ngOnInit(): void {
    this.InitializeNewPasswordForm();
    this.InitializeRecaptcha();
  }

  private InitializeNewPasswordForm() {
    this.resetForm = this.fb.group({
      newPassword: ["", Validators.required],
      repeatPassword: ["", Validators.required],
    });
  }

  public async ResetPasswordAndSignIn() {
    const data = this.resetForm.getRawValue();
    if (data.newPassword != data.repeatPassword) {
      this.popupService.Notify("Passwords do not match!");
      return;
    }
    try {
      this.emailCode = this.activatedRoute.snapshot.queryParams.oobCode;
      await this.stateService.ConfirmPasswordReset(
        this.emailCode,
        data.newPassword
      );

      setTimeout(() => {
        this.router.navigate([""]);
      }, 300);
    } catch (error) {
      this.popupService.Notify(error as string);
    }
  }

  private InitializeRecaptcha() {
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      { size: "normal" }
    );
    this.recaptchaVerifier.render();
  }
}
