<mat-form-field appearance="outline" class="no-line">
    <mat-select class="select" placeholder="Interval" (ngModelChange)="IntervalSelected($event)"
        [(ngModel)]="selectedInterval" disableOptionCentering>
        <mat-option value="last30Days">
            Ad spend 30
        </mat-option>
        <mat-option value="last90Days">
            Ad spend 90
        </mat-option>
    </mat-select>
</mat-form-field>